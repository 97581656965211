import { BASE_URI } from "./config";
import { getAccessToken } from "../utils/auth";

/* ESG TOPICS */

export const listClientAllEsgTopicsRequest = (clientId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/questions`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}

export const listClientEsgTopicAllCommentsRequest = (clientId, questionId, queryParams) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/questions/${questionId}?${queryParams}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}

export const listClientInvestorEsgTopicsRequest = (clientId, investorId, queryParams) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors/${investorId}/questions?${queryParams}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}

export const getClientInvestorEsgTopicRequest = (clientId, investorId, questionId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors/${investorId}/questions/${questionId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}

export const listClientProxyAdvisorEsgTopicsRequest = (clientId, proxyAdvisorId, queryParams) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/proxy-advisors/${proxyAdvisorId}/questions?${queryParams}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}

export const getClientProxyAdvisorEsgTopicRequest = (clientId, proxyAdvisorId, questionId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/proxy-advisors/${proxyAdvisorId}/questions/${questionId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}

/* ESG TOPICS */