import { Fragment } from 'react';
import styles from './RadioButtonGroup.module.css';

const RadioButtonGroup = (props) => {
  const radioButtons = props.items.map(item => {
    return (
      <Fragment key={`radio-button-${item['id']}`}>
        <div className={styles['radio-button']}>
          <input type='radio' id={item['id']} name={props.name} value={item['value']} onChange={item['onChange']} checked={item['id'] === props.selected} />
          <label htmlFor={item['id']}>{item['text']}</label>
          
          { item['icon'] &&
            <div className={styles['icon']}>
              <div className={styles[item['icon']]}></div>
            </div>
          }

        </div>
        <div className={styles['item-separator']}></div>
      </Fragment>
    );
  })

  return (
    <div className={styles['radio-button-group']}>
      {radioButtons}
    </div>
  );
}

export default RadioButtonGroup;
