import { BASE_URI } from "./config";
import { getAccessToken } from "../utils/auth";


export const listClientsRequest = () => {
  return {
    url: `${BASE_URI}/clients`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const createClientFromClientSetupRequest = (body) => {
  return {
    url: `${BASE_URI}/clients`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const fetchClientDetailsRequest = (clientId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const fetchClientBasicProfileRequest = (clientId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/profile`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const uploadClientProfilePictureRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/profile-picture`,
    options: {
      method: 'POST',
      body: body,
      dataType: 'FormData',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const updateClientGeneralInformationRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/general-information`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateClientSubscriptionDetailsRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/subscription-details`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateClientUserManagementRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/users`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const resetClientUserPasswordRequest = (clientId, userId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/users/${userId}`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateClientProxyAdvisorsRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/proxy-advisors`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateClientInvestorListRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const fetchClientInvestorsRequest = (clientId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const fetchClientProxyAdvisorsRequest = (clientId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/proxy-advisors`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const fetchClientInvestorDetailsRequest = (clientId, investorId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors/${investorId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const uploadClientInvestorProfilePictureRequest = (clientId, investorId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors/${investorId}/profile-picture`,
    options: {
      method: 'POST',
      body: body,
      dataType: 'FormData',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const updateClientInvestorRequest = (clientId, investorId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors/${investorId}`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const fetchClientProxyAdvisorDetailsRequest = (clientId, proxyAdvisorId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/proxy-advisors/${proxyAdvisorId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const updateClientProxyAdvisorRequest = (clientId, proxyAdvisorId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/proxy-advisors/${proxyAdvisorId}`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const listClientInvestorContactsRequest = (clientId, investorId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors/${investorId}/contacts`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const createClientInvestorContactsRequest = (clientId, investorId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors/${investorId}/contacts`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateClientInvestorContactRequest = (clientId, investorId, contactId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors/${investorId}/contacts/${contactId}`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const uploadClientInvestorContactProfilePictureRequest = (clientId, investorId, contactId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors/${investorId}/contacts/${contactId}/profile-picture`,
    options: {
      method: 'POST',
      body: body,
      dataType: 'FormData',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const deleteClientInvestorContactRequest = (clientId, investorId, contactId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/investors/${investorId}/contacts/${contactId}`,
    options: {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const listClientProxyAdvisorContactsRequest = (clientId, proxyAdvisorId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/proxy-advisors/${proxyAdvisorId}/contacts`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const createClientProxyAdvisorContactsRequest = (clientId, proxyAdvisorId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/proxy-advisors/${proxyAdvisorId}/contacts`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateClientProxyAdvisorContactRequest = (clientId, proxyAdvisorId, contactId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/proxy-advisors/${proxyAdvisorId}/contacts/${contactId}`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const uploadClientProxyAdvisorContactProfilePictureRequest = (clientId, proxyAdvisorId, contactId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/proxy-advisors/${proxyAdvisorId}/contacts/${contactId}/profile-picture`,
    options: {
      method: 'POST',
      body: body,
      dataType: 'FormData',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const deleteClientProxyAdvisorContactRequest = (clientId, proxyAdvisorId, contactId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/proxy-advisors/${proxyAdvisorId}/contacts/${contactId}`,
    options: {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}
