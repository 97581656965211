import { useEffect, useState } from 'react';
import { getClientInvestorEsgTopicRequest, listClientInvestorEsgTopicsRequest } from '../../../../requests/client-esg-topic-requests';
import { getAuthClientId } from '../../../../utils/auth';
import { generateInvestorTopicsDocument } from '../../../../utils/word-export-helpers/investor-topics-export-helpers';
import { getSquareWellFileRequest } from '../../../../requests/squarewell-requests';
import { handleRequestError } from '../../../../utils/request-error-helpers';
import useHttp from '../../../../hooks/use-http';

import EsgTopicsContainer from '../../../../components/EsgTopicsContainer/EsgTopicsContainer';

import styles from './InvestorEsgTopics.module.css'

const InvestorEsgTopics = (props) => {

  const clientId = getAuthClientId();
  const investorId = props.investorId;

  const [esgTopicsData, setEsgTopicsData] = useState([]);
  const [isLoading, error, sendRequest] = useHttp();

  useEffect(() => {
    const queryParams = '';
    sendRequest(
      listClientInvestorEsgTopicsRequest(clientId, investorId, queryParams),
      (data) => { setEsgTopicsData(data['items']); }
    );
  }, [investorId, clientId, sendRequest]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const fetchInvestorQuestionComment = (questionId, setComment, setIsOpened) => {
    sendRequest(
      getClientInvestorEsgTopicRequest(clientId, investorId, questionId),
      (data) => { setComment(data['comment']); setIsOpened(true); }
    );
  }

  const exportSelectedSubTopicComments = (selectedSubtopic) => {
    const questionIds = selectedSubtopic['questions'].map(item => item['id']).join(',');
    const queryParams = `include_comments=true&question_ids=${questionIds}`
    sendRequest(
      listClientInvestorEsgTopicsRequest(clientId, investorId, queryParams),
      (data) => { 
        if(data['items'].length > 0 && data['items'][0]['subtopics'].length > 0) {
          sendRequest(
            getSquareWellFileRequest({'path': props.investorProfilePicture}),
            (profilePictureData) => {
              generateInvestorTopicsDocument(
                props.investorName,
                profilePictureData,
                'investor',
                data['items'][0]['name'],
                data['items'][0]['subtopics'][0]['name'],
                data['items'][0]['subtopics'][0]['questions']
              );
            }
          );
        }
      }
    );
  }


  return (
    <div className={styles['investor-esg-topics-container']}>
      <EsgTopicsContainer 
        esgTopics={esgTopicsData}
        investorType='investor'
        onShowQuestionCommenClick={fetchInvestorQuestionComment}
        onExportSubtopicsCommentsClick={exportSelectedSubTopicComments}
      />
    </div>
  );
}

export default InvestorEsgTopics;
