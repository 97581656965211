import { 
  BORDER_DARK_BLUE, BORDER_DARK_GRAY, BORDER_LIGHT_BLUE, BORDER_NONE, COLOR_BLACK, COLOR_DARK_BLUE, 
  COLOR_DARK_GRAY, COLOR_LIGHT_BLUE, COLOR_LIGHT_GRAY, COLOR_MEDIUM_GRAY, COLOR_WHITE, FONT_MONTSERRAT_REGULAR, 
  FONT_MONTSERRAT_SEMI_BOLD, LINE_BREAK, convertTextFromTopicCommentHTML, generateFooter, generateHeader, generateSection 
} from './commons';

import { saveAs } from 'file-saver';
import { 
  Document, HeightRule, ImageRun, Packer, Paragraph, ShadingType, Table, TableCell, TableRow, TextRun,
  VerticalAlign, WidthType 
} from 'docx';


export const generateOtherNoteWordDocument = async (
  clientName, clientType, clientProfilePictureBlob, investorName, investorType,
  numberOfShares, noteHeader, noteBody, noteAttachments, createdBy, createdAt, 
  updatedBy, updatedAt
) => {
  const header = await generateHeader('other-note');
  const footer = generateFooter(investorName);

  const clientNameTitle = generateClientNameTitle(clientName);
  const clientLogoImage = await generateClientLogoImage(clientProfilePictureBlob);
  const investorNameAndHeaderTitle = generateInvestorNameTitle(investorName, noteHeader);

  const numberOfSharesTable = generateNumberOfSharesTable(numberOfShares);
  const notesTable = generateNotesTable(noteBody);
  const attachmentsTable = generateAttachmentsTable(noteAttachments.length > 0 ? noteAttachments.map(item => item['attachment_name']) : ['-']);
  const createdByTable = generateCreatedByTable(createdBy, createdAt);
  const editedByTable = generateEditedByTable(updatedBy, updatedAt);
  const ownerTable = generateOwnerTable(createdByTable, editedByTable);
  
  const clientNameSection = generateSection(header, footer, [clientNameTitle, LINE_BREAK]);
  const clientLogoSection = generateSection(header, footer, [clientLogoImage]);
  const fileTitleSection = generateSection(header, footer, [investorNameAndHeaderTitle, LINE_BREAK]);
  const investorDetailsSection = generateSection(header, footer, [numberOfSharesTable]);
  const notesSection = generateSection(header, footer, [notesTable]);
  const attachmentsSection = generateSection(header, footer, [attachmentsTable]);
  const ownerSection = generateSection(header, footer, [ownerTable, LINE_BREAK]);
  
  const doc = new Document({
    creator: 'AQTION',
    description: 'AQTION Other Note',
    title: 'AQTION Other Note',
    sections: 
      [clientNameSection, clientLogoSection, fileTitleSection]
        .concat(clientType === 'Corporate' ? [investorDetailsSection] : [])
        .concat([notesSection, attachmentsSection, ownerSection])
  });
  
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `AQTION – Other Note - ${investorName}.docx`);
  });
  
}

const generateClientNameTitle = (clientName) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: clientName,
        color: '6C6C6C',
        font: FONT_MONTSERRAT_SEMI_BOLD,
        size: 36
      })
    ]
  });
}

const generateClientLogoImage = async (clientProfilePictureBlob) => {
  return new Paragraph({
    children: [
      new ImageRun({
        data: await clientProfilePictureBlob,
        transformation: {
          width: 88,
          height: 88
        }
      })
    ]
  })
}

const generateInvestorNameTitle = (investorName, noteHeader) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: `${investorName} – ${noteHeader}`,
        color: COLOR_BLACK,
        font: FONT_MONTSERRAT_SEMI_BOLD,
        size: 36
      })
    ]
  })
}

const generateNumberOfSharesTable = (numberOfShares) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    margins: {
      top: 80,
      right: 0,
      bottom: 80,
      left: 80
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 35,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_DARK_BLUE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: '# of Shares',
                    color: COLOR_WHITE,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 22
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_BLUE,
              right: BORDER_DARK_BLUE,
              bottom: BORDER_DARK_BLUE,
              left: BORDER_DARK_BLUE
            }
          }),
          new TableCell({
            width: {
              size: 65,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: `${numberOfShares}`,
                    color: COLOR_BLACK,
                    font: FONT_MONTSERRAT_REGULAR,
                    size: 20
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_BLUE,
              right: BORDER_DARK_BLUE,
              bottom: BORDER_DARK_BLUE,
              left: BORDER_DARK_BLUE
            }
          }),
        ]
      })
    ]
  });
}

const generateNotesTable = (noteBody) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    margins: {
      top: 80,
      right: 0,
      bottom: 80,
      left: 80
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_LIGHT_BLUE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: 'Notes',
                    color: COLOR_WHITE,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 22
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_LIGHT_BLUE,
              right: BORDER_LIGHT_BLUE,
              bottom: BORDER_LIGHT_BLUE,
              left: BORDER_LIGHT_BLUE
            }
          })
        ]
      }),
      new TableRow({
        height: {
          value: 4000,
          rule: HeightRule.ATLEAST
        },
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.TOP,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: convertTextFromTopicCommentHTML(noteBody),
            borders: {
              top: BORDER_LIGHT_BLUE,
              right: BORDER_LIGHT_BLUE,
              bottom: BORDER_LIGHT_BLUE,
              left: BORDER_LIGHT_BLUE
            }
          })
        ]
      })
    ]
  });
}

const generateAttachmentsTable = (attachmentList) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    margins: {
      top: 80,
      right: 0,
      bottom: 80,
      left: 80
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 25,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_DARK_GRAY,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: 'Attachment(s)',
                    color: COLOR_BLACK,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 22
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          }),
          new TableCell({
            width: {
              size: 75,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: attachmentList.join(', '),
                    color: COLOR_BLACK,
                    font: FONT_MONTSERRAT_REGULAR,
                    size: 20
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          }),
        ]
      })
    ]
  });
}

const generateCreatedByTable = (createdBy, createdAt) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    margins: {
      top: 80,
      right: 0,
      bottom: 80,
      left: 80
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 2,
            shading: {
              color: COLOR_LIGHT_GRAY,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: 'Created',
                    color: COLOR_BLACK,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 16
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          })
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 15,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: 'By',
                    color: COLOR_MEDIUM_GRAY,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 20
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          }),
          new TableCell({
            width: {
              size: 85,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: createdBy,
                    color: COLOR_MEDIUM_GRAY,
                    font: FONT_MONTSERRAT_REGULAR,
                    size: 20
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          }),
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 15,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: 'At',
                    color: COLOR_MEDIUM_GRAY,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 20
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          }),
          new TableCell({
            width: {
              size: 85,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: createdAt,
                    color: COLOR_MEDIUM_GRAY,
                    font: FONT_MONTSERRAT_REGULAR,
                    size: 20
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          }),
        ]
      })
    ]
  });
}

const generateEditedByTable = (updatedBy, updatedAt) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    margins: {
      top: 80,
      right: 0,
      bottom: 80,
      left: 80
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 2,
            shading: {
              color: COLOR_LIGHT_GRAY,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: 'Edited',
                    color: COLOR_BLACK,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 16
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          })
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 15,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: 'By',
                    color: COLOR_MEDIUM_GRAY,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 20
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          }),
          new TableCell({
            width: {
              size: 85,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: updatedBy,
                    color: COLOR_MEDIUM_GRAY,
                    font: FONT_MONTSERRAT_REGULAR,
                    size: 20
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          }),
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 15,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: 'At',
                    color: COLOR_MEDIUM_GRAY,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 20
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          }),
          new TableCell({
            width: {
              size: 85,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: updatedAt,
                    color: COLOR_MEDIUM_GRAY,
                    font: FONT_MONTSERRAT_REGULAR,
                    size: 20
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_GRAY,
              right: BORDER_DARK_GRAY,
              bottom: BORDER_DARK_GRAY,
              left: BORDER_DARK_GRAY
            }
          }),
        ]
      })
    ]
  });
}

const generateOwnerTable = (createdByTable, editedByTable) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    margins: {
      top: 80,
      right: 0,
      bottom: 80,
      left: 0
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 49,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.TOP,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              createdByTable
            ],
            borders: {
              top: BORDER_NONE,
              right: BORDER_NONE,
              bottom: BORDER_NONE,
              left: BORDER_NONE
            }
          }),
          new TableCell({
            width: {
              size: 2,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.TOP,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [],
            borders: {
              top: BORDER_NONE,
              right: BORDER_NONE,
              bottom: BORDER_NONE,
              left: BORDER_NONE
            }
          }),
          new TableCell({
            width: {
              size: 49,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.TOP,
            shading: {
              color: COLOR_WHITE,
              type: ShadingType.SOLID,
            },
            children: [
              editedByTable
            ],
            borders: {
              top: BORDER_NONE,
              right: BORDER_NONE,
              bottom: BORDER_NONE,
              left: BORDER_NONE
            }
          }),
        ]
      })
    ]
  });
}