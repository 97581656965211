import { useEffect, useRef, useState } from 'react';

import IconButton from '../IconButton/IconButton';

import styles from './MultiSelectComboBox.module.css';


const MultiSelectComboBox = (props) => {
  const [showItems, setShowItems] = useState(props.alwaysShowItems ? true : false);
  const ref = useRef(null);

  const isDisabled = props.isDisabled ? true : false;
  const sizeStyle = props.size === undefined ? styles['base'] : styles[props.size];
  const errorStyle = props.hasError ? styles['error'] : '';
  const withLabelStyle = props.label !== undefined ? styles['with-label'] : '';

  const customStyle = {};
  if (props.width !== undefined) {
    customStyle['width'] = props.width
  }

  const inputChangeHandler = (event) => {
    if (props.onChange !== undefined) {
      props.onChange(event.target.value);
    }
  }


  const createSearchItems = () => {
    const items = [];
    const groupItemIds = {};
    props.itemGroups.forEach(itemGroup => {
      const filteredItems = itemGroup.items.filter(item => item['text'].toLowerCase().includes(props.value.toLowerCase()));
      if (filteredItems.length > 0){ 
        items.push({
          'id': itemGroup['id'],
          'text': itemGroup['title'],
          'type': 'group-header',
          'icon': itemGroup['icon']
        });
        groupItemIds[itemGroup['id']] = [];
      }

      filteredItems.forEach(item => {
        items.push({
          'id': item['id'],
          'text': item['text'],
          'type': 'group-item'
        });
        groupItemIds[itemGroup['id']].push(item['id']);
      });
    });

    const searchItems =  items.map(item => {
      if (item['type'] === 'group-header') {
        const currentGroupItem = props.itemGroups.find(groupItem => groupItem['id'] === item['id']);
        if(currentGroupItem['isClickable']) {
          const diff = groupItemIds[item['id']].filter(item => !props.selected.includes(item));
          let rowWidth = props.width ? props.width : 300;
          if (!props.hasHeaderCover) {
            rowWidth -= 32;
          }
          return(
            <div key={`search-item-${item['id']}`} className={`${styles['item']} ${styles[item['type']]}`}>
              <div className={styles['checkbox']}>
                <input type='checkbox' id={item['id']} value={item['value']} onChange={allItemsClickHandler} checked={diff.length === 0} />
                <label style={{'width': rowWidth}} htmlFor={item['id']}>{item['text']}</label>
              </div>
            </div>
          );
        }
        else {
          let rowWidth = props.width ? props.width : 276;
          if (!props.hasHeaderCover) {
            rowWidth -= 32;
          }
          return(
            <div key={`search-item-${item['id']}`} className={`${styles['item']} ${styles[item['type']]} ${styles['not-selectable']}`}>
              <div className={`${styles['icon']} ${item['icon'] ? styles[item['icon']] : ''}`}></div>
              <p style={{'width': rowWidth }}>{item['text']}</p>
            </div>
          )
        }
      }
      else {
        let rowWidth = props.width ? props.width : 300;
        if (!props.hasHeaderCover) {
          rowWidth -= 32;
        }
        return(
          <div key={`search-item-${item['id']}`} className={`${styles['item']} ${styles[item['type']]}`}>
            <div className={styles['checkbox']}>
              <input type='checkbox' id={item['id']} value={item['value']} onChange={() => props.onItemClick(item['id'])} checked={props.selected.includes(item['id'])} />
              <label style={{'width': rowWidth }} htmlFor={item['id']}>{item['text']}</label>
            </div>
          </div>
        );
      }
    });

    return searchItems;
  }

  const allItemsClickHandler = () => {
    props.onAllItemsClick();
  }

  const addCustomClickHandler = () => {
    props.onAddCustomClick(props.value);
    setShowItems(false);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if(!ref.current.contains(event.target)) {
        if(!props.alwaysShowItems) {
          setShowItems(false);
        }

        if(props.onClickOutside !== undefined) {
          props.onClickOutside();
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, props]);

  const createdSearchItems = createSearchItems();

  return (
    <div id={props.id} className={`${styles['input-container']} ${withLabelStyle}`} ref={ref} style={customStyle}>
      { props.label && <label className={styles['input-label']}>{props.label}</label> }
      <div className={styles['input-wrapper']}>
        <input
          id={`${props.id}-search-area`} 
          className={`${styles['text-input']} ${sizeStyle} ${errorStyle}`}
          placeholder={props.placeholder}
          value={props.value}
          disabled={isDisabled}
          onChange={inputChangeHandler}
          onFocus={() => setShowItems(true)}
          autoFocus={props.autoFocus ? true : false}
        />
        { props.hasSearchIcon &&
          <div className={styles['search-icon']}></div>
        }

        { props.hasAddCustomButton && props.value !== '' &&
          <div className={styles['add-button']}>
            <IconButton type={"secondary"} icon={"plus"} size={"xsmall"} onClick={addCustomClickHandler} />
          </div>
        }
      </div>

      { showItems && props.hasHeaderCover &&
        <div className={styles['search-list']}>
          <div className={`${styles['item-container']} ${sizeStyle} ${styles['with-header-cover']}`}>
            <div className={styles['temporary-header']}></div>
            <div className={styles['matched-items']}>
              {createdSearchItems}
            </div>
          </div>
        </div>
      }

      { showItems && !props.hasHeaderCover && createdSearchItems.length > 0 &&
        <div className={styles['search-list']}>
          <div className={`${styles['item-container']} ${sizeStyle} ${styles['without-header-cover']}`}>
            <div className={styles['matched-items']}>
              {createdSearchItems}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default MultiSelectComboBox;