import { Fragment, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useResizeDetector } from "react-resize-detector";
import { clientMenuItems } from "../../../utils/constants";
import { activistChartColors, agmParticipationChartColors, distributionMapCountries, distributionMapCountryIndices, esgFocusChartColors, esgMainServiceProvidersChartColors, investorTypeChartColors, levelOfInsightChartColors, proxyAdvisorsChartColors, proxyAdvisorsRelianceChartColors, receptivityToActivistsChartColors, votingDecisionMakerChartColors} from "../../../utils/dashboard-chart-helpers";
import { fetchClientBasicProfileRequest, fetchClientInvestorsRequest } from "../../../requests/client-requests";
import { checkIsAccountManagerViewMode, getAuthClientId, getAuthClientName, getAuthUserProfilePictureUrl, getClientAvailableModules, getClientType, setLocalStorageClientData } from "../../../utils/auth";
import { Tooltip } from "react-tooltip";
import { getPageStyle, updatePageStyle } from "../../../utils/helpers";
import { handleRequestError } from "../../../utils/request-error-helpers";
import useHttp from '../../../hooks/use-http';
import sortArray from 'sort-array';

import AccountManagerModeHeader from "../../../components/AccountManagerModeHeader/AccountManagerModeHeader";
import SideNavigationBar from "../../../components/UI/SideNavigationBar/SideNavigationBar";

import BarChart from "../../../components/Chart/BarChart/BarChart";
import DonutChart from "../../../components/Chart/DonutChart/DonutChart";
import DistributionMap from "../../../components/Chart/DistributionMap/DistributionMap";
import HalfDonutChart from "../../../components/Chart/HalfDonutChart/HalfDonutChart";
import HorizontalBarChart from "../../../components/Chart/HorizontalBarChart/HorizontalBarChart";
// import PieChart from "../../../components/Chart/PieChart/PieChart";

import styles from './DashboardPage.module.css';

const DashboardPage = (props) => {

  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  const [searchParams, setSearchParams] = useSearchParams();
  const pageHeightMargin = checkIsAccountManagerViewMode() ? '161px' : '95px';
  /* eslint-disable no-unused-vars */

  const clientId = getAuthClientId();
  const clientAvailableModules = getClientAvailableModules();
  const userProfilePictureUrl = getAuthUserProfilePictureUrl();

  const [isLoading, error, sendRequest] = useHttp();

  const [clientName, setClientName] = useState(null);
  const [clientType, setClientType] = useState(getClientType());
  const [clientTotalNumOfShares, setClientTotalNumOfShares] = useState(null);
  const [clientInvestors, setClientInvestors] = useState(null);
  const [clientInvestorListLastUpdatedAt, setClientInvestorListLastUpdatedAt] = useState(null);
  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);

  const [aqtionBannerData, setAqtionBannerData] = useState(null);
  const [summaryChartData, setSummaryChartData] = useState(null);

  const [investorTypeChartData, setInvestorTypeChartData] = useState(null);
  const [levelOfInsightChartData, setLevelOfInsightChartData] = useState(null);
  const [topInvestorMapData, setTopInvestorMapData] = useState(null);
  const [topInvestorListData, setTopInvestorListData] = useState(null);

  const [agmParticipationChartData, setAGMParticipationChartData] = useState(null);
  const [votingDecisionMakerChartData, setVotingDecisionMakerChartData] = useState(null);
  const [proxyAdvisorsRelianceChartData, setProxyAdvisorsRelianceChartData] = useState(null);

  const [activistChartData, setActivistChartData] = useState(null);
  const [receptivityToActivistsChartData, setReceptivityToActivistsChartData] = useState(null);
  const [esgFocusChartData, setEsgFocusChartData] = useState(null);

  const [proxyAdvisorsChartData, setProxyAdvisorsChartData] = useState(null);
  const [esgMainServiceProvidersChartData, setESGMainServiceProvidersChartData] = useState(null);

  const countBy = (arr, fn) => {
    return arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val, i) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {});
  }

  const sumUpShares = (arr, fn) => {
    return arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val, i) => {
      acc[val[0]] = (acc[val[0]] || 0) + val[1];
      return acc;
    }, {});
  }

  const kebabCase = string => string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

  const generateTopInvestorsMapData = useCallback((investors) => {
    const topFiveCountries = {};
    const countryCount = countBy(investors.map(item => item['general_information']['country']), value => value);
    const countValues = Object.keys(countryCount).map(key => [key, countryCount[key]]);
    countValues.sort((first, second) => second[1] - first[1]).slice(0, 5).forEach(([key, value]) => {
      topFiveCountries[key] = value;
    });

    const allCountries = JSON.parse(JSON.stringify(distributionMapCountries));
    Object.entries(topFiveCountries).forEach(([key, _]) => {
      allCountries[distributionMapCountryIndices[key]]['value'] = 1; // Using `1` to make everything same color.
    });

    const topInvestors = Object.keys(topFiveCountries).map(key => {
      return {
        'name': key,
        'countryCode': allCountries[distributionMapCountryIndices[key]]['code']
      }
    });

    const topInvestorMapData = {
      'locations': allCountries.map(country => country["location"]),
      'values': allCountries.map(country => country["value"]),
      'names': allCountries.map(country => country["name"]),
      'topInvestors': topInvestors
    };

    return topInvestorMapData;
  }, []);

  
  const generateChartData = useCallback((investors, sectionKey, valueKey, allChartColors, isSorted, totalNumOfInvestors = null) => {
    const allValues = Object.entries(allChartColors).map(([key, _]) => key);
    const availableValues = new Set(investors.map(item => item[sectionKey][valueKey])); 
    const valueCount = countBy(investors.map(item => item[sectionKey][valueKey]), value => value);
    const valueNumOfShares = sumUpShares(investors.map(item => [item[sectionKey][valueKey], item['num_of_shares']]), value => value);

    let investorCount = 0;
    if(totalNumOfInvestors === null) {
      investorCount = investors.length;
    }
    else {
      investorCount = totalNumOfInvestors;
    }

    const chartItems = 
      allValues
        .map(item => availableValues.has(item) ? 
          {
            'id': kebabCase(item),
            'name': item,
            'value': valueCount[item],
            'percentage': (clientType === 'Corporate') ? 
              ((valueNumOfShares[item] / clientTotalNumOfShares) * 100).toFixed(1) : 
              ((valueCount[item] / investorCount) * 100).toFixed(1),
            'color': allChartColors[item]
          } : null
        ).filter(item => item !== null);
      
    if(isSorted){
      chartItems.sort((first, second) => {
        if(parseFloat(first.percentage) >= parseFloat(second.percentage)) {
          return -1;
        }
        else {
          return 1;
        }
      });
    }

    const chartColors = chartItems.map(item => allChartColors[item['name']]);
    return {
      'colors': chartColors,
      'items': chartItems
    };
  }, [clientTotalNumOfShares, clientType]);


  const generateTopInvestorsList = useCallback(() => {
    return topInvestorListData.map((item, index) => {
      return (
        <div className={styles['item']} key={`top-investor-${item['id']}`}>
          <div className={styles['logo']} style={{backgroundImage:`url("${item['profile_picture_url']}")`}}></div>
          <div className={styles['name']}>
            <h4>{index + 1}. </h4><a href={`/investors/${item['id']}`}>{item['name']}</a>
          </div>
          <div className={styles['isc']}>
            <h4>{((item['num_of_shares'] / clientTotalNumOfShares) * 100).toFixed(1)}%</h4>
          </div>
        </div>
      )
    });
  }, [clientTotalNumOfShares, topInvestorListData]);

  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (dataClient) => {
        setLocalStorageClientData(dataClient);
        if(dataClient['module_management']['dashboard']) {
          setClientName(dataClient['name']);
          setClientType(dataClient['client_type']);
          setClientTotalNumOfShares(dataClient['total_num_of_shares']);
          setClientInvestorListLastUpdatedAt(dataClient['investor_list_last_updated_at']);
          setClientProfilePictureUrl(dataClient['profile_picture_url']);

          sendRequest(
            fetchClientInvestorsRequest(clientId),
            (dataInvestors) => { 
              const investors = sortArray(dataInvestors['items'], {
                'by': ['num_of_shares'],
                'order': ['desc']
              });
              setClientInvestors(investors);
            }
          );
        }
        else {
          window.location.href = 'investors';
        }
      }
    );
  }, [sendRequest, clientId]);
        
  useEffect(() => {
    if(clientInvestors !== null && clientName !== null && clientTotalNumOfShares !== null) {
      const investors = clientInvestors;

      let coveredTotalNumOfShares = 0;
      let aqtionSquareWellInvestors = 0;
      let aqtionSquareWellInvestorShares = 0;
      let aqtionCustomInvestors = 0;
      let aqtionCustomInvestorShares = 0;
      investors.forEach(item => {
        coveredTotalNumOfShares += item['num_of_shares'];
        if(item['is_custom']) {
          aqtionCustomInvestors += 1;
          aqtionCustomInvestorShares += item['num_of_shares'];
        }
        else {
          aqtionSquareWellInvestors += 1;
          aqtionSquareWellInvestorShares += item['num_of_shares'];
        }
      });
      
      setAqtionBannerData({
        'client_name': clientName,
        'num_of_top_investors': (aqtionSquareWellInvestors + aqtionCustomInvestors),
        'sqw_share_total': aqtionSquareWellInvestorShares,
        'custom_share_total': aqtionCustomInvestorShares,
        'other_share_total': (clientTotalNumOfShares - coveredTotalNumOfShares),
        'aqtion_share_representation': ((coveredTotalNumOfShares / clientTotalNumOfShares) * 100).toFixed(1),
        'sqw_investor_total': aqtionSquareWellInvestors,
        'custom_investor_total': aqtionCustomInvestors
      });

      const sqwCoveragePercentage = ((aqtionSquareWellInvestorShares / clientTotalNumOfShares) * 100);
      const customCoveragePercentage = ((aqtionCustomInvestorShares / clientTotalNumOfShares) * 100);
      const otherCoveragePercentage = (((clientTotalNumOfShares - coveredTotalNumOfShares) / clientTotalNumOfShares) * 100);
      setSummaryChartData({
        'colors': ['#00263D', '#FF5959', '#FFC68A'],
        'items': [
          {'id': 'aqtion-investors', 'name': 'AQTION Investors', 'value': sqwCoveragePercentage, 'percentage': sqwCoveragePercentage.toFixed(1), 'color': '#00263D'},
          {'id': 'custom-investors', 'name': 'Custom Investors', 'value': customCoveragePercentage, 'percentage': customCoveragePercentage.toFixed(1), 'color': '#FF5959'},
          {'id': 'other-investors', 'name': 'Other (Outside Top Investors)', 'value': otherCoveragePercentage, 'percentage': otherCoveragePercentage.toFixed(1), 'color': '#FFC68A'}
        ]
      });
      
      // Generate Graph - Investor Type
      setInvestorTypeChartData(
        generateChartData(investors, 'general_information', 'investor_type', investorTypeChartColors, true)
      );

      // Generate Graph - Level of Insight
      setLevelOfInsightChartData(
        generateChartData(investors, 'general_information', 'level_of_insight', levelOfInsightChartColors, true)
      );
      
      setTopInvestorMapData(generateTopInvestorsMapData(investors));
      setTopInvestorListData(investors.slice(0, 5));
      
      // Generate Graph - AGM Participation
      setAGMParticipationChartData(
        generateChartData(investors, 'stewardship', 'voting', agmParticipationChartColors, true)
      )

      // Generate Graph - Voting Decision Maker
      setVotingDecisionMakerChartData(
        generateChartData(investors, 'stewardship', 'voting_decision_maker', votingDecisionMakerChartColors, true)
      );

      // Generate Graph - Proxy Advisors Reliance
      setProxyAdvisorsRelianceChartData(
        generateChartData(investors, 'stewardship', 'proxy_advisors_reliance', proxyAdvisorsRelianceChartColors, true)
      );

      // Generate Graph - Activist
      setActivistChartData(
        generateChartData(investors, 'activism', 'activist', activistChartColors, true)
      );

      // Generate Graph - Receptivity to Activist
      setReceptivityToActivistsChartData(
        generateChartData(investors, 'activism', 'receptivity_to_activists', receptivityToActivistsChartColors, true)
      );

      // Generate Graph - ESG Focus
      setEsgFocusChartData(
        generateChartData(investors, 'environment_and_social', 'esg_focus', esgFocusChartColors, true)
      );

      // Generate Graph - Proxy Advisor(s)
      const proxyAdvisorsTempList = [];
      investors.forEach(
        investor => {
          investor['stewardship']['proxy_advisors'].split(',').forEach(
            proxyAdvisor => {
              proxyAdvisorsTempList.push(
                {
                  'stewardship': {
                    'proxy_advisors': proxyAdvisor.trim()
                  },
                  'num_of_shares': investor['num_of_shares']
                }
              )
            }
          )
        }
      );
      setProxyAdvisorsChartData(
        generateChartData(proxyAdvisorsTempList, 'stewardship', 'proxy_advisors', proxyAdvisorsChartColors, true, investors.length)
      );

      // Generate Graph - ESG Main Service Providers
      const esgMainServiceProvidersTempList = [];
      investors.forEach(
        investor => {
          investor['esg_service_providers']['main_providers'].split(',').forEach(
            mainServiceProvider => {
              esgMainServiceProvidersTempList.push(
                {
                  'esg_service_providers': {
                    'main_providers': mainServiceProvider.trim()
                  },
                  'num_of_shares': investor['num_of_shares']
                }
              )
            }
          )
        }
      );
      setESGMainServiceProvidersChartData(
        generateChartData(esgMainServiceProvidersTempList, 'esg_service_providers', 'main_providers', esgMainServiceProvidersChartColors, true, investors.length)
      );
    }
  }, [clientInvestors, clientName, clientTotalNumOfShares, generateTopInvestorsMapData, generateChartData]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  return (
    <div className={styles['page']}>
      {checkIsAccountManagerViewMode() &&
        <AccountManagerModeHeader
          type={pageStyle === 'wide' ? 'wide' : 'narrow'}
          clientName={getAuthClientName()}
          exitRedirecUrl='../clients'
        />
      }
      
      <SideNavigationBar
        menuItems={clientMenuItems.filter(item => clientAvailableModules[item['module_id']])}
        profilePictureUrl={userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}
        selected='dashboard'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div
        className={`${styles['container']} ${styles[checkIsAccountManagerViewMode() ? 'with-account-manager-warning' : '']} ${styles[pageStyle]}`}
        style={{ height: `calc(100% - ${pageHeightMargin})`, overflowY: 'auto', overflowX: 'hidden' }}
        ref={ref}
      >
        <div className={styles['page-header']}>
          <div className={styles['section']}>
            <div className={styles['row']}>
              <div className={styles['title']}>
                <h1>Dashboard</h1>
              </div>
            </div>
          </div>
        </div>

        <div className={styles['page-body']}>
          {clientType === 'Corporate' && 
            <Fragment>
              <div className={styles['row']}>
                <div className={styles['graph-section-header']}>
                  <div className={styles['graph-section-title']}>
                    <h2>Summary</h2>
                  </div>
                  <div className={styles['graph-section-note']}>
                    { clientInvestorListLastUpdatedAt &&
                    <p>As of latest Investor List – {new Date(clientInvestorListLastUpdatedAt).toLocaleDateString('en-GB')}</p>
                    }
                  </div>
                </div>
              </div>

              <div className={styles['row']}>
                <div className={styles['dashboard-banner-container']}>
                  <div className={styles['dashboard-banner']}>
                    {aqtionBannerData && 
                      <div className={styles['highlight']}>
                        <h1>
                          Top {aqtionBannerData['num_of_top_investors']} Investors represent 
                          <span className={styles['higlighted-dark']}> {aqtionBannerData['aqtion_share_representation']}% </span>
                          of <span className={styles['dark']}>{aqtionBannerData['client_name']}</span> Total Issued Share Capital
                        </h1>
                      </div>
                    }

                    <div className={styles['cover-graph']}>
                      {summaryChartData && 
                        <HalfDonutChart
                          id='investor-type-chart'
                          width={290}
                          height={290}
                          data={summaryChartData}
                        />
                      }
                    </div>

                    {aqtionBannerData && 
                      <div className={styles['cover-stats']}>
                        <div className={styles['ratio']}>
                          <div className={styles['covered']}>
                            <h1>{aqtionBannerData['sqw_investor_total']}</h1>
                          </div>
                          <div className={styles['total']}>
                            <h1>&nbsp;/ {aqtionBannerData['num_of_top_investors']}</h1>
                          </div>
                        </div>
                        <div className={styles['description']}>
                          <p>AQTION covers {aqtionBannerData['sqw_investor_total']} out of your Top {aqtionBannerData['num_of_top_investors']} Investors</p>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Fragment>
          }

          <div className={styles['row']}>
            <div className={styles['graph-section-header']}>
              <div className={styles['graph-section-title']}>
                <h2>Top Investors Breakdown</h2>
              </div>
              {clientType === 'Corporate' && 
                <div className={styles['graph-section-note']}>
                  <p>In number of investors (% ISC)</p>
                </div>
              }
              {clientType !== 'Corporate' && 
                <div className={styles['graph-section-note']}>
                  <p>In number of investors (%)</p>
                </div>
              }
            </div>
          </div>

          <div className={styles['row']}>
            <div className={`${styles['graph-container']} ${styles['investor-type-graph-container']}`}>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  <h3>Investor Type</h3>
                </div>
                <div className={styles['tooltip-icon']} data-tooltip-id='investor-type-graph-tooltip'>
                  <Tooltip
                    id='investor-type-graph-tooltip'
                    place='bottom'
                    content='This graph depicts the distribution of investor types within your Top Investors (by #), such as asset managers, pension funds, hedge funds, etc. This visualization provides insight into the diversity of the composition of the investor base. Further information can be found in the glossary.'
                    className='aqtion-tooltip'
                  />
                </div>
              </div>
              <div className={styles['body']}>
                <div className={styles['graph']}>
                  {investorTypeChartData &&
                    <DonutChart
                      id='investor-type-chart'
                      width={212}
                      height={212}
                      data={investorTypeChartData}
                    />
                  }
                </div>
              </div>
            </div>

            <div className={`${styles['graph-container']} ${styles['level-of-insight-graph-container']}`}>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  <h3>Level of Insight</h3>
                </div>
                <div className={styles['tooltip-icon']} data-tooltip-id='level-of-insights-graph-tooltip'>
                  <Tooltip
                    id='level-of-insights-graph-tooltip'
                    place='bottom'
                    content='This chart categorizes the level of insight provided by your Top Investors (by #) based on the quantity and quality of disclosures available in the public domain as determined by AQTION. Further information can be found in the glossary.'
                    className='aqtion-tooltip'
                  />
                </div>
              </div>
              <div className={styles['body']}>
                <div className={styles['graph']}>
                  {levelOfInsightChartData && 
                    <HorizontalBarChart
                      id='activist-chart'
                      width={560}
                      height={20}
                      data={levelOfInsightChartData}
                    />
                  }
                </div>
              </div>
            </div>
          </div>

          <div className={styles['row']}>
            <div className={`${styles['graph-container']} ${styles['top-countries-graph-container']} ${clientType === 'Corporate' ? '' : styles['full-width']}`}>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  <h3>Top 5 Countries</h3>
                </div>
                <div className={styles['tooltip-icon']} data-tooltip-id='top-five-countries-graph-tooltip'> 
                  <Tooltip
                    id='top-five-countries-graph-tooltip'
                    place='bottom'
                    content={
                      (clientType === 'Corporate') ? 
                      'This map illustrates the top five countries, based on ownership, where your investors are headquartered.' : 
                      'This map illustrates the top five countries, based on number of investors, where your investors are headquartered.'
                    }
                    className='aqtion-tooltip'
                  />
                </div>
              </div>
              <div className={styles['body']}>
                {topInvestorMapData &&
                  <DistributionMap
                    width={650}
                    height={386}
                    data={topInvestorMapData}
                  />
                }
              </div>
            </div>

            {clientType === 'Corporate' && 
              <div className={`${styles['graph-container']} ${styles['top-investors-list-container']}`}>
                <div className={styles['header']}>
                  <div className={styles['title']}>
                    <h3>Top 5 Investors</h3>
                  </div>
                  <div className={styles['tooltip-icon']} data-tooltip-id='top-five-investors-graph-tooltip'>
                    <Tooltip
                      id='top-five-investors-graph-tooltip'
                      place='bottom'
                      content='This table shows your five largest investors.'
                      className='aqtion-tooltip'
                    />
                  </div>
                </div>
                <div className={styles['body']}>
                  {topInvestorListData && 
                    generateTopInvestorsList()
                  }
                </div>
              </div>
            }
          </div>

          <div className={styles['row']}>
            <div className={styles['graph-section-header']}>
              <div className={styles['graph-section-title']}>
                <h2>Stewardship</h2>
              </div>
            </div>
          </div>

          <div className={styles['row']}>
            <div className={`${styles['graph-container']} ${styles['agm-participation-graph-container']}`}>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  <h3>AGM Participation</h3>
                </div>
                <div className={styles['tooltip-icon']} data-tooltip-id='agm-participation-graph-tooltip'>
                  <Tooltip
                    id='agm-participation-graph-tooltip'
                    place='bottom'
                    content='This graph provides a breakdown of your Top Investors’ (by #) likelihood of participating at your upcoming general meetings as determined by AQTION. Further information can be found in the glossary.'
                    className='aqtion-tooltip'
                  />
                </div>
              </div>
              <div className={styles['body']}>
                <div className={styles['graph']}>
                  {agmParticipationChartData && 
                    <DonutChart
                      id='agm-participation-chart'
                      width={212}
                      height={212}
                      data={agmParticipationChartData}
                    />
                  }
                </div>
              </div>
            </div>

            <div className={`${styles['graph-container']} ${styles['proxy-advisors-graph-container']}`}>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  <h3>Proxy Advisor(s)</h3>
                </div>
                <div className={styles['tooltip-icon']} data-tooltip-id='proxy-advisors-graph-tooltip'>
                  <Tooltip
                    id='proxy-advisors-graph-tooltip'
                    place='bottom'
                    content={`This graph depicts the Proxy Advisors utilized by your Top Investors (by #). It's worth noting that some investors may subscribe to multiple Proxy Advisors.`}
                    className='aqtion-tooltip'
                  />
                </div>
              </div>
              <div className={styles['body']}>
                <div className={styles['graph']}>
                  {proxyAdvisorsChartData && 
                    <BarChart
                      id='proxy-advisors-chart'
                      width={560}
                      height={235}
                      data={proxyAdvisorsChartData}
                    />
                  }
                </div>
              </div>
              <div className={styles['footer']}>
                <div className={styles['note']}>
                  <p>* Investors may use one or several Proxy Advisors from above list.</p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles['row']}>
            <div className={`${styles['graph-container']} ${styles['voting-decision-maker-graph-container']}`}>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  <h3>Voting Decision Maker</h3>
                </div>
                <div className={styles['tooltip-icon']} data-tooltip-id='voting-decision-maker-graph-tooltip'>
                  <Tooltip
                    id='voting-decision-maker-graph-tooltip'
                    place='bottom'
                    content='This graph illustrates which team within your Top Investors ultimately decides on how they vote their shares (by #). Further information can be found in the glossary.'
                    className='aqtion-tooltip'
                  />
                </div>
              </div>
              <div className={styles['body']}>
                <div className={styles['graph']}>
                  {votingDecisionMakerChartData &&
                    <HorizontalBarChart
                      id='voting-decision-maker-chart'
                      width={560}
                      height={20}
                      data={votingDecisionMakerChartData}
                    />
                  }
                </div>
              </div>
            </div>

            <div className={`${styles['graph-container']} ${styles['proxy-advisors-reliance-graph-container']}`}>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  <h3>Proxy Advisors Reliance </h3>
                </div>
                <div className={styles['tooltip-icon']} data-tooltip-id='proxy-advisors-reliance-graph-tooltip'>
                  <Tooltip
                    id='proxy-advisors-reliance-graph-tooltip'
                    place='bottom'
                    content='This graph provides a breakdown of how much your Top Investors depend on the recommendations provided by their Primary Proxy Advisor (by #) as determined by AQTION. Further details can be explored in the glossary.'
                    className='aqtion-tooltip'
                  />
                </div>
              </div>
              <div className={styles['body']}>
                <div className={styles['graph']}>
                  {proxyAdvisorsRelianceChartData &&
                    <HorizontalBarChart
                      id='proxy-advisors-reliance-chart'
                      width={560}
                      height={20}
                      data={proxyAdvisorsRelianceChartData}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          
          <div className={styles['row']}>
            <div className={styles['graph-section-header']}>
              <div className={styles['graph-section-title']}>
                <h2>Activism</h2>
              </div>
            </div>
          </div>

          <div className={styles['row']}>
            <div className={`${styles['graph-container']} ${styles['activist-graph-container']}`}>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  <h3>Activist</h3>
                </div>
                <div className={styles['tooltip-icon']} data-tooltip-id='activist-graph-tooltip'>
                  <Tooltip
                    id='activist-graph-tooltip'
                    place='bottom'
                    content='This graph shows the number of investors within your Top Investors are considered to be an "Activist” according to AQTION. Further information can be found in the glossary.'
                    className='aqtion-tooltip'
                  />
                </div>
              </div>
              <div className={styles['body']}>
                <div className={styles['graph']}>
                  {activistChartData &&
                    <DonutChart
                      id='activist-chart'
                      width={212}
                      height={212}
                      data={activistChartData}
                    />
                  }
                </div>
              </div>
            </div>

            <div className={`${styles['graph-container']} ${styles['receptivity-to-activists-graph-container']}`}>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  <h3>Receptivity to Activists</h3>
                </div>
                <div className={styles['tooltip-icon']} data-tooltip-id='receptivity-to-activists-graph-tooltip'>
                  <Tooltip
                    id='receptivity-to-activists-graph-tooltip'
                    place='bottom'
                    content='This graph provides a breakdown of how receptive/supportive your Top investors (by #) have been to previous activist campaigns as determined by AQTION. Further information can be found in the glossary.'
                    className='aqtion-tooltip'
                  />
                </div>
              </div>
              <div className={styles['body']}>
                <div className={styles['graph']}>
                  {receptivityToActivistsChartData &&
                    <DonutChart
                      id='receptivity-to-activists-chart'
                      width={212}
                      height={212}
                      data={receptivityToActivistsChartData}
                    />
                  }
                </div>
              </div>
            </div>
          </div>

          <div className={styles['row']}>
            <div className={styles['graph-section-header']}>
              <div className={styles['graph-section-title']}>
                <h2>Enviromental & Social</h2>
              </div>
            </div>
          </div>

          <div className={styles['row']}>
            <div className={`${styles['graph-container']} ${styles['esg-focus-graph-container']}`}>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  <h3>ESG Focus</h3>
                </div>
                <div className={styles['tooltip-icon']} data-tooltip-id='esg-focus-graph-tooltip'>
                  <Tooltip
                    id='esg-focus-graph-tooltip'
                    place='bottom'
                    content='This graph shows how sensitive your Top Investors (by #) are to Environmental, Social, and Governance (ESG) topics as determined by AQTION. Further information can be found in the glossary.'
                    className='aqtion-tooltip'
                  />
                </div>
              </div>
              <div className={styles['body']}>
                <div className={styles['graph']}>
                  {esgFocusChartData &&
                    <DonutChart
                      id='esg-focus-chart'
                      width={212}
                      height={212}
                      data={esgFocusChartData}
                    />
                  }
                </div>
              </div>
            </div>

            <div className={`${styles['graph-container']} ${styles['esg-main-service-providers-graph-container']}`}>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  <h3>Main ESG Service Providers</h3>
                </div>
                <div className={styles['tooltip-icon']} data-tooltip-id='esg-main-service-providers-graph-tooltip'>
                  <Tooltip
                    id='esg-main-service-providers-graph-tooltip'
                    place='bottom'
                    content={`This graph shows which ESG Ratings and Research providers your Top investors use (by #). It's worth noting that some investors may use one or several ESG Ratings and Research Providers.`}
                    className='aqtion-tooltip'
                  />
                </div>
              </div>
              <div className={styles['body']}>
                <div className={styles['graph']}>
                  {esgMainServiceProvidersChartData &&
                    <BarChart
                      id='esg-main-service-providers-chart'
                      width={560}
                      height={235}
                      data={esgMainServiceProvidersChartData}
                    />
                  }
                </div>
              </div>
              <div className={styles['footer']}>
                <div className={styles['note']}>
                  <p>* Investors may use one or several ESG Service Providers from above list.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
