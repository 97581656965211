import { Fragment, useState } from "react";

import styles from './UserDetailsSection.module.css';

const UserDetailsSection = (props) => {
  const clientDetails = props.clientDetails;
  const userDetails = props.userDetails;

  const [profilePictureUrl, setProfilePictureUrl] = useState(props.user['profilePictureUrl']);

  const userProfilePhotoChangeHandler = (event) => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if(file.size > 500000){
        alert('File size should be smaller than 450Kb');
        return false;				
      }

      var data = new FormData();
			data.append('profile_picture', file, file.name);
      props.onProfilePictureUploadClick(data, setProfilePictureUrl);
    }
  }

  return (
    <Fragment>
      <div className={styles['profile-picture-container']}>
        <div
          className={styles['profile-picture']}
          style={{backgroundImage:`url("${profilePictureUrl}")`}}
        ></div>

        {userDetails['user_role'] === 'Account Manager' && 
          <div className={styles['title']}>
            <h3>Account Manager</h3>
          </div>
        }
        {userDetails['user_role'] === 'Client' && 
          <div className={styles['title']}>
            <h3>{clientDetails['name']}</h3>
          </div>
        }

        <div className={styles['profile-picture-upload-container']}>
          <label className={styles['profile-picture-upload-button']}>
            <input type='file' accept='image/gif, image/jpeg, image/png' onChange={userProfilePhotoChangeHandler} />
            <h4>Change Photo</h4>
          </label>
        </div>
      </div>

      <div className={styles['user-details-container']}>
        <div className={styles['title-container']}>
          <div className={styles['row']}>
            <h3>User Details</h3>
          </div>
        </div>
        <div className={styles['details-form-container']}>
          <div className={styles['row']}>
            <div className={styles['label']}>
              <h4>Name:</h4>
            </div>
            <div className={styles['value']}>
              <p>{userDetails['firstname']}</p>
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['label']}>
              <h4>Surname:</h4>
            </div>
            <div className={styles['value']}>
              <p>{userDetails['lastname']}</p>
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['label']}>
              <h4>Email:</h4>
            </div>
            <div className={styles['value']}>
              <p>{userDetails['email']}</p>
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['label']}>
              <h4>Job Title:</h4>
            </div>
            <div className={styles['value']}>
              <p>{userDetails['job_title'] ? userDetails['job_title'] : '-'}</p>
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['label']}>
              <h4>User Type:</h4>
            </div>
            <div className={styles['value']}>
              <p>{userDetails['user_type']}</p>
            </div>
          </div>
        </div>
        <div className={styles['details-note-container']}>
          <div className={styles['note-content']}>
            <p>To change above inputs, please <a href='mailto:support@aqtion-platform.com'>contact</a> your Account Manager.</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UserDetailsSection;
