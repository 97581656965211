import { Fragment, useCallback, useEffect, useState } from 'react';
import { createId, getValueFromDropdown, getValueFromTextInput } from '../../../../utils/helpers';
import { clientUserDepartmentDropdownItems, clientUserTypeDropdownItems } from '../../../../utils/constants';
import { generateClientDetailsShowUserTableItems, generateClientDetailsEditUserTableItems } from '../../../../utils/account-manager-client-details-helpers';

import Table from '../../../../components/UI/Table/Table';
import Button from '../../../../components/UI/Button/Button';

import styles from './ClientDetailsUserManagement.module.css';
import Modal from '../../../../components/UI/Modal/Modal';

const ClientDetailsUserManagement = (props) => {
  const numberOfUsers = props.numberOfUsers;

  const [userSendResetPasswordEmailApproval, setUserSendResetPasswordEmailApproval] = useState(false);
  const [userSendResetPasswordEmailMessage, setUserSendResetPasswordEmailMessage] = useState('');
  const [userSendResetPasswordEmail, setUserSendResetPasswordEmail] = useState('');
  const [userSendResetPasswordEmailCompleted, setUserSendResetPasswordEmailCompleted] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const [tableItems, setTableItems] = useState(
    generateClientDetailsShowUserTableItems(
      props.userListData,
      setUserSendResetPasswordEmail,
      setUserSendResetPasswordEmailMessage,
      setUserSendResetPasswordEmailApproval
    )
  );
  const [userListData, setUserListData] = useState(props.userListData);

  const createUserRow = useCallback((item, deleteRowFunction) => {
    const newItemId = item['id'] !== undefined ? `client-user-existing-${item['id']}` : `client-user-new-${createId(4)}`;
    const firstname = item['firstname'];
    const lastname = item['lastname'];
    const email = item['email'];
    const department = item['department'];
    const jobTitle = item['job_title'];
    const userType = item['user_type'];

    return {
      'id': newItemId,
      'columns': [
        {
          'id': `${newItemId}#firstname`,
          'type': 'text-input',
          'placeholder': 'Enter name',
          'value': firstname,
          'width': 128,
          'stretch': false,
          'tabIndex': 0
        },
        {
          'id': `${newItemId}#lastname`,
          'type': 'text-input',
          'placeholder': 'Enter surname',
          'value': lastname,
          'width': 128,
          'stretch': false,
          'tabIndex': 0
        },
        {
          'id': `${newItemId}#email`,
          'type': 'text-input',
          'placeholder': 'Enter email',
          'value': email,
          'width': 172,
          'stretch': true,
          'tabIndex': 0
        },
        {
          'id': `${newItemId}#department`,
          'type': 'dropdown',
          'placeholder': 'Department',
          'selected': department,
          'value': department,
          'width': 172,
          'items': clientUserDepartmentDropdownItems,
          'stretch': true,
          'tabIndex': 0
        },
        {
          'id': `${newItemId}#job-title`,
          'type': 'text-input',
          'placeholder': 'Enter job title',
          'value': jobTitle,
          'width': 128,
          'stretch': true,
          'tabIndex': 0
        },
        {
          'id': `${newItemId}#user-type`,
          'type': 'dropdown',
          'placeholder': 'Select User Type',
          'selected': userType,
          'value': userType,
          'width': 128,
          'items': clientUserTypeDropdownItems,
          'stretch': true,
          'tabIndex': 0
        },
        {
          'id': `${newItemId}#delete-button`,
          'icon': 'trash',
          'type': 'icon-button',
          'onClick': deleteRowFunction
        }
      ]
    };
  }, []);


  const updateUserTableItems = useCallback((tempTableItems) => {
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const firstname = getValueFromTextInput(`${itemId}#firstname`);
        const lastname = getValueFromTextInput(`${itemId}#lastname`);
        const email = getValueFromTextInput(`${itemId}#email`);
        const department = getValueFromDropdown(`${itemId}#department`);
        const jobTitle = getValueFromTextInput(`${itemId}#job-title`);
        const userType = getValueFromDropdown(`${itemId}#user-type`);
  
        item['columns'][0]['value'] = firstname;
        item['columns'][1]['value'] = lastname;
        item['columns'][2]['email'] = email;
        item['columns'][3]['selected'] = department;
        item['columns'][3]['value'] = department;
        item['columns'][4]['value'] = jobTitle;
        item['columns'][5]['selected'] = userType;
        item['columns'][5]['value'] = userType;
      });
    }
    return tempTableItems['middle']['body']['items'];
  }, []);


  const addNewUserRowHandler = useCallback((createUserRow, removeUserRowHandler) => () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateUserTableItems(newTableItems);
      if(newTableItems['middle']['body']['items'].length >= numberOfUsers) {
        return newTableItems;
      }

      newTableItems['middle']['body']['items'].push(
        createUserRow({
          'firstname': '',
          'lastname': '',
          'email': '',
          'department': '',
          'job_title': '',
          'user_type': 'View Only'
        }, removeUserRowHandler)
      );    
      return newTableItems;
    });
  }, [numberOfUsers, updateUserTableItems]);

  
  const removeUserRowHandler = useCallback((itemId) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateUserTableItems(newTableItems);

      const itemIndex = newTableItems['middle']['body']['items'].findIndex(item => item['id'] === itemId.split('#')[0]);
      if (itemIndex > -1) {
        newTableItems['middle']['body']['items'].splice(itemIndex, 1);
      }
      return newTableItems;
    });
  }, [updateUserTableItems]);


  const isItemsValid = useCallback((tempTableItems) => {
    let isValid = true;
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const firstname = getValueFromTextInput(`${itemId}#firstname`);
        const lastname = getValueFromTextInput(`${itemId}#lastname`);
        const email = getValueFromTextInput(`${itemId}#email`);
        const department = getValueFromDropdown(`${itemId}#department`);
        const jobTitle = getValueFromTextInput(`${itemId}#job-title`);
        const userType = getValueFromDropdown(`${itemId}#user-type`);

        if(firstname === '') {
          document.getElementById(`${itemId}#firstname`).style.border = '1px solid var(--color-danger-700)';
        }
        if(lastname === '') {
          document.getElementById(`${itemId}#lastname`).style.border = '1px solid var(--color-danger-700)';
        }
        if(email === '') {
          document.getElementById(`${itemId}#email`).style.border = '1px solid var(--color-danger-700)';
        }
        if(document.getElementById(`${itemId}#department`).dataset.selected === '') {
          document.getElementById(`${itemId}#department`).style.border = '1px solid var(--color-danger-700)';
        }
        if(jobTitle === '') {
          document.getElementById(`${itemId}#job-title`).style.border = '1px solid var(--color-danger-700)';
        }
        if(document.getElementById(`${itemId}#user-type`).dataset.selected === '') {
          document.getElementById(`${itemId}#user-type`).style.border = '1px solid var(--color-danger-700)';
        }
        
        isValid = isValid && (firstname !== '' && lastname !== '' && email !== '' && department !== '' && jobTitle !== '' && userType !== '');
      });
    }
    return isValid;
  }, []);


  const fetchUserDataForRequest = useCallback((tempTableItems) => {
    const data = {
      'new_items': [],
      'updated_items': []
    };    
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const firstname = getValueFromTextInput(`${itemId}#firstname`);
        const lastname = getValueFromTextInput(`${itemId}#lastname`);
        const email = getValueFromTextInput(`${itemId}#email`);
        const department = getValueFromDropdown(`${itemId}#department`);
        const jobTitle = getValueFromTextInput(`${itemId}#job-title`);
        const userType = getValueFromDropdown(`${itemId}#user-type`);
        
        const userItem = {
          'firstname': firstname,
          'lastname': lastname,
          'email': email,
          'department': department,
          'job_title': jobTitle,
          'user_type': userType
        };

        if(itemId.includes('client-user-existing-')) {
          userItem['id'] = itemId.split('client-user-existing-')[1];
          data['updated_items'].push(userItem);
        }
        else {
          data['new_items'].push(userItem);
        }
      });
    }

    return data;
  }, []);


  const fetchUserDataForTable = useCallback((tempTableItems) => {
    const data = [];    
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const firstname = getValueFromTextInput(`${itemId}#firstname`);
        const lastname = getValueFromTextInput(`${itemId}#lastname`);
        const email = getValueFromTextInput(`${itemId}#email`);
        const department = getValueFromDropdown(`${itemId}#department`);
        const jobTitle = getValueFromTextInput(`${itemId}#job-title`);
        const userType = getValueFromDropdown(`${itemId}#user-type`);
        
        data.push({
          'id': itemId.includes('client-user-existing-') ? itemId.split('client-user-existing-')[1] : undefined,
          'firstname': firstname,
          'lastname': lastname,
          'email': email,
          'department': department,
          'job_title': jobTitle,
          'user_type': userType
        });
      });
    }

    return data;
  }, []);


  const cancelEditHandler = () => {
    setTableItems(
      generateClientDetailsShowUserTableItems(
        userListData,
        setUserSendResetPasswordEmail,
        setUserSendResetPasswordEmailMessage,
        setUserSendResetPasswordEmailApproval
      )
    );
    setIsEditMode(false);
  }


  const updateUserListHandler = () => {
    if(!isItemsValid(tableItems)) {
      return;
    }
    const requestData = fetchUserDataForRequest(tableItems);
    const tableData = fetchUserDataForTable(tableItems);
    setTableItems(
      generateClientDetailsShowUserTableItems(
        tableData,
        setUserSendResetPasswordEmail,
        setUserSendResetPasswordEmailMessage,
        setUserSendResetPasswordEmailApproval
      )
    );
    setUserListData(tableData);
    setIsEditMode(false);
    props.onSaveClick(requestData);
  }


  useEffect(() => {
    let newTableItems = null;
    if(!isEditMode) {
      newTableItems = generateClientDetailsShowUserTableItems(
        userListData,
        setUserSendResetPasswordEmail,
        setUserSendResetPasswordEmailMessage,
        setUserSendResetPasswordEmailApproval
      );
    }
    else {
      newTableItems = generateClientDetailsEditUserTableItems(addNewUserRowHandler(createUserRow, removeUserRowHandler));
      userListData.forEach(item => {
        newTableItems['middle']['body']['items'].push(
          createUserRow(item, removeUserRowHandler)
        );
      });
    }
    setTableItems(newTableItems);
  }, [userListData, isEditMode,
      addNewUserRowHandler, createUserRow, removeUserRowHandler]);


  const propsUserListData = props.userListData;
  useEffect(() => {
    setUserListData(propsUserListData);
  }, [propsUserListData]);


  useEffect(() => {
    const buttonElement = document.getElementById('client-user-list-add-button');
    if(buttonElement !== null) {
      if(tableItems !== '' && tableItems['middle']['body']['items'].length === numberOfUsers) {
        buttonElement.disabled = true;
      }
      else {
        buttonElement.disabled = false;
      }
    }
  }, [tableItems, numberOfUsers]);


  const sendResetPasswordEmailHandler = () => {
    setUserSendResetPasswordEmailApproval(false);
    setUserSendResetPasswordEmailCompleted(true);
    props.onResetPasswordClick(userSendResetPasswordEmail);
  }


  return (
    <div className={styles['client-details-user-list']}>
      {userSendResetPasswordEmailApproval && 
        <Modal
          type='info'
          title='Send a password reset email?'
          message={userSendResetPasswordEmailMessage}
          onCloseClick={() => setUserSendResetPasswordEmailApproval(false)}
          approveButton = {{
            'text': 'Send',
            'onClick': () => sendResetPasswordEmailHandler()
          }}
          cancelButton = {{
            'text': 'Cancel',
            'onClick': () => setUserSendResetPasswordEmailApproval(false)
          }}
        />
      }

      {userSendResetPasswordEmailCompleted && 
        <Modal
          type='info'
          title='Send a password reset email?'
          message={<span>Email sent successfully to <strong>{userSendResetPasswordEmail}</strong></span>}
          onCloseClick={() => setUserSendResetPasswordEmailCompleted(false)}
          approveButton = {{
            'text': 'Close',
            'onClick': () => setUserSendResetPasswordEmailCompleted(false)
          }}
        />
      }

      {tableItems !== null &&
        <Fragment>
          <div className={styles['user-list-table-top-container']}>
            <div className={styles['action-buttons-container']}>
              {!isEditMode &&
                <div className={styles['right-buttons']}>
                  <Button type={"primary"} size={"base"} onClick={() => setIsEditMode(true)}>
                    Update User List
                  </Button>
                </div>
              }
              {isEditMode &&
                <div className={styles['right-buttons']}>
                  <Button type={"cancel"} size={"base"} onClick={cancelEditHandler}>Cancel</Button>
                  <Button type={"primary"} size={"base"} onClick={updateUserListHandler}>Save</Button>
                </div>
              }
            </div>
          </div>

          <Table width={props.pageWidth} items={tableItems} isScrollable={isEditMode ? false : true} />
        </Fragment>
      }
    </div>
  );
}

export default ClientDetailsUserManagement;
