import { Fragment, useEffect, useState } from 'react';
import { fetchClientInvestorDetailsRequest, updateClientInvestorRequest, uploadClientInvestorProfilePictureRequest } from '../../../../requests/client-requests';
import { getAuthClientId, getClientAvailableModules, getClientType } from '../../../../utils/auth';
import { handleRequestError } from '../../../../utils/request-error-helpers';
import useHttp from '../../../../hooks/use-http';

import Dropdown from '../../../../components/UI/Dropdown/Dropdown';

import InvestorSummaryGeneralInfoSection from './InvestorSummaryGeneralInfoSection/InvestorSummaryGeneralInfoSection';
import InvestorSummaryStewardshipSection from './InvestorSummaryStewardshipSection/InvestorSummaryStewardshipSection';
import InvestorSummaryActivismSection from './InvestorSummaryActivismSection/InvestorSummaryActivismSection';
import InvestorSummaryESGServiceProvidersSection from './InvestorSummaryESGServiceProvidersSection/InvestorSummaryESGServiceProvidersSection';
import InvestorSummaryEnvironmentalAndSocialSection from './InvestorSummaryEnvironmentalAndSocialSection/InvestorSummaryEnvironmentalAndSocialSection';
import InvestorSummaryPastGeneralMeetingVotesSection from './InvestorSummaryPastGeneralMeetingVotesSection/InvestorSummaryPastGeneralMeetingVotesSection';

import styles from './InvestorSummary.module.css';

const InvestorSummary = (props) => {

  const relationshipStatusDropdownItems = [
    {
      'id': 'relationship-status-strong',
      'value': 'Strong',
      'width': '108px',
      'headerColor': 'success',
      'chipColor': 'success'
    },
    {
      'id': 'relationship-status-standard',
      'value': 'Standard',
      'width': '126px',
      'headerColor': 'warning',
      'chipColor': 'warning'
    },
    {
      'id': 'relationship-status-weak',
      'value': 'Weak',
      'width': '100px',
      'headerColor': 'danger',
      'chipColor': 'danger'
    },
    {
      'id': 'relationship-status-unknown',
      'value': 'Unknown',
      'width': '130px',
      'headerColor': 'unknown',
      'chipColor': 'unknown'
    }
  ];

  const clientId = getAuthClientId();
  const clientType = getClientType();
  const clientAvailableModules = getClientAvailableModules();
  const investorId = props.investorId;

  const [isLoading, error, sendRequest] = useHttp();

  const [investorData, setInvestorData] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [relationshipStatus, setRelationshipStatus] = useState(null);
  const [generalInformationData, setGeneralInformationData] = useState(null);
  const [stewardshipData, setStewardshipData] = useState(null);
  const [activismData, setActivismData] = useState(null);
  const [environmentalAndSocialData, setEnvironmentalAndSocialData] = useState(null);
  const [esgServiceProvidersData, setESGServiceProvidersData] = useState(null);
  const [pastGeneralMeetingVotesData, setPastGeneralMeetingVotesData] = useState(null);

  useEffect(() => {
    sendRequest(
      fetchClientInvestorDetailsRequest(clientId, investorId),
      (data) => {
        setInvestorData(data);
        setProfilePictureUrl(data['profile_picture_url']);
        setGeneralInformationData(data['general_information']);
        setStewardshipData(data['stewardship']);
        setActivismData(data['activism']);
        setEnvironmentalAndSocialData(data['environment_and_social']);
        setESGServiceProvidersData(data['esg_service_providers']);
        setPastGeneralMeetingVotesData(data['past_general_meeting_votes']);
        if(data['relationship_status'] === null) {
          setRelationshipStatus('Unknown');
        }
        else {
          setRelationshipStatus(data['relationship_status']);
        }
      }
    );
  }, [clientId, investorId, sendRequest]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }
    
  useEffect(() => {
    if (investorData !== null) {
      setGeneralInformationData(investorData['general_information']);
      setStewardshipData(investorData['stewardship']);
      setActivismData(investorData['activism']);
      setEnvironmentalAndSocialData(investorData['environment_and_social']);
      setESGServiceProvidersData(investorData['esg_service_providers']);
      setPastGeneralMeetingVotesData(investorData['past_general_meeting_votes']);
    }
  }, [investorData]);


  /* Save updated general information for the investor. */
  const generalInformationSaveHandler = (updatedGeneralInformationData) => {
    const body = {
      'general_information': updatedGeneralInformationData,
    };
    sendRequest(
      updateClientInvestorRequest(clientId, investorId, body),
      (data) => {
        setGeneralInformationData(data['general_information']);
        props.onProfilePictureChange(data['profile_picture_url']);
      }
    );
  }
  
  /* Save updated stewardship information for the investor. */
  const stewardshipSaveHandler = (updatedStewardshipData) => {
    const body = {
      'stewardship': updatedStewardshipData,
    };
    sendRequest(
      updateClientInvestorRequest(clientId, investorId, body),
      (data) => {
        setStewardshipData(data['general_information']);
      }
    );
  }

  /* Save updated activism information for the investor. */
  const activismSaveHandler = (updatedActivismData) => {
    const body = {
      'activism': updatedActivismData,
    };
    sendRequest(
      updateClientInvestorRequest(clientId, investorId, body),
      (data) => {
        setActivismData(data['activism']);
      }
    );
  }

  /* Save updated environmental information for the investor. */
  const environmentalAndSocialSaveHandler = (updatedEnvironmentalAndSocialData) => {
    const body = {
      'environment_and_social': updatedEnvironmentalAndSocialData,
    };
    sendRequest(
      updateClientInvestorRequest(clientId, investorId, body),
      (data) => {
        setEnvironmentalAndSocialData(data['environment_and_social']);
      }
    );
  }

  /* Save updated ESG service providers for the investor. */
  const esgServiceProvidersSaveHandler = (updatedESGServiceProvidersData) => {
    const body = {
      'esg_service_providers': updatedESGServiceProvidersData,
    };
    sendRequest(
      updateClientInvestorRequest(clientId, investorId, body),
      (data) => {
        setESGServiceProvidersData(data['esg_service_providers']);
      }
    );
  }

  /* Save updated past general meeting votes for the investor. */
  const pastGeneralMeetingVotesSaveHandler = (updatedPastGeneralMeetingVotesData) => {
    const body = {
      'past_general_meeting_votes': updatedPastGeneralMeetingVotesData,
    };
    sendRequest(
      updateClientInvestorRequest(clientId, investorId, body),
      (data) => {
        setPastGeneralMeetingVotesData(data['past_general_meeting_votes']);
      }
    );
  }

  /* Updates Relationship Status value for the selected investor. */
  const investorRelationshipStatusChangeHandler = (selectedRelationshipStatus) => {
    const body = {
      'relationship_status': selectedRelationshipStatus
    }
    sendRequest(
      updateClientInvestorRequest(clientId, investorId, body),
      (data) => { 
        setRelationshipStatus(data['relationship_status']);
      }
    );
  }

  /* Upload profile picture for the investor. */
  const uploadClientInvestorProfilePictureHandler = (profilePictureData) => {
    sendRequest(
      uploadClientInvestorProfilePictureRequest(clientId, investorId, profilePictureData),
      (data) => { setProfilePictureUrl(data['profile_picture_url']); }
    );
  }

  const getEngagementUrl = (engagement) => {
    if (engagement['type'] === 'Meeting Note') {
      return `../engagements?meeting_note_id=${engagement['id']}&type=investor`;
    }
    else if (engagement['type'] === 'Other Note') {
      return `../engagements?other_note_id=${engagement['id']}&type=investor`;
    }
    else if (engagement['type'] === 'Meeting Request') {
      return `../engagements?meeting_request_id=${engagement['id']}`;
    }
    else if (engagement['type'] === 'Shareholder Communication') {
      return `../engagements?shareholder_communication_id=${engagement['id']}`;
    }
  }

  return (
    <Fragment>
      {investorData !== null &&
        <div className={`${styles['extra-information-area']} ${!clientAvailableModules['engagements'] ? styles['no-engagements-module'] : ''}`}>
          {clientAvailableModules['engagements'] &&
            <div className={styles['engagements-container']}>
              <div className={styles['engagement-description']}>
                <p>You have <strong>{investorData['engagements']['count']}</strong> Engagements so far with {investorData['name']}.
                  {investorData['engagements']['last_engagements'].length > 0 && 
                    <span> Your last Engagement interaction is;</span>
                  }
                  {investorData['engagements']['last_engagements'].length <= 0 && 
                    <span> You can create a new engagement from <a href="../engagements">here</a>.</span>
                  }
                </p>
              </div>
              {investorData['engagements']['last_engagements'][0] && 
                <div className={styles['engagement-item']}>
                  <div className={styles['engagement-icon']}></div>
                  <div className={styles['engagement-content']}>
                    <div className={styles['label']}>
                      <h4>{investorData['engagements']['last_engagements'][0]['type']}</h4>
                    </div>
                    <div className={styles['title']}>
                      <p>
                        <a href={getEngagementUrl(investorData['engagements']['last_engagements'][0])}>
                          {investorData['engagements']['last_engagements'][0]['title']}
                        </a>
                      </p>
                    </div>
                    <div className={styles['date']}>
                      <p>on {new Date(investorData['engagements']['last_engagements'][0]['updated_at']).toLocaleDateString('en-GB')}</p>
                    </div>
                  </div>
                </div>
              }
              {investorData['engagements']['last_engagements'][1] && 
                <div className={styles['engagement-item']}>
                  <div className={styles['engagement-icon']}></div>
                  <div className={styles['engagement-content']}>
                    <div className={styles['label']}>
                      <h4>{investorData['engagements']['last_engagements'][1]['type']}</h4>
                    </div>
                    <div className={styles['title']}>
                      <p>
                        <a href={getEngagementUrl(investorData['engagements']['last_engagements'][1])}>
                          {investorData['engagements']['last_engagements'][1]['title']}
                        </a>
                      </p>
                    </div>
                    <div className={styles['date']}>
                      <p>on {new Date(investorData['engagements']['last_engagements'][1]['updated_at']).toLocaleDateString('en-GB')}</p>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
          <div className={styles['relationship-status-container']}>
            <div className={styles['value']}>
              <Dropdown
                id='investor-relationship-status'
                size='small'
                width='130px'
                items={relationshipStatusDropdownItems}
                itemContainerWidth='200px'
                itemContainerFloat='right'
                selected={relationshipStatus}
                onItemClick={investorRelationshipStatusChangeHandler}
                hasChip={true}
                hasHeaderColor={true}
              />
            </div>
            <div className={styles['title']}>
              <h4>Relationship Status</h4>
            </div>
          </div>
        </div>
      }

      {investorData !== null &&
        <div className={styles['investor-information-area']}>  
          <InvestorSummaryGeneralInfoSection
            profilePictureUrl={profilePictureUrl}
            generalInformationData={generalInformationData}
            isCustomInvestor={investorData['is_custom']}
            onProfilePictureUploadClick={uploadClientInvestorProfilePictureHandler}
            onSaveClick={generalInformationSaveHandler}
          />
          <InvestorSummaryStewardshipSection
            stewardshipData={stewardshipData}
            isCustomInvestor={investorData['is_custom']}
            onSaveClick={stewardshipSaveHandler}
          />
          <InvestorSummaryActivismSection
            activismData={activismData}
            isCustomInvestor={investorData['is_custom']}
            onSaveClick={activismSaveHandler}
          />
          <InvestorSummaryEnvironmentalAndSocialSection
            environmentalAndSocialData={environmentalAndSocialData}
            isCustomInvestor={investorData['is_custom']}
            onSaveClick={environmentalAndSocialSaveHandler}
          />
          <InvestorSummaryESGServiceProvidersSection
            esgServiceProvidersData={esgServiceProvidersData}
            isCustomInvestor={investorData['is_custom']}
            onSaveClick={esgServiceProvidersSaveHandler}
          />
          {clientType === 'Corporate' && 
            <InvestorSummaryPastGeneralMeetingVotesSection
              pastGeneralMeetingVotesData={pastGeneralMeetingVotesData}
              onSaveClick={pastGeneralMeetingVotesSaveHandler}
            />
          }
        </div>
      }
    </Fragment>
  );
}

export default InvestorSummary;
