import styles from './Button.module.css';

const Button = (props) => {
  const customStyleClasses = props.className ? props.className : '';
  const isDisabled = props.isDisabled ? true : false;
  const sizeStyle = props.size === undefined ? styles['base'] : styles[props.size];

  const clickHandler = () => {
    props.onClick();
  }

  const customStyle = {};
  if (props.width !== undefined) {
    customStyle['width'] = props.width
  }

  return (
    <div className={`${styles['button-container']} ${customStyleClasses}`} style={customStyle}>
      <button id={props.id} className={`${styles[props.type]} ${sizeStyle}`} disabled={isDisabled} onClick={clickHandler}>
        <div className={styles['content']}>
          { props.leftIcon &&
            <div className={`${styles['icon-container']} ${styles['left']} ${styles[props.leftIcon]}`}></div>
          }
          <div className={styles['text']}>
            <p>{props.children}</p>
          </div>
          { props.rightIcon &&
            <div className={`${styles['icon-container']} ${styles['right']} ${styles[props.rightIcon]}`}></div>
          }
        </div>
      </button>
    </div>
  )
}

export default Button
