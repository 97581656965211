import { useRef } from 'react';
import TableRow from '../TableRow/TableRow';
import styles from './RightFixedContent.module.css';

const RightFixedContent = (props) => {
  const ref = useRef();

  const zIndex = props.items['header']['config']['zIndex'] ? props.items['header']['config']['zIndex'] : 2;

  const headerRowItems = props.items['header']['items'].map(item => {
    return (
      <TableRow
        key={`${item['id']}-right`}
        section='header'
        container='right-fixed'
        columns={item['columns']}
        config={props.items['header']['config']}
      />
    );
  })

  const bodyRowItems = props.items['body']['items'].map(item => {
    return (
      <TableRow
        id={`${item['id']}#right`}
        key={`${item['id']}-right`}
        section='body'
        container='right-fixed'
        columns={item['columns']}
        config={props.items['body']['config']}
      />
    );
  })

  return (
    <div ref={ref} className={styles['right-fixed']} style={{ 'width': props.width, 'zIndex': zIndex }}>
      <div className={styles['header']}>
        {headerRowItems}
      </div>
      <div className={styles['body']}>
        {bodyRowItems}
      </div>
    </div>
  )
}

export default RightFixedContent
