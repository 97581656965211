import Plot from "react-plotly.js";

import styles from './BarChart.module.css';

const BarChart = (props) => {
  const generateBarItems = () => {
    const data = {
      x: props.data['items'].map(item => item['name']),
      // y: props.data['items'].map(item => item['value']),
      y: props.data['items'].map(item => item['percentage']),
      marker: {
        color: props.data['items'].map(item => item['color'])
      },
      width: props.data['items'].map(item => 0.2),
      type: 'bar'
    };
    return [data];
  }

  const generateInfoBox = () => {
    return props.data['items'].map(item => {
      return (
        <div key={`info-box-${props.id}-${item['id']}`} className={styles['item']}>
          <div className={styles['color']} style={{backgroundColor: item['color']}}></div>
          <div className={styles['text']}>
            <h4>{item['name']}</h4>
          </div>
          <div className={styles['percentage']}>
            <h4>{`(${item['percentage']}%)`}</h4>
          </div>
          <div className={styles['count']}>
            <h4>{`${item['value']}`}</h4>
          </div>
        </div>
      );
    });
  }

  return (
    <div className={styles['bar-chart-container']}>
      <div className={styles['graph']}>
        <Plot
          data={generateBarItems()}
          layout={{
            autosize: false,
            width: props.width,
            height: props.height,
            barmode: 'stack',
            showlegend: false,
            margin: { l: 20, r: 0, t: 0, b: 20, autoexpand: false },
            plot_bgcolor: "rgba(0,0,0,0)",
            paper_bgcolor: "rgba(0,0,0,0)",
            hovermode: false,
            xaxis: {
              visible: false
            },
            yaxis: {
              zerolinecolor: '#EAEAEA',
            }
          }}
          config={{
            showLink: false,
            staticPlot: true
          }}
        />
      </div>
      <div className={styles['info-box']}>
        <div className={styles['item']}>
          {generateInfoBox()}
        </div>
      </div>
    </div>
  );
}

export default BarChart;
