import EsgAllTopicsQuestionItem from './EsgAllTopicsQuestionItem';

import styles from '../EsgAllTopicsContainer.module.css';

const EsgAllTopicsQuestionList = (props) => {
  const questionData = props.questions;

  const questionContent = questionData.map(item => {
    return (
      <EsgAllTopicsQuestionItem
        key={`question-item-${item['id']}`}
        id={item['id']}
        text={item['text']}
        numberOfComments={item['comment_count']}
        description={item['description']}
        onShowQuestionDetailsClick={() => props.onShowQuestionDetailsClick(item)}
      />
    );
  });


  return (
    <div className={styles['question-list']}>
      <div className={styles['list-title']}>
        <h3>{props.title}</h3>
      </div>
      {questionContent}
    </div>
  );
}

export default EsgAllTopicsQuestionList;
