import Button from '../../../../components/UI/Button/Button';
import styles from './ClientSetupInitialStep.module.css';

const ClientSetupInitialStep = (props) => {
  return (
    <div className={styles['client-setup-initial-step']}>
      <div className={styles['text-container']}>
        <h3>You’re about to start a new Client set-up...</h3>
        <p>You may leave this page and continue filling in information later, it will be automatically saved.</p>
      </div>
      <div className={styles['button-container']}>
        <Button type={"primary"} size={"base"} width={160} onClick={props.onStart}>Start</Button>
      </div>
    </div>
  );
}

export default ClientSetupInitialStep;
