import { useCallback, useEffect, useMemo, useState } from 'react';
import { countryDropdownItems } from '../../../../utils/constants';
import { generateClientSetupEditInvestorListTableItems, generateClientSetupShowInvestorListTableItems } from '../../../../utils/account-manager-helper';
import { commaSeperatedValueToNumber, createId, getValueFromDropdown, getValueFromParagraph, getValueFromTextInput, numberToCommaSeperatedValue, setValueOfParagraph } from '../../../../utils/helpers';
import { utils, writeFileXLSX } from 'xlsx';

import Button from '../../../../components/UI/Button/Button';
import Modal from '../../../../components/UI/Modal/Modal';
import Table from '../../../../components/UI/Table/Table';

import styles from './ClientSetupInvestorList.module.css';

const ClientSetupInvestorList = (props) => {
  const [tableItems, setTableItems] = useState(null);
  const [isActivated, setIsActivated] = useState(props.investorListActivated);

  const [investorListSaveWarning, setInvestorListSaveWarning] = useState(false);
  const [investorListActivateApproval, setInvestorListActivateApproval] = useState(false);
  const [investorListActivateWarning, setInvestorListActivateWarning] = useState(false);
  const [isValidationAvailable, setIsValidationAvailable] = useState(false);

  const clientType = props.clientType;
  const propsInvestorList = props.investorList;
  const numberOfInvestors = props.numberOfInvestors;
  const totalNumOfShares = props.totalNumOfShares;

  const hasErrorStyle = props.numberOfInvestors < 1 ? styles['has-error'] : '';
 
  const squareWellInvestors = props.squareWellInvestors;
  const searchInvestorItems = useMemo(() => squareWellInvestors.map(item => {
    return {
      'id': item['id'],
      'text': item['name']
    }
  }), [squareWellInvestors]);


  const createInvestorRow = useCallback((item, selectInvestorComboboxItemHandler, iscCalculateFunction, deleteRowFunction) => {
    const newItemId = `client-intestor-item-${createId(4)}`;
    const investorName = item['name'] !== null ? item['name'] : '';
    const country = item['country'] !== null ? item['country'] : '';
    
    let numberOfShares = '-';
    let iscPercentage = '-';
    let lastMeetingVotes = '-';
    if(clientType === 'Corporate') {
      numberOfShares = item['num_of_shares'] !== null ? item['num_of_shares'] : '';
      iscPercentage = numberOfShares !== '' ? ((numberOfShares / totalNumOfShares) * 100).toFixed(2) : '-';
      lastMeetingVotes = item['last_general_meeting_votes'] !== null ? item['last_general_meeting_votes'] : '';
    }
    
    const isCustom = item['is_custom'] !== null ? item['is_custom'] : false;
    const countryColumnType = isCustom ? 'dropdown' : 'default';

    return {
      'id': newItemId,
      'columns': [
        {
          'id': `${newItemId}#investor-name`,
          'type': 'combobox',
          'placeholder': 'Enter Investor Name',
          'value': investorName,
          'lastSavedValue': investorName,
          'items': searchInvestorItems,
          'onSelected': selectInvestorComboboxItemHandler,
          'width': 192,
          'stretch': true
        },
        {
          'id': `${newItemId}#country`,
          'type': countryColumnType,
          'placeholder': 'Country',
          'selected': country,
          'value': country,
          'text': country,
          'items': countryDropdownItems, 
          'width': 168,
          'stretch': true
        },
        {
          'id': `${newItemId}#number-of-shares`,
          'type': 'number-input',
          'placeholder': 'Enter Number',
          'value': numberOfShares,
          'onChange': iscCalculateFunction,
          'isDisabled': clientType === 'Corporate' ? false : true,
          'width': 192,
          'stretch': true
        },
        {
          'id': `${newItemId}#isc-percentage`,
          'text': iscPercentage,
          'width': 48,
          'stretch': true
        },
        {
          'id': `${newItemId}#last-meeting-votes`,
          'type': 'text-input',
          'placeholder': 'Enter Votes',
          'value': lastMeetingVotes,
          'isDisabled': clientType === 'Corporate' ? false : true,
          'width': 320,
          'stretch': true
        },
        { 
          'id': `${newItemId}#delete-button`,
          'type': 'icon-button',
          'icon': 'trash',
          'onClick': deleteRowFunction
        }
      ]
    };
  }, [clientType, searchInvestorItems, totalNumOfShares]);


  const updateInvestorTableItems = useCallback((tempTableItems) => {
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];

        let investorName = '';
        let numberOfShares = 0;
        let lastMeetingVotes = '';
        let country = '';
        if(!isActivated) {
          investorName = getValueFromTextInput(`${itemId}#investor-name`);
          country = getValueFromParagraph(`${itemId}#country`);
          if(clientType === 'Corporate') {
            numberOfShares = commaSeperatedValueToNumber(getValueFromTextInput(`${itemId}#number-of-shares`) !== '' ? getValueFromTextInput(`${itemId}#number-of-shares`) : '');
            lastMeetingVotes = getValueFromTextInput(`${itemId}#last-meeting-votes`);
          }
          else {
            numberOfShares = '-';
            lastMeetingVotes = '-';
          }
        }
        else {
          investorName = getValueFromParagraph(`${itemId}#investor-name`);
          country = getValueFromParagraph(`${itemId}#country`) !== '' ? getValueFromParagraph(`${itemId}#country`) : '';
          if(clientType === 'Corporate') {
            numberOfShares = commaSeperatedValueToNumber(getValueFromParagraph(`${itemId}#number-of-shares`) !== '' ? getValueFromParagraph(`${itemId}#number-of-shares`) : '');
            lastMeetingVotes = getValueFromParagraph(`${itemId}#last-meeting-votes`);
          }
          else {
            numberOfShares = '-';
            lastMeetingVotes = '-';
          }
        }
        
        let iscPercentage = '-';
        if(clientType === 'Corporate') {
          iscPercentage = (numberOfShares !== '' && !isNaN(numberOfShares)) ? ((numberOfShares / totalNumOfShares) * 100).toFixed(2) : '-';
        }

        item['columns'][0]['value'] = investorName;
        item['columns'][0]['lastSavedValue'] = investorName;
        item['columns'][1]['selected'] = country;
        item['columns'][1]['value'] = country;
        item['columns'][2]['value'] = clientType === 'Corporate' ? (isNaN(numberOfShares) ? '' : numberToCommaSeperatedValue(numberOfShares)) : '-';
        item['columns'][3]['text'] = iscPercentage;
        item['columns'][4]['value'] = lastMeetingVotes;
      });
    }
    return tempTableItems['middle']['body']['items'];
  }, [clientType, isActivated, totalNumOfShares]);


  const addNewInvestorRowHandler = useCallback((createInvestorRow, selectInvestorComboboxItemHandler, iscCalculateHandler, removeInvestorRowHandler) => () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);

      if(newTableItems['middle']['body']['items'].length >= numberOfInvestors) {
        return newTableItems;
      }
      
      newTableItems['middle']['body']['items'].push(
        createInvestorRow({
          'name': '',
          'country': '',
          'num_of_shares': '',
          'last_general_meeting_votes': '',
          'is_custom': true
        }, selectInvestorComboboxItemHandler, iscCalculateHandler, removeInvestorRowHandler)
      );
      newTableItems['middle']['header']['items'][0]['columns'][0]['text'] = `INVESTOR NAME (${newTableItems['middle']['body']['items'].length})`;
      
      const totalEnteredShares = newTableItems['middle']['body']['items'].reduce((acc, curr) => {
        const numberOfShares = getValueFromTextInput(`${curr['id']}#number-of-shares`) !== '' ? parseInt(commaSeperatedValueToNumber(getValueFromTextInput(`${curr['id']}#number-of-shares`))) : 0;
        return acc + numberOfShares;
      }, 0);
      newTableItems['middle']['header']['items'][0]['columns'][2]['text'] = `# OF SHARES (${numberToCommaSeperatedValue(totalEnteredShares)})`;

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedComboboxItems = searchInvestorItems.filter(comboboxItem => !selectedItems.includes(comboboxItem['text']) || comboboxItem['text'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedComboboxItems;
      });

      if (newTableItems['middle']['body']['items'].length >= numberOfInvestors) {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = true;
      }
      else {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = false;
      }

      return newTableItems;
    });
    setIsValidationAvailable(false);
  }, [numberOfInvestors, searchInvestorItems, updateInvestorTableItems]);

  
  const removeInvestorRowHandler = useCallback((itemId) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);

      const itemIndex = newTableItems['middle']['body']['items'].findIndex(item => item['id'] === itemId.split('#')[0]);
      if (itemIndex > -1) {
        newTableItems['middle']['body']['items'].splice(itemIndex, 1);
      }

      newTableItems['middle']['header']['items'][0]['columns'][0]['text'] = `INVESTOR NAME (${newTableItems['middle']['body']['items'].length})`;

      const totalEnteredShares = newTableItems['middle']['body']['items'].reduce((acc, curr) => {
        const numberOfShares = getValueFromTextInput(`${curr['id']}#number-of-shares`) !== '' ? parseInt(commaSeperatedValueToNumber(getValueFromTextInput(`${curr['id']}#number-of-shares`))) : 0;
        return acc + numberOfShares;
      }, 0);
      newTableItems['middle']['header']['items'][0]['columns'][2]['text'] = `# OF SHARES (${numberToCommaSeperatedValue(totalEnteredShares)})`;

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedComboboxItems = searchInvestorItems.filter(comboboxItem => !selectedItems.includes(comboboxItem['text']) || comboboxItem['text'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedComboboxItems;
      });

      if (newTableItems['middle']['body']['items'].length >= numberOfInvestors) {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = true;
      }
      else {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = false;
      }

      return newTableItems;
    });
  }, [searchInvestorItems, numberOfInvestors, updateInvestorTableItems]);


  const selectInvestorComboboxItemHandler = useCallback((itemId, investorName) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);

      newTableItems['middle']['body']['items'].filter(item => item['id'] === itemId).forEach(item => {
        const squareWellItemIndex = squareWellInvestors.findIndex(item => item['name'] === investorName);
        if(squareWellItemIndex > -1) {
          item['columns'][1]['type'] = 'default';
          item['columns'][1]['selected'] = squareWellInvestors[squareWellItemIndex]['country'];
          item['columns'][1]['value'] = squareWellInvestors[squareWellItemIndex]['country'];
          item['columns'][1]['text'] = squareWellInvestors[squareWellItemIndex]['country'];
        }
        else {
          item['columns'][1]['type'] = 'dropdown';
        }
        item['columns'][0]['value'] = investorName;
        item['columns'][0]['lastSavedValue'] = investorName;
      });

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedComboboxItems = searchInvestorItems.filter(comboboxItem => !selectedItems.includes(comboboxItem['text']) || comboboxItem['text'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedComboboxItems;
      });
      
      return newTableItems;
    });
  }, [searchInvestorItems, squareWellInvestors, updateInvestorTableItems]);


  const iscCalculateHandler = useCallback((itemId) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);

      const totalEnteredShares = newTableItems['middle']['body']['items'].reduce((acc, curr) => {
        const numberOfShares = getValueFromTextInput(`${curr['id']}#number-of-shares`) !== '' ? parseInt(commaSeperatedValueToNumber(getValueFromTextInput(`${curr['id']}#number-of-shares`))) : 0;
        return acc + numberOfShares;
      }, 0);
      setValueOfParagraph('client-investor-list-num-of-shares', `# OF SHARES (${numberToCommaSeperatedValue(totalEnteredShares)})`);

      const updatedSharesValue = commaSeperatedValueToNumber(getValueFromTextInput(`${itemId}#number-of-shares`));
      if(updatedSharesValue !== '') {
        if(clientType === 'Corporate') {
          setValueOfParagraph(`${itemId}#isc-percentage`, ((parseInt(updatedSharesValue) / totalNumOfShares) * 100).toFixed(2));
        }
        else {
          setValueOfParagraph(`${itemId}#isc-percentage`, '-');
        }
      }
      else {
        setValueOfParagraph(`${itemId}#isc-percentage`, '-');
      }
      
      return prevTableItems;
    });
  }, [updateInvestorTableItems, clientType, totalNumOfShares]);


  const fetchInvestorData = useCallback((tempTableItems) => {
    const data = [];

    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        let investorName = '';
        let numberOfShares = 0;
        let lastMeetingVotes = '';
        if(!isActivated) {
          investorName = getValueFromTextInput(`${itemId}#investor-name`);
          if(clientType === 'Corporate') {
            numberOfShares = commaSeperatedValueToNumber(getValueFromTextInput(`${itemId}#number-of-shares`));
            lastMeetingVotes = getValueFromTextInput(`${itemId}#last-meeting-votes`);
          }
        }
        else {
          investorName = getValueFromParagraph(`${itemId}#investor-name`);
          if(clientType === 'Corporate') {
            numberOfShares = commaSeperatedValueToNumber(getValueFromParagraph(`${itemId}#number-of-shares`));
            lastMeetingVotes = getValueFromParagraph(`${itemId}#last-meeting-votes`);
          }
        }
        
        const squareWellItemIndex = squareWellInvestors.findIndex(item => item['name'] === investorName);
        if (squareWellItemIndex > -1) {
          const country = getValueFromParagraph(`${itemId}#country`);
          data.push({
            'squarewell_investor_id': squareWellInvestors[squareWellItemIndex]['id'],
            'name': investorName,
            'country': country,
            'num_of_shares': numberOfShares,
            'last_general_meeting_votes': lastMeetingVotes,
            'is_custom': false,
          });
        }
        else {
          const country = !isActivated ? getValueFromDropdown(`${itemId}#country`) : getValueFromParagraph(`${itemId}#country`);
          data.push({
            'name': investorName,
            'country': country,
            'num_of_shares': numberOfShares,
            'last_general_meeting_votes': lastMeetingVotes,
            'is_custom': true
          });
        }
      });
    }

    return data;
  }, [isActivated, clientType, squareWellInvestors]);


  const tableExportHandler = () => {
    const data = [];
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);
      return newTableItems;
    });
    
    if (tableItems['middle']['body']['items'] !== null) {
      tableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        let name = '';
        let numberOfShares = '';
        let lastMeetingVotes = '';
        let country = '';
          
        if (isActivated) {
          name = getValueFromParagraph(`${itemId}#investor-name`);
          numberOfShares = commaSeperatedValueToNumber(getValueFromParagraph(`${itemId}#number-of-shares`));
          lastMeetingVotes = getValueFromParagraph(`${itemId}#last-meeting-votes`);
          country = country = getValueFromParagraph(`${itemId}#country`);
        }
        else {
          name = getValueFromTextInput(`${itemId}#investor-name`);
          numberOfShares = commaSeperatedValueToNumber(getValueFromTextInput(`${itemId}#number-of-shares`));
          lastMeetingVotes = getValueFromTextInput(`${itemId}#last-meeting-votes`);

          const countryDropwdownElement = document.getElementById(`${itemId}#country`);
          if(countryDropwdownElement.dataset.selected !== undefined) {
            country = getValueFromDropdown(`${itemId}#country`);
          }
          else {
            country = getValueFromParagraph(`${itemId}#country`);
          }
        }

        if(clientType === 'Corporate') {
          data.push({
            'INVESTOR NAME': name,
            'COUNTRY': country,
            '# OF SHARES': numberOfShares,
            // 'ISC %': isc,
            'LAST GENERAL MEETING VOTES': lastMeetingVotes
          });
        }
        else {
          data.push({
            'INVESTOR NAME': name,
            'COUNTRY': country
          });
        }
      });
    }

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Investor List");
    writeFileXLSX(wb, "InvestorListExport.xlsx");
  }


  const isItemsValid = useCallback((tempTableItems) => {
    let isValid = true;
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const investorName = getValueFromTextInput(`${itemId}#investor-name`);
        const numberOfShares = getValueFromTextInput(`${itemId}#number-of-shares`);
        const lastMeetingVotes = getValueFromTextInput(`${itemId}#last-meeting-votes`);

        if(document.getElementById(`${itemId}#investor-name`).dataset.value === '') {
          document.getElementById(`${itemId}#investor-name`).style.border = '1px solid var(--color-danger-700)';
        }

        const countryDropwdownElement = document.getElementById(`${itemId}#country`);
        if(countryDropwdownElement.dataset.selected !== undefined && countryDropwdownElement.dataset.selected === countryDropwdownElement.dataset.placeholder) {
          countryDropwdownElement.style.border = '1px solid var(--color-danger-700)';
        }

        let country = '';
        if(countryDropwdownElement.dataset.selected !== undefined) {
          country = getValueFromDropdown(`${itemId}#country`);
        }
        else {
          country = getValueFromParagraph(`${itemId}#country`);
        }

        if(numberOfShares === '') {
          document.getElementById(`${itemId}#number-of-shares`).style.border = '1px solid var(--color-danger-700)';
        }
        if(lastMeetingVotes === '') {
          document.getElementById(`${itemId}#last-meeting-votes`).style.border = '1px solid var(--color-danger-700)';
        }
        
        isValid = isValid && (investorName !== '' && country !== '' && numberOfShares !== '' && lastMeetingVotes !== '');
      });
    }
    return isValid;
  }, []);


  const [pagePreference, setPagePreference] = useState('next');
  const saveInvestorList = (activationStatus, pageDirectedTo) => {
    const data = fetchInvestorData(tableItems);
    props.onNextClick(data, activationStatus, pageDirectedTo);
  }

  const showActivationModalHandler = () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);
      return newTableItems;
    });
    if(!isItemsValid(tableItems)) {
      setIsValidationAvailable(true);
      setInvestorListActivateWarning(true);
    }
    else {
      setInvestorListActivateApproval(true);
    }
  }

  const activateWithValidationHandler = () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);
      return newTableItems;
    });
    if(!isItemsValid(tableItems)) {
      setIsValidationAvailable(true);
      setInvestorListActivateApproval(true);
    }
    else {
      setInvestorListActivateApproval(false);
      setIsValidationAvailable(false);
      setIsActivated(true);
      saveInvestorList(true, 'stay');
    }
  }

  const saveWithValidationHandler = (clickedButton) => {
    setPagePreference(clickedButton);
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);
      return newTableItems;
    });
    if(!isActivated && !isItemsValid(tableItems)) {
      setIsValidationAvailable(true);
      setInvestorListSaveWarning(true);
    }
    else {
      setIsValidationAvailable(false);
      saveInvestorList(isActivated, clickedButton);
    }
  }


  useEffect(() => {
    if(isValidationAvailable && tableItems !== '') {
      isItemsValid(tableItems);
    }
  }, [isValidationAvailable, investorListSaveWarning, investorListActivateWarning, investorListActivateApproval, tableItems, isItemsValid]);


  useEffect(() => {
    let newTableItems = null;
    if(isActivated) {
      newTableItems = generateClientSetupShowInvestorListTableItems(clientType, propsInvestorList, totalNumOfShares);
    }
    else {
      newTableItems = generateClientSetupEditInvestorListTableItems(addNewInvestorRowHandler(createInvestorRow, selectInvestorComboboxItemHandler, iscCalculateHandler, removeInvestorRowHandler));
      propsInvestorList.forEach(item => {
        newTableItems['middle']['body']['items'].push(
          createInvestorRow(item, selectInvestorComboboxItemHandler, iscCalculateHandler, removeInvestorRowHandler)
        );
      });

      const totalCalculatedNumOfShares = propsInvestorList.reduce((acc, curr) => acc + parseInt(curr['num_of_shares']), 0);
      newTableItems['middle']['header']['items'][0]['columns'][0]['text'] = `INVESTOR NAME (${propsInvestorList.length})`;
      newTableItems['middle']['header']['items'][0]['columns'][2]['text'] = `# OF SHARES (${numberToCommaSeperatedValue(totalCalculatedNumOfShares)})`;

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedComboboxItems = searchInvestorItems.filter(comboboxItem => !selectedItems.includes(comboboxItem['text']) || comboboxItem['text'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedComboboxItems;
      });

      if (newTableItems['middle']['body']['items'].length >= numberOfInvestors) {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = true;
      }
      else {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = false;
      }
    }
    setTableItems(newTableItems);
  }, [clientType, propsInvestorList, totalNumOfShares, numberOfInvestors, searchInvestorItems, isActivated,
      addNewInvestorRowHandler, createInvestorRow, selectInvestorComboboxItemHandler, iscCalculateHandler, removeInvestorRowHandler]);


  return (
    <div className={styles['client-setup-investor-list-step']}>
      { investorListActivateWarning &&
        <Modal
          type='warning'
          title='Missing Field Error'
          message='One or several fields are missing. Please review and fill in missing information to be able to complete Investor List set-up.'
          onCloseClick={() => setInvestorListActivateWarning(false)}
          approveButton = {{
            'text': 'Close',
            'onClick': () => setInvestorListActivateWarning(false)
          }}
        />
      }

      { investorListActivateApproval &&
        <Modal
          type='warning'
          title='Activate Investor List'
          message='Once "activated", you will no longer be able to edit the list from this screen. You may proceed with further edits from "Client Details page" once the client set-up has been finalized.'
          onCloseClick={() => setInvestorListActivateApproval(false)}
          approveButton = {{
            'text': 'Activate',
            'onClick': () => activateWithValidationHandler()
          }}
          cancelButton = {{
            'text': 'Cancel',
            'onClick': () => setInvestorListActivateApproval(false)
          }}
        />
      }

      { investorListSaveWarning && 
        <Modal
          type='warning'
          title='Missing Field Error'
          message='One or several fields are missing. Please review and fill in missing information to be able to complete Investor List set-up.'
          onCloseClick={() => setInvestorListSaveWarning(false)}
          approveButton = {{
            'text': 'Continue',
            'onClick': () => saveInvestorList(false, pagePreference)
          }}
          cancelButton = {{
            'text': 'Cancel',
            'onClick': () => setInvestorListSaveWarning(false)
          }}
        />
      }

      { tableItems !== null &&
        <div className={`${styles['table-container']} ${hasErrorStyle}`}>
          <div className={styles['investor-table-top-container']}>
            <Button
              type={"secondary"}
              size={"xsmall"}
              leftIcon={"download"}
              onClick={tableExportHandler}
              isDisabled={tableItems !== null && tableItems['middle']['body']['items'].length > 0 ? false : true}
            >
              Download as Excel
            </Button>
          </div>

          <Table width={props.pageWidth} items={tableItems} />

          { !isActivated &&
            <div className={styles['investor-table-bottom-container']} style={{marginBottom: 144}}>
              <Button
                type={"primary"}
                size={"xsmall"}
                onClick={showActivationModalHandler}
                isDisabled={tableItems !== null && tableItems['middle']['body']['items'].length > 0 ? false : true}
              >
                Activate the List
              </Button>
            </div>
          }
        </div>
      }

      { (clientType === 'Corporate' && (numberOfInvestors < 1 || totalNumOfShares < 1)) &&
        <div className={styles['missing-data-error']}>
          <div className={styles['error-box']}>
            <div className={styles['title']}>
              <div className={`${styles['icon']} ${styles['lock']}`}></div>
              <h3>Adding Investor is not available</h3>
            </div>
            <div>
              {/*
              <p>You need to specify Number of Investors in Subscription Details and Total Number 
                  of Shares in General Info section to be able to start adding Investors.</p>
              */}
              <p>You need to specify Number of Investors in Subscription Details section to be able to start adding Investors.</p>
            </div>
          </div>
        </div>
      }
      
      <div className={styles['action-buttons-container']} style={{'width': props.pageWidth}}>
        <div className={styles['left-buttons']}>
          <Button type={'cancel'} size={'base'} leftIcon={'arrow-left'} onClick={() => saveWithValidationHandler('prev')}>Previous</Button>
        </div>
        <div className={styles['right-buttons']}>
          <Button type={'cancel'} size={'base'} onClick={() => saveWithValidationHandler('quit')}>Save & Quit</Button>
          <Button type={'primary'} size={'base'} rightIcon={'arrow-right'} onClick={() => saveWithValidationHandler('next')}>Next</Button>
        </div>
      </div>
    </div>
  )
}

export default ClientSetupInvestorList;
