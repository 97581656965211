import { Fragment, useEffect, useState } from "react";
import { commaSeperatedValueToNumber } from "../../../utils/helpers";
import { engagementRatingDropdownItems, meetingTypeDropdownItems } from "../../../utils/constants";
import { fetchClientBasicProfileRequest, fetchClientInvestorsRequest, fetchClientProxyAdvisorsRequest } from "../../../requests/client-requests";
import { getAuthClientId, getClientType } from '../../../utils/auth';
import { handleRequestError } from "../../../utils/request-error-helpers";
import useHttp from "../../../hooks/use-http";
import useInputValidation from "../../../hooks/use-input-validation";

import Button from "../../UI/Button/Button";
import RadioButtonGroup from "../../UI/RadioButtonGroup/RadioButtonGroup";

import EngagementFormItem from "../EngagementFormItem/EngagementFormItem";

import styles from './EngagementMeetingNoteForm.module.css';

const EngagementMeetingNoteCreateForm = (props) => {

  const clientId = getAuthClientId();
  const clientType = getClientType();

  const [isLoading, error, sendRequest] = useHttp();

  const [clientTotalNumOfShares, setClientTotalNumOfShares] = useState(null);

  const investorId = props.investorId;
  const proxyAdvisorId = props.proxyAdvisorId;
  const isInvestorTypeEditable = props.isInvestorTypeEditable
  const hasFormTitle = props.hasFormTitle;

  const [investorListData, setInvestorListData] = useState([]);
  const [proxyAdvisorListData, setProxyAdvisorListData] = useState([]);

  const [noteHeader, setNoteHeader, isNoteHeaderValid, hasNoteHeaderError, noteHeaderChangeHandler, noteHeaderBlurHandler] =
    useInputValidation('', (noteHeader) => noteHeader.trim() !== '');

  const [noteBody, setNoteBody, isNoteBodyValid, hasNoteBodyError, noteBodyChangeHandler, noteBodyBlurHandler] =
    useInputValidation('', (noteBody) => noteBody.trim() !== '');

  const [nextSteps, setNextSteps, isNextStepsValid, hasNextStepsError, nextStepsChangeHandler, nextStepsBlurHandler] =
    useInputValidation('', (nextSteps) => nextSteps.trim() !== '');

  const [meetingDate, setMeetingDate, isMeetingDateValid, hasMeetingDateError, meetingDateChangeHandler, meetingDateBlurHandler] =
    useInputValidation('', (meetingDate) => meetingDate !== null && meetingDate !== undefined && meetingDate !== '');

  const [meetingType, setMeetingType, isMeetingTypeValid, hasMeetingTypeError, meetingTypeChangeHandler, meetingTypeBlurHandler] =
    useInputValidation('', (meetingType) => meetingType !== '');

  const [engagementRating, setEngagementRating, isEngagementRatingValid, hasEngagementRatingError, engagementRatingChangeHandler, engagementRatingBlurHandler] =
    useInputValidation('', (engagementRating) => engagementRating !== '');

  const [companyRepresentatives, setCompanyRepresentatives, isCompanyRepresentativesValid, hasCompanyRepresentativesError, companyRepresentativesChangeHandler, companyRepresentativesBlurHandler] =
    useInputValidation('', (companyRepresentatives) => companyRepresentatives !== '');

  const [investorName, setInvestorName, isInvestorNameValid, hasInvestorNameError, investorNameChangeHandler, investorNameBlurHandler] =
  useInputValidation('', (investorName) => investorName.trim() !== '');

  const [investorNumOfShares, setInvestorNumOfShares, isInvestorNumOfSharesValid, hasInvestorNumOfSharesError, investorNumOfSharesChangeHandler, investorNumOfSharesBlurHandler] =
    useInputValidation('', () => true);

  const [investorRepresentatives, setInvestorRepresentatives, isInvestorRepresentativesValid, hasInvestorRepresentativesError, investorRepresentativesChangeHandler, investorRepresentativesBlurHandler] =
    useInputValidation('', (investorRepresentatives) => investorRepresentatives !== '');

  const [proxyAdvisorName, setProxyAdvisorName, isProxyAdvisorNameValid, hasProxyAdvisorNameError, proxyAdvisorNameChangeHandler, proxyAdvisorNameBlurHandler] =
    useInputValidation('', (proxyAdvisorName) => proxyAdvisorName.trim() !== '');

  const [proxyAdvisorRepresentatives, setProxyAdvisorRepresentatives, isProxyAdvisorRepresentativesValid, hasProxyAdvisorRepresentativesError, proxyAdvisorRepresentativesChangeHandler, proxyAdvisorRepresentativesBlurHandler] =
    useInputValidation('', (proxyAdvisorRepresentatives) => proxyAdvisorRepresentatives !== '');

  const [noteAttachments, setNoteAttachments] = useState([]);
  const noteBodyAttachments = noteAttachments.filter(item => item['attachment_section'] === 'Note Body');
  const noteNextStepsAttachments = noteAttachments.filter(item => item['attachment_section'] === 'Next Steps');
  
  const [selectedInvestorType, setSelectedInvestorType] = useState(props.investorType ? props.investorType : 'type-investor');

  const radioButtonChangeToInvestorHandler = () => {
    setSelectedInvestorType('type-investor');
  }

  const radioButtonChangeToProxyAdvisorHandler = () => {
    setSelectedInvestorType('type-proxy-advisor');
  }

  const investorTypeRadioButtonItems = [
    {
      id: 'type-investor',
      value: 'Investor',
      text: 'Investor',
      isChecked: true,
      onChange: radioButtonChangeToInvestorHandler
    },
    {
      id: 'type-proxy-advisor',
      value: 'Proxy Advisor',
      text: 'Proxy Advisor',
      isChecked: false,
      onChange: radioButtonChangeToProxyAdvisorHandler
    }
  ];

  /* Send a request to fetch investor and proxy advisor list for the client to
   * fill combobox values located in the new meeting note form. */
  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (data) => { setClientTotalNumOfShares(data['total_num_of_shares']); }
    );
    
    if (isInvestorTypeEditable || selectedInvestorType === 'type-investor') {
      sendRequest(
        fetchClientInvestorsRequest(clientId),
        (data) => {
          if(investorId !== undefined) {
            const investor = data['items'].find(item => item['id'].toString() === investorId)
            setInvestorName(investor['name']);
            setInvestorNumOfShares(investor['num_of_shares']);
          }
          setInvestorListData(data['items']);
        }
      );
    }
    else {
      setInvestorListData([]);
    }

    if (isInvestorTypeEditable || selectedInvestorType === 'type-proxy-advisor') {
      sendRequest(
        fetchClientProxyAdvisorsRequest(clientId),
        (data) => {
          if(proxyAdvisorId !== undefined) {
            const proxyAdvisor = data['items'].find(item => item['id'].toString() === proxyAdvisorId)
            setProxyAdvisorName(proxyAdvisor['name']);
          }
          setProxyAdvisorListData(data['items']);
        }
      );
    }
    else {
      setProxyAdvisorListData([]);
    }
  }, [sendRequest, clientId, isInvestorTypeEditable, selectedInvestorType, investorId, proxyAdvisorId,
      setInvestorName, setInvestorNumOfShares, setProxyAdvisorName]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const investorComboboxItems = investorListData.map(item => {
    return {
      'id': item['id'],
      'text': item['name']
    };
  });

  const proxyAdvisorComboboxItems = proxyAdvisorListData.map(item => {
    return {
      'id': item['id'],
      'text': item['name']
    };
  });

  useEffect(() => {
    /* Shared fields for both investor and proxy advisor notes. */
    setNoteHeader('');
    setNoteBody('');
    setNextSteps('');
    setMeetingDate('');
    setMeetingType('');
    setEngagementRating('');
    setCompanyRepresentatives('');
    setNoteAttachments([]);
    /* Investor specific fields. */
    setInvestorName('');
    setInvestorNumOfShares('');
    setInvestorRepresentatives('');
    /* Proxy advisor specific fields. */
    setProxyAdvisorName('');
    setProxyAdvisorRepresentatives('');
  }, [setCompanyRepresentatives, setEngagementRating, setInvestorName, setInvestorNumOfShares, 
      setInvestorRepresentatives, setMeetingDate, setMeetingType, setNextSteps, setNoteBody, 
      setNoteHeader, setProxyAdvisorName, setProxyAdvisorRepresentatives]);

  
  const meetingNoteBodyAttachmentsChangeHandler = (event) => {
    meetingNoteAttachmentsChangeHandler(event, 'Note Body');
  }

  const meetingNoteNextStepsAttachmentsChangeHandler = (event) => {
    meetingNoteAttachmentsChangeHandler(event, 'Next Steps');
  }

  const meetingNoteAttachmentsChangeHandler = (event, attachmentSection) => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if(file.size > 500000){
        alert('File size should be smaller than 450Kb');
        return false;				
      }

      var data = new FormData();
      data.append('attachment', file, file.name);
      props.onAttachmentUploadClick(data, attachmentSection, setNoteAttachments);
    }
  }

  const meetingNoteAttachmentRemoveHandler = (clickedAttachmentId) => {
    setNoteAttachments(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const attachmentIndex = items.findIndex(item => item['id'] === clickedAttachmentId);
      items.splice(attachmentIndex, 1);
      return items;
    });
  }


  const isMeetingNoteFormValid = () => {
    noteHeaderBlurHandler();
    noteBodyBlurHandler();
    nextStepsBlurHandler();
    meetingDateBlurHandler();
    meetingTypeBlurHandler();
    engagementRatingBlurHandler();
    companyRepresentativesBlurHandler();
    
    const isGeneralInfoValid = isNoteHeaderValid && isNoteBodyValid && isNextStepsValid && 
      isMeetingDateValid && isMeetingTypeValid && isEngagementRatingValid && isCompanyRepresentativesValid;
    if (selectedInvestorType === 'type-investor') {
      investorNameBlurHandler();
      investorNumOfSharesBlurHandler();
      investorRepresentativesBlurHandler();
      return isGeneralInfoValid && isInvestorNameValid && isInvestorNumOfSharesValid && isInvestorRepresentativesValid;
    }
    else if (selectedInvestorType === 'type-proxy-advisor') {
      proxyAdvisorNameBlurHandler();
      proxyAdvisorRepresentativesBlurHandler();
      return isGeneralInfoValid && isProxyAdvisorNameValid && isProxyAdvisorRepresentativesValid;
    }
    else {
      return false;
    }
  }

  const saveMeetingNoteClickHandler = () => {
    if (!isMeetingNoteFormValid()) {
      return;
    }

    const meetingNoteData = {
      'note_header': noteHeader,
      'note_body': noteBody,
      'next_steps': nextSteps,
      'meeting_date': meetingDate,
      'meeting_type': meetingType,
      'company_representatives': companyRepresentatives,
      'engagement_rating': engagementRating,
      'note_attachments': noteAttachments
    };

    if (selectedInvestorType === 'type-investor') {
      const itemIndex = investorComboboxItems.findIndex(item => item['text'] === investorName);
      meetingNoteData['investor_type'] = 'Investor';
      meetingNoteData['investor'] = {
        'id': investorComboboxItems[itemIndex]['id'],
        'name': investorName,
        'representatives': investorRepresentatives
      };
      if(clientType === 'Corporate') {
        meetingNoteData['investor']['num_of_shares'] = commaSeperatedValueToNumber(investorNumOfShares);
        meetingNoteData['investor']['isc'] = (commaSeperatedValueToNumber(investorNumOfShares) / clientTotalNumOfShares) * 100;
      }
      else {
        meetingNoteData['investor']['num_of_shares'] = 0;
        meetingNoteData['investor']['isc'] = 0;
      }
      props.onSaveClick(meetingNoteData);
    }
    else if (selectedInvestorType === 'type-proxy-advisor') {
      const itemIndex = proxyAdvisorComboboxItems.findIndex(item => item['text'] === proxyAdvisorName);
      meetingNoteData['investor_type'] = 'Proxy Advisor';
      meetingNoteData['proxy_advisor'] = {
        'id': proxyAdvisorComboboxItems[itemIndex]['id'],
        'name': proxyAdvisorName,
        'representatives': proxyAdvisorRepresentatives
      };
      props.onSaveClick(meetingNoteData);
    }
    else {
      return;
    }
  }


  useEffect(() => {
    const selectedInvestor = investorListData.filter(item => item['name'] === investorName);
    if (selectedInvestor.length > 0) {
      setInvestorNumOfShares(selectedInvestor[0]['num_of_shares']);
    }
  }, [investorName, investorListData, setInvestorNumOfShares]);

  
  return (
    <div className={styles['meeting-note-container']}>
      <div className={styles['header']}>
        {hasFormTitle && 
          <div className={styles['form-title']}>
            <h2>Create a Meeting Note</h2>
          </div>
        }
        <div className={styles['meeting-note-title-container']}>
          <EngagementFormItem
            id='investor-meeting-note#note-header'
            type='text-input'
            label='Note Header'
            value={noteHeader}
            placeholder='Enter Note Header'
            hasError={hasNoteHeaderError}
            onBlur={noteHeaderBlurHandler}
            onChange={noteHeaderChangeHandler}
          />
        </div>
        
        <div className={styles['button-container']}>
          <Button type={"cancel"} size={"xsmall"} onClick={props.onCancel}>Cancel</Button>
          <Button type={'primary'} size={'xsmall'} onClick={saveMeetingNoteClickHandler}>Save</Button>
        </div>
      </div>
      <div className={styles['body']}>
        <div className={styles['meeting-note-content-container']}>
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#note-body'
              type='rich-text-input'
              label='Note Body'
              value={noteBody}
              placeholder='Enter Note'
              hasError={hasNoteBodyError}
              onBlur={noteBodyBlurHandler}
              onChange={noteBodyChangeHandler}
            />
          </div>

          <div className={styles['row']}>
            { noteBodyAttachments.length > 0 &&
              <Fragment>
                {noteBodyAttachments.map(item => {
                  return (
                    <EngagementFormItem
                      key={`attached-item-${item['id']}`}
                      type='attached-item'
                      value={item['attachment_name']}
                      isRemovable={true}
                      onItemClick={() => window.open(item['attachment_url'], '_blank')}
                      onRemoveClick={() => meetingNoteAttachmentRemoveHandler(item['id'])}
                    />
                  );
                })}
                
                <EngagementFormItem
                  type='new-attachment-icon-button'
                  onChange={meetingNoteBodyAttachmentsChangeHandler}
                />
              </Fragment>
            }

            { noteBodyAttachments.length === 0 &&
              <EngagementFormItem
                type='new-attachment-button'
                onChange={meetingNoteBodyAttachmentsChangeHandler}
              />
            }
          </div>

          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#next-steps'
              type='rich-text-input'
              label='Next Steps'
              value={nextSteps}
              placeholder='Enter Next Steps'
              hasError={hasNextStepsError}
              onBlur={nextStepsBlurHandler}
              onChange={nextStepsChangeHandler}
            />
          </div>

          <div className={styles['row']}>
            { noteNextStepsAttachments.length > 0 &&
              <Fragment>
                {noteNextStepsAttachments.map(item => {
                  return (
                    <EngagementFormItem
                      key={`attached-item-${item['id']}`}
                      type='attached-item'
                      value={item['attachment_name']}
                      isRemovable={true}
                      onItemClick={() => window.open(item['attachment_url'], '_blank')}
                      onRemoveClick={() => meetingNoteAttachmentRemoveHandler(item['id'])}
                    />
                  );
                })}
                
                <EngagementFormItem
                  type='new-attachment-icon-button'
                  onChange={meetingNoteNextStepsAttachmentsChangeHandler}
                />
              </Fragment>
            }

            { noteNextStepsAttachments.length === 0 &&
              <EngagementFormItem
                type='new-attachment-button'
                onChange={meetingNoteNextStepsAttachmentsChangeHandler}
              />
            }
          </div>
        </div>

        <div className={styles['meeting-note-details-container']}>
          {isInvestorTypeEditable &&
            <div className={styles['row']}>
              <RadioButtonGroup
                name='type-investor'
                items={investorTypeRadioButtonItems}
                selected={selectedInvestorType}
              />
            </div>
          }

          {selectedInvestorType === 'type-investor' && 
            <Fragment>
              <div className={styles['row']}>
                <EngagementFormItem
                  id='investor-meeting-note#investor-name'
                  type='combobox'
                  label='Investor'
                  value={investorName}
                  items={investorComboboxItems}
                  placeholder='Select Investor'
                  hasError={hasInvestorNameError}
                  onBlur={investorNameBlurHandler}
                  onChange={investorNameChangeHandler}
                  isDisabled={!isInvestorTypeEditable}
                />
              </div>
              {clientType === 'Corporate' && 
                <div className={styles['row']}>
                  <EngagementFormItem
                    id='investor-meeting-note#investor-num-of-shares'
                    type='number-input'
                    label='# of Shares'
                    value={investorNumOfShares}
                    placeholder='Enter # of Shares'
                    hasError={hasInvestorNumOfSharesError}
                    onBlur={investorNumOfSharesBlurHandler}
                    onChange={investorNumOfSharesChangeHandler}
                  />
                </div>
              }
            </Fragment>
          }
          {selectedInvestorType === 'type-proxy-advisor' && 
            <div className={styles['row']}>
              <EngagementFormItem
                id='investor-meeting-note#proxy-advisor-name'
                type='combobox'
                label='Proxy Advisor'
                value={proxyAdvisorName}
                items={proxyAdvisorComboboxItems}
                placeholder='Select Proxy Advisor'
                hasError={hasProxyAdvisorNameError}
                onBlur={proxyAdvisorNameBlurHandler}
                onChange={proxyAdvisorNameChangeHandler}
              />
            </div>
          }
          
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#meeting-date'
              type='date-input'
              label='Meeting Date'
              value={meetingDate}
              placeholder='DD / MM / YYYY'
              hasError={hasMeetingDateError}
              onBlur={meetingDateBlurHandler}
              onChange={meetingDateChangeHandler}
            />
          </div>
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#meeting-type'
              type='dropdown'
              label='Meeting Type'
              value={meetingType}
              placeholder='Select Meeting Type'
              items={meetingTypeDropdownItems}
              hasError={hasMeetingTypeError}
              onBlur={meetingTypeBlurHandler}
              onChange={meetingTypeChangeHandler}
            />
          </div>
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#engagement-rating'
              type='dropdown'
              label='Engagement Rating'
              value={engagementRating}
              placeholder='Select Engagement Rating'
              items={engagementRatingDropdownItems}
              hasChip={true}
              hasError={hasEngagementRatingError}
              onBlur={engagementRatingBlurHandler}
              onChange={engagementRatingChangeHandler}
            />
          </div>
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#company-representatives'
              type='textarea-input'
              height='120px'
              label='Company Representative(s)'
              value={companyRepresentatives}
              placeholder='Enter Company Representative(s)'
              hasError={hasCompanyRepresentativesError}
              onBlur={companyRepresentativesBlurHandler}
              onChange={companyRepresentativesChangeHandler}
            />
          </div>

          {selectedInvestorType === 'type-investor' && 
            <div className={styles['row']}>
              <EngagementFormItem
                id='investor-meeting-note#investor-representatives'
                type='textarea-input'
                height='120px'
                label='Investor Representative(s)'
                value={investorRepresentatives}
                placeholder='Enter Investor Representative(s)'
                hasError={hasInvestorRepresentativesError}
                onBlur={investorRepresentativesBlurHandler}
                onChange={investorRepresentativesChangeHandler}
              />
            </div>
          }
          {selectedInvestorType === 'type-proxy-advisor' && 
            <div className={styles['row']}>
              <EngagementFormItem
                id='investor-meeting-note#proxy-advisor-representatives'
                type='textarea-input'
                height='120px'
                label='Proxy Advisor Representative(s)'
                value={proxyAdvisorRepresentatives}
                placeholder='Enter Proxy Advisor Representative(s)'
                hasError={hasProxyAdvisorRepresentativesError}
                onBlur={proxyAdvisorRepresentativesBlurHandler}
                onChange={proxyAdvisorRepresentativesChangeHandler}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default EngagementMeetingNoteCreateForm;
