import { Fragment, useCallback, useEffect, useState } from "react";
import { generateClientInvestorEngagementsOtherNotesTableItems } from "../../../../../utils/client-investor-helpers";
import { createClientEngagementsOtherNoteRequest, listClientEngagementsOtherNotesRequest, updateClientEngagementsOtherNoteRequest, uploadClientEngagementsOtherNoteAttachmentRequest } from "../../../../../requests/client-engagement-requests";
import { getAuthClientId } from "../../../../../utils/auth";
import { handleRequestError } from "../../../../../utils/request-error-helpers";
import useHttp from "../../../../../hooks/use-http";
import sortArray from 'sort-array';

import Button from "../../../../../components/UI/Button/Button";
import Table from "../../../../../components/UI/Table/Table";

import EngagementOtherNoteCreateForm from "../../../../../components/EngagementForm/EngagementOtherNoteForm/EngagementOtherNoteCreateForm";
import EngagementOtherNoteUpdateForm from "../../../../../components/EngagementForm/EngagementOtherNoteForm/EngagementOtherNoteUpdateForm";

import styles from '../InvestorEngagements.module.css';

const InvestorEngagementsOtherNotes = (props) => {

  const clientId = getAuthClientId();
  const investorId = props.investorId;
  const setShowEngagementsTab = props.setShowEngagementsTab;

  const [isLoading, error, sendRequest] = useHttp();

  const [investorEngagementsOtherNotesData, setInvestorEngagementsOtherNotesData] = useState([]);
  const [tableItems, setTableItems] = useState(null);

  const [selectedOtherNoteId, setSelectedOtherNoteId] = useState(null);
  const [showOtherNoteForm, setShowOtherNoteForm] = useState(false);
  const [otherNoteFormType, setOtherNoteFormType] = useState('new');

  const [tableSortConfig, setTableSortConfig] = useState({
    'by': ['header'],
    'order': ['asc']
  });

  const investorEnagementsOtherNotesSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  /* Open new other note creation form. */
  const addNewOtherNoteClickHandler = () => {
    setShowEngagementsTab(false);
    setOtherNoteFormType('new');
    setShowOtherNoteForm(true);
  };

  /* Upload attachment for the other. */
  const uploadClientOtherNoteAttachmentHandler = (attachmentData, setAttachments) => {
    const otherNoteId = selectedOtherNoteId !== null ? selectedOtherNoteId : 'temp';
    sendRequest(
      uploadClientEngagementsOtherNoteAttachmentRequest(clientId, otherNoteId, attachmentData),
      (data) => { 
        setAttachments(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          items.push(data)
          return items;
        });
      }
    );
  }

  /* Create new other note. */
  const clientOtherNoteCreateHandler = (otherNoteData) => {
    const createClientEngagementsOtherNoteResponse = (data) => {
      setInvestorEngagementsOtherNotesData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        items.push(data)
        return items;
      });
      setShowOtherNoteForm(false);
      setShowEngagementsTab(true);
    }
    sendRequest(
      createClientEngagementsOtherNoteRequest(clientId, otherNoteData),
      createClientEngagementsOtherNoteResponse
    );
  }

  /* Update selected other note. */
  const clientOtherNoteEditHandler = (otherNoteData, setOtherNoteData) => {
    const updateClientEngagementsOtherNoteResponse = (data) => {
      setInvestorEngagementsOtherNotesData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        const otherNoteIndex = items.findIndex(item => item['id'] === parseInt(selectedOtherNoteId));
        items[otherNoteIndex] = data;
        return items;
      });
      setOtherNoteData(data);
    }
    sendRequest(
      updateClientEngagementsOtherNoteRequest(clientId, selectedOtherNoteId, otherNoteData),
      updateClientEngagementsOtherNoteResponse
    );
  }
  
  /* Show other note details. */
  const otherNoteClickHandler = useCallback((clickedItemId) => {
    const otherNoteId = clickedItemId.split('client-investor-engagements-other-note-')[1].split('#')[0];
    setShowEngagementsTab(false);
    setOtherNoteFormType('edit');
    setShowOtherNoteForm(true);
    setSelectedOtherNoteId(otherNoteId);
  }, [setShowEngagementsTab]);

  /* Return back to other notes table. */
  const returnToOtherNotesClickHandler = () => {
    setShowOtherNoteForm(false);
    setShowEngagementsTab(true);
    setSelectedOtherNoteId(null);
  }

  /* Send a request to fetch other note items and set the list with the response. */
  useEffect(() => {
    const queryParams = `investor_id=${investorId}`;
    sendRequest(
      listClientEngagementsOtherNotesRequest(clientId, queryParams),
      (data) => { setInvestorEngagementsOtherNotesData(data['items']); }
    );
  }, [sendRequest, clientId, investorId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  /* Sort list items when sort configuration is updated. */
  useEffect(() => {
    setInvestorEngagementsOtherNotesData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, tableSortConfig);
    });
  }, [tableSortConfig]);

  /* Generate other notes list. */
  useEffect(() => {
    setTableItems(
      generateClientInvestorEngagementsOtherNotesTableItems(
        investorEngagementsOtherNotesData, otherNoteClickHandler, tableSortConfig, investorEnagementsOtherNotesSortHandler
      )
    );
  }, [investorEngagementsOtherNotesData, tableSortConfig, otherNoteClickHandler]);


  return (
    <Fragment>
      { !isLoading && tableItems && tableItems['middle']['body']['items'].length === 0 && !showOtherNoteForm && 
        <div className={styles['no-enagements-div']}>
          <div className={styles['icon-container']}>
            <div className={styles['icon']}></div>
          </div>
          <div className={styles['text-container']}>
            <h4>You Have No Other Notes</h4>
            <p>Use below button to Create an Other Note</p>
          </div>
          <div className={styles['button-container']}>
            <Button
              type={"primary"}
              leftIcon={"plus"}
              size={"xsmall"}
              onClick={addNewOtherNoteClickHandler}>
                Create Other Note
              </Button>
          </div>
        </div>
      }

      { !isLoading && tableItems && tableItems['middle']['body']['items'].length > 0 && !showOtherNoteForm &&
        <Fragment>
          <div className={styles['investor-engagement-list-buttons']}>  
            <div className={styles['right-buttons']}>
              <Button type={'primary'} size={'xsmall'} leftIcon={'plus'} onClick={addNewOtherNoteClickHandler}>Create Other Note</Button>
            </div>
          </div>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={tableItems} isScrollable={false} />
        </Fragment>
      }

      { showOtherNoteForm && otherNoteFormType === 'new' &&
        <EngagementOtherNoteCreateForm
          investorId={investorId}
          investorType='type-investor'
          isInvestorTypeEditable={false}
          onAttachmentUploadClick={uploadClientOtherNoteAttachmentHandler}
          onSaveClick={clientOtherNoteCreateHandler}
          onCancel={returnToOtherNotesClickHandler}
        />
      }

      { showOtherNoteForm && otherNoteFormType === 'edit' && selectedOtherNoteId &&
        <EngagementOtherNoteUpdateForm
          otherNoteId={selectedOtherNoteId}
          investorId={investorId}
          investorType='type-investor'
          isInvestorTypeEditable={false}
          hasBackButton={true}
          onAttachmentUploadClick={uploadClientOtherNoteAttachmentHandler}
          onSaveClick={clientOtherNoteEditHandler}
          onBackClick={returnToOtherNotesClickHandler}
        />
      }
    </Fragment>
  );
}

export default InvestorEngagementsOtherNotes;
