import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useResizeDetector } from 'react-resize-detector';
import { clientMenuItems } from '../../../utils/constants';
import { fetchClientBasicProfileRequest, fetchClientInvestorDetailsRequest, fetchClientInvestorsRequest } from '../../../requests/client-requests';
import { checkIsAccountManagerViewMode, getAuthClientId, getAuthClientName, getAuthUserProfilePictureUrl, getClientAvailableModules, getClientType, setLocalStorageClientData } from '../../../utils/auth';
import { getPageStyle, updatePageStyle } from "../../../utils/helpers";
import { handleRequestError } from '../../../utils/request-error-helpers';
import useHttp from '../../../hooks/use-http';

import AccountManagerModeHeader from '../../../components/AccountManagerModeHeader/AccountManagerModeHeader';
import SideNavigationBar from '../../../components/UI/SideNavigationBar/SideNavigationBar';

import Breadcrumbs from '../../../components/UI/Breadcrumbs/Breadcrumbs';
import SearchBox from '../../../components/UI/SearchBox/SearchBox';
import Tab from '../../../components/UI/Tab/Tab';

import InvestorContactList from './InvestorContactList/InvestorContactList';
import InvestorEngagements from './InvestorEngagements/InvestorEngagements';
import InvestorEsgTopics from './InvestorEsgTopics/InvestorEsgTopics';
import InvestorSummary from './InvestorSummary/InvestorSummary';

import styles from './InvestorDetailsPage.module.css';

const InvestorDetailsPage = (props) => {

  const clientId = getAuthClientId();
  const clientAvailableModules = getClientAvailableModules();
  const userProfilePictureUrl = getAuthUserProfilePictureUrl();

  const { investorId } = useParams();

  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  const pageHeightMargin = checkIsAccountManagerViewMode() ? '161px' : '95px';
  /* eslint-disable no-unused-vars */

  const [isLoading, error, sendRequest] = useHttp();

  const [clientType, setClientType] = useState(getClientType());
  const [clientTotalNumOfShares, setClientTotalNumOfShares] = useState(null);
  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);

  const [tabItems, setTabItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedTab, setSelectedTab] = useState('tab-details');
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [searchInvestorItems, setSearchInvestorItems] = useState([]);

  const investorDetailsTabChangeHandler = (selectedTabId) => {
    setSelectedTab(selectedTabId);

    let tabTitle = '';
    if(selectedTabId === 'tab-details') {
      tabTitle = 'Investor Details';
    }
    else if(selectedTabId === 'tab-engagements') {
      tabTitle = 'Engagements';
    }
    else if(selectedTabId === 'tab-contact-list') {
      tabTitle = 'Contact List';
    }
    else if(selectedTabId === 'tab-esg-topics') {
      tabTitle = 'ESG Topics';
    }
    setBreadcrumbItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      items[2]['text'] = tabTitle;
      return items;
    });
  }

  const selectInvestorSearchResultHandler = (selectedItemId) => {
    window.location.href = `./${selectedItemId}`;
  }

  const [investorData, setInvestorData] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState('');

  const investorTabItems = useMemo(() => [
    {
      'id': 'tab-details',
      'text': 'Details'
    },
    {
      'id': 'tab-esg-topics',
      'text': 'ESG Topics'
    },
    {
      'id': 'tab-engagements',
      'text': 'Engagements'
    },
    {
      'id': 'tab-contact-list',
      'text': 'Contact List'
    }
  ], []);

  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (dataClient) => {
        setLocalStorageClientData(dataClient);
        setClientType(dataClient['client_type']);
        setClientTotalNumOfShares(dataClient['total_num_of_shares']);
        setClientProfilePictureUrl(dataClient['profile_picture_url']);

        sendRequest(
          fetchClientInvestorDetailsRequest(clientId, investorId),
          (dataInvestorDetails) => {
            setInvestorData(dataInvestorDetails);
            setProfilePictureUrl(dataInvestorDetails['profile_picture_url']);
            setBreadcrumbItems([{
              'text': 'Investors',
              'href': '../investors'
            },
            {
              'text': dataInvestorDetails['name'],
              'href': `../investors/${investorId}`
            },
            {
              'text': 'Investor Details',
              'href': ''
            }]);

            const filteredTabItems = [];
            if(dataInvestorDetails['is_custom'] || !dataClient['module_management']['esg_topics']) {
              filteredTabItems.push('tab-esg-topics');
            }
            if(!dataClient['module_management']['engagements']) {
              filteredTabItems.push('tab-engagements');
            }
            setTabItems(investorTabItems.filter(item => !filteredTabItems.includes(item['id'])));
          }
        );

        sendRequest(
          fetchClientInvestorsRequest(clientId),
          (dataInvestors) => {
            const newSearchInvestorItems = dataInvestors['items'].map(item => {
              return {
                'id': item['id'],
                'text': item['name']
              }
            });
            setSearchInvestorItems(newSearchInvestorItems);
          }
        );
      }
    );
  }, [investorId, clientId, investorTabItems, sendRequest]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  return (
    <div className={styles['page']}>
      {checkIsAccountManagerViewMode() &&
        <AccountManagerModeHeader
          type={pageStyle === 'wide' ? 'wide' : 'narrow'}
          clientName={getAuthClientName()}
          exitRedirecUrl='../clients'
        />
      }

      <SideNavigationBar
        menuItems={clientMenuItems.filter(item => clientAvailableModules[item['module_id']])}
        profilePictureUrl={userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}
        selected='investors'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      {investorData !== null && 
        <div
          id='page-right-container'
          className={`${styles['container']} ${styles[checkIsAccountManagerViewMode() ? 'with-account-manager-warning' : '']} ${styles[pageStyle]}`}
          style={{ height: `calc(100% - ${pageHeightMargin})`, overflowY: 'auto', overflowX: 'hidden' }}
          ref={ref}
        >
          <Breadcrumbs items={breadcrumbItems} />

          <div className={styles['page-header']}>
            <div className={styles['section']}>
              <div className={styles['row']}>
                <div className={styles['title']}>
                  <h1>Investors</h1>
                </div>
                <div className={styles['search-box']}>
                  <SearchBox
                    items={searchInvestorItems}
                    value={searchValue}
                    placeholder="Search Investors"
                    onChange={setSearchValue}
                    onItemSelect={selectInvestorSearchResultHandler}
                    size={"xsmall"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles['page-body']}>
            <div className={styles['investor-details-container']}>
              <div className={styles['investor-details-header']}>
                { (profilePictureUrl !== '' && profilePictureUrl !== null) && 
                  <div
                    id="investorProfilePicture"
                    className={styles['logo']}
                    style={{
                      'background': `url("${profilePictureUrl}") center center no-repeat`,
                      'backgroundSize': 'cover'
                    }}></div>
                }
                { (profilePictureUrl === '' || profilePictureUrl === null) && 
                  <div className={styles['logo']}>
                    <div className={styles['missing-logo']}></div>
                  </div>
                }
                <div className={styles['title-container']}>
                  <div className={styles['title']}>
                    <h1>{investorData['name']}</h1>
                  </div>
                  {clientType === 'Corporate' && 
                    <div className={styles['isc-percentage']}>
                      <h4>{((investorData['num_of_shares'] / clientTotalNumOfShares) * 100).toFixed(2) + '%'}</h4>
                    </div>
                  }
                  {investorData['is_custom'] && 
                    <div className={styles['custom-investor-ribbon']}>
                      <p>Custom Investor</p>
                    </div>
                  }
                </div>
                <div className={styles['tab-container']}>
                  <Tab items={tabItems} selectedTab={selectedTab} onTabChange={investorDetailsTabChangeHandler} />
                </div>
              </div>
              
              <div className={styles['investor-details-body']}>
                { selectedTab === 'tab-details' && 
                  <InvestorSummary
                    investorId={investorId}
                    onProfilePictureChange={setProfilePictureUrl}
                  />
                }
                { selectedTab === 'tab-esg-topics' && 
                  <InvestorEsgTopics
                    width={width}
                    investorId={investorId}
                    investorName={investorData['name']}
                    investorProfilePicture={profilePictureUrl}
                  />
                }
                { selectedTab === 'tab-engagements' &&
                  <InvestorEngagements
                    width={width}
                    investorId={investorId}
                  />
                }
                { selectedTab === 'tab-contact-list' && 
                  <InvestorContactList
                    width={width}
                    investorId={investorId}
                  />
                }
              </div>
            </div>
          </div>
          
        </div>
      }
    </div>
  );
}

export default InvestorDetailsPage;
