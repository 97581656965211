import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { generateClientSetupEditProxyAdvisorTableItems, generateClientSetupShowProxyAdvisorTableItems } from '../../../../utils/account-manager-helper';
import { createId, getValueFromDropdown, getValueFromParagraph, getValueFromTextInput } from '../../../../utils/helpers';

import Button from '../../../../components/UI/Button/Button';
import Modal from '../../../../components/UI/Modal/Modal';
import Table from '../../../../components/UI/Table/Table';

import styles from './ClientSetupProxyAdvisors.module.css';


const ClientSetupProxyAdvisors = (props) => {
  const [tableItems, setTableItems] = useState(null);
  const [isActivated, setIsActivated] = useState(props.proxyAdvisorListActivated);
  
  const [proxyAdvisorsSaveWarning, setProxyAdvisorsSaveWarning] = useState(false);
  const [proxyAdvisorsActivateApproval, setProxyAdvisorsActivateApproval] = useState(false);
  const [proxyAdvisorsActivateWarning, setProxyAdvisorsActivateWarning] = useState(false);
  const [isValidationAvailable, setIsValidationAvailable] = useState(false);
  
  const clientType = props.clientType;
  const propsProxyAdvisorList = props.proxyAdvisorList;
  const squareWellProxyAdvisors = props.squareWellProxyAdvisors;

  const dropdownProxyAdvisorItems = useMemo(() => squareWellProxyAdvisors.map(item => {
    return {
      'id': item['id'],
      'value': item['name']
    }
  }), [squareWellProxyAdvisors]);


  const createProxyAdvisorRow = useCallback((item, selectProxyAdvisorDropdownItemHandler, deleteRowFunction) => {
    const newItemId = `client-user-${createId(4)}`;
    const proxyAdvisorName = item['proxy_advisor_name'];
    
    let lastAgmRecommendations = '-';
    if(clientType === 'Corporate') {
      lastAgmRecommendations = item['last_general_meeting_recommendations'];
    }

    return {
      'id': newItemId,
      'columns': [
        {
          'id': `${newItemId}#proxy-advisor-name`,
          'type': 'dropdown',
          'placeholder': 'Select Proxy Advisor',
          'selected': proxyAdvisorName,
          'value': proxyAdvisorName,
          'items': dropdownProxyAdvisorItems,
          'onSelected': selectProxyAdvisorDropdownItemHandler,
          'width': 248,
          'stretch': false
        },
        {
          'id': `${newItemId}#last-agm-recommendations`,
          'type': 'text-input',
          'placeholder': 'Enter Last AGM Recommendations',
          'value': lastAgmRecommendations,
          'isDisabled': clientType === 'Corporate' ? false : true,
          'width': 344,
          'stretch': false
        },
        {
          'type': 'empty-cell',
          'stretch': true
        },
        {
          'id': `${newItemId}#delete-button`,
          'icon': 'trash',
          'type': 'icon-button',
          'onClick': deleteRowFunction
        }
      ]
    };
  }, [clientType, dropdownProxyAdvisorItems]);


  const updateProxyAdvisorTableItems = useCallback((tempTableItems) => {
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const proxyAdvisorName = getValueFromDropdown(`${itemId}#proxy-advisor-name`);
        
        let lastAgmRecommendations = '-';
        if(clientType === 'Corporate') {
          lastAgmRecommendations = getValueFromTextInput(`${itemId}#last-agm-recommendations`);
        }
  
        item['columns'][0]['selected'] = proxyAdvisorName;
        item['columns'][0]['value'] = proxyAdvisorName;
        item['columns'][1]['value'] = lastAgmRecommendations;
      });
    }
    return tempTableItems['middle']['body']['items'];
  }, [clientType]);


  const addNewProxyAdvisorRowHandler = useCallback((createProxyAdvisorRow, selectProxyAdvisorDropdownItemHandler, removeProxyAdvisorRowHandler) => () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateProxyAdvisorTableItems(newTableItems);
      newTableItems['middle']['body']['items'].push(
        createProxyAdvisorRow({
          'proxy_advisor_name': '',
          'last_general_meeting_recommendations': ''
        }, selectProxyAdvisorDropdownItemHandler, removeProxyAdvisorRowHandler)
      );    
      
      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedDropdownItems = dropdownProxyAdvisorItems.filter(dropdownItem => !selectedItems.includes(dropdownItem['value']) || dropdownItem['value'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedDropdownItems;
      });
      return newTableItems;
    });
    setIsValidationAvailable(false);
  }, [dropdownProxyAdvisorItems, updateProxyAdvisorTableItems]);


  const removeProxyAdvisorRowHandler = useCallback((itemId) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateProxyAdvisorTableItems(newTableItems);

      const itemIndex = newTableItems['middle']['body']['items'].findIndex(item => item['id'] === itemId.split('#')[0]);
      if (itemIndex > -1) {
        newTableItems['middle']['body']['items'].splice(itemIndex, 1);
      }

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedDropdownItems = dropdownProxyAdvisorItems.filter(dropdownItem => !selectedItems.includes(dropdownItem['value']) || dropdownItem['value'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedDropdownItems;
      });
      return newTableItems;
    });
  }, [dropdownProxyAdvisorItems, updateProxyAdvisorTableItems]);


  const selectProxyAdvisorDropdownItemHandler = useCallback((itemId, proxyAdvisorName) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateProxyAdvisorTableItems(newTableItems);

      newTableItems['middle']['body']['items'].filter(item => item['id'] === itemId).forEach(item => {
        item['columns'][0]['value'] = proxyAdvisorName;
        item['columns'][0]['selected'] = proxyAdvisorName;
      });

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedDropdownItems = dropdownProxyAdvisorItems.filter(dropdownItem => !selectedItems.includes(dropdownItem['value']) || dropdownItem['value'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedDropdownItems;
      });

      return newTableItems;
    });
  }, [dropdownProxyAdvisorItems, updateProxyAdvisorTableItems]);


  const fetchProxyAdvisorData = useCallback((tempTableItems) => {
    const data = [];    
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        let proxyAdvisorName = '';
        let lastAgmRecommendations = '';
        if(!isActivated) {
          proxyAdvisorName = getValueFromDropdown(`${itemId}#proxy-advisor-name`);
          if(clientType === 'Corporate') {
            lastAgmRecommendations = getValueFromTextInput(`${itemId}#last-agm-recommendations`);
          }
        }
        else {
          proxyAdvisorName = getValueFromParagraph(`${itemId}#proxy-advisor-name`);
          if(clientType === 'Corporate') {
            lastAgmRecommendations = getValueFromParagraph(`${itemId}#last-agm-recommendations`);          
          }
        }

        const squareWellItemIndex = squareWellProxyAdvisors.findIndex(item => item['name'] === proxyAdvisorName);
        const squareWellProxyAdvisorId = squareWellItemIndex > -1 ? squareWellProxyAdvisors[squareWellItemIndex]['id'] : '';
        data.push({
          'squarewell_proxy_advisor_id': squareWellProxyAdvisorId,
          'proxy_advisor_name': proxyAdvisorName,
          'last_general_meeting_recommendations': lastAgmRecommendations
        });
      });
    }

    return data;
  }, [isActivated, clientType, squareWellProxyAdvisors]);


  const isItemsValid = useCallback((tempTableItems) => {
    let isValid = true;
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const proxyAdvisorName = getValueFromDropdown(`${itemId}#proxy-advisor-name`);
        const lastAgmRecommendations = getValueFromTextInput(`${itemId}#last-agm-recommendations`);

        if(document.getElementById(`${itemId}#proxy-advisor-name`).dataset.selected === '') {
          document.getElementById(`${itemId}#proxy-advisor-name`).style.border = '1px solid var(--color-danger-700)';
        }
        if(lastAgmRecommendations === '') {
          document.getElementById(`${itemId}#last-agm-recommendations`).style.border = '1px solid var(--color-danger-700)';
        }
        
        isValid = isValid && (proxyAdvisorName !== '' && lastAgmRecommendations !== '');
      });
    }
    return isValid;
  }, []);


  const [pagePreference, setPagePreference] = useState('next');
  const saveProxyAdvisors = (activationStatus, pageDirectedTo) => {
    const data = fetchProxyAdvisorData(tableItems);
    props.onNextClick(data, activationStatus, pageDirectedTo);
  }

  const showActivationModalHandler = () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateProxyAdvisorTableItems(newTableItems);
      return newTableItems;
    });
    if(!isItemsValid(tableItems)) {
      setIsValidationAvailable(true);
      setProxyAdvisorsActivateWarning(true);
    }
    else {
      setProxyAdvisorsActivateApproval(true);
    }
  }

  const activateWithValidationHandler = () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateProxyAdvisorTableItems(newTableItems);
      return newTableItems;
    });
    if(!isItemsValid(tableItems)) {
      setIsValidationAvailable(true);
      setProxyAdvisorsActivateApproval(true);
    }
    else {
      setProxyAdvisorsActivateApproval(false);
      setIsValidationAvailable(false);
      setIsActivated(true);
      saveProxyAdvisors(true, 'stay');
    }
  }

  const saveWithValidationHandler = (clickedButton) => {
    setPagePreference(clickedButton);
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateProxyAdvisorTableItems(newTableItems);
      return newTableItems;
    });
    if(!isActivated && !isItemsValid(tableItems)) {
      setIsValidationAvailable(true);
      setProxyAdvisorsSaveWarning(true);
    }
    else {
      setIsValidationAvailable(false);
      saveProxyAdvisors(isActivated, clickedButton);
    }
  }


  useEffect(() => {
    if(isValidationAvailable && tableItems !== '') {
      isItemsValid(tableItems);
    }
  }, [isValidationAvailable, proxyAdvisorsSaveWarning, proxyAdvisorsActivateWarning, proxyAdvisorsActivateApproval, tableItems, isItemsValid]);


  useEffect(() => {
    let newTableItems = null;
    if(isActivated) {
      newTableItems = generateClientSetupShowProxyAdvisorTableItems(clientType, propsProxyAdvisorList);
    }
    else {
      newTableItems = generateClientSetupEditProxyAdvisorTableItems(addNewProxyAdvisorRowHandler(createProxyAdvisorRow, selectProxyAdvisorDropdownItemHandler, removeProxyAdvisorRowHandler));
      propsProxyAdvisorList.forEach(item => {
        newTableItems['middle']['body']['items'].push(
          createProxyAdvisorRow(item, selectProxyAdvisorDropdownItemHandler, removeProxyAdvisorRowHandler)
        );
      });
    }
    setTableItems(newTableItems);
  }, [propsProxyAdvisorList, isActivated, clientType,
      addNewProxyAdvisorRowHandler, createProxyAdvisorRow,
      selectProxyAdvisorDropdownItemHandler, removeProxyAdvisorRowHandler]);


  return (
    <div className={styles['client-setup-proxy-advisors-step']}>
      { proxyAdvisorsActivateWarning &&
        <Modal
          type='warning'
          title='Missing Field Error'
          message='One or several fields are missing. Please review and fill in missing information to be able to complete Proxy Advisor List set-up.'
          onCloseClick={() => setProxyAdvisorsActivateWarning(false)}
          approveButton = {{
            'text': 'Close',
            'onClick': () => setProxyAdvisorsActivateWarning(false)
          }}
        />
      }

      { proxyAdvisorsActivateApproval &&
        <Modal
          type='warning'
          title='Activate Proxy Advisor List'
          message='Once "activated", you will no longer be able to edit the list from this screen. You may proceed with further edits from "Client Details page" once the client set-up has been finalized.'
          onCloseClick={() => setProxyAdvisorsActivateApproval(false)}
          approveButton = {{
            'text': 'Activate',
            'onClick': () => activateWithValidationHandler()
          }}
          cancelButton = {{
            'text': 'Cancel',
            'onClick': () => setProxyAdvisorsActivateApproval(false)
          }}
        />
      }

      { proxyAdvisorsSaveWarning && 
        <Modal
          type='warning'
          title='Missing Field Error'
          message='One or several fields are missing. Please review and fill in missing information to be able to complete Proxy Advisor List set-up.'
          onCloseClick={() => setProxyAdvisorsSaveWarning(false)}
          approveButton = {{
            'text': 'Continue',
            'onClick': () => saveProxyAdvisors(false, pagePreference)
          }}
          cancelButton = {{
            'text': 'Cancel',
            'onClick': () => setProxyAdvisorsSaveWarning(false)
          }}
        />
      }

      {tableItems !== null &&
        <Fragment>
          <Table width={props.pageWidth} items={tableItems} />
        
          { !isActivated &&
            <div className={styles['proxy-advisors-table-bottom-container']}>
              <Button
                type={"primary"}
                size={"xsmall"}
                onClick={showActivationModalHandler}
                isDisabled={tableItems !== null && tableItems['middle']['body']['items'].length > 0 ? false : true}
              >
                Activate the List
              </Button>
            </div>
          }
        </Fragment>
      }

      <div className={styles['action-buttons-container']} style={{'width': props.pageWidth}}>
        <div className={styles['left-buttons']}>
          <Button type={'cancel'} size={'base'} leftIcon={'arrow-left'} onClick={() => saveWithValidationHandler('prev')}>Previous</Button>
        </div>
        <div className={styles['right-buttons']}>
          <Button type={'cancel'} size={'base'} onClick={() => saveWithValidationHandler('quit')}>Save & Quit</Button>
          <Button type={'primary'} size={'base'} rightIcon={'arrow-right'} onClick={() => saveWithValidationHandler('next')}>Next</Button>
        </div>
      </div>
    </div>
  );
}

export default ClientSetupProxyAdvisors;
