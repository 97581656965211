import styles from './DropdownItem.module.css';

const DropdowmItem = (props) => {
  const selectItemHandler = () => {
    props.onSelectItem(props.value);
  }

  return (
    <div className={styles['dropdown-item']} onClick={selectItemHandler}>
      { props.hasChip && 
        <div className={`${styles['chip']} ${styles[props.chipColor]}`}></div>
      }
      <p>{props.value}</p>
      { props.isSelected && <div className={styles['selected-icon']}></div> }
    </div>
  );
}

export default DropdowmItem
