import { Fragment, useCallback, useEffect, useState } from "react";
import { engagementRatingDropdownItems, meetingTypeDropdownItems } from "../../../utils/constants";
import { commaSeperatedValueToNumber, getValueFromVariable, numberToCommaSeperatedValue } from "../../../utils/helpers";
import { fetchClientEngagementsMeetingNoteRequest } from "../../../requests/client-engagement-requests";
import { fetchClientBasicProfileRequest, fetchClientInvestorsRequest, fetchClientProxyAdvisorsRequest } from "../../../requests/client-requests";
import { getAuthClientId, getClientType } from "../../../utils/auth";
import { getSquareWellFileRequest } from "../../../requests/squarewell-requests";
import { generateMeetingNoteWordDocument } from "../../../utils/word-export-helpers/meeting-note-export-helpers";
import { handleRequestError } from "../../../utils/request-error-helpers";
import useHttp from "../../../hooks/use-http";
import useInputValidation from "../../../hooks/use-input-validation";

import Button from "../../UI/Button/Button";
import RadioButtonGroup from "../../UI/RadioButtonGroup/RadioButtonGroup";

import EngagementFormItem from "../EngagementFormItem/EngagementFormItem";

import styles from './EngagementMeetingNoteForm.module.css';

const EngagementMeetingNoteUpdateForm = (props) => {

  const clientId = getAuthClientId();
  const clientType = getClientType();

  const [isLoading, error, sendRequest] = useHttp();

  const [clientName, setClientName] = useState(null);
  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);
  const [clientTotalNumOfShares, setClientTotalNumOfShares] = useState(null);

  const meetingNoteId = props.meetingNoteId;
  const isInvestorTypeEditable = props.isInvestorTypeEditable;

  const setBreadcrumbItems = props.setBreadcrumbItems;

  const [meetingNoteData, setMeetingNoteData] = useState({});
  const [investorListData, setInvestorListData] = useState([]);
  const [proxyAdvisorListData, setProxyAdvisorListData] = useState([]);

  const [isEditMode, setIsEditMode] = useState(false);

  const [noteHeader, setNoteHeader, isNoteHeaderValid, hasNoteHeaderError, noteHeaderChangeHandler, noteHeaderBlurHandler] =
    useInputValidation(getValueFromVariable(meetingNoteData['note_header']), (noteHeader) => noteHeader.trim() !== '');

  const [noteBody, setNoteBody, isNoteBodyValid, hasNoteBodyError, noteBodyChangeHandler, noteBodyBlurHandler] =
    useInputValidation(getValueFromVariable(meetingNoteData['note_body']), (noteBody) => noteBody.trim() !== '');

  const [nextSteps, setNextSteps, isNextStepsValid, hasNextStepsError, nextStepsChangeHandler, nextStepsBlurHandler] =
    useInputValidation(getValueFromVariable(meetingNoteData['next_steps']), (nextSteps) => nextSteps.trim() !== '');

  const [meetingDate, setMeetingDate, isMeetingDateValid, hasMeetingDateError, meetingDateChangeHandler, meetingDateBlurHandler] =
    useInputValidation(getValueFromVariable(meetingNoteData['meeting_date'], 'date'), (meetingDate) => meetingDate !== null && meetingDate !== undefined);

  const [meetingType, setMeetingType, isMeetingTypeValid, hasMeetingTypeError, meetingTypeChangeHandler, meetingTypeBlurHandler] =
    useInputValidation(getValueFromVariable(meetingNoteData['meeting_type']), (meetingType) => meetingType !== '');

  const [engagementRating, setEngagementRating, isEngagementRatingValid, hasEngagementRatingError, engagementRatingChangeHandler, engagementRatingBlurHandler] =
    useInputValidation(getValueFromVariable(meetingNoteData['engagement_rating']), (engagementRating) => engagementRating !== '');

  const [companyRepresentatives, setCompanyRepresentatives, isCompanyRepresentativesValid, hasCompanyRepresentativesError, companyRepresentativesChangeHandler, companyRepresentativesBlurHandler] =
    useInputValidation(getValueFromVariable(meetingNoteData['company_representatives']), (companyRepresentatives) => companyRepresentatives !== '');

  const [investorName, setInvestorName, isInvestorNameValid, hasInvestorNameError, investorNameChangeHandler, investorNameBlurHandler] =
    useInputValidation(
      meetingNoteData['investor_type'] === 'Investor' ? getValueFromVariable(meetingNoteData['investor']['name']) : '', 
      (investorName) => investorName.trim() !== ''
    );

  const [investorNumOfShares, setInvestorNumOfShares, isInvestorNumOfSharesValid, hasInvestorNumOfSharesError, investorNumOfSharesChangeHandler, investorNumOfSharesBlurHandler] =
    useInputValidation(
      meetingNoteData['investor_type'] === 'Investor' ? getValueFromVariable(meetingNoteData['investor']['num_of_shares']) : '', 
      () => true
    );

  const [investorRepresentatives, setInvestorRepresentatives, isInvestorRepresentativesValid, hasInvestorRepresentativesError, investorRepresentativesChangeHandler, investorRepresentativesBlurHandler] =
    useInputValidation(
      meetingNoteData['investor_type'] === 'Investor' ? getValueFromVariable(meetingNoteData['investor']['representatives']) : '', 
      (investorRepresentatives) => investorRepresentatives !== ''
    );

  const [proxyAdvisorName, setProxyAdvisorName, isProxyAdvisorNameValid, hasProxyAdvisorNameError, proxyAdvisorNameChangeHandler, proxyAdvisorNameBlurHandler] =
    useInputValidation(
      meetingNoteData['investor_type'] === 'Proxy Advisor' ? getValueFromVariable(meetingNoteData['proxy_advisor']['name']) : '', 
      (proxyAdvisorName) => proxyAdvisorName.trim() !== ''
    );

  const [proxyAdvisorRepresentatives, setProxyAdvisorRepresentatives, isProxyAdvisorRepresentativesValid, hasProxyAdvisorRepresentativesError, proxyAdvisorRepresentativesChangeHandler, proxyAdvisorRepresentativesBlurHandler] =
    useInputValidation(
      meetingNoteData['investor_type'] === 'Proxy Advisor' ? getValueFromVariable(meetingNoteData['proxy_advisor']['representatives']) : '', 
      (proxyAdvisorRepresentatives) => proxyAdvisorRepresentatives !== ''
    );

  const [noteAttachments, setNoteAttachments] = useState(getValueFromVariable(meetingNoteData['attachments']) ? getValueFromVariable(meetingNoteData['attachments']) : []);
  const noteBodyAttachments = noteAttachments.filter(item => item['attachment_section'] === 'Note Body');
  const noteNextStepsAttachments = noteAttachments.filter(item => item['attachment_section'] === 'Next Steps');

  const [createdAt, setCreatedAt] = useState(getValueFromVariable(meetingNoteData['created_at'], 'date'));
  const [createdBy, setCreatedBy] = useState(getValueFromVariable(meetingNoteData['created_by']));
  const [updatedAt, setUpdatedAt] = useState(getValueFromVariable(meetingNoteData['updated_at'], 'date'));
  const [updatedBy, setUpdatedBy] = useState(getValueFromVariable(meetingNoteData['updated_by']));


  const [selectedInvestorType, setSelectedInvestorType] = useState(props.investorType ? props.investorType : 'type-investor');

  const radioButtonChangeToInvestorHandler = () => {
    setSelectedInvestorType('type-investor');
  }

  const radioButtonChangeToProxyAdvisorHandler = () => {
    setSelectedInvestorType('type-proxy-advisor');
  }

  const investorTypeRadioButtonItems = [
    {
      id: 'type-investor',
      value: 'Investor',
      text: 'Investor',
      isChecked: true,
      onChange: radioButtonChangeToInvestorHandler
    },
    {
      id: 'type-proxy-advisor',
      value: 'Proxy Advisor',
      text: 'Proxy Advisor',
      isChecked: false,
      onChange: radioButtonChangeToProxyAdvisorHandler
    }
  ];


  /* Send a request to fetch meeting note item's details, investor list and 
   * proxy advisor list for the client to fill the form and combobox values. */
  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (data) => { 
        setClientName(data['name']);
        setClientProfilePictureUrl(data['profile_picture_url']);
        setClientTotalNumOfShares(data['total_num_of_shares']);
      }
    );

    sendRequest(
      fetchClientEngagementsMeetingNoteRequest(clientId, meetingNoteId),
      (data) => {
        setMeetingNoteData(data);
        setBreadcrumbItems(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          if(items.length < 3){
            items[1]['text'] = 'Meeting Notes';
            items[1]['href'] = '../engagements?type=meeting-notes';
            items.push({
              'text': data['note_header'],
              'href': ''
            });
          }
          return items;
        });
      }
    );

    if (isInvestorTypeEditable || selectedInvestorType === 'type-investor') {
      sendRequest(
        fetchClientInvestorsRequest(clientId),
        (data) => {
          setInvestorListData(data['items']);
        }
      );
    }
    else {
      setInvestorListData([]);
    }

    if (isInvestorTypeEditable || selectedInvestorType === 'type-proxy-advisor') {
      sendRequest(
        fetchClientProxyAdvisorsRequest(clientId),
        (data) => {
          setProxyAdvisorListData(data['items']);
        }
      );
    }
    else {
      setProxyAdvisorListData([]);
    }
  }, [sendRequest, clientId, meetingNoteId, isInvestorTypeEditable, selectedInvestorType,
      setInvestorName, setInvestorNumOfShares, setProxyAdvisorName, setBreadcrumbItems]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const investorComboboxItems = investorListData.map(item => {
    return {
      'id': item['id'],
      'text': item['name']
    };
  });

  const proxyAdvisorComboboxItems = proxyAdvisorListData.map(item => {
    return {
      'id': item['id'],
      'text': item['name']
    };
  });


  const setInitialValues = useCallback(() => {
    /* Shared fields for both investor and proxy advisor notes. */
    setNoteHeader(getValueFromVariable(meetingNoteData['note_header']));
    setNoteBody(getValueFromVariable(meetingNoteData['note_body']));
    setNextSteps(getValueFromVariable(meetingNoteData['next_steps']));
    setMeetingDate(getValueFromVariable(meetingNoteData['meeting_date'], 'date'));
    setMeetingType(getValueFromVariable(meetingNoteData['meeting_type']));
    setEngagementRating(getValueFromVariable(meetingNoteData['engagement_rating']));
    setCompanyRepresentatives(getValueFromVariable(meetingNoteData['company_representatives']));
    setNoteAttachments(getValueFromVariable(meetingNoteData['attachments']) ? getValueFromVariable(meetingNoteData['attachments']) : []);
    setCreatedAt(getValueFromVariable(meetingNoteData['created_at'], 'date'));
    setCreatedBy(getValueFromVariable(meetingNoteData['created_by']));
    setUpdatedAt(getValueFromVariable(meetingNoteData['updated_at'], 'date'));
    setUpdatedBy(getValueFromVariable(meetingNoteData['updated_by']));
    /* Investor specific fields. */
    setInvestorName(meetingNoteData['investor_type'] === 'Investor' ? getValueFromVariable(meetingNoteData['investor']['name']) : '');
    setInvestorNumOfShares(meetingNoteData['investor_type'] === 'Investor' ? getValueFromVariable(meetingNoteData['investor']['num_of_shares']) : '');
    setInvestorRepresentatives(meetingNoteData['investor_type'] === 'Investor' ? getValueFromVariable(meetingNoteData['investor']['representatives']) : '');
    /* Proxy advisor specific fields. */
    setProxyAdvisorName(meetingNoteData['investor_type'] === 'Proxy Advisor' ? getValueFromVariable(meetingNoteData['proxy_advisor']['name']) : '');
    setProxyAdvisorRepresentatives(meetingNoteData['investor_type'] === 'Proxy Advisor' ? getValueFromVariable(meetingNoteData['proxy_advisor']['representatives']) : '');
  }, [meetingNoteData, setCompanyRepresentatives, setEngagementRating, setInvestorName, setInvestorNumOfShares,
      setInvestorRepresentatives, setMeetingDate, setMeetingType, setNextSteps, setNoteBody, setNoteHeader,
      setProxyAdvisorName, setProxyAdvisorRepresentatives]);

  useEffect(() => {
    setInitialValues();
  }, [setInitialValues]);


  const cancelClickHandler = () => {
    setInitialValues();
    setIsEditMode(false);
  }

  const meetingNoteBodyAttachmentsChangeHandler = (event) => {
    meetingNoteAttachmentsChangeHandler(event, 'Note Body');
  }

  const meetingNoteNextStepsAttachmentsChangeHandler = (event) => {
    meetingNoteAttachmentsChangeHandler(event, 'Next Steps');
  }

  const meetingNoteAttachmentsChangeHandler = (event, attachmentSection) => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if(file.size > 500000){
        alert('File size should be smaller than 450Kb');
        return false;				
      }

      var data = new FormData();
      data.append('attachment', file, file.name);
      props.onAttachmentUploadClick(data, attachmentSection, setNoteAttachments);
    }
  }

  const meetingNoteAttachmentRemoveHandler = (clickedAttachmentId) => {
    setNoteAttachments(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const attachmentIndex = items.findIndex(item => item['id'] === clickedAttachmentId);
      items.splice(attachmentIndex, 1);
      return items;
    });
  }

  const isMeetingNoteFormValid = () => {
    noteHeaderBlurHandler();
    noteBodyBlurHandler();
    nextStepsBlurHandler();
    meetingDateBlurHandler();
    meetingTypeBlurHandler();
    engagementRatingBlurHandler();
    companyRepresentativesBlurHandler();
    
    const isGeneralInfoValid = isNoteHeaderValid && isNoteBodyValid && isNextStepsValid && 
      isMeetingDateValid && isMeetingTypeValid && isEngagementRatingValid && isCompanyRepresentativesValid;
    if (selectedInvestorType === 'type-investor') {
      investorNameBlurHandler();
      investorNumOfSharesBlurHandler();
      investorRepresentativesBlurHandler();
      return isGeneralInfoValid && isInvestorNameValid && isInvestorNumOfSharesValid && isInvestorRepresentativesValid;
    }
    else if (selectedInvestorType === 'type-proxy-advisor') {
      proxyAdvisorNameBlurHandler();
      proxyAdvisorRepresentativesBlurHandler();
      return isGeneralInfoValid && isProxyAdvisorNameValid && isProxyAdvisorRepresentativesValid;
    }
    else {
      return false;
    }
  }

  const updateMeetingNoteClickHandler = () => {
    if (!isMeetingNoteFormValid()) {
      return;
    }

    const meetingNoteData = {
      'note_header': noteHeader,
      'note_body': noteBody,
      'next_steps': nextSteps,
      'meeting_date': meetingDate,
      'meeting_type': meetingType,
      'company_representatives': companyRepresentatives,
      'engagement_rating': engagementRating,
      'note_attachments': noteAttachments
    };

    if (selectedInvestorType === 'type-investor') {
      const itemIndex = investorComboboxItems.findIndex(item => item['text'].toLowerCase() === investorName.toLowerCase());
      meetingNoteData['investor_type'] = 'Investor';
      meetingNoteData['investor'] = {
        'id': investorComboboxItems[itemIndex]['id'],
        'name': investorName,
        'representatives': investorRepresentatives
      };
      if(clientType === 'Corporate') {
        meetingNoteData['investor']['num_of_shares'] = commaSeperatedValueToNumber(investorNumOfShares);
        meetingNoteData['investor']['isc'] = (commaSeperatedValueToNumber(investorNumOfShares) / clientTotalNumOfShares) * 100;
      }
      else {
        meetingNoteData['investor']['num_of_shares'] = 0;
        meetingNoteData['investor']['isc'] = 0;
      }
      props.onSaveClick(meetingNoteData, setMeetingNoteData);
      setIsEditMode(false);
    }
    else if (selectedInvestorType === 'type-proxy-advisor') {
      const itemIndex = proxyAdvisorComboboxItems.findIndex(item => item['text'] === proxyAdvisorName);
      meetingNoteData['investor_type'] = 'Proxy Advisor';
      meetingNoteData['proxy_advisor'] = {
        'id': proxyAdvisorComboboxItems[itemIndex]['id'],
        'name': proxyAdvisorName,
        'representatives': proxyAdvisorRepresentatives
      };
      props.onSaveClick(meetingNoteData, setMeetingNoteData);
      setIsEditMode(false);
    }
    else {
      return;
    }
  }
  
  const exportToWordClickHandler = () => { 
    sendRequest(
      getSquareWellFileRequest({'path': clientProfilePictureUrl}),
      (profilePictureData) => {
        if(selectedInvestorType === 'type-investor') {
          generateMeetingNoteWordDocument(
            clientName, clientType, profilePictureData, 
            investorName, selectedInvestorType, numberToCommaSeperatedValue(investorNumOfShares),
            noteHeader, noteBody, noteBodyAttachments, nextSteps, noteNextStepsAttachments,
            meetingDate !== '' && meetingDate !== null ? meetingDate.toLocaleDateString('en-GB') : '',
            meetingType, engagementRating, companyRepresentatives, investorRepresentatives,
            createdBy,
            createdAt !== '' && createdAt !== null ? createdAt.toLocaleDateString('en-GB') : '-',
            updatedBy !== '' ? updatedBy : '-',
            updatedAt !== '' && updatedAt !== null ? updatedAt.toLocaleDateString('en-GB') : '-'
          );
        }
        else if(selectedInvestorType === 'type-proxy-advisor') {
          generateMeetingNoteWordDocument(
            clientName, clientType, profilePictureData, 
            proxyAdvisorName, selectedInvestorType, '-',
            noteHeader, noteBody, noteBodyAttachments, nextSteps, noteNextStepsAttachments,
            meetingDate !== '' && meetingDate !== null ? meetingDate.toLocaleDateString('en-GB') : '',
            meetingType, engagementRating, companyRepresentatives, proxyAdvisorRepresentatives,
            createdBy,
            createdAt !== '' && createdAt !== null ? createdAt.toLocaleDateString('en-GB') : '-',
            updatedBy !== '' ? updatedBy : '-',
            updatedAt !== '' && updatedAt !== null ? updatedAt.toLocaleDateString('en-GB') : '-'
          );
        }
      }
    );    
  }

  const getEngagementRatingChipColor = () => {
    if(engagementRating !== undefined) {
      const filteredItems = engagementRatingDropdownItems.filter(item => item.value === engagementRating);
      if(filteredItems.length > 0) {
        return filteredItems[0]['chipColor'];
      }
    }
    return '';
  }

  return (
    <div className={styles['meeting-note-container']}>
      <div className={styles['header']}>
        {props.hasBackButton &&
          <div className={styles['back-button-container']}>
            <div className={styles['back-button']} onClick={props.onBackClick}>
              <div className={styles['back-icon']}></div>
              <h4>Back</h4>
            </div>
          </div>
        }

        <div className={styles['meeting-note-title-container']}>
          {!isEditMode && 
            <Fragment>
              <div className={styles['title']}>
                <h2>{noteHeader}</h2>
              </div>
              <div className={styles['type-chip']}>
                <div className={`${styles['icon']} ${styles['meeting-note']}`}></div>
                <div className={styles['label']}>
                  <p>Meeting Note</p>
                </div>
              </div>
            </Fragment>
          }
          {isEditMode && 
            <EngagementFormItem
              id='investor-meeting-note#note-header'
              type='text-input'
              label='Note Header'
              value={noteHeader}
              placeholder=''
              hasError={hasNoteHeaderError}
              onBlur={noteHeaderBlurHandler}
              onChange={noteHeaderChangeHandler}
            />
          }
        </div>
        
        {!isEditMode && 
          <div className={styles['button-container']}>
            {true &&
              <Button type={"secondary"} size={"xsmall"} leftIcon={'download'} onClick={exportToWordClickHandler}>Export to Word</Button>
            }
            <Button type={'primary'} size={'xsmall'} leftIcon={'edit'} onClick={() => setIsEditMode(true)}>Edit</Button>
          </div>
        }
        {isEditMode && 
          <div className={styles['button-container']}>
            <Button type={"cancel"} size={"xsmall"} onClick={cancelClickHandler}>Cancel</Button>
            <Button type={'primary'} size={'xsmall'} onClick={updateMeetingNoteClickHandler}>Save</Button>
          </div>
        }
      </div>
      <div className={styles['body']}>
        <div className={styles['meeting-note-content-container']}>
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#note-body'
              type={isEditMode ? 'rich-text-input' : 'rich-text-content'}
              size='long'
              label='Note Body'
              value={noteBody}
              placeholder='Enter Note'
              hasError={hasNoteBodyError}
              onBlur={noteBodyBlurHandler}
              onChange={noteBodyChangeHandler}
            />
          </div>

          <div className={styles['row']}>
            {!isEditMode && noteBodyAttachments && noteBodyAttachments.length > 0 &&
              <Fragment>
                {noteBodyAttachments.map(item => {
                  return (
                    <EngagementFormItem
                      key={`attached-item-${item['id']}`}
                      type='attached-item'
                      value={item['attachment_name']}
                      isRemovable={false}
                      onItemClick={() => window.open(item['attachment_url'], '_blank')}
                    />
                  );
                })}
              </Fragment>
            }

            {isEditMode && noteBodyAttachments && noteBodyAttachments.length === 0 &&
              <EngagementFormItem
                type='new-attachment-button'
                onChange={meetingNoteBodyAttachmentsChangeHandler}
              />
            }

            {isEditMode && noteBodyAttachments && noteBodyAttachments.length > 0 &&
              <Fragment>
                {noteBodyAttachments.map(item => {
                  return (
                    <EngagementFormItem
                      key={`attached-item-${item['id']}`}
                      type='attached-item'
                      value={item['attachment_name']}
                      isRemovable={true}
                      onRemoveClick={() => meetingNoteAttachmentRemoveHandler(item['id'])}
                    />
                  );
                })}
                
                <EngagementFormItem
                  type='new-attachment-icon-button'
                  onChange={meetingNoteBodyAttachmentsChangeHandler}
                />
              </Fragment>
            }
          </div>

          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#next-steps'
              type={isEditMode ? 'rich-text-input' : 'rich-text-content'}
              size='long'
              label='Next Steps'
              value={nextSteps}
              placeholder='Enter Next Steps'
              hasError={hasNextStepsError}
              onBlur={nextStepsBlurHandler}
              onChange={nextStepsChangeHandler}
            />
          </div>

          <div className={styles['row']}>
            {!isEditMode && noteNextStepsAttachments && noteNextStepsAttachments.length > 0 &&
              <Fragment>
                {noteNextStepsAttachments.map(item => {
                  return (
                    <EngagementFormItem
                      key={`attached-item-${item['id']}`}
                      type='attached-item'
                      value={item['attachment_name']}
                      isRemovable={false}
                      onItemClick={() => window.open(item['attachment_url'], '_blank')}
                    />
                  );
                })}
              </Fragment>
            }

            {isEditMode && noteNextStepsAttachments && noteNextStepsAttachments.length === 0 &&
              <EngagementFormItem
                type='new-attachment-button'
                onChange={meetingNoteNextStepsAttachmentsChangeHandler}
              />
            }

            {isEditMode && noteNextStepsAttachments && noteNextStepsAttachments.length > 0 &&
              <Fragment>
                {noteNextStepsAttachments.map(item => {
                  return (
                    <EngagementFormItem
                      key={`attached-item-${item['id']}`}
                      type='attached-item'
                      value={item['attachment_name']}
                      isRemovable={true}
                      onRemoveClick={() => meetingNoteAttachmentRemoveHandler(item['id'])}
                    />
                  );
                })}
                
                <EngagementFormItem
                  type='new-attachment-icon-button'
                  onChange={meetingNoteNextStepsAttachmentsChangeHandler}
                />
              </Fragment>
            }
          </div>
        </div>

        <div className={styles['meeting-note-details-container']}>
          { isInvestorTypeEditable && isEditMode &&
            <div className={styles['row']}>
              <RadioButtonGroup
                name='type-investor'
                items={investorTypeRadioButtonItems}
                selected={selectedInvestorType}
              />
            </div>
          }

          { selectedInvestorType === 'type-investor' && 
            <div className={styles['row']}>
              <div className={clientType === 'Corporate' ? styles['half-col'] : ''}>
                <EngagementFormItem
                  id='investor-meeting-note#investor-name'
                  type={isEditMode ? 'combobox' : 'text'}
                  label='Investor'
                  value={investorName}
                  items={investorComboboxItems}
                  placeholder='Select Investor'
                  hasError={hasInvestorNameError}
                  onBlur={investorNameBlurHandler}
                  onChange={investorNameChangeHandler}
                  isDisabled={!isInvestorTypeEditable}
                />
              </div>
              {clientType === 'Corporate' && 
                <div className={styles['half-col']}>
                  <EngagementFormItem
                    id='investor-meeting-note#investor-num-of-shares'
                    type={isEditMode ? 'number-input' : 'text'}
                    label='# of Shares'
                    value={numberToCommaSeperatedValue(investorNumOfShares)}
                    placeholder='Enter # of Shares'
                    hasError={hasInvestorNumOfSharesError}
                    onBlur={investorNumOfSharesBlurHandler}
                    onChange={investorNumOfSharesChangeHandler}
                  />
                </div>
              }
            </div>
          }
          { selectedInvestorType === 'type-proxy-advisor' && 
            <div className={styles['row']}>
              <EngagementFormItem
                id='investor-meeting-note#proxy-advisor-name'
                type={isEditMode ? 'combobox' : 'text'}
                label='Proxy Advisor'
                value={proxyAdvisorName}
                items={proxyAdvisorComboboxItems}
                placeholder='Select Proxy Advisor'
                hasError={hasProxyAdvisorNameError}
                onBlur={proxyAdvisorNameBlurHandler}
                onChange={proxyAdvisorNameChangeHandler}
              />
            </div>
          }
          <div className={styles['separator']}></div>
          
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#meeting-date'
              type={isEditMode ? 'date-input' : 'text'}
              label='Meeting Date'
              value={isEditMode ? meetingDate : (meetingDate !== '' && meetingDate !== null ? meetingDate.toLocaleDateString('en-GB') : '')}
              placeholder='DD / MM / YYYY'
              hasError={hasMeetingDateError}
              onBlur={meetingDateBlurHandler}
              onChange={meetingDateChangeHandler}
            />
          </div>
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#meeting-type'
              type={isEditMode ? 'dropdown' : 'text'}
              label='Meeting Type'
              value={meetingType}
              placeholder='Select Meeting Type'
              items={meetingTypeDropdownItems}
              hasError={hasMeetingTypeError}
              onBlur={meetingTypeBlurHandler}
              onChange={meetingTypeChangeHandler}
            />
          </div>
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#engagement-rating'
              type={isEditMode ? 'dropdown' : 'traffic-light'}
              label='Engagement Rating'
              value={engagementRating}
              color_code={getEngagementRatingChipColor()}
              placeholder='Select Engagement Rating'
              items={engagementRatingDropdownItems}
              hasChip={true}
              hasError={hasEngagementRatingError}
              onBlur={engagementRatingBlurHandler}
              onChange={engagementRatingChangeHandler}
            />
          </div>
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-meeting-note#company-representatives'
              type={isEditMode ? 'textarea-input' : 'text'}
              height='120px'
              label='Company Representative(s)'
              value={companyRepresentatives}
              placeholder='Enter Company Representative(s)'
              hasError={hasCompanyRepresentativesError}
              onBlur={companyRepresentativesBlurHandler}
              onChange={companyRepresentativesChangeHandler}
            />
          </div>

          {selectedInvestorType === 'type-investor' && 
            <div className={styles['row']}>
              <EngagementFormItem
                id='investor-meeting-note#investor-representatives'
                type={isEditMode ? 'textarea-input' : 'text'}
                height='120px'
                label='Investor Representative(s)'
                value={investorRepresentatives}
                placeholder='Enter Investor Representative(s)'
                hasError={hasInvestorRepresentativesError}
                onBlur={investorRepresentativesBlurHandler}
                onChange={investorRepresentativesChangeHandler}
              />
            </div>
          }
          {selectedInvestorType === 'type-proxy-advisor' && 
            <div className={styles['row']}>
              <EngagementFormItem
                id='investor-meeting-note#proxy-advisor-representatives'
                type={isEditMode ? 'textarea-input' : 'text'}
                height='120px'
                label='Proxy Advisor Representative(s)'
                value={proxyAdvisorRepresentatives}
                placeholder='Enter Proxy Advisor Representative(s)'
                hasError={hasProxyAdvisorRepresentativesError}
                onBlur={proxyAdvisorRepresentativesBlurHandler}
                onChange={proxyAdvisorRepresentativesChangeHandler}
              />
            </div>
          }

          <div className={styles['separator']}></div>
          <div className={styles['row']}>
            <div className={styles['half-col']}>
              <EngagementFormItem
                type='text'
                label='Created By'
                value={createdBy}
              />
            </div>
            <div className={styles['half-col']}>
              <EngagementFormItem
                type='text'
                label='Created On'
                value={createdAt !== '' && createdAt !== null ? createdAt.toLocaleDateString('en-GB') : '-'}
              />
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['half-col']}>
              <EngagementFormItem
                type='text'
                label='Edited By'
                value={updatedBy !== '' ? updatedBy : '-'}
              />
            </div>
            <div className={styles['half-col']}>
              <EngagementFormItem
                type='text'
                label='Edited On'
                value={updatedAt !== '' && updatedAt !== null ? updatedAt.toLocaleDateString('en-GB') : '-'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EngagementMeetingNoteUpdateForm;
