import Button from '../Button/Button';
import styles from './Modal.module.css';

const Modal = (props) => {
  return (
    <div className={styles['modal-container']}>
      <div className={styles['overlay']}></div>
      <div className={styles['content']}>
        <div className={styles['header']}>
          <div className={`${styles['icon']} ${styles[props.type]}`}></div>
          <div className={styles['title']}>
            <h1>{props.title}</h1>
          </div>
          <div className={styles['close-button']} onClick={props.onCloseClick}>
            <div className={styles['close-icon']}></div>
          </div>
        </div>
        <div className={styles['body']}>
          <p>{props.message}</p>
        </div>
        <div className={styles['footer']}>
          {props.approveButton && 
            <Button
              className={styles['modal-button']}
              type={props.approveButtonType ? props.approveButtonType : "primary"}
              size={"base"}
              onClick={props.approveButton['onClick']}
            >
              {props.approveButton['text']}
            </Button>
          }
          {props.cancelButton && 
            <Button
              className={styles['modal-button']}
              type={"cancel"}
              size={"base"}
              onClick={props.cancelButton['onClick']}
            >
              {props.cancelButton['text']}
            </Button>
          }
        </div>
      </div>
    </div>
  );
}

export default Modal
