import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { getPageStyle, updatePageStyle } from '../../../utils/helpers';
import { accountManagerMenuItems, clientMenuItems } from '../../../utils/constants';
import { sendContactUsFormRequest, uploadHelpFormImageFileRequest } from '../../../requests/client-help-requests';
import { fetchClientBasicProfileRequest } from '../../../requests/client-requests';
import { getAuthClientId, getAuthUserProfilePictureUrl, getAuthUserRole, getClientAvailableModules } from '../../../utils/auth';
import { handleRequestError } from '../../../utils/request-error-helpers';
import useHttp from '../../../hooks/use-http';

import Tab from '../../../components/UI/Tab/Tab';
import SideNavigationBar from '../../../components/UI/SideNavigationBar/SideNavigationBar';

import HelpContactForm from './HelpContactForm/HelpContactForm';
import Glossary from './Glossary/Glossary';

import SquareWellProfilePicture from '../../../assets/other/squarewell-profile-picture.png';
import styles from './HelpPage.module.css';

const HelpPage = (props) => {

  const clientId = getAuthClientId();
  const clientAvailableModules = getClientAvailableModules();
  const userRole = getAuthUserRole();
  const userProfilePictureUrl = getAuthUserProfilePictureUrl();

  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  const pageHeightMargin = '95px';
  /* eslint-disable no-unused-vars */

  const [isLoading, error, sendRequest] = useHttp();

  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);

  const [tabItems, setTabItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState('tab-contact-us');

  const helpTabChangeHandler = (selectedTabId) => {
    setSelectedTab(selectedTabId);
  }

  useEffect(() => {

    setTabItems([
      {
        'id': 'tab-contact-us',
        'text': 'Contact Us'
      },
      {
        'id': 'tab-glossary',
        'text': 'Glossary'
      },
      /*{
        'id': 'tab-how-to-use',
        'text': 'How to use?'
      }*/
    ]);

  }, []);

  useEffect(() => {
    if (userRole === 'Client') {
      sendRequest(
        fetchClientBasicProfileRequest(clientId),
        (data) => {
          setClientProfilePictureUrl(data['profile_picture_url']);
        }
      );
    }
    else {
      setClientProfilePictureUrl(SquareWellProfilePicture);
    }
  }, [sendRequest, clientId, userRole]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const helpContactFormSendClickHandler = (contactUsFormData, setIsClientHelpContactFormSent) => {
    sendRequest(
      sendContactUsFormRequest(clientId, contactUsFormData),
      (data) => {
        setIsClientHelpContactFormSent(true);
      }
    );
  }

  const imageAttachmentUploadHandler = (attachmentData, setImageAttachment) => {
    sendRequest(
      uploadHelpFormImageFileRequest(clientId, attachmentData),
      (data) => { 
        setImageAttachment(data);
      }
    );
  }

  return (
    <div className={styles['page']}>
      <SideNavigationBar
        menuItems={userRole === 'Account Manager' ? accountManagerMenuItems : clientMenuItems.filter(item => clientAvailableModules[item['module_id']])}
        profilePictureUrl={userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}
        selected='help'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div 
        id='page-right-container'
        className={`${styles['container']} ${styles[pageStyle]}`}
        style={{ height: `calc(100% - ${pageHeightMargin})`, overflowY: 'auto', overflowX: 'hidden' }}
        ref={ref}
      >
        <div className={styles['page-header']}>
          <div className={styles['section']}>
            <div className={styles['row']}>
              <div className={styles['title']}>
                <h1>Help</h1>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['page-body']} >
          <div className={styles['tab-container']}>
            <Tab items={tabItems} selectedTab={selectedTab} onTabChange={helpTabChangeHandler} />
          </div>
          <div className={styles['help-content-container']}>
            { selectedTab === 'tab-contact-us' && 
              <HelpContactForm onImageAttachmentUploadClick={imageAttachmentUploadHandler} onSendClick={helpContactFormSendClickHandler} />
            }
            { selectedTab === 'tab-glossary' && 
              <Glossary />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default HelpPage;
