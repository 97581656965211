import { getAccessToken } from "../utils/auth";
import { BASE_URI } from "./config";

/* LOGIN / LOGOUT / PASSWORD RESET */

export const userLoginRequest = (body) => {
  return {
    url: `${BASE_URI}/login`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }
  };
}

export const renewAccessTokenRequest = () => {
  return {
    url: `${BASE_URI}/renew-access-token`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}

export const validateUserLoginCodeRequest = (body) => {
  return {
    url: `${BASE_URI}/validate-login-code`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }
  };
}

export const resendLoginCodeRequest = (body) => {
  return {
    url: `${BASE_URI}/resend-login-code`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }
  };
}

export const userLogoutRequest = (body) => {
  return {
    url: `${BASE_URI}/logout`,
    options: {
      method: 'GET'
    }
  };
}

export const sendResetPasswordCodeRequest = (body) => {
  return {
    url: `${BASE_URI}/send-reset-password-code`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }
  };
}

export const validateResetPasswordCodeRequest = (body) => {
  return {
    url: `${BASE_URI}/validate-reset-password-code`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }
  };
}

export const resetUserPasswordRequest = (body) => {
  return {
    url: `${BASE_URI}/reset-user-password`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }
  };
}
