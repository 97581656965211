import { useEffect, useRef, useState } from 'react';
import styles from './ComboBox.module.css';

const ComboBox = (props) => {
  const [showItems, setShowItems] = useState(false);
  const [isCustom, setIsCustom] = useState(props.value !== '' && props.items.filter(item => item['text'].toLowerCase() === props.value.toLowerCase()).length === 0);
  const ref = useRef(null);

  const isDisabled = props.isDisabled ? true : false;
  const sizeStyle = props.size === undefined ? styles['base'] : styles[props.size];
  const errorStyle = props.hasError ? styles['error'] : '';
  const isCustomStyle = isCustom ? styles['custom'] : '';
  const withLabelStyle = props.label !== undefined ? styles['with-label'] : '';
  const hasAddCustomButtonStyle = props.allowCustomValues ? styles['has-add-custom-button'] : '';

  const showItemsHandler = () => {
    if(!isDisabled) {
      setShowItems(true);
    }
  }

  const inputChangeHandler = (event) => {
    if (props.onChange !== undefined) {
      props.onChange(event.target.value);
    }
  }

  const buttonClickHandler = () => {
    setShowItems(false);
    setIsCustom(true);
    props.onButtonClick(props.value);
  }

  const itemClickHandler = (selectedItem) => {
    setShowItems(false);
    props.onItemClick(selectedItem);
  }

  const propsLastSavedValue = props.lastSavedValue;
  const propsOnChange = props.onChange;
  useEffect(() => {
    const handleClickOutside = (event) => {
      if(!ref.current.contains(event.target)) {
        propsOnChange(propsLastSavedValue);
        setShowItems(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, propsLastSavedValue, propsOnChange]);

  const matchedItems = props.items.filter(item => item['text'].toLowerCase().includes(props.value.toLowerCase())).map(item => {
    return(
      <div key={`matched-item-${item['id']}`} className={styles['item']} onClick={() => itemClickHandler(item['text'])}>
        <p>{item['text']}</p>
      </div>
    );
  })

  const customStyle = {};
  if (props.width !== undefined) {
    customStyle['width'] = props.width
  }

  const allowCustomValues = props.allowCustomValues;

  const propsItems = props.items;
  const propsValue = props.value;
  useEffect(() => {
    if(propsValue !== '' && propsItems.filter(item => item['text'].toLowerCase() === propsValue.toLowerCase()).length === 0) {
      setIsCustom(true);
    }
    else {
      setIsCustom(false);
    }
  }, [propsItems, propsValue])


  return (
    <div className={`${styles['input-container']} ${withLabelStyle}`} ref={ref} style={customStyle}>
      { props.label && <label className={styles['input-label']}>{props.label}</label> }
      <div className={styles['input-wrapper']}>
        <input
          id={props.id}
          className={`${styles['text-input']} ${sizeStyle} ${errorStyle} ${isCustomStyle}`}
          placeholder={props.placeholder}
          value={props.value}
          disabled={isDisabled}
          onChange={inputChangeHandler}
          onClick={showItemsHandler}
          data-value={props.value}
          tabIndex={props.tabIndex}
        />
        {isCustom && allowCustomValues &&
          <div className={styles['chip']}>
            <p>Custom</p>
          </div>
        }
      </div>
      {showItems && 
        <div className={styles['content']}>
          <div className={`${styles['item-container']} ${sizeStyle} ${hasAddCustomButtonStyle}`}>
            <div className={styles['matched-items']}>
              {matchedItems}
            </div>
            {allowCustomValues &&
              <div className={styles['button-container']} onClick={buttonClickHandler}>
                <div className={`${styles['icon']} ${styles['plus']}`}></div>
                <div className={styles['text']}>
                  <p>Add as custom</p>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default ComboBox;
