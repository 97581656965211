import { BASE_URI } from "./config";
import { getAccessToken } from "../utils/auth";


export const fetchHyperSearchInvestorList = () => {
  return {
    url: `${BASE_URI}/esgds-hypersearch/fetch-investors`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}

export const fetchHyperSearchResults = (body) => {
  return {
    url: `${BASE_URI}/esgds-hypersearch/search`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}

export const fetchHyperSearchReportUrl = (reportId, queryParams) => {
  return {
    url: `${BASE_URI}/esgds-hypersearch/view-document/${reportId}?${queryParams}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}
