import { useRef } from 'react';
import TableRow from '../TableRow/TableRow';
import styles from './LeftFixedContent.module.css';

const LeftFixedContent = (props) => {
  const ref = useRef();

  const headerRowItems = props.items['header']['items'].map(item => {
    return (
      <TableRow
        key={`${item['id']}-left`}
        section='header'
        container='left-fixed'
        columns={item['columns']}
        config={props.items['header']['config']}
      />
    );
  })

  const bodyRowItems = props.items['body']['items'].map(item => {
    return (
      <TableRow
        id={`${item['id']}#left`}
        key={`${item['id']}-left`}
        section='body'
        container='left-fixed'
        columns={item['columns']}
        config={props.items['body']['config']}
        viewMode={props.viewMode}
      />
    );
  })

  return (
    <div ref={ref} className={styles['left-fixed']} style={{ 'width': props.width }}>
      <div className={styles['header']}>
        {headerRowItems}
      </div>
      <div className={styles['body']}>
        {bodyRowItems}
      </div>
    </div>
  )
}

export default LeftFixedContent
