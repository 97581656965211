import { useState } from "react"

const useInputValidation = (initialValue, validateValue) => {
  const [value, setValue] = useState(initialValue);
  const [isTouched, setIsTouched] = useState(false);

  const isValueValid = validateValue(value);
  const hasError = !isValueValid && isTouched;

  const inputChangeHandler = (enteredValue) => {
    setValue(enteredValue);
    if (enteredValue === '') {
      setIsTouched(false);
    }
  }

  const inputBlurHandler = (enteredValue) => {
    setIsTouched(true);
  }

  /*
  const resetInput = () => {
    setValue('');
    setIsTouched(false);
  }
  */

  return [value, setValue, isValueValid, hasError, inputChangeHandler, inputBlurHandler];
}

export default useInputValidation;