import { exitViewAsClientMode } from '../../utils/helpers';
import Button from '../UI/Button/Button';
import styles from './AccountManagerModeHeader.module.css';

const AccountManagerModeHeader = (props) => {

  const exitClientModeHandler = () => {
    exitViewAsClientMode(props.exitRedirecUrl);
  }

  return (
    <div className={`${styles['warning-container']} ${styles[props.type]}`}>
      <div className={styles['title']}>
        <div className={`${styles['icon']} ${styles['warning']}`}></div>
        <h3>Viewing as Client - {props.clientName}</h3>
      </div>
      <div className={styles['button-container']}>
        <Button type={"primary"} size={"xsmall"} onClick={exitClientModeHandler}>Exit Client Mode</Button>
      </div>
    </div>
  )
}

export default AccountManagerModeHeader;
