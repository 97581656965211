import { useCallback, useEffect, useState } from "react";
import { generateClientShareholderCommunicationInvestorsTableItems, generateClientShareholderCommunicationProxyAdvisorsTableItems } from "../../../utils/client-engagement-helpers";
import { fetchClientEngagementsShareholderCommunicationRequest, updateClientEngagementsShareholderCommunicationInvestorRequest, updateClientEngagementsShareholderCommunicationProxyAdvisorRequest } from "../../../requests/client-engagement-requests";
import { getAuthClientId, getClientType } from "../../../utils/auth";
import { handleRequestError } from "../../../utils/request-error-helpers";
import useHttp from "../../../hooks/use-http";
import sortArray from 'sort-array';

import Button from "../../UI/Button/Button";
import Modal from "../../UI/Modal/Modal";
import Table from "../../UI/Table/Table";

import EngagementDraftEmailModal from "../EngagementDraftEmailModal/EngagementDraftEmailModal";
import EngagementEmailCustomizationModal from "../EngagementEmailCustomizationModal/EngagementEmailCustomizationModal";

import styles from './EngagementShareholderCommunicationForm.module.css';

const EngagementShareholderCommunicationDetailsForm = (props) => {

  const clientId = getAuthClientId();
  const clientType = getClientType();
  const shareholderCommunicationId = props.shareholderCommunicationId;

  const [isLoading, error, sendRequest] = useHttp();

  const [shareholderCommunicationDetailsData, setShareholderCommunicationDetailsData] = useState([]);

  const setBreadcrumbItems = props.setBreadcrumbItems;

  const [showUpdateEmailContentWarning, setShowUpdateEmailContentWarning] = useState(false);
  const [showDraftEmailModal, setShowDraftEmailModal] = useState(false);
  const [showEmailCustomizationModal, setShowEmailCustomizationModal] = useState(false);

  const [selectedInvestorType, setSelectedInvestorType] = useState(null);
  const [selectedInvestorId, setSelectedInvestorId] = useState(null);
  const [selectedProxyAdvisorId, setSelectedProxyAdvisorId] = useState(null);
  const [isSelectedItemDraftEmailDisabled, setIsSelectedItemDraftEmailDisabled] = useState(false);

  const [shareholderCommunicationInvestorsData, setShareholderCommunicationInvestorsData] = useState([]);
  const [shareholderCommunicationInvestorsTableItems, setShareholderCommunicationInvestorsTableItems] = useState(null);

  const [shareholderCommunicationProxyAdvisorsData, setShareholderCommunicationProxyAdvisorsData] = useState([]);
  const [shareholderCommunicationProxyAdvisorsTableItems, setShareholderCommunicationProxyAdvisorsTableItems] = useState(null);

  const [shareholderCommunicationInvestorsTableSortConfig, setShareholderCommunicationInvestorsTableSortConfig] = useState({
    'by': ['name'],
    'order': ['asc']
  });

  const shareholderCommunicationInvestorsTableSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setShareholderCommunicationInvestorsTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setShareholderCommunicationInvestorsData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, shareholderCommunicationInvestorsTableSortConfig);
    });
  }, [shareholderCommunicationInvestorsTableSortConfig]);


  const [shareholderCommunicationProxyAdvisorsTableSortConfig, setShareholderCommunicationProxyAdvisorsTableSortConfig] = useState({
    'by': ['name'],
    'order': ['asc']
  });

  const shareholderCommunicationProxyAdvisorsTableSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setShareholderCommunicationProxyAdvisorsTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setShareholderCommunicationProxyAdvisorsData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, shareholderCommunicationProxyAdvisorsTableSortConfig);
    });
  }, [shareholderCommunicationProxyAdvisorsTableSortConfig]);

  /* Navigates to details page for the selected investor. */
  const investorClickHandler = (investorId) => {
    window.location.href = `../investors/${investorId}`;
  }

  const investorCustomizeClickHandler = useCallback((investorId) => {
    setSelectedInvestorId(investorId);
    setSelectedProxyAdvisorId(null);
    setSelectedInvestorType('type-investor');
    setShowEmailCustomizationModal(true);

    const indexOfInvestor = shareholderCommunicationInvestorsData.map(item => item['client_investor_id']).indexOf(investorId);
    if(shareholderCommunicationInvestorsData[indexOfInvestor]['email_status'] === 'Sent') {
      setIsSelectedItemDraftEmailDisabled(true);
    }
    else {
      setIsSelectedItemDraftEmailDisabled(false);
    }
  }, [shareholderCommunicationInvestorsData]);

  /* Updates email status value for the selected investor. */
  const investorEmailStatusChangeHandler = useCallback((itemId, selectedStatus) => {
    const clickedInvestorId = parseInt(itemId.split('client-engagements-shareholder-communication-investor-')[1]);
    const body = {
      'email_status': selectedStatus
    }
    sendRequest(
      updateClientEngagementsShareholderCommunicationInvestorRequest(clientId, shareholderCommunicationId, clickedInvestorId, body),
      (data) => { 
        setShareholderCommunicationInvestorsData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          const indexOfInvestor = items.map(item => item['client_investor_id']).indexOf(clickedInvestorId);
          items[indexOfInvestor] = {...items[indexOfInvestor], ...data};
          return items;
        });
      }
    );
  }, [clientId, shareholderCommunicationId, sendRequest]);


  /* Navigates to details page for the selected proxy advisor. */
  const proxyAdvisorClickHandler = (proxyAdvisorId) => {
    window.location.href = `../proxy-advisors/${proxyAdvisorId}`;
  }

  const proxyAdvisorCustomizeClickHandler = useCallback((proxyAdvisorId) => {
    setSelectedInvestorId(null);
    setSelectedProxyAdvisorId(proxyAdvisorId);
    setSelectedInvestorType('type-proxy-advisor');
    setShowEmailCustomizationModal(true);

    const indexOfProxyAdvisor = shareholderCommunicationProxyAdvisorsData.map(item => item['client_proxy_advisor_id']).indexOf(proxyAdvisorId);
    if(shareholderCommunicationProxyAdvisorsData[indexOfProxyAdvisor]['email_status'] === 'Sent') {
      setIsSelectedItemDraftEmailDisabled(true);
    }
    else {
      setIsSelectedItemDraftEmailDisabled(false);
    }
  }, [shareholderCommunicationProxyAdvisorsData]);

  /* Updates email status value for the selected proxy advisor. */
  const proxyAdvisorEmailStatusChangeHandler = useCallback((itemId, selectedStatus) => {
    const clickedProxyAdvisorId = parseInt(itemId.split('client-engagements-shareholder-communication-proxy-advisor-')[1]);
    const body = {
      'email_status': selectedStatus
    }
    sendRequest(
      updateClientEngagementsShareholderCommunicationProxyAdvisorRequest(clientId, shareholderCommunicationId, clickedProxyAdvisorId, body),
      (data) => { 
        setShareholderCommunicationProxyAdvisorsData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          const indexOfProxyAdvisor = items.map(item => item['client_proxy_advisor_id']).indexOf(clickedProxyAdvisorId);
          items[indexOfProxyAdvisor] = {...items[indexOfProxyAdvisor], ...data};
          return items;
        });
      }
    );
  }, [clientId, shareholderCommunicationId, sendRequest]);


  /* Updates draft email for the selected investor or proxy advisor. */
  const selectedItemEmailCustomizeHandler = (customizedEmailData, emailStatus) => {
    const body = {
      'draft_email': customizedEmailData
    };
    if (emailStatus !== null && emailStatus !== undefined) {
      body['email_status'] = emailStatus;
    }
    if (selectedInvestorType === 'type-investor') {
      sendRequest(
        updateClientEngagementsShareholderCommunicationInvestorRequest(clientId, shareholderCommunicationId, selectedInvestorId, body),
        (data) => { 
          setShowEmailCustomizationModal(false);
          setSelectedInvestorId(null);
          setSelectedInvestorType(null);
          setShareholderCommunicationInvestorsData(prevState => {
            const items = JSON.parse(JSON.stringify(prevState));
            const indexOfInvestor = items.map(item => item['client_investor_id']).indexOf(selectedInvestorId);
            items[indexOfInvestor] = {...items[indexOfInvestor], ...data};
            return items;
          });
        }
      );
    }
    else if (selectedInvestorType === 'type-proxy-advisor') {
      sendRequest(
        updateClientEngagementsShareholderCommunicationProxyAdvisorRequest(clientId, shareholderCommunicationId, selectedProxyAdvisorId, body),
        (data) => { 
          setShowEmailCustomizationModal(false);
          setSelectedInvestorId(null);
          setSelectedInvestorType(null);
          setShareholderCommunicationProxyAdvisorsData(prevState => {
            const items = JSON.parse(JSON.stringify(prevState));
            const indexOfProxyAdvisor = items.map(item => item['client_proxy_advisor_id']).indexOf(selectedProxyAdvisorId);
            items[indexOfProxyAdvisor] = {...items[indexOfProxyAdvisor], ...data};
            return items;
          });
        }
      );
    }
  }

  /* Send a request to fetch shareholder communication item and its investor and proxy advisor list. */
  useEffect(() => {
    sendRequest(
      fetchClientEngagementsShareholderCommunicationRequest(clientId, shareholderCommunicationId),
      (data) => { 
        setShareholderCommunicationDetailsData(data)
        setShareholderCommunicationInvestorsData(data['investors']);
        setShareholderCommunicationProxyAdvisorsData(data['proxy_advisors']);
        setBreadcrumbItems(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          if(items.length < 3){
            items[1]['text'] = 'Shareholder Communications';
            items[1]['href'] = '../engagements?type=shareholder-communications';
            items.push({
              'text': data['engagement_name'],
              'href': ''
            });
          }
          return items;
        });
      }
    );
  }, [sendRequest, clientId, shareholderCommunicationId, setBreadcrumbItems]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  useEffect(() => {
    setShareholderCommunicationInvestorsTableItems(
      generateClientShareholderCommunicationInvestorsTableItems(
        clientType, shareholderCommunicationInvestorsData, investorClickHandler, 
        investorCustomizeClickHandler, investorEmailStatusChangeHandler,
        shareholderCommunicationInvestorsTableSortConfig, shareholderCommunicationInvestorsTableSortHandler
      )
    );
  }, [clientType, shareholderCommunicationInvestorsData, shareholderCommunicationInvestorsTableSortConfig,
      investorCustomizeClickHandler, investorEmailStatusChangeHandler]);

  useEffect(() => {
    setShareholderCommunicationProxyAdvisorsTableItems(
      generateClientShareholderCommunicationProxyAdvisorsTableItems(
        shareholderCommunicationProxyAdvisorsData, proxyAdvisorClickHandler, 
        proxyAdvisorCustomizeClickHandler, proxyAdvisorEmailStatusChangeHandler,
        shareholderCommunicationProxyAdvisorsTableSortConfig, shareholderCommunicationProxyAdvisorsTableSortHandler
      )
    );
  }, [shareholderCommunicationProxyAdvisorsData, shareholderCommunicationProxyAdvisorsTableSortConfig, 
      proxyAdvisorCustomizeClickHandler, proxyAdvisorEmailStatusChangeHandler]);

  return (
    <div className={styles['shareholder-communication-container']}>
      {showUpdateEmailContentWarning &&
        <Modal
          type='critical'
          title='Edit Email Content'
          message={`If you proceed with editing the Email content, you will override all customizations you may have done except the ones marked as sent. Would you still like to proceed with updating the template?`}
          onCloseClick={() => setShowUpdateEmailContentWarning(false)}
          approveButton = {{
            'text': 'Proceed',
            'onClick': () => props.onEditEngagement()
          }}
          cancelButton = {{
            'text': 'Cancel',
            'onClick': () => setShowUpdateEmailContentWarning(false)
          }}
        />
      }

      <div className={styles['header']}>
        <div className={styles['shareholder-communication-title-container']}>
          <div className={styles['title']}>
            <h2>{shareholderCommunicationDetailsData['engagement_name']}</h2>
          </div>
          <div className={styles['type-chip']}>
            <div className={`${styles['icon']} ${styles['shareholder-communication']}`}></div>
            <div className={styles['label']}>
              <p>Shareholder Communication</p>
            </div>
          </div>
        </div>

        <div className={styles['shareholder-communication-engagement-list-buttons']}>  
          <div className={styles['right-buttons']}>
            <Button type={'primary'} size={'xsmall'} leftIcon={'document'} onClick={() => setShowDraftEmailModal(true)}>Preview Email</Button>
            <Button type={'secondary'} size={'xsmall'} leftIcon={'edit'} onClick={() => setShowUpdateEmailContentWarning(true)}>Edit Engagement</Button>
          </div>
        </div>
      </div>
            

      { shareholderCommunicationProxyAdvisorsTableItems &&
        <div className={styles['engagement-shareholder-communication-proxy-advisor-list-container']}>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={shareholderCommunicationProxyAdvisorsTableItems} isScrollable={false} />
        </div>
      }

      { shareholderCommunicationInvestorsTableItems &&
        <div className={styles['engagement-shareholder-communication-investor-list-container']}>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={shareholderCommunicationInvestorsTableItems} isScrollable={false} />
        </div>
      }

      { showDraftEmailModal &&
        <EngagementDraftEmailModal
          shareholderCommunicationId={shareholderCommunicationId}
          onCloseClick={ () => setShowDraftEmailModal(false) }
          onCancel={ () => setShowDraftEmailModal(false) }
          onEditEngagement={() => {
            setShowDraftEmailModal(false);
            setShowUpdateEmailContentWarning(true);
          }}
        />
      }

      { showEmailCustomizationModal && 
        <EngagementEmailCustomizationModal
          shareholderCommunicationId={shareholderCommunicationId}
          investorType={selectedInvestorType}
          investorId={selectedInvestorId}
          proxyAdvisorId={selectedProxyAdvisorId}
          onSaveClick={selectedItemEmailCustomizeHandler}
          onCloseClick={ () => setShowEmailCustomizationModal(false) }
          isDisabled={isSelectedItemDraftEmailDisabled}
        />
      }

    </div>
  );
}

export default EngagementShareholderCommunicationDetailsForm;
