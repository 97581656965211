import { useEffect, useState } from 'react';
import { resetUserPasswordRequest, sendResetPasswordCodeRequest, validateResetPasswordCodeRequest } from '../../../requests/auth-requests';
import useInputValidation from '../../../hooks/use-input-validation';
import useHttp from '../../../hooks/use-http';

import Button from '../../../components/UI/Button/Button';
import LinkButton from '../../../components/UI/LinkButton/LinkButton';
import TextInput from '../../../components/UI/TextInput/TextInput';
import PasswordInput from '../../../components/UI/PasswordInput/PasswordInput';

import styles from './ForgotPasswordPage.module.css';

const ForgotPasswordPage = (props) => {
  const [email, setEmail, isEmailValid, hasEmailError, emailChangeHandler, emailBlurHandler] = 
    useInputValidation('', (email) => email.trim() !== '');

  const [userPasswordCreationSessionToken, setUserPasswordCreationSessionToken] = useState(null);

  const [newPassword, setNewPassword, isNewPasswordValid, hasNewPasswordError, newPasswordChangeHandler, newPasswordBlurHandler] = 
    useInputValidation('', (newPassword) => newPassword.trim() !== '');

  const [confirmNewPassword, setConfirmNewPassword, isConfirmNewPasswordValid, hasConfirmNewPasswordError, confirmNewPasswordChangeHandler, confirmNewPasswordBlurHandler] = 
    useInputValidation('', (confirmNewPassword) => confirmNewPassword.trim() !== '');

  const [forgotPasswordValidateCodeDigit1, setForgotPasswordValidateCodeDigit1, isForgotPasswordValidateCodeDigit1Valid, hasForgotPasswordValidateCodeDigit1Error, forgotPasswordValidateCodeDigit1ChangeHandler, forgotPasswordValidateCodeDigit1BlurHandler] = 
    useInputValidation('', (forgotPasswordValidateCodeDigit1) => forgotPasswordValidateCodeDigit1.trim() !== '');
  
  const [forgotPasswordValidateCodeDigit2, setForgotPasswordValidateCodeDigit2, isForgotPasswordValidateCodeDigit2Valid, hasForgotPasswordValidateCodeDigit2Error, forgotPasswordValidateCodeDigit2ChangeHandler, forgotPasswordValidateCodeDigit2BlurHandler] = 
    useInputValidation('', (forgotPasswordValidateCodeDigit1) => forgotPasswordValidateCodeDigit1.trim() !== '');

  const [forgotPasswordValidateCodeDigit3, setForgotPasswordValidateCodeDigit3, isForgotPasswordValidateCodeDigit3Valid, hasForgotPasswordValidateCodeDigit3Error, forgotPasswordValidateCodeDigit3ChangeHandler, forgotPasswordValidateCodeDigit3BlurHandler] = 
    useInputValidation('', (forgotPasswordValidateCodeDigit1) => forgotPasswordValidateCodeDigit1.trim() !== '');

  const [forgotPasswordValidateCodeDigit4, setForgotPasswordValidateCodeDigit4, isForgotPasswordValidateCodeDigit4Valid, hasForgotPasswordValidateCodeDigit4Error, forgotPasswordValidateCodeDigit4ChangeHandler, forgotPasswordValidateCodeDigit4BlurHandler] = 
    useInputValidation('', (forgotPasswordValidateCodeDigit1) => forgotPasswordValidateCodeDigit1.trim() !== '');

  const [forgotPasswordValidateCodeDigit5, setForgotPasswordValidateCodeDigit5, isForgotPasswordValidateCodeDigit5Valid, hasForgotPasswordValidateCodeDigit5Error, forgotPasswordValidateCodeDigit5ChangeHandler, forgotPasswordValidateCodeDigit5BlurHandler] = 
    useInputValidation('', (forgotPasswordValidateCodeDigit1) => forgotPasswordValidateCodeDigit1.trim() !== '');

  const [forgotPasswordValidateCodeDigit6, setForgotPasswordValidateCodeDigit6, isForgotPasswordValidateCodeDigit6Valid, hasForgotPasswordValidateCodeDigit6Error, forgotPasswordValidateCodeDigit6ChangeHandler, forgotPasswordValidateCodeDigit6BlurHandler] = 
    useInputValidation('', (forgotPasswordValidateCodeDigit1) => forgotPasswordValidateCodeDigit1.trim() !== '');

  const [isResetPasswordButtonDisabled, setIsResetPasswordButtonDisabled] = useState(true);

  const [isLoading, error, sendRequest] = useHttp();

  const isForgotPasswordFormValid = () => {
    return isEmailValid;
  }

  const [activeForm, setActiveForm] = useState('forgot-password-form');

  const [formError, setFormError] = useState({
    hasError: false,
    title: '',
    text: ''
  });

  const [resendCodeInfo, setResendCodeInfo] = useState({
    hasInfo: false,
    title: '',
    text: ''
  });

  const forgotPasswordHandler = () => {
    if (!isForgotPasswordFormValid()) {
      return;
    }

    const body = {
      'email': email
    };
    sendRequest(
      sendResetPasswordCodeRequest(body),
      (data) => {
        setFormError({
          hasError: false,
          title: '',
          text: ''
        });
        setActiveForm('validate-reset-password-code-form');
      }
    );
  }

  const isForgotPasswordCodeValid = () => {
    return isForgotPasswordValidateCodeDigit1Valid && isForgotPasswordValidateCodeDigit2Valid && 
            isForgotPasswordValidateCodeDigit3Valid && isForgotPasswordValidateCodeDigit4Valid && 
            isForgotPasswordValidateCodeDigit5Valid && isForgotPasswordValidateCodeDigit6Valid;
  }

  const forgotPasswordCodeCheckHandler = () => {
    if (!isForgotPasswordCodeValid()) {
      return;
    }

    const body = {
      'email': email,
      'reset_password_code': `${forgotPasswordValidateCodeDigit1}${forgotPasswordValidateCodeDigit2}${forgotPasswordValidateCodeDigit3}-${forgotPasswordValidateCodeDigit4}${forgotPasswordValidateCodeDigit5}${forgotPasswordValidateCodeDigit6}`
    };
    sendRequest(
      validateResetPasswordCodeRequest(body),
      (data) => {
        setFormError({
          hasError: false,
          title: '',
          text: ''
        });
        setUserPasswordCreationSessionToken(data['token'])
        setActiveForm('reset-password-form');
      }
    );
  }

  useEffect(() => {
    if(!isLoading && error !== undefined) {
      if (error.message === 'User is not available.') { 
        setFormError({
          hasError: true,
          title: 'Wrong Email',
          text: 'Please check entered email.'
        });
      }
      else if(activeForm === 'validate-reset-password-code-form' && error.status === 401) {
        setFormError({
          hasError: true,
          title: 'Wrong Reset Password Code',
          text: `Reset password code is wrong. Please enter the reset password code that you received before it expires.`
        });
      }
      else if(activeForm === 'validate-reset-password-code-form' && error.status === 410) {
        setFormError({
          hasError: true,
          title: 'Expired Reset Password Code',
          text: `Reset password code is expired. Please request another code.`
        });
      }
    }
  }, [isLoading, error, activeForm]);

  useEffect(() => {
    setEmail('');
    setForgotPasswordValidateCodeDigit1('');
    setForgotPasswordValidateCodeDigit2('');
    setForgotPasswordValidateCodeDigit3('');
    setForgotPasswordValidateCodeDigit4('');
    setForgotPasswordValidateCodeDigit5('');
    setForgotPasswordValidateCodeDigit6('');
    setNewPassword('');
    setConfirmNewPassword('');
  }, [setEmail, setForgotPasswordValidateCodeDigit1, setForgotPasswordValidateCodeDigit2,
      setForgotPasswordValidateCodeDigit3, setForgotPasswordValidateCodeDigit4,
      setForgotPasswordValidateCodeDigit5, setForgotPasswordValidateCodeDigit6,
      setNewPassword, setConfirmNewPassword]);

  const forgotPasswordValidateCodeDigit1ChangeAndMoveHandler = (enteredValue) => {
    forgotPasswordValidateCodeDigit1ChangeHandler(enteredValue);
    document.getElementById('forgot-password-form#code-box-2').focus();
  }

  const forgotPasswordValidateCodeDigit2ChangeAndMoveHandler = (enteredValue) => {
    forgotPasswordValidateCodeDigit2ChangeHandler(enteredValue);
    document.getElementById('forgot-password-form#code-box-3').focus();
  }

  const forgotPasswordValidateCodeDigit3ChangeAndMoveHandler = (enteredValue) => {
    forgotPasswordValidateCodeDigit3ChangeHandler(enteredValue);
    document.getElementById('forgot-password-form#code-box-4').focus();
  }

  const forgotPasswordValidateCodeDigit4ChangeAndMoveHandler = (enteredValue) => {
    forgotPasswordValidateCodeDigit4ChangeHandler(enteredValue);
    document.getElementById('forgot-password-form#code-box-5').focus();
  }

  const forgotPasswordValidateCodeDigit5ChangeAndMoveHandler = (enteredValue) => {
    forgotPasswordValidateCodeDigit5ChangeHandler(enteredValue);
    document.getElementById('forgot-password-form#code-box-6').focus();
  }

  const resendCodeHandler = () => {
    const body = {
      'email': email
    };

    sendRequest(
      sendResetPasswordCodeRequest(body),
      (data) => {
        setResendCodeInfo({
          hasInfo: true,
          title: 'Reset Password Code Has Been Sent',
          text: `Reset password code has been sent again. Please contact your Account Manager if you don't receive the code.`
        });

        setForgotPasswordValidateCodeDigit1('');
        setForgotPasswordValidateCodeDigit2('');
        setForgotPasswordValidateCodeDigit3('');
        setForgotPasswordValidateCodeDigit4('');
        setForgotPasswordValidateCodeDigit5('');
        setForgotPasswordValidateCodeDigit6('');
      }
    );
  }


  const checkLength = (password, length) => {
    return (password.length >= length);
  }

  const containsUpperCaseLetter = (password) => {
    return /[A-Z]/.test(password);
  }

  const containsLowerCaseLetter = (password) => {
    return /[a-z]/.test(password);
  }

  const containsNumber = (password) => {
    return /\d/.test(password);
  }

  const containsSpecialCharacter = (password) => {
    const specialCharacters = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    return specialCharacters.test(password);
  }

  const validateNewPassword = (password) => {
    const passwordConditionLength = document.getElementById('passwordConditionLength');
    const passwordConditionUppercaseChar = document.getElementById('passwordConditionUppercaseChar');
    const passwordConditionLowercaseChar = document.getElementById('passwordConditionLowercaseChar');
    const passwordConditionNumber = document.getElementById('passwordConditionNumber');
    const passwordConditionSpecialChar = document.getElementById('passwordConditionSpecialChar');

    if(passwordConditionLength) {
      if(!checkLength(password, 12)) {
        passwordConditionLength.classList.remove(styles['success']);
        passwordConditionLength.classList.add(styles['error']);
      }
      else {
        passwordConditionLength.classList.remove(styles['error']);
        passwordConditionLength.classList.add(styles['success']);
      }
    }

    if(passwordConditionUppercaseChar) {
      if(!containsUpperCaseLetter(password)) {
        passwordConditionUppercaseChar.classList.remove(styles['success']);
        passwordConditionUppercaseChar.classList.add(styles['error']);
      }
      else {
        passwordConditionUppercaseChar.classList.remove(styles['error']);
        passwordConditionUppercaseChar.classList.add(styles['success']);
      }
    }

    if(passwordConditionLowercaseChar) {
      if(!containsLowerCaseLetter(password)) {
        passwordConditionLowercaseChar.classList.remove(styles['success']);
        passwordConditionLowercaseChar.classList.add(styles['error']);
      }
      else {
        passwordConditionLowercaseChar.classList.remove(styles['error']);
        passwordConditionLowercaseChar.classList.add(styles['success']);
      }
    }

    if(passwordConditionNumber) {
      if(!containsNumber(password)) {
        passwordConditionNumber.classList.remove(styles['success']);
        passwordConditionNumber.classList.add(styles['error']);
      }
      else {
        passwordConditionNumber.classList.remove(styles['error']);
        passwordConditionNumber.classList.add(styles['success']);
      }
    }

    if(passwordConditionSpecialChar) {
      if(!containsSpecialCharacter(password)) {
        passwordConditionSpecialChar.classList.remove(styles['success']);
        passwordConditionSpecialChar.classList.add(styles['error']);
      }
      else {
        passwordConditionSpecialChar.classList.remove(styles['error']);
        passwordConditionSpecialChar.classList.add(styles['success']);
      }
    }

    return (
      password.trim() !== '' && checkLength(password, 12) && 
      containsUpperCaseLetter(password) && containsLowerCaseLetter(password) && 
      containsNumber(password) && containsSpecialCharacter(password)
    );
  }

  const validateAndNewPasswordChangeHandler = (enteredNewPassword) => {
    newPasswordChangeHandler(enteredNewPassword);
    validateNewPassword(enteredNewPassword);
    if(enteredNewPassword.trim() !== '' && isConfirmNewPasswordValid && 
      validatePasswordResetForm(enteredNewPassword, confirmNewPassword)
    ) {
      setIsResetPasswordButtonDisabled(false);
    }
    else {
      setIsResetPasswordButtonDisabled(true);
    }
  }

  const validateReNewPassword = (password, rePassword) => {
    return (password === rePassword);
  }

  const validateAndReNewPasswordChangeHandler = (enteredReNewPassword) => {
    confirmNewPasswordChangeHandler(enteredReNewPassword);
    validateReNewPassword(newPassword, enteredReNewPassword);
    if(isNewPasswordValid && enteredReNewPassword.trim() !== '' && 
      validatePasswordResetForm(newPassword, enteredReNewPassword)
    ) {
      setIsResetPasswordButtonDisabled(false);
    }
    else {
      setIsResetPasswordButtonDisabled(true);
    }
  }

  const validatePasswordResetForm = (password, rePassword) => {
    const isFormValid = validateNewPassword(password) && validateReNewPassword(password, rePassword);
    return isFormValid;
  }

  const resetPasswordHandler = () => {
    const passwordResetData = {
      'token': userPasswordCreationSessionToken,
      'new_password': newPassword
    };

    sendRequest(
      resetUserPasswordRequest(passwordResetData),
      (data) => {
        window.location.href='login';
      }
    );
  }

  return (
    <div className={styles['forgot-password-page']}>
      {activeForm === 'forgot-password-form' && 
        <div className={styles['forgot-password-form-container']}>
          <div className={styles['form-row']}>
            <div className={styles['logo-area']}>
              <div className={styles['logo']}></div>
            </div>
          </div>
          <div className={styles['form-row']}>
            <div className={styles['title']}>
              <h3>Forgot your password?</h3>
            </div>
          </div>
          <div className={styles['form-row']}>
            <TextInput
              id='forgot-password-form#email'
              width='100%'
              label='Email'
              placeholder='Enter your Email'
              value={email}
              hasError={hasEmailError}
              warningText={hasEmailError && 'Email needs to be filled!'}
              onBlur={emailBlurHandler}
              onChange={emailChangeHandler}
              isDisabled={false}
            />
          </div>
          {formError['hasError'] && 
            <div className={styles['form-row']}>
              <div className={styles['form-error']}>
                <div className={styles['error-title']}>
                  <div className={styles['warning-icon']}></div>
                  <h4>{formError['title']}</h4>
                </div>
                <div className={styles['error-content']}>
                  <p>{formError['text']}</p>
                </div>
              </div>
            </div>
          }
          <div className={styles['form-row']}>
            <Button width='100%' size='base' type='primary' onClick={forgotPasswordHandler} isDisabled={false}>Submit</Button>
          </div>
          <div className={styles['form-row']}>
            <LinkButton onClick={() => window.location.href='login'} width='100%' type={"primary"} size={"base"}>Sign In to Your Account</LinkButton>
          </div>
          <div className={styles['form-row']}>
            <div className={styles['note']}>
              <p>If you don’t have a username and/or password, please contact your Account Manager.</p>
            </div>
          </div>
        </div>
      }

      {activeForm === 'validate-reset-password-code-form' && 
        <div className={styles['forgot-password-form-container']}>
          <div className={styles['form-row']}>
            <div className={styles['logo-area']}>
              <div className={styles['logo']}></div>
            </div>
          </div>
          <div className={styles['form-row']}>
            <div className={`${styles['title']} ${styles['no-bottom-padding']}`}>
              <h3>Forgot your password?</h3>
            </div>
          </div>
          <div className={styles['form-row']}>
            <div className={styles['description']}>
              <p>
                An e-mail has been sent to your address <strong>{`${email}`}</strong> with a validation code that you may enter below.
              </p>
            </div>
          </div>
          <div className={styles['form-row']}>
            <div className={styles['code-area']}>
              <div className={styles['code-box']}>
                <TextInput
                  id='forgot-password-form#code-box-1'
                  width='40px'
                  placeholder=''
                  value={forgotPasswordValidateCodeDigit1}
                  hasError={hasForgotPasswordValidateCodeDigit1Error}
                  onBlur={forgotPasswordValidateCodeDigit1BlurHandler}
                  onChange={forgotPasswordValidateCodeDigit1ChangeAndMoveHandler}
                  isDisabled={false}
                />
              </div>
              <div className={styles['code-box']}>
                <TextInput
                  id='forgot-password-form#code-box-2'
                  width='40px'
                  placeholder=''
                  value={forgotPasswordValidateCodeDigit2}
                  hasError={hasForgotPasswordValidateCodeDigit2Error}
                  onBlur={forgotPasswordValidateCodeDigit2BlurHandler}
                  onChange={forgotPasswordValidateCodeDigit2ChangeAndMoveHandler}
                  isDisabled={false}
                />
              </div>
              <div className={styles['code-box']}>
                <TextInput
                  id='forgot-password-form#code-box-3'
                  width='40px'
                  placeholder=''
                  value={forgotPasswordValidateCodeDigit3}
                  hasError={hasForgotPasswordValidateCodeDigit3Error}
                  onBlur={forgotPasswordValidateCodeDigit3BlurHandler}
                  onChange={forgotPasswordValidateCodeDigit3ChangeAndMoveHandler}
                  isDisabled={false}
                />
              </div>
              <div className={styles['code-separator']}></div>
              <div className={styles['code-box']}>
                <TextInput
                  id='forgot-password-form#code-box-4'
                  width='40px'
                  placeholder=''
                  value={forgotPasswordValidateCodeDigit4}
                  hasError={hasForgotPasswordValidateCodeDigit4Error}
                  onBlur={forgotPasswordValidateCodeDigit4BlurHandler}
                  onChange={forgotPasswordValidateCodeDigit4ChangeAndMoveHandler}
                  isDisabled={false}
                />
              </div>
              <div className={styles['code-box']}>
                <TextInput
                  id='forgot-password-form#code-box-5'
                  width='40px'
                  placeholder=''
                  value={forgotPasswordValidateCodeDigit5}
                  hasError={hasForgotPasswordValidateCodeDigit5Error}
                  onBlur={forgotPasswordValidateCodeDigit5BlurHandler}
                  onChange={forgotPasswordValidateCodeDigit5ChangeAndMoveHandler}
                  isDisabled={false}
                />
              </div>
              <div className={styles['code-box']}>
                <TextInput
                  id='forgot-password-form#code-box-6'
                  width='40px'
                  placeholder=''
                  value={forgotPasswordValidateCodeDigit6}
                  hasError={hasForgotPasswordValidateCodeDigit6Error}
                  onBlur={forgotPasswordValidateCodeDigit6BlurHandler}
                  onChange={forgotPasswordValidateCodeDigit6ChangeHandler}
                  isDisabled={false}
                />
              </div>
            </div>
          </div>
          {formError['hasError'] && 
            <div className={styles['form-row']}>
              <div className={styles['form-error']}>
                <div className={styles['error-title']}>
                  <div className={styles['warning-icon']}></div>
                  <h4>{formError['title']}</h4>
                </div>
                <div className={styles['error-content']}>
                  <p>{formError['text']}</p>
                </div>
              </div>
            </div>
          }
          <div className={styles['form-row']}>
            <Button width='100%' size='base' type='primary' onClick={forgotPasswordCodeCheckHandler} isDisabled={false}>Submit</Button>
          </div>
          {!resendCodeInfo['hasInfo'] &&
            <div className={styles['form-row']}>
              <div className={styles['note']}>
                <p>Have you not received the code? </p>
                <div className={styles['send-code-button']}>
                  <LinkButton type={"primary"} size={"xsmall"} hasUnderline={false} onClick={resendCodeHandler}>Resend Code</LinkButton>
                </div>
              </div>
            </div>
          }
          {resendCodeInfo['hasInfo'] &&
            <div className={styles['form-row']}>
              <div className={styles['form-info']}>
                <div className={styles['info-title']}>
                  <div className={styles['warning-icon']}></div>
                  <h4>{resendCodeInfo['title']}</h4>
                </div>
                <div className={styles['info-content']}>
                  <p>{resendCodeInfo['text']}</p>
                </div>
              </div>
            </div>
          }
        </div>
      }

      {activeForm === 'reset-password-form' && 
        <div className={styles['forgot-password-form-container']}>
          <div className={styles['form-row']}>
            <div className={styles['logo-area']}>
              <div className={styles['logo']}></div>
            </div>
          </div>
          <div className={styles['form-row']}>
            <div className={styles['title']}>
              <h3>Reset Password</h3>
            </div>
          </div>
          <div className={styles['form-row']}>
            <PasswordInput
              id='reset-password-form#new-password'
              width='100%'
              label='New Password'
              placeholder='Enter your new Password'
              value={newPassword}
              hasError={hasNewPasswordError}
              warningText={hasNewPasswordError && 'New Password needs to be filled!'}
              onBlur={newPasswordBlurHandler}
              onChange={validateAndNewPasswordChangeHandler}
              isDisabled={false}
            />
            <div className={styles['new-password-conditions']}>
              <div className={styles['conditions-note']}>
                <p>Your password must include:</p>
              </div>
              <ul className={styles['conditions-list']}>
                <li id="passwordConditionLength">12 or more characters</li>
                <li id="passwordConditionUppercaseChar">Uppercase letter</li>
                <li id="passwordConditionLowercaseChar">Lowercase letter</li>
                <li id="passwordConditionNumber">Number</li>
                <li id="passwordConditionSpecialChar">Special character</li>
              </ul>
            </div>
          </div>
          <div className={styles['form-row']}>
            <PasswordInput
              id='reset-password-form#confirm-new-password'
              width='100%'
              label='Confirm New Password'
              placeholder='Re-enter your new Password'
              value={confirmNewPassword}
              hasError={hasConfirmNewPasswordError}
              warningText={hasConfirmNewPasswordError && 'Confirm New Password needs to be filled!'}
              onBlur={confirmNewPasswordBlurHandler}
              onChange={validateAndReNewPasswordChangeHandler}
              isDisabled={false}
            />
          </div>
          {formError['hasError'] && 
            <div className={styles['form-row']}>
              <div className={styles['form-error']}>
                <div className={styles['error-title']}>
                  <div className={styles['warning-icon']}></div>
                  <h4>{formError['title']}</h4>
                </div>
                <div className={styles['error-content']}>
                  <p>{formError['text']}</p>
                </div>
              </div>
            </div>
          }
          <div className={styles['form-row']}>
            <Button width='100%' size='base' type='primary' onClick={resetPasswordHandler} isDisabled={isResetPasswordButtonDisabled}>Reset Password</Button>
          </div>
        </div>
      }
    </div>
  );
}

export default ForgotPasswordPage;
