import { Fragment } from 'react';
import styles from './Breadcrumbs.module.css';

const Breadcrumbs = (props) => {
  const breadcrumbItems = props.items.map((item, index) => {
    let breadcrumbItem = (
      <Fragment key={`breadcrumb-item-level-${index}`}>
        <div className={styles['breadcrumb-item']}>
          {item['href'] !== '' && 
            <p><a href={item['href']}>{item['text']}</a></p>
          }
          {item['href'] === '' && 
            <p>{item['text']}</p>
          }
        </div>
        {props.items.length !== (index + 1) &&
          <div className={styles['breadcrumb-separator']}>
            <p>/</p>
          </div>  
        }
      </Fragment>
    );
    return breadcrumbItem;
  });

  return (
    <div className={styles['breadcrumbs-container']}>
      {breadcrumbItems}
    </div>
  )
}

export default Breadcrumbs;
