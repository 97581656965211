export const investorTypeChartColors = {
  'Asset Manager': '#00263D',
  'Hedge Fund': '#FF5959',
  'Pension Fund': '#66C1AD',
  'Private Bank / Prime Broker': '#336EBE',
  'Sovereign Wealth Fund': '#4BAC8C',
  'Proxy Advisor': '#FECDD3',
  'Governance Code': '#7084A3',
  'Insurer': '#FFC68A',
  'Strategic Investor': '#FF5959',
  'Unknown': '#DADEE8'
};

export const levelOfInsightChartColors = {
  'Strong': '#4BAC8C',
  'Standard': '#FFC68A',
  'Weak': '#FF5959',
  'Not Profiled': '#D1D5DB',
  'Unknown': '#DADEE8'
};

export const agmParticipationChartColors = {
  'Yes': '#00263D',
  'No': '#FF5959',
  'Case-by-Case': '#00A7CE',
  'Unknown': '#DADEE8'
};

export const esgFocusChartColors = {
  'High': '#4BAC8C',
  'Medium': '#FFC68A',
  'Low': '#FF5959',
  'Not Profiled': '#4BAC8C',
  'Unknown': '#DADEE8'
};

export const activistChartColors = {
  'Yes': '#FF5959',
  'No': '#00263D',
  'Not Determined': '#D1D5DB',
  'Unknown': '#DADEE8'
};

export const receptivityToActivistsChartColors = {
  'High': '#FF5959',
  'Medium': '#FFC68A',
  'Low': '#4BAC8C',
  'Not Determined': '#7084A3',
  'Not Profiled': '#D1D5DB',
  'Unknown': '#DADEE8'
};

export const votingDecisionMakerChartColors = {
  'Client Request': '#FFC68A',
  'Fund Manager': '#00263D',
  'Outsourced - Engagement Firm': '#FF9B9C',
  'Outsourced - Proxy Advisor': '#FF5959',
  'Stewardship Team': '#4BAC8C',
  'Stewardship Team with Fund Manager': '#66C1AD',
  'Not Voting': '#7084A3',
  'Outsourced – External Manager': '#FFC68A',
  'Unknown': '#DADEE8'
}

export const proxyAdvisorsRelianceChartColors = {
  'High': '#FF5959',
  'Medium': '#FFC68A',
  'Low': '#4BAC8C',
  'Low - Less Strict': '#66C1AD',
  'Low - Stricter': '#FF9B9C',
  'Not Applicable': '#7084A3',
  'Unknown': '#DADEE8'
}

export const dedicatedStewardshipTeamChartColors = {
  'Yes': '#00263D',
  'No': '#FF5959',
  'Not Determined': '#D1D5DB',
  'Unknown': '#DADEE8'
};

export const proxyAdvisorsChartColors = {
  'AFG': '#5D7E95',
  'ECGS': '#DFE5EA',
  'Glass Lewis': '#FFC68A',
  'ISS': '#00A7CE',
  'ISS-SRI': '#99DCEC',
  'IVIS': '#7D98AA',
  'PIRC': '#99A8B2',
  'Proxinvest': '#FFC68A',
  'Local Proxy Advisor': '#00263D',
  'Not Disclosed': '#7084A3',
  'No Advisor': '#000000',
  'Unknown': '#DADEE8'
};

export const esgMainServiceProvidersChartColors = {
  'MSCI': '#00263D',
  'Sustainalytics': '#66C1AD',
  'Moody\'s ESG Solutions': '#80D3E7',
  'ISS ESG': '#00A7CE',
  'Bloomberg ESG': '#FFC68A',
  'RepRisk': '#FF9B9C',
  'Trucost': '#FF5959',
  'None': '#000000',
  'Not Disclosed': '#7084A3',
  'Unknown': '#DADEE8'
};

















export const distributionMapCountries = [
  { "name": "Afghanistan","location": "AFG", "code": "af", "value": 0 },
  { "name": "Albania","location": "ALB", "code": "al", "value": 0 },
  { "name": "Algeria","location": "DZA", "code": "dz", "value": 0 },
  { "name": "American Samoa","location": "ASM", "code": "as", "value": 0 },
  { "name": "Andorra","location": "AND", "code": "ad", "value": 0 },
  { "name": "Angola","location": "AGO", "code": "ao", "value": 0 },
  { "name": "Anguilla","location": "AIA", "code": "ai", "value": 0 },
  { "name": "Antigua and Barbuda","location": "ATG", "code": "ag", "value": 0 },
  { "name": "Argentina","location": "ARG", "code": "ar", "value": 0 },
  { "name": "Armenia","location": "ARM", "code": "am", "value": 0 },
  { "name": "Aruba","location": "ABW", "code": "aw", "value": 0 },
  { "name": "Australia","location": "AUS", "code": "au", "value": 0 },
  { "name": "Austria","location": "AUT", "code": "at", "value": 0 },
  { "name": "Azerbaijan","location": "AZE", "code": "az", "value": 0 },
  { "name": "Bahamas","location": "BHM", "code": "bs", "value": 0 },
  { "name": "Bahrain","location": "BHR", "code": "bh", "value": 0 },
  { "name": "Bangladesh","location": "BGD", "code": "bd", "value": 0 },
  { "name": "Barbados","location": "BRB", "code": "bb", "value": 0 },
  { "name": "Belarus","location": "BLR", "code": "by", "value": 0 },
  { "name": "Belgium","location": "BEL", "code": "be", "value": 0 },
  { "name": "Belize","location": "BLZ", "code": "bz", "value": 0 },
  { "name": "Benin","location": "BEN", "code": "bj", "value": 0 },
  { "name": "Bermuda","location": "BMU", "code": "bm", "value": 0 },
  { "name": "Bhutan","location": "BTN", "code": "bt", "value": 0 },
  { "name": "Bolivia","location": "BOL", "code": "bo", "value": 0 },
  { "name": "Bosnia and Herzegovina","location": "BIH", "code": "ba", "value": 0 },
  { "name": "Botswana","location": "BWA", "code": "bw", "value": 0 },
  { "name": "Brazil","location": "BRA", "code": "br", "value": 0 },
  { "name": "British Virgin Islands","location": "VGB", "code": "vg", "value": 0 },
  { "name": "Brunei","location": "BRN", "code": "bn", "value": 0 },
  { "name": "Bulgaria","location": "BGR", "code": "bg", "value": 0 },
  { "name": "Burkina Faso","location": "BFA", "code": "bf", "value": 0 },
  { "name": "Burma","location": "MMR", "code": "mm", "value": 0 },
  { "name": "Burundi","location": "BDI", "code": "bi", "value": 0 },
  { "name": "Cabo Verde","location": "CPV", "code": "cv", "value": 0 },
  { "name": "Cambodia","location": "KHM", "code": "kh", "value": 0 },
  { "name": "Cameroon","location": "CMR", "code": "cm", "value": 0 },
  { "name": "Canada","location": "CAN", "code": "ca", "value": 0 },
  { "name": "Cayman Islands","location": "CYM", "code": "ky", "value": 0 },
  { "name": "Central African Republic","location": "CAF", "code": "cf", "value": 0 },
  { "name": "Chad","location": "TCD", "code": "td", "value": 0 },
  { "name": "Chile","location": "CHL", "code": "cl", "value": 0 },
  { "name": "China","location": "CHN", "code": "cn", "value": 0 },
  { "name": "Colombia","location": "COL", "code": "co", "value": 0 },
  { "name": "Comoros","location": "COM", "code": "km", "value": 0 },
  { "name": "Democratic Republic of the Congo","location": "COD", "code": "cd", "value": 0 },
  { "name": "Republic of the Congo","location": "COG", "code": "cg", "value": 0 },
  { "name": "Cook Islands","location": "COK", "code": "ck", "value": 0 },
  { "name": "Costa Rica","location": "CRI", "code": "cr", "value": 0 },
  { "name": "Cote d'Ivoire","location": "CIV", "code": "ci", "value": 0 },
  { "name": "Croatia","location": "HRV", "code": "hr", "value": 0 },
  { "name": "Cuba","location": "CUB", "code": "cu", "value": 0 },
  { "name": "Curacao","location": "CUW", "code": "cw", "value": 0 },
  { "name": "Cyprus","location": "CYP", "code": "cy", "value": 0 },
  { "name": "Czech Republic","location": "CZE", "code": "cz", "value": 0 },
  { "name": "Denmark","location": "DNK", "code": "dk", "value": 0 },
  { "name": "Djibouti","location": "DJI", "code": "dj", "value": 0 },
  { "name": "Dominica","location": "DMA", "code": "dm", "value": 0 },
  { "name": "Dominican Republic","location": "DOM", "code": "do", "value": 0 },
  { "name": "Ecuador","location": "ECU", "code": "ec", "value": 0 },
  { "name": "Egypt","location": "EGY", "code": "eg", "value": 0 },
  { "name": "El Salvador","location": "SLV", "code": "sv", "value": 0 },
  { "name": "Equatorial Guinea","location": "GNQ", "code": "gq", "value": 0 },
  { "name": "Eritrea","location": "ERI", "code": "er", "value": 0 },
  { "name": "Estonia","location": "EST", "code": "ee", "value": 0 },
  { "name": "Ethiopia","location": "ETH", "code": "et", "value": 0 },
  { "name": "Falkland Islands (Islas Malvinas)","location": "FLK", "code": "fk", "value": 0 },
  { "name": "Faroe Islands","location": "FRO", "code": "fo", "value": 0 },
  { "name": "Fiji","location": "FJI", "code": "fj", "value": 0 },
  { "name": "Finland","location": "FIN", "code": "fi", "value": 0 },
  { "name": "France","location": "FRA", "code": "fr", "value": 0 },
  { "name": "French Polynesia","location": "PYF", "code": "pf", "value": 0 },
  { "name": "Gabon","location": "GAB", "code": "ga", "value": 0 },
  { "name": "Gambia","location": "GMB", "code": "gm", "value": 0 },
  { "name": "Georgia","location": "GEO", "code": "ge", "value": 0 },
  { "name": "Germany","location": "DEU", "code": "de", "value": 0 },
  { "name": "Ghana","location": "GHA", "code": "gh", "value": 0 },
  { "name": "Gibraltar","location": "GIB", "code": "gi", "value": 0 },
  { "name": "Greece","location": "GRC", "code": "gr", "value": 0 },
  { "name": "Greenland","location": "GRL", "code": "gl", "value": 0 },
  { "name": "Grenada","location": "GRD", "code": "gd", "value": 0 },
  { "name": "Guam","location": "GUM", "code": "gu", "value": 0 },
  { "name": "Guatemala","location": "GTM", "code": "gt", "value": 0 },
  { "name": "Guernsey","location": "GGY", "code": "gg", "value": 0 },
  { "name": "Guinea-Bissau","location": "GNB", "code": "gw", "value": 0 },
  { "name": "Guinea","location": "GIN", "code": "gn", "value": 0 },
  { "name": "Guyana","location": "GUY", "code": "gy", "value": 0 },
  { "name": "Haiti","location": "HTI", "code": "ht", "value": 0 },
  { "name": "Honduras","location": "HND", "code": "hn", "value": 0 },
  { "name": "Hong Kong","location": "HKG", "code": "hk", "value": 0 },
  { "name": "Hungary","location": "HUN", "code": "hu", "value": 0 },
  { "name": "Iceland","location": "ISL", "code": "is", "value": 0 },
  { "name": "India","location": "IND", "code": "in", "value": 0 },
  { "name": "Indonesia","location": "IDN", "code": "id", "value": 0 },
  { "name": "Iran","location": "IRN", "code": "ir", "value": 0 },
  { "name": "Iraq","location": "IRQ", "code": "iq", "value": 0 },
  { "name": "Ireland","location": "IRL", "code": "ie", "value": 0 },
  { "name": "Isle of Man","location": "IMN", "code": "im", "value": 0 },
  { "name": "Israel","location": "ISR", "code": "il", "value": 0 },
  { "name": "Italy","location": "ITA", "code": "it", "value": 0 },
  { "name": "Jamaica","location": "JAM", "code": "jm", "value": 0 },
  { "name": "Japan","location": "JPN", "code": "jp", "value": 0 },
  { "name": "Jersey","location": "JEY", "code": "je", "value": 0 },
  { "name": "Jordan","location": "JOR", "code": "jo", "value": 0 },
  { "name": "Kazakhstan","location": "KAZ", "code": "kz", "value": 0 },
  { "name": "Kenya","location": "KEN", "code": "ke", "value": 0 },
  { "name": "Kiribati","location": "KIR", "code": "ki", "value": 0 },
  { "name": "North Korea","location": "PRK", "code": "kp", "value": 0 },
  { "name": "South Korea","location": "KOR", "code": "kr", "value": 0 },
  { "name": "Kosovo","location": "KSV", "code": "xk", "value": 0 },
  { "name": "Kuwait","location": "KWT", "code": "kw", "value": 0 },
  { "name": "Kyrgyzstan","location": "KGZ", "code": "kg", "value": 0 },
  { "name": "Laos","location": "LAO", "code": "la", "value": 0 },
  { "name": "Latvia","location": "LVA", "code": "lv", "value": 0 },
  { "name": "Lebanon","location": "LBN", "code": "lb", "value": 0 },
  { "name": "Lesotho","location": "LSO", "code": "ls", "value": 0 },
  { "name": "Liberia","location": "LBR", "code": "lr", "value": 0 },
  { "name": "Libya","location": "LBY", "code": "ly", "value": 0 },
  { "name": "Liechtenstein","location": "LIE", "code": "li", "value": 0 },
  { "name": "Lithuania","location": "LTU", "code": "lt", "value": 0 },
  { "name": "Luxembourg","location": "LUX", "code": "lu", "value": 0 },
  { "name": "Macau","location": "MAC", "code": "mo", "value": 0 },
  { "name": "Macedonia","location": "MKD", "code": "mk", "value": 0 },
  { "name": "Madagascar","location": "MDG", "code": "mg", "value": 0 },
  { "name": "Malawi","location": "MWI", "code": "mw", "value": 0 },
  { "name": "Malaysia","location": "MYS", "code": "my", "value": 0 },
  { "name": "Maldives","location": "MDV", "code": "mv", "value": 0 },
  { "name": "Mali","location": "MLI", "code": "ml", "value": 0 },
  { "name": "Malta","location": "MLT", "code": "mt", "value": 0 },
  { "name": "Marshall Islands","location": "MHL", "code": "mh", "value": 0 },
  { "name": "Mauritania","location": "MRT", "code": "mr", "value": 0 },
  { "name": "Mauritius","location": "MUS", "code": "mu", "value": 0 },
  { "name": "Mexico","location": "MEX", "code": "mx", "value": 0 },
  { "name": "Micronesia","location": "FSM", "code": "fm", "value": 0 },
  { "name": "Moldova","location": "MDA", "code": "md", "value": 0 },
  { "name": "Monaco","location": "MCO", "code": "mc", "value": 0 },
  { "name": "Mongolia","location": "MNG", "code": "mn", "value": 0 },
  { "name": "Montenegro","location": "MNE", "code": "me", "value": 0 },
  { "name": "Morocco","location": "MAR", "code": "ma", "value": 0 },
  { "name": "Mozambique","location": "MOZ", "code": "mz", "value": 0 },
  { "name": "Namibia","location": "NAM", "code": "na", "value": 0 },
  { "name": "Nepal","location": "NPL", "code": "np", "value": 0 },
  { "name": "Netherlands","location": "NLD", "code": "nl", "value": 0 },
  { "name": "New Caledonia","location": "NCL", "code": "nc", "value": 0 },
  { "name": "New Zealand","location": "NZL", "code": "nz", "value": 0 },
  { "name": "Nicaragua","location": "NIC", "code": "ni", "value": 0 },
  { "name": "Nigeria","location": "NGA", "code": "ng", "value": 0 },
  { "name": "Niger","location": "NER", "code": "ne", "value": 0 },
  { "name": "Niue","location": "NIU", "code": "nu", "value": 0 },
  { "name": "Northern Mariana Islands","location": "MNP", "code": "mp", "value": 0 },
  { "name": "Norway","location": "NOR", "code": "no", "value": 0 },
  { "name": "Oman","location": "OMN", "code": "om", "value": 0 },
  { "name": "Pakistan","location": "PAK", "code": "pk", "value": 0 },
  { "name": "Palau","location": "PLW", "code": "pw", "value": 0 },
  { "name": "Panama","location": "PAN", "code": "pa", "value": 0 },
  { "name": "Papua New Guinea","location": "PNG", "code": "pg", "value": 0 },
  { "name": "Paraguay","location": "PRY", "code": "py", "value": 0 },
  { "name": "Peru","location": "PER", "code": "pe", "value": 0 },
  { "name": "Philippines","location": "PHL", "code": "ph", "value": 0 },
  { "name": "Poland","location": "POL", "code": "pl", "value": 0 },
  { "name": "Portugal","location": "PRT", "code": "pt", "value": 0 },
  { "name": "Puerto Rico","location": "PRI", "code": "pr", "value": 0 },
  { "name": "Qatar","location": "QAT", "code": "qa", "value": 0 },
  { "name": "Romania","location": "ROU", "code": "ro", "value": 0 },
  { "name": "Russia","location": "RUS", "code": "ru", "value": 0 },
  { "name": "Rwanda","location": "RWA", "code": "rw", "value": 0 },
  { "name": "Saint Kitts and Nevis","location": "KNA", "code": "kn", "value": 0 },
  { "name": "Saint Lucia","location": "LCA", "code": "lc", "value": 0 },
  { "name": "Saint Martin","location": "MAF", "code": "mf", "value": 0 },
  { "name": "Saint Pierre and Miquelon","location": "SPM", "code": "pm", "value": 0 },
  { "name": "Saint Vincent and the Grenadines","location": "VCT", "code": "vc", "value": 0 },
  { "name": "Samoa","location": "WSM", "code": "ws", "value": 0 },
  { "name": "San Marino","location": "SMR", "code": "sm", "value": 0 },
  { "name": "Sao Tome and Principe","location": "STP", "code": "st", "value": 0 },
  { "name": "Saudi Arabia","location": "SAU", "code": "sa", "value": 0 },
  { "name": "Senegal","location": "SEN", "code": "sn", "value": 0 },
  { "name": "Serbia","location": "SRB", "code": "rs", "value": 0 },
  { "name": "Seychelles","location": "SYC", "code": "sc", "value": 0 },
  { "name": "Sierra Leone","location": "SLE", "code": "sl", "value": 0 },
  { "name": "Singapore","location": "SGP", "code": "sg", "value": 0 },
  { "name": "Sint Maarten","location": "SXM", "code": "sx", "value": 0 },
  { "name": "Slovakia","location": "SVK", "code": "sk", "value": 0 },
  { "name": "Slovenia","location": "SVN", "code": "si", "value": 0 },
  { "name": "Solomon Islands","location": "SLB", "code": "sb", "value": 0 },
  { "name": "Somalia","location": "SOM", "code": "so", "value": 0 },
  { "name": "South Africa","location": "ZAF", "code": "za", "value": 0 },
  { "name": "South Sudan","location": "SSD", "code": "ss", "value": 0 },
  { "name": "Spain","location": "ESP", "code": "es", "value": 0 },
  { "name": "Sri Lanka","location": "LKA", "code": "lk", "value": 0 },
  { "name": "Sudan","location": "SDN", "code": "sd", "value": 0 },
  { "name": "Suriname","location": "SUR", "code": "sr", "value": 0 },
  { "name": "Swaziland","location": "SWZ", "code": "sz", "value": 0 },
  { "name": "Sweden","location": "SWE", "code": "se", "value": 0 },
  { "name": "Switzerland","location": "CHE", "code": "ch", "value": 0 },
  { "name": "Syria","location": "SYR", "code": "sy", "value": 0 },
  { "name": "Taiwan","location": "TWN", "code": "tw", "value": 0 },
  { "name": "Tajikistan","location": "TJK", "code": "tj", "value": 0 },
  { "name": "Tanzania","location": "TZA", "code": "tz", "value": 0 },
  { "name": "Thailand","location": "THA", "code": "th", "value": 0 },
  { "name": "Timor-Leste","location": "TLS", "code": "tl", "value": 0 },
  { "name": "Togo","location": "TGO", "code": "tg", "value": 0 },
  { "name": "Tonga","location": "TON", "code": "to", "value": 0 },
  { "name": "Trinidad and Tobago","location": "TTO", "code": "tt", "value": 0 },
  { "name": "Tunisia","location": "TUN", "code": "tn", "value": 0 },
  { "name": "Turkey","location": "TUR", "code": "tr", "value": 0 },
  { "name": "Turkmenistan","location": "TKM", "code": "tm", "value": 0 },
  { "name": "Tuvalu","location": "TUV", "code": "tv", "value": 0 },
  { "name": "Uganda","location": "UGA", "code": "ug", "value": 0 },
  { "name": "Ukraine","location": "UKR", "code": "ua", "value": 0 },
  { "name": "United Arab Emirates","location": "ARE", "code": "ae", "value": 0 },
  { "name": "United Kingdom","location": "GBR", "code": "gb", "value": 0 },
  { "name": "United States","location": "USA", "code": "us", "value": 0 },
  { "name": "Uruguay","location": "URY", "code": "uy", "value": 0 },
  { "name": "Uzbekistan","location": "UZB", "code": "uz", "value": 0 },
  { "name": "Vanuatu","location": "VUT", "code": "vu", "value": 0 },
  { "name": "Venezuela","location": "VEN", "code": "ve", "value": 0 },
  { "name": "Vietnam","location": "VNM", "code": "vn", "value": 0 },
  { "name": "Virgin Islands","location": "VGB", "code": "vi", "value": 0 },
  { "name": "West Bank","location": "WBG", "code": "ps", "value": 0 },
  { "name": "Yemen","location": "YEM", "code": "ye", "value": 0 },
  { "name": "Zambia","location": "ZMB", "code": "zm", "value": 0 },
  { "name": "Zimbabwe","location": "ZWE", "code": "zw", "value": 0 }
];

export const distributionMapCountryIndices = {
  "Afghanistan": 0,
  "Albania": 1,
  "Algeria": 2,
  "American Samoa": 3,
  "Andorra": 4,
  "Angola": 5,
  "Anguilla": 6,
  "Antigua and Barbuda": 7,
  "Argentina": 8,
  "Armenia": 9,
  "Aruba": 10,
  "Australia": 11,
  "Austria": 12,
  "Azerbaijan": 13,
  "Bahamas": 14,
  "Bahrain": 15,
  "Bangladesh": 16,
  "Barbados": 17,
  "Belarus": 18,
  "Belgium": 19,
  "Belize": 20,
  "Benin": 21,
  "Bermuda": 22,
  "Bhutan": 23,
  "Bolivia": 24,
  "Bosnia and Herzegovina": 25,
  "Botswana": 26,
  "Brazil": 27,
  "British Virgin Islands": 28,
  "Brunei": 29,
  "Bulgaria": 30,
  "Burkina Faso": 31,
  "Burma": 32,
  "Burundi": 33,
  "Cabo Verde": 34,
  "Cambodia": 35,
  "Cameroon": 36,
  "Canada": 37,
  "Cayman Islands": 38,
  "Central African Republic": 39,
  "Chad": 40,
  "Chile": 41,
  "China": 42,
  "Colombia": 43,
  "Comoros": 44,
  "Democratic Republic of the Congo": 45,
  "Republic of the Congo": 46,
  "Cook Islands": 47,
  "Costa Rica": 48,
  "Cote d'Ivoire": 49,
  "Croatia": 50,
  "Cuba": 51,
  "Curacao": 52,
  "Cyprus": 53,
  "Czech Republic": 54,
  "Denmark": 55,
  "Djibouti": 56,
  "Dominica": 57,
  "Dominican Republic": 58,
  "Ecuador": 59,
  "Egypt": 60,
  "El Salvador": 61,
  "Equatorial Guinea": 62,
  "Eritrea": 63,
  "Estonia": 64,
  "Ethiopia": 65,
  "Falkland Islands (Islas Malvinas)": 66,
  "Faroe Islands": 67,
  "Fiji": 68,
  "Finland": 69,
  "France": 70,
  "French Polynesia": 71,
  "Gabon": 72,
  "Gambia": 73,
  "Georgia": 74,
  "Germany": 75,
  "Ghana": 76,
  "Gibraltar": 77,
  "Greece": 78,
  "Greenland": 79,
  "Grenada": 80,
  "Guam": 81,
  "Guatemala": 82,
  "Guernsey": 83,
  "Guinea-Bissau": 84,
  "Guinea": 85,
  "Guyana": 86,
  "Haiti": 87,
  "Honduras": 88,
  "Hong Kong": 89,
  "Hungary": 90,
  "Iceland": 91,
  "India": 92,
  "Indonesia": 93,
  "Iran": 94,
  "Iraq": 95,
  "Ireland": 96,
  "Isle of Man": 97,
  "Israel": 98,
  "Italy": 99,
  "Jamaica": 100,
  "Japan": 101,
  "Jersey": 102,
  "Jordan": 103,
  "Kazakhstan": 104,
  "Kenya": 105,
  "Kiribati": 106,
  "Northh Korea": 107,
  "South Korea": 108,
  "Kosovo": 109,
  "Kuwait": 110,
  "Kyrgyzstan": 111,
  "Laos": 112,
  "Latvia": 113,
  "Lebanon": 114,
  "Lesotho": 115,
  "Liberia": 116,
  "Libya": 117,
  "Liechtenstein": 118,
  "Lithuania": 119,
  "Luxembourg": 120,
  "Macau": 121,
  "Macedonia": 122,
  "Madagascar": 123,
  "Malawi": 124,
  "Malaysia": 125,
  "Maldives": 126,
  "Mali": 127,
  "Malta": 128,
  "Marshall Islands": 129,
  "Mauritania": 130,
  "Mauritius": 131,
  "Mexico": 132,
  "Micronesia": 133,
  "Moldova": 134,
  "Monaco": 135,
  "Mongolia": 136,
  "Montenegro": 137,
  "Morocco": 138,
  "Mozambique": 139,
  "Namibia": 140,
  "Nepal": 141,
  "Netherlands": 142,
  "New Caledonia": 143,
  "New Zealand": 144,
  "Nicaragua": 145,
  "Nigeria": 146,
  "Niger": 147,
  "Niue": 148,
  "Northern Mariana Islands": 149,
  "Norway": 150,
  "Oman": 151,
  "Pakistan": 152,
  "Palau": 153,
  "Panama": 154,
  "Papua New Guinea": 155,
  "Paraguay": 156,
  "Peru": 157,
  "Philippines": 158,
  "Poland": 159,
  "Portugal": 160,
  "Puerto Rico": 161,
  "Qatar": 162,
  "Romania": 163,
  "Russia": 164,
  "Rwanda": 165,
  "Saint Kitts and Nevis": 166,
  "Saint Lucia": 167,
  "Saint Martin": 168,
  "Saint Pierre and Miquelon": 169,
  "Saint Vincent and the Grenadines": 170,
  "Samoa": 171,
  "San Marino": 172,
  "Sao Tome and Principe": 173,
  "Saudi Arabia": 174,
  "Senegal": 175,
  "Serbia": 176,
  "Seychelles": 177,
  "Sierra Leone": 178,
  "Singapore": 179,
  "Sint Maarten": 180,
  "Slovakia": 181,
  "Slovenia": 182,
  "Solomon Islands": 183,
  "Somalia": 184,
  "South Africa": 185,
  "South Sudan": 186,
  "Spain": 187,
  "Sri Lanka": 188,
  "Sudan": 189,
  "Suriname": 190,
  "Swaziland": 191,
  "Sweden": 192,
  "Switzerland": 193,
  "Syria": 194,
  "Taiwan": 195,
  "Tajikistan": 196,
  "Tanzania": 197,
  "Thailand": 198,
  "Timor-Leste": 199,
  "Togo": 200,
  "Tonga": 201,
  "Trinidad and Tobago": 202,
  "Tunisia": 203,
  "Turkey": 204,
  "Turkmenistan": 205,
  "Tuvalu": 206,
  "Uganda": 207,
  "Ukraine": 208,
  "United Arab Emirates": 209,
  "United Kingdom": 210,
  "United States": 211,
  "Uruguay": 212,
  "Uzbekistan": 213,
  "Vanuatu": 214,
  "Venezuela": 215,
  "Vietnam": 216,
  "Virgin Islands": 217,
  "West Bank": 218,
  "Yemen": 219,
  "Zambia": 220,
  "Zimbabwe": 221
};