import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useResizeDetector } from 'react-resize-detector';
import { clientMenuItems } from '../../../utils/constants';
import { fetchClientBasicProfileRequest, fetchClientProxyAdvisorDetailsRequest } from '../../../requests/client-requests';
import { checkIsAccountManagerViewMode, getAuthClientId, getAuthClientName, getAuthUserProfilePictureUrl, getClientAvailableModules, getClientType, setLocalStorageClientData } from '../../../utils/auth';
import { getPageStyle, updatePageStyle } from "../../../utils/helpers";
import { handleRequestError } from '../../../utils/request-error-helpers';
import useHttp from '../../../hooks/use-http';

import Breadcrumbs from '../../../components/UI/Breadcrumbs/Breadcrumbs';
import Tab from '../../../components/UI/Tab/Tab';

import AccountManagerModeHeader from '../../../components/AccountManagerModeHeader/AccountManagerModeHeader';
import SideNavigationBar from '../../../components/UI/SideNavigationBar/SideNavigationBar';

import ProxyAdvisorSummary from './ProxyAdvisorSummary/ProxyAdvisorSummary';
import ProxyAdvisorContactList from './ProxyAdvisorContactList/ProxyAdvisorContactList';
import ProxyAdvisorEngagements from './ProxyAdvisorEngagements/ProxyAdvisorEngagements';
import ProxyAdvisorEsgTopics from './ProxyAdvisorEsgTopics/ProxyAdvisorEsgTopics';

import styles from './ProxyAdvisorDetailsPage.module.css';

const ProxyAdvisorDetailsPage = (props) => {

  const clientId = getAuthClientId();
  const clientAvailableModules = getClientAvailableModules();
  const userProfilePictureUrl = getAuthUserProfilePictureUrl();

  const { proxyAdvisorId } = useParams();

  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  const pageHeightMargin = checkIsAccountManagerViewMode() ? '161px' : '95px';
  /* eslint-disable no-unused-vars */

  const [isLoading, error, sendRequest] = useHttp();

  const [clientType, setClientType] = useState(getClientType());
  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);
  const [tabItems, setTabItems] = useState([]);

  const proxyAdvisorTabItems = useMemo(() => [
    {
      'id': 'tab-details',
      'text': 'Details'
    },
    {
      'id': 'tab-esg-topics',
      'text': 'ESG Topics'
    },
    {
      'id': 'tab-engagements',
      'text': 'Engagements'
    },
    {
      'id': 'tab-contact-list',
      'text': 'Contact List'
    }
  ], []);

  const [selectedTab, setSelectedTab] = useState('tab-details');
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const proxyAdvisorDetailsTabChangeHandler = (selectedTabId) => {
    setSelectedTab(selectedTabId)

    let tabTitle = '';
    if(selectedTabId === 'tab-details') {
      tabTitle = 'Proxy Advisor Details';
    }
    else if(selectedTabId === 'tab-engagements') {
      tabTitle = 'Engagements';
    }
    else if(selectedTabId === 'tab-contact-list') {
      tabTitle = 'Contact List';
    }
    else if(selectedTabId === 'tab-esg-topics') {
      tabTitle = 'ESG Topics';
    }
    setBreadcrumbItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      items[2]['text'] = tabTitle;
      return items;
    });
  }

  const [proxyAdvisorData, setProxyAdvisorData] = useState(null);

  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (dataClient) => {
        setLocalStorageClientData(dataClient);
        setClientType(dataClient['client_type']);
        setClientProfilePictureUrl(dataClient['profile_picture_url']);

        sendRequest(
          fetchClientProxyAdvisorDetailsRequest(clientId, proxyAdvisorId),
          (dataProxyAdvisorDetails) => {
            setProxyAdvisorData(dataProxyAdvisorDetails);
            setBreadcrumbItems([{
              'text': 'Proxy Advisors',
              'href': '../proxy-advisors'
            },
            {
              'text': dataProxyAdvisorDetails['name'],
              'href': `../proxy-advisors/${proxyAdvisorId}`
            },
            {
              'text': 'Proxy Advisor Details',
              'href': ''
            }]);

            const filteredTabItems = [];
            if(!dataClient['module_management']['esg_topics']) {
              filteredTabItems.push('tab-esg-topics');
            }
            if(!dataClient['module_management']['engagements']) {
              filteredTabItems.push('tab-engagements');
            }
            setTabItems(proxyAdvisorTabItems.filter(item => !filteredTabItems.includes(item['id'])));
          }
        );
      }
    );
  }, [clientId, proxyAdvisorId, proxyAdvisorTabItems, sendRequest]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  return (
    <div className={styles['page']}>
      {checkIsAccountManagerViewMode() &&
        <AccountManagerModeHeader
          type={pageStyle === 'wide' ? 'wide' : 'narrow'}
          clientName={getAuthClientName()}
          exitRedirecUrl='../clients'
        />
      }

      <SideNavigationBar
        menuItems={clientMenuItems.filter(item => clientAvailableModules[item['module_id']])}
        profilePictureUrl={userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}
        selected='proxy-advisors'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      {proxyAdvisorData !== null && 
        <div
          id='page-right-container'
          className={`${styles['container']} ${styles[checkIsAccountManagerViewMode() ? 'with-account-manager-warning' : '']} ${styles[pageStyle]}`}
          style={{ height: `calc(100% - ${pageHeightMargin})`, overflowY: 'auto', overflowX: 'hidden' }}
          ref={ref}
        >
          <Breadcrumbs items={breadcrumbItems} />

          <div className={styles['page-header']}>
            <div className={styles['section']}>
              <div className={styles['row']}>
                <div className={styles['title']}>
                  <h1>Proxy Advisors</h1>
                </div>
              </div>
            </div>
          </div>

          <div className={styles['page-body']}>
            <div className={styles['proxy-advisor-details-container']}>
              <div className={styles['proxy-advisor-details-header']}>
              { proxyAdvisorData['profile_picture_url'] !== '' && 
                  <div
                    className={styles['logo']}
                    style={{
                      'background': `url("${proxyAdvisorData['profile_picture_url']}") center center no-repeat`,
                      'backgroundSize': 'cover'
                    }}></div>
                }
                { proxyAdvisorData['profile_picture_url'] === '' && 
                  <div className={styles['logo']}></div>
                }
                <div className={styles['title-container']}>
                  <div className={styles['title']}>
                    <h1>{proxyAdvisorData['name']}</h1>
                  </div>
                </div>
                <div className={styles['tab-container']}>
                  <Tab items={tabItems} selectedTab={selectedTab} onTabChange={proxyAdvisorDetailsTabChangeHandler} />
                </div>
              </div>
              
              <div className={styles['proxy-advisor-details-body']}>
                { selectedTab === 'tab-details' && 
                  <ProxyAdvisorSummary
                    proxyAdvisorId={proxyAdvisorId}
                  />
                }
                { selectedTab === 'tab-esg-topics' && 
                  <ProxyAdvisorEsgTopics
                    width={width}
                    proxyAdvisorId={proxyAdvisorId}
                    proxyAdvisorName={proxyAdvisorData['name']}
                    proxyAdvisorProfilePicture={proxyAdvisorData['profile_picture_url']}
                  />
                }
                { selectedTab === 'tab-engagements' &&
                  <ProxyAdvisorEngagements
                    width={width}
                    proxyAdvisorId={proxyAdvisorId}
                  />
                }
                { selectedTab === 'tab-contact-list' && 
                  <ProxyAdvisorContactList
                    width={width}
                    proxyAdvisorId={proxyAdvisorId}
                  />
                }
              </div>
            </div>
          </div>
          
        </div>
      }
    </div>
  );
}

export default ProxyAdvisorDetailsPage;
