import { Fragment, useEffect, useState } from 'react';
import { fetchClientProxyAdvisorDetailsRequest, updateClientProxyAdvisorRequest } from '../../../../requests/client-requests';
import { getAuthClientId, getClientAvailableModules, getClientType } from '../../../../utils/auth';
import { handleRequestError } from '../../../../utils/request-error-helpers';
import useHttp from '../../../../hooks/use-http';

import ProxyAdvisorSummaryGeneralInfoSection from './ProxyAdvisorSummaryGeneralInfoSection/ProxyAdvisorSummaryGeneralInfoSection';
import ProxyAdvisorSummaryPastGeneralMeetingRecommendationsSection from './ProxyAdvisorSummaryPastGeneralMeetingRecommendationsSection/ProxyAdvisorSummaryPastGeneralMeetingRecommendationsSection';

import styles from './ProxyAdvisorSummary.module.css';

const ProxyAdvisorSummary = (props) => {

  const clientId = getAuthClientId();
  const clientType = getClientType();
  const clientAvailableModules = getClientAvailableModules();
  const proxyAdvisorId = props.proxyAdvisorId;

  const [isLoading, error, sendRequest] = useHttp();

  const [proxyAdvisorData, setProxyAdvisorData] = useState(null);
  const [generalInformationData, setGeneralInformationData] = useState(null);
  const [pastGeneralMeetingRecommendationsData, setPastGeneralMeetingRecommendationsData] = useState(null);


  useEffect(() => {
    sendRequest(
      fetchClientProxyAdvisorDetailsRequest(clientId, proxyAdvisorId),
      (data) => {
        setProxyAdvisorData(data);
        setGeneralInformationData(data['general_information']);
        setPastGeneralMeetingRecommendationsData(data['past_general_meeting_recommendations']);
      }
    );
  }, [clientId, proxyAdvisorId, sendRequest]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }
    
  useEffect(() => {
    if (proxyAdvisorData !== null) {
      setGeneralInformationData(proxyAdvisorData['general_information']);
      setPastGeneralMeetingRecommendationsData(proxyAdvisorData['past_general_meeting_recommendations']);
    }
  }, [proxyAdvisorData]);


  /* Save updated past general meeting recommendations for the proxy advisor. */
  const pastGeneralMeetingRecommendationsSaveHandler = (updatedPastGeneralMeetingRecommendationsData) => {
    const body = {
      'past_general_meeting_recommendations': updatedPastGeneralMeetingRecommendationsData,
    };
    sendRequest(
      updateClientProxyAdvisorRequest(clientId, proxyAdvisorId, body),
      (data) => {
        setPastGeneralMeetingRecommendationsData(data['past_general_meeting_recommendations']);
      }
    );
  }


  const getEngagementUrl = (engagement) => {
    if (engagement['type'] === 'Meeting Note') {
      return `../engagements?meeting_note_id=${engagement['id']}&type=proxy-advisor`;
    }
    else if (engagement['type'] === 'Other Note') {
      return `../engagements?other_note_id=${engagement['id']}&type=proxy-advisor`;
    }
    else if (engagement['type'] === 'Meeting Request') {
      return `../engagements?meeting_request_id=${engagement['id']}`;
    }
    else if (engagement['type'] === 'Shareholder Communication') {
      return `../engagements?shareholder_communication_id=${engagement['id']}`;
    }
  }


  return (
    <Fragment>
      {proxyAdvisorData !== null && clientAvailableModules['engagements'] &&
        <div className={styles['extra-information-area']}>
          <div className={styles['engagements-container']}>
          <div className={styles['engagement-description']}>
              <p>You have <strong>{proxyAdvisorData['engagements']['count']}</strong> Engagements so far with {proxyAdvisorData['name']}.
                {proxyAdvisorData['engagements']['last_engagements'].length > 0 && 
                  <span> Your last Engagement interaction is;</span>
                }
                {proxyAdvisorData['engagements']['last_engagements'].length <= 0 && 
                  <span> You can create a new engagement from <a href="../engagements">here</a>.</span>
                }
              </p>
            </div>
            {proxyAdvisorData['engagements']['last_engagements'][0] && 
              <div className={styles['engagement-item']}>
                <div className={styles['engagement-icon']}></div>
                <div className={styles['engagement-content']}>
                  <div className={styles['label']}>
                    <h4>{proxyAdvisorData['engagements']['last_engagements'][0]['type']}</h4>
                  </div>
                  <div className={styles['title']}>
                    <p>
                      <a href={getEngagementUrl(proxyAdvisorData['engagements']['last_engagements'][0])}>
                        {proxyAdvisorData['engagements']['last_engagements'][0]['title']}
                      </a>
                    </p>
                  </div>
                  <div className={styles['date']}>
                    <p>on {new Date(proxyAdvisorData['engagements']['last_engagements'][0]['updated_at']).toLocaleDateString('en-GB')}</p>
                  </div>
                </div>
              </div>
            }
            {proxyAdvisorData['engagements']['last_engagements'][1] && 
              <div className={styles['engagement-item']}>
                <div className={styles['engagement-icon']}></div>
                <div className={styles['engagement-content']}>
                  <div className={styles['label']}>
                    <h4>{proxyAdvisorData['engagements']['last_engagements'][1]['type']}</h4>
                  </div>
                  <div className={styles['title']}>
                    <p>
                      <a href={getEngagementUrl(proxyAdvisorData['engagements']['last_engagements'][1])}>
                        {proxyAdvisorData['engagements']['last_engagements'][1]['title']}
                      </a>
                    </p>
                  </div>
                  <div className={styles['date']}>
                    <p>on {new Date(proxyAdvisorData['engagements']['last_engagements'][1]['updated_at']).toLocaleDateString('en-GB')}</p>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }


      {proxyAdvisorData !== null &&
        <div className={styles['proxy-advisor-information-area']}>  
          <ProxyAdvisorSummaryGeneralInfoSection
            generalInformationData={generalInformationData}
          />
          {clientType === 'Corporate' && 
            <ProxyAdvisorSummaryPastGeneralMeetingRecommendationsSection
              pastGeneralMeetingRecommendationsData={pastGeneralMeetingRecommendationsData}
              onSaveClick={pastGeneralMeetingRecommendationsSaveHandler}
            />
          }
        </div>
      }
    </Fragment>
  );
}

export default ProxyAdvisorSummary;
