import { useEffect, useState } from 'react';
import { updateUserPasswordRequest } from '../../../../requests/user-requests';
import useHttp from '../../../../hooks/use-http';
import useInputValidation from '../../../../hooks/use-input-validation';

import Button from '../../../../components/UI/Button/Button';
import Modal from '../../../../components/UI/Modal/Modal';
import PasswordInput from '../../../../components/UI/PasswordInput/PasswordInput';

import styles from './PasswordSettingSection.module.css';

const PasswordSettingSection = (props) => {
  const [isLoading, error, sendRequest] = useHttp();

  const [showPasswordChangeForm, setShowPasswordChangeForm] = useState(false);
  const [isSavePasswordButtonDisabled, setIsSavePasswordButtonDisabled] = useState(true);

  const [currentPassword, setCurrentPassword, isCurrentPasswordValid, hasCurrentPasswordError, currentPasswordChangeHandler, currentPasswordBlurHandler] = 
    useInputValidation('', (currentPassword) => currentPassword.trim() !== '');

  const [newPassword, setNewPassword, isNewPasswordValid, hasNewPasswordError, newPasswordChangeHandler, newPasswordBlurHandler] = 
    useInputValidation('', (newPassword) => newPassword.trim() !== '');

  const [reNewPassword, setReNewPassword, isReNewPasswordValid, hasReNewPasswordError, reNewPasswordChangeHandler, reNewPasswordBlurHandler] = 
    useInputValidation('', (reNewPassword) => reNewPassword.trim() !== '');

  const [formError, setFormError] = useState({
    hasError: false,
    title: '',
    text: ''
  });

  const [showPasswordUpdatedInfo, setShowPasswordUpdatedInfo] = useState(false);

  const checkLength = (password, length) => {
    return (password.length >= length);
  }

  const containsUpperCaseLetter = (password) => {
    return /[A-Z]/.test(password);
  }

  const containsLowerCaseLetter = (password) => {
    return /[a-z]/.test(password);
  }

  const containsNumber = (password) => {
    return /\d/.test(password);
  }

  const containsSpecialCharacter = (password) => {
    const specialCharacters = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    return specialCharacters.test(password);
  }

  const validateNewPassword = (password) => {
    const passwordConditionLength = document.getElementById('passwordConditionLength');
    const passwordConditionUppercaseChar = document.getElementById('passwordConditionUppercaseChar');
    const passwordConditionLowercaseChar = document.getElementById('passwordConditionLowercaseChar');
    const passwordConditionNumber = document.getElementById('passwordConditionNumber');
    const passwordConditionSpecialChar = document.getElementById('passwordConditionSpecialChar');

    if(passwordConditionLength) {
      if(!checkLength(password, 12)) {
        passwordConditionLength.classList.remove(styles['success']);
        passwordConditionLength.classList.add(styles['error']);
      }
      else {
        passwordConditionLength.classList.remove(styles['error']);
        passwordConditionLength.classList.add(styles['success']);
      }
    }

    if(passwordConditionUppercaseChar) {
      if(!containsUpperCaseLetter(password)) {
        passwordConditionUppercaseChar.classList.remove(styles['success']);
        passwordConditionUppercaseChar.classList.add(styles['error']);
      }
      else {
        passwordConditionUppercaseChar.classList.remove(styles['error']);
        passwordConditionUppercaseChar.classList.add(styles['success']);
      }
    }

    if(passwordConditionLowercaseChar) {
      if(!containsLowerCaseLetter(password)) {
        passwordConditionLowercaseChar.classList.remove(styles['success']);
        passwordConditionLowercaseChar.classList.add(styles['error']);
      }
      else {
        passwordConditionLowercaseChar.classList.remove(styles['error']);
        passwordConditionLowercaseChar.classList.add(styles['success']);
      }
    }

    if(passwordConditionNumber) {
      if(!containsNumber(password)) {
        passwordConditionNumber.classList.remove(styles['success']);
        passwordConditionNumber.classList.add(styles['error']);
      }
      else {
        passwordConditionNumber.classList.remove(styles['error']);
        passwordConditionNumber.classList.add(styles['success']);
      }
    }

    if(passwordConditionSpecialChar) {
      if(!containsSpecialCharacter(password)) {
        passwordConditionSpecialChar.classList.remove(styles['success']);
        passwordConditionSpecialChar.classList.add(styles['error']);
      }
      else {
        passwordConditionSpecialChar.classList.remove(styles['error']);
        passwordConditionSpecialChar.classList.add(styles['success']);
      }
    }

    return (
      password.trim() !== '' && checkLength(password, 12) && 
      containsUpperCaseLetter(password) && containsLowerCaseLetter(password) && 
      containsNumber(password) && containsSpecialCharacter(password)
    );
  }

  const validateAndNewPasswordChangeHandler = (enteredNewPassword) => {
    newPasswordChangeHandler(enteredNewPassword);
    validateNewPassword(enteredNewPassword);
    if(isCurrentPasswordValid && enteredNewPassword.trim() !== '' && isReNewPasswordValid && 
        validatePasswordChangeForm(enteredNewPassword, reNewPassword)
    ) {
      setIsSavePasswordButtonDisabled(false);
    }
    else {
      setIsSavePasswordButtonDisabled(true);
    }
  }

  const validateReNewPassword = (password, rePassword) => {
    return (password === rePassword);
  }

  const validateAndReNewPasswordChangeHandler = (enteredReNewPassword) => {
    reNewPasswordChangeHandler(enteredReNewPassword);
    validateReNewPassword(newPassword, enteredReNewPassword);
    if(isCurrentPasswordValid && isNewPasswordValid && enteredReNewPassword.trim() !== '' && 
        validatePasswordChangeForm(newPassword, enteredReNewPassword)
    ) {
      setIsSavePasswordButtonDisabled(false);
    }
    else {
      setIsSavePasswordButtonDisabled(true);
    }
  }

  const validateAndCurrentPasswordChangeHandler = (enteredCurrentPassword) => {
    currentPasswordChangeHandler(enteredCurrentPassword);
    if(enteredCurrentPassword.trim() !== '' && isNewPasswordValid && isReNewPasswordValid && 
        validatePasswordChangeForm(newPassword, reNewPassword)
    ) {
      setIsSavePasswordButtonDisabled(false);
    }
    else {
      setIsSavePasswordButtonDisabled(true);
    }
  }

  useEffect(() => {
    setCurrentPassword('');
    setNewPassword('');
    setReNewPassword('');
  }, [setCurrentPassword, setNewPassword, setReNewPassword]);

  const validatePasswordChangeForm = (password, rePassword) => {
    const isFormValid = validateNewPassword(password) && validateReNewPassword(password, rePassword);
    return isFormValid;
  }

  const changePasswordClickHandler = () => {
    props.onChangePasswordClick();
    setShowPasswordChangeForm(true);
  }

  const cancelChangePasswordClickHandler = () => {
    props.onChangePasswordClick();
    setShowPasswordChangeForm(false);
  }

  const updatePasswordClickHandler = () => {
    const passwordUpdateData = {
      'current_password': currentPassword,
      'new_password': newPassword
    };

    sendRequest(
      updateUserPasswordRequest(passwordUpdateData),
      (data) => {
        setShowPasswordUpdatedInfo(true);
      }
    );
  }
  
  useEffect(() => {
    if(!isLoading && error !== undefined) {
      if(error.status === 401) {
        setFormError({
          hasError: true,
          title: 'Wrong Password',
          text: 'Please check entered current password.'
        });  
      }
    }  
  }, [isLoading, error])

  const paswordUpdatedInfoModalCloseClickHandler = () => {
    setShowPasswordUpdatedInfo(false);
    cancelChangePasswordClickHandler();
    setCurrentPassword('');
    setNewPassword('');
    setReNewPassword('');
  }

  return (
    <div className={styles['change-password-container']}>
      {showPasswordUpdatedInfo && 
        <Modal
          type='success'
          title='Password Updated'
          message={<span>Password for your account has been updated successfully.</span>}
          onCloseClick={() => paswordUpdatedInfoModalCloseClickHandler()}
          approveButton = {{
            'text': 'Close',
            'onClick': () => paswordUpdatedInfoModalCloseClickHandler()
          }}
        />
      }

      {!showPasswordChangeForm &&
        <div className={styles['change-password-content']}>
          <div className={styles['title-container']}>
            <h3>Password Setting</h3>
          </div>
          <div className={styles['button-container']}>
            <Button size='small' type='secondary' onClick={changePasswordClickHandler}>Change Password</Button>
          </div>
        </div>
      }

      {showPasswordChangeForm &&
        <div className={styles['change-password-form']}>
          <div className={styles['row']}>
            <PasswordInput
              id='password-change#current-password'
              width='100%'
              label='Current Password'
              placeholder='Enter Current Password'
              value={currentPassword}
              hasError={hasCurrentPasswordError}
              onBlur={currentPasswordBlurHandler}
              onChange={validateAndCurrentPasswordChangeHandler}
            />
          </div>
          <div className={styles['row']}>
            <PasswordInput
              id='password-change#new-password'
              width='100%'
              label='New Password'
              placeholder='Enter New Password'
              value={newPassword}
              hasError={hasNewPasswordError}
              onBlur={newPasswordBlurHandler}
              onChange={validateAndNewPasswordChangeHandler}
            />
            <div className={styles['new-password-conditions']}>
              <div className={styles['conditions-note']}>
                <p>Your password must include:</p>
              </div>
              <ul className={styles['conditions-list']}>
                <li id="passwordConditionLength">12 or more characters</li>
                <li id="passwordConditionUppercaseChar">Uppercase letter</li>
                <li id="passwordConditionLowercaseChar">Lowercase letter</li>
                <li id="passwordConditionNumber">Number</li>
                <li id="passwordConditionSpecialChar">Special character</li>
              </ul>
            </div>
          </div>
          <div className={styles['row']}>
            <PasswordInput
              id='password-change#confirm-new-password'
              width='100%'
              label='Confirm New Password'
              placeholder='Re-Enter New Password'
              value={reNewPassword}
              hasError={hasReNewPasswordError}
              onBlur={reNewPasswordBlurHandler}
              onChange={validateAndReNewPasswordChangeHandler}
            />
          </div>
          {formError['hasError'] && 
            <div className={styles['row']}>
              <div className={styles['form-error']}>
                <div className={styles['error-title']}>
                  <div className={styles['warning-icon']}></div>
                  <h4>{formError['title']}</h4>
                </div>
                <div className={styles['error-content']}>
                  <p>{formError['text']}</p>
                </div>
              </div>
            </div>
          }
          <div className={styles['row']}>
            <Button
              className={styles['form-button']}
              type={'primary'}
              size={'base'}
              onClick={updatePasswordClickHandler}
              isDisabled={isSavePasswordButtonDisabled}
            >
              Save New Password
            </Button>
            <Button
              className={styles['form-button']}
              type={'cancel'}
              size={'base'}
              onClick={cancelChangePasswordClickHandler}
            >
              Cancel
            </Button>
          </div>
        </div>
      }
    </div>
  );
}

export default PasswordSettingSection;
