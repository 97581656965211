import { useCallback, useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { clientMenuItems } from '../../../utils/constants';
import { checkIsAccountManagerViewMode, getAuthClientId, getAuthClientName, getAuthUserProfilePictureUrl, getClientAvailableModules, getClientType, setLocalStorageClientData } from '../../../utils/auth';
import { fetchClientBasicProfileRequest, fetchClientInvestorsRequest, updateClientInvestorRequest } from '../../../requests/client-requests';
import { generateClientInvestorListTableItems } from '../../../utils/client-investor-helpers';
import { generateClientInvestorListSkeletonTableItems } from '../../../utils/skeleton-helpers';
import { utils, writeFileXLSX } from 'xlsx';
import { createId, getPageStyle, updatePageStyle } from "../../../utils/helpers";
import { handleRequestError } from '../../../utils/request-error-helpers';
import sortArray from 'sort-array';
import useHttp from '../../../hooks/use-http';

import AccountManagerModeHeader from '../../../components/AccountManagerModeHeader/AccountManagerModeHeader';
import SideNavigationBar from '../../../components/UI/SideNavigationBar/SideNavigationBar';

import Button from '../../../components/UI/Button/Button';
import SearchBox from '../../../components/UI/SearchBox/SearchBox';
import Table from '../../../components/UI/Table/Table';

import styles from './InvestorListPage.module.css';

const InvestorListPage = (props) => {

  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  const pageHeightMargin = checkIsAccountManagerViewMode() ? '161px' : '95px';
  /* eslint-disable no-unused-vars */

  const [isLoading, error, sendRequest] = useHttp();
  const [searchValue, setSearchValue] = useState('');
  const [pageViewMode, setPageViewMode] = useState('skeleton');
  
  const clientId = getAuthClientId();
  const clientAvailableModules = getClientAvailableModules();
  const userProfilePictureUrl = getAuthUserProfilePictureUrl();
  
  const [clientType, setClientType] = useState(getClientType());
  const [clientTotalNumOfShares, setClientTotalNumOfShares] = useState(null);
  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);
  const [investorListData, setInvestorListData] = useState([]);
  const [searchInvestorItems, setSearchInvestorItems] = useState([]);
  const [tableItems, setTableItems] = useState(null);
  
  const [tableSortConfig, setTableSortConfig] = useState({
    'by': ['num_of_shares', 'name'],
    'order': ['desc', 'asc'],
    'computed': {
      'country': investor => investor['general_information']['country']
    }
  })

  const selectInvestorSearchResultHandler = (selectedItemId) => {
    window.location.href = `./investors/${selectedItemId}`;
  }


  const tableExportHandler = () => {
    const data = [];
    if (investorListData !== null) {
      investorListData.forEach(item => {
        data.push(
          Object.assign({},
            {
              'INVESTOR NAME': item['name'],
              'COUNTRY': item['general_information']['country']
            },
            (clientType === 'Corporate') ? {
              '# OF SHARES': item['num_of_shares'],
              'ISC %': item['num_of_shares'] !== '' ? ((item['num_of_shares'] / clientTotalNumOfShares) * 100).toFixed(2) : '-',
              'LAST GENERAL MEETING VOTES': item['past_general_meeting_votes']['last_general_meeting_votes']
            } : {},
            {
              'INVESTOR TYPE': item['general_information']['investor_type'],
              'LEVEL OF INSIGHT': item['general_information']['level_of_insight'],
              'VOTING DECISION MAKER': item['stewardship']['voting_decision_maker'],
              'PROXY ADVISORS': item['stewardship']['proxy_advisors'],
              'PROXY ADVISORS RELIANCE': item['stewardship']['proxy_advisors_reliance'],
              'ESG FOCUS': item['environment_and_social']['esg_focus'],
              'ESG SERVICE PROVIDERS': item['esg_service_providers']['main_providers'],
              'ACTIVIST': item['activism']['activist'],
              'RECEPTIVITY TO ACTIVISTS': item['activism']['receptivity_to_activists']
            }
          )
        );
      });
    }

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Investor List");
    writeFileXLSX(wb, "InvestorListExport.xlsx");
  }

  const investorListSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);

      return newConfig;
    });
  }

  const pinClickHandler = useCallback((clickedItemId) => {
    const clientInvestorId = parseInt(clickedItemId.split('client-investor-')[1].split('#')[0]);
    setInvestorListData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const clickedItem = items.filter(item => item['id'] === clientInvestorId)[0];
      if (clickedItem !== undefined) {
        const body = {'is_starred': !clickedItem['is_starred']};
        sendRequest(
          updateClientInvestorRequest(clientId, clientInvestorId, body),
          (_) => { }
        );
        clickedItem['is_starred'] = !clickedItem['is_starred'];
      }
      return items;
    });
  }, [sendRequest, clientId]);

  useEffect(() => {
    setInvestorListData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, tableSortConfig);
    });
  }, [tableSortConfig]);


  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (dataClient) => {
        setLocalStorageClientData(dataClient);
        setClientType(dataClient['client_type']);
        setClientTotalNumOfShares(dataClient['total_num_of_shares']); 
        setClientProfilePictureUrl(dataClient['profile_picture_url']);

        sendRequest(
          fetchClientInvestorsRequest(clientId),
          (dataInvestors) => {
            const sortedInvestors = sortArray(dataInvestors['items'], tableSortConfig);
            setInvestorListData(sortedInvestors);
            setTimeout(() => {
              setPageViewMode('normal');
            }, 200);
          }
        );
      }
    );
    
    
  }, [sendRequest, clientId, tableSortConfig]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  useEffect(() => {
    setTableItems(generateClientInvestorListTableItems(clientType, investorListData, clientTotalNumOfShares, tableSortConfig, pinClickHandler, investorListSortHandler));
    const newSearchInvestorItems = investorListData.map(item => {
      return {
        'id': item['id'],
        'text': item['name']
      }
    });
    setSearchInvestorItems(newSearchInvestorItems);
  }, [clientType, investorListData, clientTotalNumOfShares, tableSortConfig, pinClickHandler]);


  return (
    <div className={styles['page']}>
      {checkIsAccountManagerViewMode() &&
        <AccountManagerModeHeader
          type={pageStyle === 'wide' ? 'wide' : 'narrow'}
          clientName={getAuthClientName()}
          exitRedirecUrl='../clients'
        />
      }

      <SideNavigationBar
        menuItems={clientMenuItems.filter(item => clientAvailableModules[item['module_id']])}
        profilePictureUrl={userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}
        selected='investors'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div 
        id='page-right-container'
        className={`${styles['container']} ${styles[checkIsAccountManagerViewMode() ? 'with-account-manager-warning' : '']} ${styles[pageStyle]}`}
        style={{ height: `calc(100% - ${pageHeightMargin})`, overflowY: 'auto', overflowX: 'hidden' }}
        ref={ref}
      >
        <div className={styles['page-header']}>
          <div className={styles['section']}>
            <div className={styles['row']}>
              <div className={styles['title']}>
                <h1>Investors</h1>
              </div>
              <div className={styles['search-box']}>
                <SearchBox
                  items={searchInvestorItems}
                  value={searchValue}
                  placeholder="Search Investors"
                  onChange={setSearchValue}
                  onItemSelect={selectInvestorSearchResultHandler}
                  size={"xsmall"}
                />
              </div>
            </div>
          </div>
        </div>




        <div className={styles['page-body']} >
          <div className={styles['investor-list-buttons']}>  
            <div className={styles['right-buttons']}>
              <Button type={'primary'} size={'base'} leftIcon={'download'} onClick={tableExportHandler}>Download as Excel</Button>

              {false &&
                <Button type={"cancel"} size={"base"} onClick={() => console.log()}>Deactivated Investors</Button>
              }
            </div>
          </div>
          <div className={styles['investor-list-container']}>
              <Table 
                width={isNaN(width) ? 1400 : (width - 2)}
                items={
                  pageViewMode === 'skeleton' ?
                  generateClientInvestorListSkeletonTableItems(clientType, Array.from({length: 5}, () => createId(4))) :
                  tableItems
                }
                isScrollable={true}
                hasDynamicScroll={true}
                viewMode={pageViewMode}
              />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvestorListPage;
