import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { resetUserPasswordRequest } from '../../../requests/auth-requests';
import useInputValidation from '../../../hooks/use-input-validation';
import useHttp from '../../../hooks/use-http';

import Button from '../../../components/UI/Button/Button';
import PasswordInput from '../../../components/UI/PasswordInput/PasswordInput';

import styles from './CreateCredentialsPage.module.css';

const CreateCredentialsPage = (props) => {

  const [isLoading, error, sendRequest] = useHttp();

  /* eslint-disable no-unused-vars */
  const [searchParams, setSearchParams] = useSearchParams();
  /* eslint-disable no-unused-vars */

  const [newPassword, setNewPassword, isNewPasswordValid, hasNewPasswordError, newPasswordChangeHandler, newPasswordBlurHandler] = 
    useInputValidation('', (newPassword) => newPassword.trim() !== '');

  const [confirmNewPassword, setConfirmNewPassword, isConfirmNewPasswordValid, hasConfirmNewPasswordError, confirmNewPasswordChangeHandler, confirmNewPasswordBlurHandler] = 
    useInputValidation('', (confirmNewPassword) => confirmNewPassword.trim() !== '');

  const [isCreateCredentialsButtonDisabled, setIsCreateCredentialsButtonDisabled] = useState(true);

  const [userPasswordCreationSessionToken, setUserPasswordCreationSessionToken] = useState(searchParams.get('token'));

  const [formError, setFormError] = useState({
    hasError: false,
    title: '',
    text: ''
  });

  useEffect(() => {
    if(!isLoading && error !== undefined) {
      
    }
  }, [isLoading, error]);

  useEffect(() => {
    if(userPasswordCreationSessionToken === null || userPasswordCreationSessionToken === undefined || userPasswordCreationSessionToken === '') {
      window.location.href = 'login';
    }

    setNewPassword('');
    setConfirmNewPassword('');
    setFormError({
      hasError: false,
      title: '',
      text: ''
    });
  }, [userPasswordCreationSessionToken, setNewPassword, setConfirmNewPassword]);

  const checkLength = (password, length) => {
    return (password.length >= length);
  }

  const containsUpperCaseLetter = (password) => {
    return /[A-Z]/.test(password);
  }

  const containsLowerCaseLetter = (password) => {
    return /[a-z]/.test(password);
  }

  const containsNumber = (password) => {
    return /\d/.test(password);
  }

  const containsSpecialCharacter = (password) => {
    const specialCharacters = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    return specialCharacters.test(password);
  }

  const validateNewPassword = (password) => {
    const passwordConditionLength = document.getElementById('passwordConditionLength');
    const passwordConditionUppercaseChar = document.getElementById('passwordConditionUppercaseChar');
    const passwordConditionLowercaseChar = document.getElementById('passwordConditionLowercaseChar');
    const passwordConditionNumber = document.getElementById('passwordConditionNumber');
    const passwordConditionSpecialChar = document.getElementById('passwordConditionSpecialChar');

    if(passwordConditionLength) {
      if(!checkLength(password, 12)) {
        passwordConditionLength.classList.remove(styles['success']);
        passwordConditionLength.classList.add(styles['error']);
      }
      else {
        passwordConditionLength.classList.remove(styles['error']);
        passwordConditionLength.classList.add(styles['success']);
      }
    }

    if(passwordConditionUppercaseChar) {
      if(!containsUpperCaseLetter(password)) {
        passwordConditionUppercaseChar.classList.remove(styles['success']);
        passwordConditionUppercaseChar.classList.add(styles['error']);
      }
      else {
        passwordConditionUppercaseChar.classList.remove(styles['error']);
        passwordConditionUppercaseChar.classList.add(styles['success']);
      }
    }

    if(passwordConditionLowercaseChar) {
      if(!containsLowerCaseLetter(password)) {
        passwordConditionLowercaseChar.classList.remove(styles['success']);
        passwordConditionLowercaseChar.classList.add(styles['error']);
      }
      else {
        passwordConditionLowercaseChar.classList.remove(styles['error']);
        passwordConditionLowercaseChar.classList.add(styles['success']);
      }
    }

    if(passwordConditionNumber) {
      if(!containsNumber(password)) {
        passwordConditionNumber.classList.remove(styles['success']);
        passwordConditionNumber.classList.add(styles['error']);
      }
      else {
        passwordConditionNumber.classList.remove(styles['error']);
        passwordConditionNumber.classList.add(styles['success']);
      }
    }

    if(passwordConditionSpecialChar) {
      if(!containsSpecialCharacter(password)) {
        passwordConditionSpecialChar.classList.remove(styles['success']);
        passwordConditionSpecialChar.classList.add(styles['error']);
      }
      else {
        passwordConditionSpecialChar.classList.remove(styles['error']);
        passwordConditionSpecialChar.classList.add(styles['success']);
      }
    }

    return (
      password.trim() !== '' && checkLength(password, 12) && 
      containsUpperCaseLetter(password) && containsLowerCaseLetter(password) && 
      containsNumber(password) && containsSpecialCharacter(password)
    );
  }

  const validateAndNewPasswordChangeHandler = (enteredNewPassword) => {
    newPasswordChangeHandler(enteredNewPassword);
    validateNewPassword(enteredNewPassword);
    if(enteredNewPassword.trim() !== '' && isConfirmNewPasswordValid && 
      validateCreateCredentialsForm(enteredNewPassword, confirmNewPassword)
    ) {
      setIsCreateCredentialsButtonDisabled(false);
    }
    else {
      setIsCreateCredentialsButtonDisabled(true);
    }
  }

  const validateReNewPassword = (password, rePassword) => {
    return (password === rePassword);
  }

  const validateAndReNewPasswordChangeHandler = (enteredReNewPassword) => {
    confirmNewPasswordChangeHandler(enteredReNewPassword);
    validateReNewPassword(newPassword, enteredReNewPassword);
    if(isNewPasswordValid && enteredReNewPassword.trim() !== '' && 
      validateCreateCredentialsForm(newPassword, enteredReNewPassword)
    ) {
      setIsCreateCredentialsButtonDisabled(false);
    }
    else {
      setIsCreateCredentialsButtonDisabled(true);
    }
  }

  const validateCreateCredentialsForm = (password, rePassword) => {
    const isFormValid = validateNewPassword(password) && validateReNewPassword(password, rePassword);
    return isFormValid;
  }

  const createCredentialsHandler = () => {
    const passwordResetData = {
      'token': userPasswordCreationSessionToken,
      'new_password': newPassword
    };

    sendRequest(
      resetUserPasswordRequest(passwordResetData),
      (data) => {
        window.location.href='login';
      }
    );
  }

  return (
    <div className={styles['create-credentials-page']}>
      <div className={styles['create-credentials-password-form-container']}>
        <div className={styles['form-row']}>
          <div className={styles['logo-area']}>
            <div className={styles['logo']}></div>
          </div>
        </div>
        <div className={styles['form-row']}>
          <div className={styles['title']}>
            <h3>Create Credentials</h3>
          </div>
        </div>
        <div className={styles['form-row']}>
          <PasswordInput
            id='create-credentials-password-form#new-password'
            width='100%'
            label='New Password'
            placeholder='Enter your new Password'
            value={newPassword}
            hasError={hasNewPasswordError}
            warningText={hasNewPasswordError && 'New Password needs to be filled!'}
            onBlur={newPasswordBlurHandler}
            onChange={validateAndNewPasswordChangeHandler}
            isDisabled={false}
          />
          <div className={styles['new-password-conditions']}>
            <div className={styles['conditions-note']}>
              <p>Your password must include:</p>
            </div>
            <ul className={styles['conditions-list']}>
              <li id="passwordConditionLength">12 or more characters</li>
              <li id="passwordConditionUppercaseChar">Uppercase letter</li>
              <li id="passwordConditionLowercaseChar">Lowercase letter</li>
              <li id="passwordConditionNumber">Number</li>
              <li id="passwordConditionSpecialChar">Special character</li>
            </ul>
          </div>
        </div>
        <div className={styles['form-row']}>
          <PasswordInput
            id='create-credentials-password-form#confirm-new-password'
            width='100%'
            label='Confirm New Password'
            placeholder='Re-enter your new Password'
            value={confirmNewPassword}
            hasError={hasConfirmNewPasswordError}
            warningText={hasConfirmNewPasswordError && 'Confirm New Password needs to be filled!'}
            onBlur={confirmNewPasswordBlurHandler}
            onChange={validateAndReNewPasswordChangeHandler}
            isDisabled={false}
          />
        </div>
        {formError['hasError'] && 
          <div className={styles['form-row']}>
            <div className={styles['form-error']}>
              <div className={styles['error-title']}>
                <div className={styles['warning-icon']}></div>
                <h4>{formError['title']}</h4>
              </div>
              <div className={styles['error-content']}>
                <p>{formError['text']}</p>
              </div>
            </div>
          </div>
        }
        <div className={styles['form-row']}>
          <Button width='100%' size='base' type='primary' onClick={createCredentialsHandler} isDisabled={isCreateCredentialsButtonDisabled}>Create Credentials</Button>
        </div>
      </div>
    </div>
  );
}

export default CreateCredentialsPage;
