import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { getValueFromVariable } from '../../../../../utils/helpers';

import Button from '../../../../../components/UI/Button/Button';
import SectionInfoBox from '../SectionInfoBox/SectionInfoBox';

import summaryPageStyles from '../InvestorSummary.module.css';

const InvestorSummaryPastGeneralMeetingVotesSection = (props) => {

  const [pastGeneralMeetingVotesData, setPastGeneralMeetingVotesData] = useState(props.pastGeneralMeetingVotesData);
  const [isEditMode, setIsEditMode] = useState(false);

  const [lastGeneralMeetingVotes, setLastGeneralMeetingVotes] = useState(getValueFromVariable(pastGeneralMeetingVotesData['last_general_meeting_votes']));
  const [previousGeneralMeetingVotes, setPreviousGeneralMeetingVotes] = useState(getValueFromVariable(pastGeneralMeetingVotesData['previous_general_meeting_votes']));

  const cancelEditHandler = () => {
    setIsEditMode(false);
    setLastGeneralMeetingVotes(getValueFromVariable(pastGeneralMeetingVotesData['last_general_meeting_votes']));
    setPreviousGeneralMeetingVotes(getValueFromVariable(pastGeneralMeetingVotesData['previous_general_meeting_votes']));
  }

  const updatePastGeneralMeetingVotesHandler = () => {
    const updatedPastGeneralMeetingVotesData = {
      'last_general_meeting_votes': lastGeneralMeetingVotes,
      'previous_general_meeting_votes': previousGeneralMeetingVotes
    };
    setPastGeneralMeetingVotesData(updatedPastGeneralMeetingVotesData);
    setIsEditMode(false);
    props.onSaveClick(updatedPastGeneralMeetingVotesData);
  }

  return (
    <div className={summaryPageStyles['section']}>
      <div className={summaryPageStyles['section-title']}>
        <h3>Past General Meeting Votes</h3>
        <div className={summaryPageStyles['tooltip-icon']} data-tooltip-id='past-general-meeting-notes-title-tooltip'>
          <Tooltip
            id='past-general-meeting-notes-title-tooltip'
            place='bottom'
            content='This section allows the user to put notes on how this investor voted at general meetings. Please note, depending on your subscription, AQTION may pre-populate these sections.'
            className='aqtion-tooltip'
          />
        </div>
      </div>
      { !isEditMode && 
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='secondary' leftIcon='edit' onClick={() => setIsEditMode(true)}>Edit</Button>
        </div>
      }
      { isEditMode && 
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='cancel' onClick={cancelEditHandler}>Cancel</Button>
          <Button size='xsmall' type='primary' onClick={updatePastGeneralMeetingVotesHandler}>Save</Button>
        </div>
      }

      <div className={`${summaryPageStyles['content']} ${isEditMode && summaryPageStyles['edit-mode']}`}>
        <SectionInfoBox
          id='investor#last-general-meeting-votes'
          size='double-col'
          type={!isEditMode ? 'text-block' : 'text-block-input'}
          height='184px'
          label='Last General Meeting Votes' 
          value={lastGeneralMeetingVotes}
          onInputChange={setLastGeneralMeetingVotes}
        />
        <SectionInfoBox
          id='investor#previous-general-meeting-votes'
          size='double-col'
          type={!isEditMode ? 'text-block' : 'text-block-input'}
          height='184px'
          label='Previous General Meeting Votes' 
          value={previousGeneralMeetingVotes}
          onInputChange={setPreviousGeneralMeetingVotes}
        />
      </div>
      <div className={summaryPageStyles['separator']}></div>
    </div>
  );
}

export default InvestorSummaryPastGeneralMeetingVotesSection;
