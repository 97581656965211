import Plot from "react-plotly.js";

import styles from './DonutChart.module.css';

const DonutChart = (props) => {

  const generateInfoBox = () => {
    return props.data['items'].map(item => {
      return (
        <div key={`info-box-${props.id}-${item['id']}`} className={styles['item']}>
          <div className={styles['color']} style={{backgroundColor: item['color']}}></div>
          <div className={styles['text']}>
            <h4>{item['name']}</h4>
          </div>
          <div className={styles['percentage']}>
            <h4>{`(${item['percentage']}%)`}</h4>
          </div>
          <div className={styles['count']}>
            <h4>{`${item['value']}`}</h4>
          </div>
        </div>
      );
    });
  }

  return (
    <div className={styles['donut-chart-container']}>
      <div className={styles['graph']}>
        <Plot
          data={[{
            type: 'pie',
            hole: .70,
            // values: props.data['items'].map(item => item['value']),
            values: props.data['items'].map(item => item['percentage']),
            marker: { colors: props.data['colors'], line: {color: '#FFFFFF', width: 2} },
            textinfo: 'none'
          }]}
          layout={{
            autosize: false,
            width: props.width,
            height: props.height,
            showlegend: false,
            grid: { rows: 1, columns: 1 },
            margin: { l: 0, r: 0, t: 0, b: 0, autoexpand: false },
            plot_bgcolor: "rgba(0,0,0,0)",
            paper_bgcolor: "rgba(0,0,0,0)",
            hovermode: false
          }}
          config={{
            showLink: false,
            staticPlot: true
          }}
        />
      </div>
      <div className={styles['info-box']}>
        <div className={styles['item']}>
          {generateInfoBox()}
        </div>
      </div>
    </div>
  );
}

export default DonutChart;
