export const gloassaryAlphabet = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

export const glossaryDictionary = {
  'A': [
    {
      'word': 'Activist',
      'definition': '<p>AQTION classifies an investor as an "Activist" if they have recently agitated for change at a portfolio company where they have proposed or co-filed a proposal to elect/dismiss director, amend capital allocation policy, push for a corporate transaction, etc. A "vote no" campaign initiated by an investor would also qualify as AQTION considering an investor as an "Activist".</p>'
    },
    {
      'word': 'Annual General Meeting',
      'definition': `<p>An Annual General Meeting (AGM) is a yearly gathering of a company's shareholders, where they discuss performance, vote on proposals, and engage with management and the board of directors.</p>`
    },
    {
      'word': 'AGM Participation',
      'definition': [
        '<p>Not all shareholders vote at Annual General Meetings (AGMs) for various reasons, including the potential cost associated with voting. AQTION determines whether an investor participates at AGMs based on their previous voting behavior at companies globally and/or their policies regarding proxy voting.</p>',
        '<ul>',
        '<li><p><strong>Yes</strong> - This investor generally participates at AGMs.</p></li>',
        '<li><p><strong>No</strong> - This investor generally does not participate at AGMs.</p></li>',
        '<li><p><strong>Case-by-Case</strong> - This investor will participate at AGMs if only certain criteria are met (such as their level of economic exposure to the company).</p></li>',
        `<li><p><strong>Unknown</strong> - The investor's participation cannot be determined.</p></li>`,
        '</ul>'
      ].join('')
    },
    {
      'word': 'AQTION Investors',
      'definition': '<p>This represents the universe of investors that the client has provided to AQTION to upload unto the platform.</p>'
    },
    {
      'word': 'Asset Manager',
      'definition': '<p>An asset manager oversees investments for clients, aiming to maximize returns while managing risk by pursuing an active and/or passive investment strategy.</p>'
    },
    {
      'word': 'AUM ($)',
      'definition': '<p>AUM stands for “Assets under Management” which measures the total market value of all the financial assets that an investor controls on behalf of its clients.</p>'
    }
  ],
  'C': [
    {
      'word': 'Client Request',
      'definition': `<p>The investor would participate at a general meeting based on their underlying clients' request and instructions.</p>`
    },
    {
      'word': 'Custom Investor',
      'definition': '<p>A "Custom Investor" is a unique entry for an investor that is currently not covered by the AQTION platform.</p>'
    }
  ],
  'E': [
    {
      'word': 'ESG Focus',
      'definition': [
        `<p>AQTION categorizes each investor's focus on Environmental, Social, and Governance (ESG) topics based on a multiple of proprietary criteria, including the investor's investment strategy, support for Environmental and/or Social initiatives, disclosure practices, etc.</p>`,
        '<ul>',
        '<li><p><strong>High</strong> - This investor is focused on ESG topics.</p></li>',
        '<li><p><strong>Medium</strong> - This investor occasionally focuses on ESG topics.</p></li>',
        '<li><p><strong>Low</strong> - This investor does not focus on ESG topics.</p></li>',
        '</ul>'
      ].join('')
    },
    {
      'word': 'ESG Service Provider',
      'definition': `<p>An ESG Service Provider (which encompasses both ratings firms as well as research providers) offers ratings, data, and research to investors on companies' ESG practices and disclosures. </p>`
    }
  ],
  'F': [
    {
      'word': 'Fixed Income',
      'definition': '<p>A Fixed Income investor generally invests in assets such as bonds, loans, or other debt securities that provide a fixed or predictable stream of income over time. These investors typically seek to preserve capital while generating regular income through interest payments or dividends, making fixed income investments suitable for those with a low tolerance for risk or seeking stable returns.</p>'
    },
    {
      'word': 'Fund Manager',
      'definition': '<p>A fund manager is a professional responsible for making investment decisions and managing the portfolio of assets within a specific investment fund to achieve its stated objectives. For the purposes of AQTION, a Fund Manager means he/she also is involved in proxy voting decisions.</p>'
    }
  ],
  'H': [
    {
      'word': 'Hedge Fund',
      'definition': '<p>A hedge fund is an investment fund typically open to a limited number of accredited investors and employs various strategies to maximize returns, often including leveraging, derivatives, and short-selling. Hedge funds aim to generate high returns regardless of market conditions while mitigating risk through diversification and hedging techniques.</p>'
    }
  ],
  'I': [
    {
      'word': 'Insurer',
      'definition': '<p>An Insurer, or Insurance Company, offers financial protection against losses or liabilities in exchange for premiums paid by individuals or entities.</p>'
    },
    {
      'word': 'ISC',
      'definition': '<p>ISC stands for "Issued Share Capital": 100% represents the total number of shares that a company has issued to its shareholders (incl. treasury shares). Another term for ISC is shares outstanding.</p>'
    }
  ],
  'L': [
    {
      'word': 'Level of Insight',
      'definition': [
        '<p>Not all investors disclose the same level of information as to their expectations surrounding Environmental, Social, and Governance (ESG) topics. AQTION has categorized the level of insight provided by each investor based on the quantity and quality of disclosures available in the public domain.</p>',
        '<ul>',
        '<li><p><strong>Strong</strong> - The investor provides comprehensive information on its expectations surrounding ESG topics.</p></li>',
        '<li><p><strong>Standard</strong> - The investor provides a sufficient level of information to understand its expectations on certain ESG topics.</p></li>',
        '<li><p><strong>Weak</strong> - The investor provides very limited information as to its expectations on ESG topics.</p></li>',
        '</ul>'
      ].join('')
    }
  ],
  'M': [
    {
      'word': 'Majority Active',
      'definition': '<p>This investor pursues an investment strategy which is majority active, i.e. it invests based on the fundamentals of a company and its convictions of future profit.</p>'
    },
    {
      'word': 'Majority Passive',
      'definition': '<p>This investor pursues an investment strategy which is majority passive, i.e. it invests solely as a result of them being within an index and/or the application of quantitative models.</p>'
    },
    {
      'word': 'Meeting Notes',
      'definition': '<p>This feature on AQTION allows the user to input meeting notes, designed to streamline your note-taking process by capturing essential details such as key topics discussed, participants, action items, and more, facilitating organized collaboration and effective follow-up.</p>'
    },
    {
      'word': 'Meeting Requests',
      'definition': '<p>This feature on AQTION allows the user to reach out to its investors, allowing users to efficiently schedule meetings as part of their roadshows by sending personalized e-mails to investors, facilitating efficient coordination and maximizing engagement throughout the process.</p>'
    },
    {
      'word': 'Meeting Type',
      'definition': [
        '<p>When inputting a "Meeting Note" within the "Engagements" tab, the user could select the categorize the engagement with the following options:<p>',
        '<ul>',
        '<li><p><strong>Governance off-season</strong> - This category is for engagements that take place outside of the proxy/AGM season to discuss governance topics.</p></li>',
        '<li><p><strong>AGM</strong> - This category is for engagements that take place in the lead-up to the AGM.</p></li>',
        '<li><p><strong>Sustainability</strong> - This category is for engagements that take place to discuss sustainability topics (inclusive of governance topics).</p></li>',
        '<li><p><strong>Reactive</strong> - This category is for engagements where an investor has contacted you to discuss a governance or sustainability topic.</p></li>',
        '<li><p><strong>Proactive</strong> - This category is for engagements where a company contacts an investor to discuss a governance or sustainability topic.</p></li>',
        '</ul>'
      ].join('')
    }
  ],
  'O': [
    {
      'word': 'Other (Outside Top Investors)',
      'definition': '<p>This represents the %ISC of investors that were not uploaded onto the AQTION platform.</p>'
    },
    {
      'word': 'Other Notes',
      'definition': '<p>This feature on AQTION allows users to input notes on a specific investor whereby it was not through a one-on-one meeting. For example, if at a conference where one of your investors participates as a speaker, you could input notes on what they have said to capture for future use. Another example would be where an investor sends an informative e-mail about their expectations which you could capture as a note.</p>'
    },
    {
      'word': 'Outsourced – Engagement Firm',
      'definition': '<p>Certain investors delegate their voting rights to a service provider that would engage on its behalf as well as vote their shares. For example, Federate Hermes offers such service where their Equity Ownership Services (EOS) team will engage and vote on behalf of pension funds, asset managers, etc.</p>'
    },
    {
      'word': 'Outsourced – External Manager',
      'definition': '<p>Certain asset owners leave proxy voting decisions to their selected External Manager(s).</p>'
    },
    {
      'word': 'Outsourced – Proxy Advisor',
      'definition': `<p>Certain investors may outsource their proxy voting decisions fully to their selected Proxy Advisor. This investor may either strictly follow the Proxy Advisor's recommendations.</p>`
    }
  ],
  'P': [
    {
      'word': 'Pension Fund',
      'definition': '<p>A pension fund is a retirement investment fund created by employers, unions, or governments to provide retirement benefits. It pools contributions, investing them in various assets to generate returns for future pension payments.</p>'
    },
    {
      'word': 'Primary Proxy Advisor',
      'definition': '<p>Certain investors subscribe to the recommendations of more than one Proxy Advisor. Based on their voting behavior, AQTION defines the Primary Proxy Advisor as the one that the investor follows more closely their recommendations.</p>'
    },
    {
      'word': 'Proxy Advisor',
      'definition': '<p>A Proxy Advisor is a service provider that offers independent guidance to institutional investors on corporate governance issues and issues recommendations as to how they should vote on agenda items coming to a vote.</p>'
    },
    {
      'word': 'Proxy Advisor Reliance',
      'definition': [
        `<p>Each investor has a certain level of reliance on their selected Proxy Advisors' recommendations. While most investors have their own internal voting guidelines, a Proxy Advisors' recommendation may still be influential in the final voting decision of the investor.</p>`,
        '<ul>',
        '<li><p><strong>High</strong> - This investor relies heavily on the recommendations of its Primary Proxy Advisor.</p></li>',
        '<li><p><strong>Medium</strong> - This investor relies on the recommendations of its Primary Proxy Advisor, but may deviate from their recommendations in certain situations. </p></li>',
        '<li><p><strong>Low</strong> - This investor does not rely on the recommendations of their Primary Proxy Advisor.</p></li>',
        '<li><p><strong>Low - Less Strict</strong> - This investor does not rely on the recommendations of its Primary Proxy Advisor, and tends to supports management recommendations.</p></li>',
        '<li><p><strong>Low - Stricter</strong> - This investor does not rely on the recommendations of its Primary Proxy Advisor, and applies a stricter approach when evaluating items coming to a vote.</p></li>',
        '</ul>'
      ].join('')
    }
  ],
  'R': [
    {
      'word': 'Receptivity to Activists',
      'definition': [
        '<p>AQTION determines the "Receptivity to Activists" for each investor based on their behavior at previous high-profile activist campaigns. AQTION selects activism campaigns that garner the most investor attention to ensure that votes cast are as informed as possible.<p>',
        '<ul>',
        '<li><p><strong>High</strong> - This investor is highly receptive to activist campaigns, and tends to support activist campaigns.</p></li>',
        '<li><p><strong>Medium</strong> - This investor is receptive to activist campaigns, and has shown to support activist campaigns selectively.</p></li>',
        '<li><p><strong>Low</strong> - This investor tends to not support activist campaigns, and generally favours management.</p></li>',
        '</ul>'
      ].join('')
    }
  ],
  'S': [
    {
      'word': 'Shareholder Communications',
      'definition': '<p>This feature on AQTION within the "Engagements" tab allows the user to send e-mails to its investors on any topic it wishes to communicate on.</p>'
    },
    {
      'word': 'Sovereign Wealth Fund',
      'definition': `<p>A sovereign wealth fund is a state-owned investment fund managing a country's surplus wealth, often generated from natural resources or trade surpluses, with long-term investment goals to benefit the economy or future generations. For example, Norges Bank Investment Management (NBIM) is a sovereign wealth fund.</p>`
    },
    {
      'word': 'Stewardship Team',
      'definition': '<p>The decisions for Proxy Voting are made by the Stewardship Team. A Stewardship Team generally engages with portfolio companies to advocate for responsible corporate governance and sustainable practices, exercising voting rights and promoting long-term value creation. They, however, do not have any role in investment decisions.</p>'
    },
    {
      'word': 'Stewardship Team with Fund Manager',
      'definition': '<p>The decisions for Proxy Voting are shared between the Stewardship Team and the Fund / Portfolio Manager.</p>'
    }
  ],
  'V': [
    {
      'word': 'Voting',
      'definition': [
        '<p>Not all shareholders vote at Annual General Meetings (AGMs) for various reasons, including the potential cost associated with voting. AQTION determines whether an investor participates at AGMs based on their previous voting behavior at companies globally and/or their policies regarding proxy voting.</p>',
        '<ul>',
        '<li><p><strong>Yes</strong> - This investor generally participates at AGMs.</p></li>',
        '<li><p><strong>No</strong> - This investor generally does not participate at AGMs.</p></li>',
        '<li><p><strong>Case-by-Case</strong> - This investor will participate at AGMs if only certain criteria are met (such as their level of economic exposure to the company).</p></li>',
        '<li><p><strong>Unknown</strong> - The investor’s participation cannot be determined.</p></li>',
        '</ul>'
      ].join('')
    }
  ]
};