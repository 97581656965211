import { useEffect, useState } from 'react';
import useInputValidation from '../../../../hooks/use-input-validation';
import { helpCategoryDropdownItems, platformUseAreaDropdownItems } from '../../../../utils/constants';

import Button from '../../../../components/UI/Button/Button';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import Modal from '../../../../components/UI/Modal/Modal';
import Textarea from '../../../../components/UI/Textarea/Textarea';
import TextInput from '../../../../components/UI/TextInput/TextInput';

import styles from './HelpContactForm.module.css';

const HelpContactForm = (props) => {
  const [imageAttachment, setImageAttachment] = useState(null);
  const [isClientHelpContactFormSent, setIsClientHelpContactFormSent] = useState(false);

  const [fullname, setFullname, isFullnameValid, hasFullnameError, fullnameChangeHandler, fullnameBlurHandler] =
    useInputValidation('', (fullname) => fullname.trim() !== '');

  const [jobTitle, setJobTitle, isJobTitleValid, hasJobTitleError, jobTitleChangeHandler, jobTitleBlurHandler] =
    useInputValidation('', (jobTitle) => jobTitle.trim() !== '');

  const [companyName, setCompanyName, isCompanyNameValid, hasCompanyNameError, companyNameChangeHandler, companyNameBlurHandler] =
    useInputValidation('', (companyName) => companyName.trim() !== '');

  const [email, setEmail, isEmailValid, hasEmailError, emailChangeHandler, emailBlurHandler] =
    useInputValidation('', (email) => email.trim() !== '');

  const [helpCategory, setHelpCategory, isHelpCategoryValid, hasHelpCategoryError, helpCategoryChangeHandler, helpCategoryBlurHandler] =
    useInputValidation('', (helpCategory) => helpCategory.trim() !== '');

  const [platformUseArea, setPlatformUseArea, isPlatformUseAreaValid, hasPlatformUseAreaError, platformUseAreaChangeHandler, platformUseAreaBlurHandler] =
    useInputValidation('', (platformUseArea) => platformUseArea.trim() !== '');

  const [helpMessage, setHelpMessage, isHelpMessageValid, hasHelpMessageError, helpMessageChangeHandler, helpMessageBlurHandler] =
    useInputValidation('', (helpMessage) => helpMessage.trim() !== '');

  useEffect(() => {
    setFullname('');
    setJobTitle('');
    setCompanyName('');
    setEmail('');
    setHelpCategory('');
    setPlatformUseArea('');
    setHelpMessage('');
  }, [setFullname, setJobTitle, setCompanyName, setEmail, setHelpCategory, setPlatformUseArea, setHelpMessage]);

  const isContactUsFormValid = () => {
    fullnameBlurHandler();
    jobTitleBlurHandler();
    companyNameBlurHandler();
    emailBlurHandler();
    helpCategoryBlurHandler();
    platformUseAreaBlurHandler();
    helpMessageBlurHandler();

    const isFormValid = isFullnameValid && isJobTitleValid && isCompanyNameValid && isEmailValid && isHelpCategoryValid && isHelpMessageValid;
    if(helpCategory === 'Platform Use') {
      return isFormValid && isPlatformUseAreaValid;
    }
    return isFormValid;
  }

  const sendContactFormHandler = () => {
    if(!isContactUsFormValid()) {
      return;
    }
    const contactUsFormData = {
      'fullname': fullname,
      'job_title': jobTitle,
      'company_name': companyName,
      'email': email,
      'category': helpCategory,
      'message': helpMessage,
      'attachment': imageAttachment
    };
    if(helpCategory === 'Platform Use') {
      contactUsFormData['area'] = platformUseArea;
    }
    else {
      contactUsFormData['area'] = '';
    }
    props.onSendClick(contactUsFormData, setIsClientHelpContactFormSent);
  }

  const imageAttachmentChangeHandler = (event) => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if(file.size > 500000){
        alert('File size should be smaller than 450Kb');
        return false;				
      }

      var data = new FormData();
			data.append('attachment', file, file.name);
      props.onImageAttachmentUploadClick(data, setImageAttachment);
    }
  }

  const removeImageAttachmentHandler = () => {
    setImageAttachment(null);
  }

  const closeClientHelpContactFormSentHandler = () =>{
    fullnameChangeHandler('');
    jobTitleChangeHandler('');
    companyNameChangeHandler('');
    emailChangeHandler('');
    helpCategoryChangeHandler('');
    platformUseAreaChangeHandler('');
    helpMessageChangeHandler('');
    setImageAttachment(null);
    setIsClientHelpContactFormSent(false);
  }

  return (
    <div className={styles['contact-us-form']}>
      {isClientHelpContactFormSent && 
        <Modal
          type='success'
          title='Request Sent'
          message={<span>Your request has been successfully sent. One of AQTION account managers will be in contact shortly!</span>}
          onCloseClick={closeClientHelpContactFormSentHandler}
          approveButton = {{
            'text': 'Close',
            'onClick': () => closeClientHelpContactFormSentHandler()
          }}
        />
      }

      <div className={styles['contact-us-left-container']}>
        <div className={styles['title']}>
          <h2>Need Assistance?</h2>
        </div>
        <div className={styles['description']}>
          <p>Please submit a request using the form below and we will get back to you as soon as possible.</p>
          <ul>
            <li>To request assistance associated with a bug please select "Technical Support".</li>
            <li>For any questions relating to how to use the platform, please consult the Glossary in the Help section and/or fill in the contact form below and select "Platform Use" if you need further assistance.</li>
          </ul>
        </div>
      </div>
      <div className={styles['contact-us-right-container']}>
        <div className={styles['row']}>
          <TextInput
            id='contact-us#full-name'
            width='100%'
            label='Full Name *'
            placeholder='Enter your Full Name'
            value={fullname}
            hasError={hasFullnameError}
            onBlur={fullnameBlurHandler}
            onChange={fullnameChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <TextInput
            id='contact-us#job-title'
            width='100%'
            label='Job Title *'
            placeholder='Enter your Job Title'
            value={jobTitle}
            hasError={hasJobTitleError}
            onBlur={jobTitleBlurHandler}
            onChange={jobTitleChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <TextInput
            id='contact-us#company'
            width='100%'
            label='Company *'
            placeholder='Enter your Company'
            value={companyName}
            hasError={hasCompanyNameError}
            onBlur={companyNameBlurHandler}
            onChange={companyNameChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <TextInput
            id='contact-us#email'
            width='100%'
            label='Email *'
            placeholder='Enter your Email'
            value={email}
            hasError={hasEmailError}
            onBlur={emailBlurHandler}
            onChange={emailChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='contact-us#help-category'
            width='100%'
            label='What can we help you with? *'
            placeholder='Select a Category'
            items={helpCategoryDropdownItems}
            selected={helpCategory}
            hasError={hasHelpCategoryError}
            onBlur={helpCategoryBlurHandler}
            onItemClick={helpCategoryChangeHandler}
          />
        </div>
        { helpCategory === 'Platform Use' &&
          <div className={styles['row']}>
            <Dropdown
              id='contact-us#platform-use-area'
              width='100%'
              label='Area *'
              placeholder='Select an Area'
              items={platformUseAreaDropdownItems}
              selected={platformUseArea}
              hasError={hasPlatformUseAreaError}
              onBlur={platformUseAreaBlurHandler}
              onItemClick={platformUseAreaChangeHandler}
            />
          </div>
        }
        <div className={styles['row']}>
          <Textarea
            id='contact-us#help-message'
            width='100%'
            height='120px'
            label='Tell Us More... *'
            size='base'
            placeholder='Enter Your Message'
            hasError={hasHelpMessageError}
            onBlur={helpMessageBlurHandler}
            onChange={helpMessageChangeHandler}
          >
            {helpMessage}
          </Textarea>
        </div>
        <div className={styles['row']}>
          {!imageAttachment &&
            <div className={styles['new-attachment-container']}>
              <label className={styles['new-attachment-button']}>
                <div className={styles['new-attachment-icon']}></div>
                <div className={styles['new-attachment-text']}>
                  <h4>Add Image</h4>
                </div>
                <input type='file' accept='image/gif, image/jpeg, image/png' onChange={imageAttachmentChangeHandler} />
              </label>
            </div>
          }
          {imageAttachment &&
            <div className={styles['attached-item-container']}>
              <div className={styles['attached-item']}>
                <div className={styles['attached-item-icon']}></div>
                <div className={`${styles['attached-item-text']} ${styles['clickable']}`} onClick={() => window.open(imageAttachment['attachment_url'], '_blank')}>
                  <h4>{imageAttachment['attachment_name']}</h4>
                </div>
                <div className={styles['remove-button']} onClick={removeImageAttachmentHandler}></div>
              </div>
            </div>
          }
        
          <div className={styles['send-button-container']}>
            <Button size='base' type='primary' onClick={sendContactFormHandler}>Send</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpContactForm;
