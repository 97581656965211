import styles from './Checkbox.module.css';

const Checkbox = (props) => {
  return (
    <div className={styles['checkbox-container']}>
      <div className={styles['checkbox']}>
        <input 
          id={props.id}
          type='checkbox'
          value={props.value} 
          onChange={props.onChange}
          checked={props.isChecked} 
          disabled={props.isDisabled ? props.isDisabled : false}
        />
        <label htmlFor={props.id}>{props.text}</label>
      </div>
    </div>
  );
}

export default Checkbox;
