import { useEffect, useRef, useState } from 'react';
import styles from './SearchBox.module.css';

const SearchBox = (props) => {
  const [showItems, setShowItems] = useState(false);
  const ref = useRef(null);

  const isDisabled = props.isDisabled ? true : false;
  const sizeStyle = props.size === undefined ? styles['base'] : styles[props.size];
  const errorStyle = props.hasError ? styles['error'] : '';

  const inputChangeHandler = (event) => {
    if (props.onChange !== undefined) {
      props.onChange(event.target.value);
    }
  }

  const searchItems = props.items.filter(item => item['text'].toLowerCase().includes(props.value.toLowerCase())).map(item => {
    return(
      <div key={`search-item-${item['id']}`} className={styles['item']} onClick={() => props.onItemSelect(item['id'])}>
        <p>{item['text']}</p>
      </div>
    );
  })

  useEffect(() => {
    const handleClickOutside = (event) => {
      if(!ref.current.contains(event.target)) {
        setShowItems(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className={styles['input-container']} ref={ref}>
      <div className={styles['input-wrapper']}>
        <input
          className={`${styles['text-input']} ${sizeStyle} ${errorStyle}`}
          placeholder={props.placeholder}
          value={props.value}
          disabled={isDisabled}
          onChange={inputChangeHandler}
          onFocus={() => setShowItems(true)}
        />
        <div className={styles['search-icon']}></div>
      </div>
      { showItems && 
        <div className={styles['search-list']}>
          <div className={styles['item-container']}>
            <div className={styles['temporary-header']}></div>
            <div className={styles['matched-items']}>
              {searchItems}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default SearchBox
