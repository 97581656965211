import { useEffect, useState } from 'react';
import { createId, getValueFromVariable } from '../../../utils/helpers';
import { listClientInvestorContactsRequest, listClientProxyAdvisorContactsRequest } from '../../../requests/client-requests';
import { getAuthClientId } from "../../../utils/auth";
import { fetchClientEngagementsMeetingRequestInvestorRequest, fetchClientEngagementsMeetingRequestProxyAdvisorRequest, fetchClientEngagementsShareholderCommunicationInvestorRequest, fetchClientEngagementsShareholderCommunicationProxyAdvisorRequest } from '../../../requests/client-engagement-requests';
import { handleRequestError } from '../../../utils/request-error-helpers';
import useHttp from '../../../hooks/use-http';
import useInputValidation from '../../../hooks/use-input-validation';

import Button from '../../UI/Button/Button';
import EngagementFormItem from '../EngagementFormItem/EngagementFormItem';
import MultiSelectComboBox from '../../UI/MultiSelectComboBox/MultiSelectComboBox';

import styles from './EngagementEmailCustomizationModal.module.css';

const EngagementEmailCustomizationModal = (props) => {

  const clientId = getAuthClientId();
  const meetingRequestId = props.meetingRequestId;
  const shareholderCommunicationId = props.shareholderCommunicationId;
  const investorType = props.investorType;
  const investorId = props.investorId;
  const proxyAdvisorId = props.proxyAdvisorId;

  const isDisabled = props.isDisabled;

  const [isLoading, error, sendRequest] = useHttp();

  const [emailSubject, setEmailSubject, isEmailSubjectValid, hasEmailSubjectError, emailSubjectChangeHandler, emailSubjectBlurHandler] =
    useInputValidation('', (emailSubject) => emailSubject.trim() !== '');

  const [emailBody, setEmailBody, isEmailBodyValid, hasEmailBodyError, emailBodyChangeHandler, emailBodyBlurHandler] =
    useInputValidation('', (emailBody) => emailBody.trim() !== '');

  const [emailToSearchValue, setEmailToSearchValue] = useState('');
  const [selectedEmailToItems, setSelectedEmailToItems] = useState([]);
  const [addedEmailToItems, setAddedEmailToItems] = useState([]);

  const [emailCcSearchValue, setEmailCcSearchValue] = useState('');
  const [addedEmailCcItems, setAddedEmailCcItems] = useState([]);

  const [clientSelectedItemContactListData, setClientSelectedItemContactListData] = useState([]);
  const [clientMeetingRequestSelectedItemData, setClientMeetingRequestSelectedItemData] = useState({});

  /* Send a request to fetch investor or proxy advisor contacts according
   * to selected investor type. */
  useEffect(() => {
    if (investorType === 'type-investor') {
      sendRequest(
        listClientInvestorContactsRequest(clientId, investorId),
        (data) => { setClientSelectedItemContactListData(data['items']); }
      );
      if(meetingRequestId){
        sendRequest(
          fetchClientEngagementsMeetingRequestInvestorRequest(clientId, meetingRequestId, investorId),
          (data) => { setClientMeetingRequestSelectedItemData(data); }
        )
      }
      if(shareholderCommunicationId){
        sendRequest(
          fetchClientEngagementsShareholderCommunicationInvestorRequest(clientId, shareholderCommunicationId, investorId),
          (data) => { setClientMeetingRequestSelectedItemData(data); }
        )
      }
    }
    else if (investorType === 'type-proxy-advisor') {
      sendRequest(
        listClientProxyAdvisorContactsRequest(clientId, proxyAdvisorId),
        (data) => { setClientSelectedItemContactListData(data['items']); }
      );
      if(meetingRequestId){
        sendRequest(
          fetchClientEngagementsMeetingRequestProxyAdvisorRequest(clientId, meetingRequestId, proxyAdvisorId),
          (data) => { setClientMeetingRequestSelectedItemData(data); }
        )
      }
      if(shareholderCommunicationId){
        sendRequest(
          fetchClientEngagementsShareholderCommunicationProxyAdvisorRequest(clientId, shareholderCommunicationId, proxyAdvisorId),
          (data) => { setClientMeetingRequestSelectedItemData(data); }
        )
      }
    }
    
  }, [sendRequest, clientId, investorType, investorId, proxyAdvisorId, meetingRequestId, shareholderCommunicationId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  useEffect(() => {
    setEmailSubject(getValueFromVariable(clientMeetingRequestSelectedItemData['email_subject']));
    setEmailBody(getValueFromVariable(clientMeetingRequestSelectedItemData['email_body']));

    const contactEmailAddresses = clientSelectedItemContactListData.map(item => item['email']);
    const emailToList = getValueFromVariable(clientMeetingRequestSelectedItemData['email_to']).split(';').filter(item => item !== '');
    const newSelectedEmailToItems = [];
    const newAddedEmailToItems = emailToList.map(item => {
      const contactEmailIndex = contactEmailAddresses.indexOf(item);
      if (contactEmailIndex > -1) {
        newSelectedEmailToItems.push(clientSelectedItemContactListData[contactEmailIndex]['id']);
        return clientSelectedItemContactListData[contactEmailIndex]
      }
      else {
        return {
          'id': createId(4),
          'email': item,
          'is_custom_email': true
        };
      }
    });
    setAddedEmailToItems(newAddedEmailToItems);
    setSelectedEmailToItems(newSelectedEmailToItems);

    const emailCcList = getValueFromVariable(clientMeetingRequestSelectedItemData['email_cc']).split(';').filter(item => item !== '');
    const newAddedEmailCcItems = emailCcList.map(item => {
      return {
        'id': createId(4),
        'email': item,
        'is_custom_email': true
      };
    });
    setAddedEmailCcItems(newAddedEmailCcItems);
  }, [clientSelectedItemContactListData, clientMeetingRequestSelectedItemData, setEmailBody, setEmailSubject]);

  /* Creates combobox values with the using the investor contacts. */
  const comboBoxClientSelectedItemContactGroups = [
    {
      'id': 'client-selected-item-contact-starred',
      'icon': 'star',
      'title': 'Starred Contacts',
      'isClickable': false,
      'items': clientSelectedItemContactListData.filter(item => item['is_starred']).map(item => {
        return {
          'id': item['id'],
          'text': `${item['fullname']} - ${item['role']}`
        };
      })
    },
    {
      'id': 'client-selected-item-contact-others',
      'title': 'Other Contacts',
      'isClickable': false,
      'items': clientSelectedItemContactListData.filter(item => !item['is_starred']).map(item => {
        return {
          'id': item['id'],
          'text': `${item['fullname']} - ${item['role']}`
        };
      })
    }
  ];

  /* Adds an investor or proxy advisor contact to the `To` area table or removes
   * it from there according the value of the clicked checkbox. */
  const emailToItemClickHandler = (contactId) => {
    const operation = selectedEmailToItems.indexOf(contactId) > -1 ? 'remove' : 'add';
    setSelectedEmailToItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const itemIndex = items.indexOf(contactId);
      if (itemIndex > -1) {
        items.splice(itemIndex, 1);
      }
      else {
        items.push(contactId);
      }
      return items;
    });

    if (operation === 'add') {
      setAddedEmailToItems(prevAddedEmailToItems => {
        const itemIndex = clientSelectedItemContactListData.map(item => item['id']).indexOf(contactId);
        const newAddedEmailToItems = JSON.parse(JSON.stringify(prevAddedEmailToItems));
        newAddedEmailToItems.push(clientSelectedItemContactListData[itemIndex]);
        return newAddedEmailToItems;
      });
    }
    else if (operation === 'remove') {
      setAddedEmailToItems(prevAddedEmailToItems => {
        const newAddedEmailToItems = JSON.parse(JSON.stringify(prevAddedEmailToItems));
        const itemIndex = newAddedEmailToItems.map(item => item['id']).indexOf(contactId);
        newAddedEmailToItems.splice(itemIndex, 1);
        return newAddedEmailToItems;
      });
    }
  }

  /* Adds new custom emaill address for the `To` area. */
  const addCustomEmailToItemHandler = (email) => {
    setAddedEmailToItems(prevAddedEmailToItems => {
      const newAddedEmailToItems = JSON.parse(JSON.stringify(prevAddedEmailToItems));
      newAddedEmailToItems.push({
        'id': createId(4),
        'email': email,
        'is_custom_email': true
      });
      return newAddedEmailToItems;
    });
    setEmailToSearchValue('');
  }

  /* Renoves emaill address from the `To` area. */
  const removeCustomEmailToItemHandler = (clickedItem) => { 
    if (clickedItem['is_custom_email']) {
      setAddedEmailToItems(prevAddedEmailToItems => {
        const newAddedEmailToItems = JSON.parse(JSON.stringify(prevAddedEmailToItems));
        return newAddedEmailToItems.filter(item => item['email'] !== clickedItem['email']);
      });
    }
    else {
      emailToItemClickHandler(clickedItem['id']);
    }
  }

  /* Generates selected / added email tiles for the `To` area. */
  const generateEmailToItems = () => {
    return addedEmailToItems.map(item => {
      return (
        <div key={`added-to-contact-${item['id']}`} className={styles['added-email-contact']}>
          <div className={`${styles['added-email-contact-text']} ${isDisabled ? styles['disabled'] : ''}`}>
            {item['is_custom_email'] &&
              <h3>{item['email']}</h3>
            }
            {!item['is_custom_email'] &&
              <h3>{item['fullname']} ({item['role']})</h3>
            }
          </div>
          {isDisabled !== true &&
            <div className={styles['remove-button']} onClick={() => removeCustomEmailToItemHandler(item)}></div>
          }
        </div>
      );
    });
  }

  /* Adds new custom emaill address for the `Cc` area. */
  const addCustomEmailCcItemHandler = (email) => {
    setAddedEmailCcItems(prevAddedEmailCcItems => {
      const newAddedEmailCcItems = JSON.parse(JSON.stringify(prevAddedEmailCcItems));
      newAddedEmailCcItems.push({
        'id': createId(4),
        'email': email,
        'is_custom_email': true
      });
      return newAddedEmailCcItems;
    });
    setEmailCcSearchValue('');
  }

  /* Renoves emaill address from the `Cc` area. */
  const removeCustomEmailCcItemHandler = (clickedItem) => { 
    setAddedEmailCcItems(prevAddedEmailCcItems => {
      const newAddedEmailCcItems = JSON.parse(JSON.stringify(prevAddedEmailCcItems));
      return newAddedEmailCcItems.filter(item => item['email'] !== clickedItem['email']);
    });
  }

  /* Generates selected / added email tiles for the `Cc` area. */
  const generateEmailCcItems = () => {
    return addedEmailCcItems.map(item => {
      return (
        <div key={`added-cc-contact-${item['id']}`} className={styles['added-email-contact']}>
          <div className={`${styles['added-email-contact-text']} ${isDisabled ? styles['disabled'] : ''}`}>
            <h3>{item['email']}</h3>
          </div>
          {isDisabled !== true &&
            <div className={styles['remove-button']} onClick={() => removeCustomEmailCcItemHandler(item)}></div>
          }
        </div>
      );
    });
  }


  const selectText = (element) => {
    if (/INPUT|TEXTAREA/i.test(element.tagName)) {
      element.focus();
      if (element.setSelectionRange) {
        element.setSelectionRange(0, element.value.length);
      } else {
        element.select();
      }
      return;
    }
    
    if (window.getSelection) { // All browsers, except IE <=8
      window.getSelection().selectAllChildren(element);
    } else if (document.body.createTextRange) { // IE <=8
      var range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    }
  }
  
  const deselectAll = () => {
    var element = document.activeElement;
    
    if (element && /INPUT|TEXTAREA/i.test(element.tagName)) {
      if ('selectionStart' in element) {
        element.selectionEnd = element.selectionStart;
      }
      element.blur();
    }
  
    if (window.getSelection) { // All browsers, except IE <=8
      window.getSelection().removeAllRanges();
    } else if (document.selection) { // IE <=8
      document.selection.empty();
    }
  }


  const isCustomizedEmailTemplateValidHandler = () => {
    emailSubjectBlurHandler();
    emailBodyBlurHandler();
    return isEmailSubjectValid && isEmailBodyValid;
  }

  const saveChangesClickHandler = () => {
    if (!isCustomizedEmailTemplateValidHandler()) {
      return;
    }

    const customizedEmailData = {
      'email_subject': emailSubject,
      'email_body': emailBody,
      'email_to': addedEmailToItems.map(item => item['email']).join(';'),
      'email_cc': addedEmailCcItems.map(item => item['email']).join(';')
    };
    props.onSaveClick(customizedEmailData);
  }
  

  // TODO: Select font as Calibri
  const copyContentAndOpenEmailClient = () => {
    if (!isCustomizedEmailTemplateValidHandler()) {
      return;
    }

    /* eslint-disable */
    const emailBodyForOutlook = 
      emailBody.replace(/<p><br><\/p>/g, '<p><\/p>')
        .replace(/<p><\/p>/g, '<p>&nbsp;<\/p>')
        .replace(/<li>/g, '<li><p>')
        .replace(/<\/li>/g, '<\/p><\/li>')
        .replace(/<p><\/p><ol>/g, '<p>&nbsp;<\/p><ol>')
        .replace(/<\/ol><p><\/p>/g, '<\/ol><p>&nbsp;<\/p>')
        .replace(/<p><\/p><ul>/g, '<p>&nbsp;<\/p><ul>')
        .replace(/<\/ul><p><\/p>/g, '<\/ul><p>&nbsp;<\/p>')
    /* eslint-disable */
  
    document.getElementById('emailBodyCopyContainer').innerHTML = '<article>' + emailBodyForOutlook + '</article>';
    selectText(document.getElementById('emailBodyCopyContainer'));
    document.execCommand('copy');
    deselectAll();
    document.getElementById('emailBodyCopyContainer').innerHTML = '';
  
    const emailToForOutlook = addedEmailToItems.map(item => item['email']).join(';');
    const emailCcForOutlook = addedEmailCcItems.map(item => item['email']).join(';');
    const emailSubjectForOutlook = encodeURIComponent(emailSubject);
    const emailHrefContent = `mailto:${emailToForOutlook}?subject=${emailSubjectForOutlook}&cc=${emailCcForOutlook}`;
    // window.open(emailHrefContent, '_blank');
    window.location.href = emailHrefContent;

    const customizedEmailData = {
      'email_subject': emailSubject,
      'email_body': emailBody,
      'email_to': addedEmailToItems.map(item => item['email']).join(';'),
      'email_cc': addedEmailCcItems.map(item => item['email']).join(';')
    };
    props.onSaveClick(customizedEmailData, 'Sent');
  }


  return (
    <div className={styles['modal-container']}>
      <div className={styles['overlay']}></div>
      <div id="emailBodyCopyContainer"></div>
      <div className={styles['content']}>
        <div className={styles['header']}>
          <div className={styles['title']}>
            { investorType === 'type-investor' &&
              <h3>Customize Engagement Email for {getValueFromVariable(clientMeetingRequestSelectedItemData['client_investor_name'])}</h3>
            }
            { investorType === 'type-proxy-advisor' &&
              <h3>Customize Engagement Email for {getValueFromVariable(clientMeetingRequestSelectedItemData['client_proxy_advisor_name'])}</h3>
            }
          </div>
          <div className={styles['close-button']} onClick={props.onCloseClick}>
            <div className={styles['close-icon']}></div>
          </div>
        </div>

        <div className={styles['body']}>
          <div className={styles['left-container']}>
            <div className={styles['email-subject']}>
              <EngagementFormItem
                id='selected-item-meeting-request#email-subject'
                type='text-input'
                label='Email Subject'
                value={emailSubject}
                placeholder='Enter Email Subject'
                hasError={hasEmailSubjectError}
                onBlur={emailSubjectBlurHandler}
                onChange={emailSubjectChangeHandler}
                isDisabled={isDisabled}
              />
            </div>
            <div className={styles['email-body']}>
              <EngagementFormItem
                id='selected-item-meeting-request#email-body'
                type='rich-text-input'
                label='Email Body'
                value={emailBody}
                placeholder='Enter Content'
                hasError={hasEmailBodyError}
                onBlur={emailBodyBlurHandler}
                onChange={emailBodyChangeHandler}
                isDisabled={isDisabled}
              />
            </div>
          </div>
          
          <div className={styles['right-container']}>
            <div className={styles['email-to-container']}>
              <MultiSelectComboBox
                id='add-email-to-combobox'
                width={384}
                size='base'
                label='To'
                itemGroups={comboBoxClientSelectedItemContactGroups}
                alwaysShowItems={false}
                hasSearchIcon={false}
                hasHeaderCover={false}
                value={emailToSearchValue}
                placeholder='Enter recipient emails manually'
                selected={selectedEmailToItems}
                onChange={setEmailToSearchValue}
                onItemClick={emailToItemClickHandler}
                autoFocus={false}
                hasAddCustomButton={true}
                onAddCustomClick={addCustomEmailToItemHandler}
                isDisabled={isDisabled}
              />

              <div className={styles['added-email-contacts-container']}>
                { addedEmailToItems.length === 0 &&
                  <div className={styles['note']}>
                    <p>You may delete unwanted recipients and/or enter additional recipient email addresses manually.</p>
                  </div>
                }
                { addedEmailToItems.length > 0 &&
                  generateEmailToItems()
                }
              </div>
            </div>

            <div className={styles['email-cc-container']}>
              <MultiSelectComboBox
                id='add-email-cc-combobox'
                width='100%'
                size='base'
                label='Cc'
                itemGroups={[]}
                alwaysShowItems={false}
                hasSearchIcon={false}
                hasHeaderCover={false}
                value={emailCcSearchValue}
                placeholder='Enter recipient emails manually'
                selected={[]}
                onChange={setEmailCcSearchValue}
                onItemClick={null}
                autoFocus={false}
                hasAddCustomButton={true}
                onAddCustomClick={addCustomEmailCcItemHandler}
                isDisabled={isDisabled}
              />

              <div className={styles['added-email-contacts-container']}>
                { addedEmailCcItems.length === 0 &&
                  <div className={styles['note']}>
                    <p>You may enter recipient email addresses manually.</p>
                  </div>
                }
                { addedEmailCcItems.length > 0 &&
                  generateEmailCcItems()
                }
              </div>
            </div>
          </div>
        </div>

        <div className={styles['footer']}>
          {isDisabled !== true &&
            <Button
              className={styles['modal-button']}
              type={'primary'}
              size={'base'}
              onClick={saveChangesClickHandler}
            >
              Save
            </Button>
          }
          <Button
            className={styles['modal-button']}
            type={'secondary'}
            size={'base'}
            onClick={copyContentAndOpenEmailClient}
          >
            Open in Outlook / Mailbox
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EngagementEmailCustomizationModal;
