import { Fragment, useEffect, useState } from 'react';
import { listClientEngagementsMeetingRequestsRequest } from '../../../../../requests/client-engagement-requests';
import { generateClientProxyAdvisorEngagementsMeetingRequestsTableItems } from '../../../../../utils/client-proxy-advisor-helpers';
import { getAuthClientId } from '../../../../../utils/auth';
import { handleRequestError } from '../../../../../utils/request-error-helpers';
import useHttp from '../../../../../hooks/use-http';
import sortArray from 'sort-array';

import Button from '../../../../../components/UI/Button/Button';
import Table from '../../../../../components/UI/Table/Table';

import styles from '../ProxyAdvisorEngagements.module.css';

const ProxyAdvisorEngagementsMeetingRequests = (props) => {

  const clientId = getAuthClientId();
  const proxyAdvisorId = props.proxyAdvisorId;

  const [isLoading, error, sendRequest] = useHttp();

  const [proxyAdvisorEngagementsMeetingRequestsData, setProxyAdvisorEngagementsMeetingRequestsData] = useState([]);
  const [tableItems, setTableItems] = useState(null);

  const [tableSortConfig, setTableSortConfig] = useState({
    'by': ['header'],
    'order': ['asc']
  });

  const proxyAdvisorEnagementsMeetingRequestsSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  /* Open new meeting request creation form. */
  const addNewMeetingRequestClickHandler = () => {
    window.location.href = '../engagements?form_type=new';
  };

  /* Send a request to list meeting request items and set the list with the response. */
  useEffect(() => {
    const queryParams = `proxy_advisor_id=${proxyAdvisorId}`;
    sendRequest(
      listClientEngagementsMeetingRequestsRequest(clientId, queryParams),
      (data) => { setProxyAdvisorEngagementsMeetingRequestsData(data['items']); }
    );
  }, [sendRequest, clientId, proxyAdvisorId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  /* Sort list items when sort configuration is updated. */
  useEffect(() => {
    setProxyAdvisorEngagementsMeetingRequestsData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, tableSortConfig);
    });
  }, [tableSortConfig]);

  /* Generate other notes list. */
  useEffect(() => {
    setTableItems(
      generateClientProxyAdvisorEngagementsMeetingRequestsTableItems(
        proxyAdvisorEngagementsMeetingRequestsData, tableSortConfig, proxyAdvisorEnagementsMeetingRequestsSortHandler
      )
    );
  }, [proxyAdvisorEngagementsMeetingRequestsData, tableSortConfig]);

  return (
    <div>
      { !isLoading && tableItems && tableItems['middle']['body']['items'].length === 0 && 
        <div className={styles['no-enagements-div']}>
          <div className={styles['icon-container']}>
            <div className={styles['icon']}></div>
          </div>
          <div className={styles['text-container']}>
            <h4>You Have No Meeting Requests</h4>
            <p>Use below button to Create a Meeting Request</p>
          </div>
          <div className={styles['button-container']}>
            <Button
              type={"primary"}
              leftIcon={"plus"}
              size={"xsmall"}
              onClick={addNewMeetingRequestClickHandler}>
                Create Meeting Request
              </Button>
          </div>
        </div>
      }

      { !isLoading && tableItems && tableItems['middle']['body']['items'].length > 0 &&
        <Fragment>
          <div className={styles['proxy-advisor-engagement-list-buttons']}>  
            <div className={styles['right-buttons']}>
              <Button type={'primary'} size={'xsmall'} leftIcon={'plus'} onClick={addNewMeetingRequestClickHandler}>Create Meeting Request</Button>
            </div>
          </div>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={tableItems} isScrollable={false} />
        </Fragment>
      }
    </div>
  );
}

export default ProxyAdvisorEngagementsMeetingRequests;
