import { 
  BORDER_AQTION_PINK, BORDER_DARK_BLUE, BORDER_LIGHTER_GRAY, BORDER_LIGHT_BLUE, BORDER_LIGHT_GRAY, 
  BORDER_WHITE, COLOR_AQTION_PINK, COLOR_BLACK, COLOR_DARK_BLUE, COLOR_LIGHTER_GRAY, COLOR_LIGHT_BLUE, 
  COLOR_LIGHT_GRAY, COLOR_MEDIUM_GRAY, COLOR_WHITE, FONT_MONTSERRAT_REGULAR, FONT_MONTSERRAT_SEMI_BOLD, 
  LINE_BREAK, convertTextFromTopicCommentHTML, generateFooter, generateHeader, generateSection 
} from './commons';

import { saveAs } from 'file-saver';
import { 
  AlignmentType, Document, Packer, Paragraph, ShadingType, Table, TableCell, TableRow, TextRun,
  VerticalAlign, WidthType 
} from 'docx';


export const generateTopicCommentsDocument = async (topicMainCategory, topicSubCategory, question, investorComments, proxyAdvisorComments) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const now = new Date();
  const publicationDate = `${now.getDate()} ${months[now.getMonth()]} ${now.getFullYear()}`;

  const header = await generateHeader('esg-topics-memo');
  const footer = generateFooter(`${topicMainCategory} / ${topicSubCategory}`);

  const publicationDateText = generatePublicationDateText(publicationDate);
  const topicHeaderTitle = generateTopicTitle(topicMainCategory, topicSubCategory);

  const questionTable = generateQuestionTable(question);

  const proxyAdvisorsTable = generateCommentsTable('Proxy Advisor', proxyAdvisorComments);
  const investorsTable = generateCommentsTable('Investor', investorComments);


  const publicationDateSection = generateSection(header, footer, [publicationDateText, LINE_BREAK]);
  const fileTitleSection = generateSection(header, footer, [topicHeaderTitle, LINE_BREAK, LINE_BREAK]);
  const questionSection = generateSection(header, footer, [questionTable, LINE_BREAK, LINE_BREAK]);
  const proxyAdvisorCommentsSection = generateSection(header, footer, [proxyAdvisorsTable, LINE_BREAK, LINE_BREAK]);
  const investorCommentsSection = generateSection(header, footer, [investorsTable]);

  const doc = new Document({
    creator: 'AQTION',
    description: 'AQTION Topic Comments',
    title: 'AQTION Topic Comments',
    sections: [
      publicationDateSection, fileTitleSection, questionSection, proxyAdvisorCommentsSection, investorCommentsSection
    ]
  });
  
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `AQTION – ESG Topics_[${topicMainCategory}]-[${topicSubCategory}].docx`);
  });
}

const generatePublicationDateText = (publicationDate) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: `Publication date: ${publicationDate}`,
        color: COLOR_AQTION_PINK,
        font: FONT_MONTSERRAT_REGULAR,
        size: 16
      })
    ]
  })
}

const generateTopicTitle = (topicMainCategory, topicSubCategory) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: `${topicMainCategory} / ${topicSubCategory}`,
        color: COLOR_BLACK,
        font: FONT_MONTSERRAT_SEMI_BOLD,
        size: 36
      })
    ]
  })
}

const generateQuestionTable = (question) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    margins: {
      top: 80,
      right: 0,
      bottom: 80,
      left: 80
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 7,
              type: WidthType.PERCENTAGE
            },
            margins: {
              top: 0,
              right: 80,
              bottom: 0,
              left: 0
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_AQTION_PINK,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: 'Q',
                    color: COLOR_WHITE,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 36
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_BLUE,
              right: BORDER_WHITE,
              bottom: BORDER_AQTION_PINK,
              left: BORDER_DARK_BLUE
            }
          }),
          new TableCell({
            width: {
              size: 93,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_AQTION_PINK,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: question['text'],
                    color: COLOR_WHITE,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 22
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_BLUE,
              right: BORDER_DARK_BLUE,
              bottom: BORDER_AQTION_PINK,
              left: BORDER_WHITE
            }
          }),
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 2,
            shading: {
              color: COLOR_LIGHT_GRAY,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: 'Description',
                    color: COLOR_BLACK,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 16
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_LIGHT_GRAY,
              right: BORDER_DARK_BLUE,
              bottom: BORDER_LIGHT_GRAY,
              left: BORDER_DARK_BLUE
            }
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 2,
            shading: {
              color: COLOR_LIGHTER_GRAY,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text:  question['description'] !== '' ? question['description'] : '-',
                    color: COLOR_MEDIUM_GRAY,
                    font: FONT_MONTSERRAT_REGULAR,
                    size: 18
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_LIGHTER_GRAY,
              right: BORDER_DARK_BLUE,
              bottom: BORDER_DARK_BLUE,
              left: BORDER_DARK_BLUE
            }
          })
        ]
      })
    ]
  });
}

const generateCommentsTable = (investorType, comments) => {
  const commentItems = comments.map(comment => {
    return (
      [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE
              },
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2,
              shading: {
                color: COLOR_DARK_BLUE,
                type: ShadingType.SOLID,
              },
              children: [
                new Paragraph({
                  spacing: {
                    line: 276
                  },
                  children: [
                    new TextRun({
                      text: comment['investor_name'],
                      color: COLOR_WHITE,
                      font: FONT_MONTSERRAT_SEMI_BOLD,
                      size: 18
                    })
                  ]
                })
              ],
              borders: {
                top: BORDER_DARK_BLUE,
                right: BORDER_DARK_BLUE,
                bottom: BORDER_DARK_BLUE,
                left: BORDER_DARK_BLUE
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE
              },
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2,
              shading: {
                color: COLOR_WHITE,
                type: ShadingType.SOLID,
              },
              children: convertTextFromTopicCommentHTML(comment['question_external_comment']),
              borders: {
                top: BORDER_DARK_BLUE,
                right: BORDER_DARK_BLUE,
                bottom: BORDER_DARK_BLUE,
                left: BORDER_DARK_BLUE
              }
            })
          ]
        })
      ]
    )
  }).flat();

  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    margins: {
      top: 80,
      right: 0,
      bottom: 80,
      left: 80
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 92,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_LIGHT_BLUE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: `${investorType} Commentary`,
                    color: COLOR_WHITE,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 22
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_BLUE,
              right: BORDER_WHITE,
              bottom: BORDER_LIGHT_BLUE,
              left: BORDER_DARK_BLUE
            }
          }),
          new TableCell({
            width: {
              size: 8,
              type: WidthType.PERCENTAGE
            },
            margins: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 80
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_LIGHT_BLUE,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${comments.length}`,
                    color: COLOR_WHITE,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 22
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_BLUE,
              right: BORDER_DARK_BLUE,
              bottom: BORDER_LIGHT_BLUE,
              left: BORDER_WHITE
            }
          }),
        ]
      })
    ].concat(commentItems)
  });
}