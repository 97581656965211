import { useCallback, useEffect, useState } from 'react';
import { clientUserDepartmentDropdownItems, clientUserTypeDropdownItems } from '../../../../utils/constants';
import { createId, getValueFromDropdown, getValueFromTextInput } from '../../../../utils/helpers';
import { generateClientSetupUserTableItems } from '../../../../utils/account-manager-helper';

import Button from '../../../../components/UI/Button/Button';
import Table from '../../../../components/UI/Table/Table';
import Modal from '../../../../components/UI/Modal/Modal';

import styles from './ClientSetupUserManagement.module.css';


const ClientSetupUserManagement = (props) => {
  const [tableItems, setTableItems] = useState('');
  const [userManagementWarning, setUserManagementWarning] = useState(false);
  const [isValidationAvailable, setIsValidationAvailable] = useState(false);

  const [pagePreference, setPagePreference] = useState('next');

  const propsNumberOfUsers = props.numberOfUsers;
  const propsUserList = props.userList;

  const hasErrorStyle = propsNumberOfUsers < 1 ? styles['has-error'] : '';

  const createUserRow = useCallback((firstname, lastname, email, department, jobTitle, userType, deleteRowFunction) => {
    const newItemId = `client-user-${createId(4)}`;
    return {
      'id': newItemId,
      'columns': [
        {
          'id': `${newItemId}#firstname`,
          'type': 'text-input',
          'placeholder': 'Enter name',
          'value': firstname,
          'width': 128,
          'stretch': true
        },
        {
          'id': `${newItemId}#lastname`,
          'type': 'text-input',
          'placeholder': 'Enter surname',
          'value': lastname,
          'width': 128,
          'stretch': true
        },
        {
          'id': `${newItemId}#email`,
          'type': 'text-input',
          'placeholder': 'Enter email',
          'value': email,
          'width': 172,
          'stretch': true
        },
        {
          'id': `${newItemId}#department`,
          'type': 'dropdown',
          'placeholder': 'Department',
          'selected': department,
          'value': department,
          'width': 172,
          'items': clientUserDepartmentDropdownItems,
          'stretch': true
        },
        {
          'id': `${newItemId}#job-title`,
          'type': 'text-input',
          'placeholder': 'Enter job title',
          'value': jobTitle,
          'width': 128,
          'stretch': true
        },
        {
          'id': `${newItemId}#user-type`,
          'type': 'dropdown',
          'placeholder': 'Select User Type',
          'selected': userType,
          'value': userType,
          'width': 128,
          'items': clientUserTypeDropdownItems,
          'stretch': true
        },
        {
          'id': `${newItemId}#delete-button`,
          'icon': 'trash',
          'type': 'icon-button',
          'onClick': deleteRowFunction
        }
      ]
    };
  }, []);


  const updateUserTableItems = useCallback((tempTableItems) => {
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const firstname = getValueFromTextInput(`${itemId}#firstname`);
        const lastname = getValueFromTextInput(`${itemId}#lastname`);
        const email = getValueFromTextInput(`${itemId}#email`);
        const department = getValueFromDropdown(`${itemId}#department`);
        const jobTitle = getValueFromTextInput(`${itemId}#job-title`);
        const userType = getValueFromDropdown(`${itemId}#user-type`);
  
        item['columns'][0]['value'] = firstname;
        item['columns'][1]['value'] = lastname;
        item['columns'][2]['value'] = email;
        item['columns'][3]['selected'] = department;
        item['columns'][3]['value'] = department;
        item['columns'][4]['value'] = jobTitle;
        item['columns'][5]['selected'] = userType;
        item['columns'][5]['value'] = userType;
      });
    }
    return tempTableItems['middle']['body']['items'];
  }, []);


  const isItemsValid = useCallback((tempTableItems) => {
    let isValid = true;
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const firstname = getValueFromTextInput(`${itemId}#firstname`);
        const lastname = getValueFromTextInput(`${itemId}#lastname`);
        const email = getValueFromTextInput(`${itemId}#email`);
        const department = getValueFromDropdown(`${itemId}#department`);
        const jobTitle = getValueFromTextInput(`${itemId}#job-title`);
        const userType = getValueFromDropdown(`${itemId}#user-type`);

        if(firstname === '') {
          document.getElementById(`${itemId}#firstname`).style.border = '1px solid var(--color-danger-700)';
        }
        if(lastname === '') {
          document.getElementById(`${itemId}#lastname`).style.border = '1px solid var(--color-danger-700)';
        }
        if(email === '') {
          document.getElementById(`${itemId}#email`).style.border = '1px solid var(--color-danger-700)';
        }
        if(document.getElementById(`${itemId}#department`).dataset.selected === '') {
          document.getElementById(`${itemId}#department`).style.border = '1px solid var(--color-danger-700)';
        }
        if(jobTitle === '') {
          document.getElementById(`${itemId}#job-title`).style.border = '1px solid var(--color-danger-700)';
        }
        if(document.getElementById(`${itemId}#user-type`).dataset.selected === '') {
          document.getElementById(`${itemId}#user-type`).style.border = '1px solid var(--color-danger-700)';
        }
        
        isValid = isValid && (firstname !== '' && lastname !== '' && email !== '' && department !== '' && jobTitle !== '' && userType !== '');
      });
    }
    return isValid;
  }, []);


  const fetchUserData = useCallback((tempTableItems) => {
    const data = [];    
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const firstname = getValueFromTextInput(`${itemId}#firstname`);
        const lastname = getValueFromTextInput(`${itemId}#lastname`);
        const email = getValueFromTextInput(`${itemId}#email`);
        const department = getValueFromDropdown(`${itemId}#department`);
        const jobTitle = getValueFromTextInput(`${itemId}#job-title`);
        const userType = getValueFromDropdown(`${itemId}#user-type`);
        
        data.push({
          'firstname': firstname,
          'lastname': lastname,
          'email': email,
          'department': department,
          'job_title': jobTitle,
          'user_type': userType
        });
      });
    }

    return data;
  }, []);


  const addNewUserRowHandler = useCallback((createUserRow, removeUserRowHandler) => () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateUserTableItems(newTableItems);
      if(newTableItems['middle']['body']['items'].length >= propsNumberOfUsers) {
        return newTableItems;
      }

      newTableItems['middle']['body']['items'].push(
        createUserRow('', '', '', '', '', 'View Only', removeUserRowHandler)
      );    
      return newTableItems;
    });
    setIsValidationAvailable(false);
  }, [propsNumberOfUsers, updateUserTableItems]);

  
  const removeUserRowHandler = useCallback((itemId) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateUserTableItems(newTableItems);

      const itemIndex = newTableItems['middle']['body']['items'].findIndex(item => item['id'] === itemId.split('#')[0]);
      if (itemIndex > -1) {
        newTableItems['middle']['body']['items'].splice(itemIndex, 1);
      }
      return newTableItems;
    });
  }, [updateUserTableItems]);


  const saveUserList = (pageDirectedTo) => {
    const data = fetchUserData(tableItems);
    props.onNextClick(data, pageDirectedTo);
  }

  const saveWithValidationHandler = (clickedButton) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateUserTableItems(newTableItems);
      return newTableItems;
    });
    if(!isItemsValid(tableItems)) {
      setIsValidationAvailable(true);
      setUserManagementWarning(true);
      setPagePreference(clickedButton);
    }
    else {
      setIsValidationAvailable(false);
      saveUserList(clickedButton);
    }
  }


  useEffect(() => {
    if(isValidationAvailable && tableItems !== '') {
      isItemsValid(tableItems);
    }
  }, [isValidationAvailable, userManagementWarning, tableItems, isItemsValid]);


  useEffect(() => {
    const newTableItems = generateClientSetupUserTableItems(addNewUserRowHandler(createUserRow, removeUserRowHandler));
    propsUserList.forEach(item => {
      newTableItems['middle']['body']['items'].push(
        createUserRow(item['firstname'], item['lastname'], item['email'], item['department'], item['job_title'], item['user_type'], removeUserRowHandler)
      );
    });
    setTableItems(newTableItems);
  }, [propsUserList, createUserRow, removeUserRowHandler, addNewUserRowHandler]);


  useEffect(() => {
    const buttonElement = document.getElementById('client-user-list-add-button');
    if(buttonElement !== null) {
      if(tableItems !== '' && tableItems['middle']['body']['items'].length === propsNumberOfUsers) {
        buttonElement.disabled = true;
      }
      else {
        buttonElement.disabled = false;
      }
    }
  }, [tableItems, propsNumberOfUsers]);


  return (
    <div className={styles['client-setup-user-management-step']}>
      { userManagementWarning && 
        <Modal
          type='warning'
          title='Missing Field Error'
          message='One or several fields are missing. Please review and fill in missing information to be able to complete User Management set-up.'
          onCloseClick={() => setUserManagementWarning(false)}
          approveButton = {{
            'text': 'Continue',
            'onClick': () => saveUserList(pagePreference)
          }}
          cancelButton = {{
            'text': 'Cancel',
            'onClick': () => setUserManagementWarning(false)
          }}
        />
      }

      { tableItems !== '' &&
        <div className={`${styles['table-container']} ${hasErrorStyle}`}>
          <Table width={props.pageWidth} items={tableItems} />
        </div>
      }

      { propsNumberOfUsers < 1 && 
        <div className={styles['missing-data-error']}>
          <div className={styles['error-box']}>
            <div className={styles['title']}>
              <div className={`${styles['icon']} ${styles['lock']}`}></div>
              <h3>Adding User is not available</h3>
            </div>
            <div>
              <p>You need to specify Number of Users in Subscription Details section to be able to start adding Users.</p>
            </div>
          </div>
        </div>
      }

      <div className={styles['action-buttons-container']} style={{'width': props.pageWidth}}>
        <div className={styles['left-buttons']}>
          <Button type={'cancel'} size={'base'} leftIcon={'arrow-left'} onClick={() => saveWithValidationHandler('prev')}>Previous</Button>
        </div>
        <div className={styles['right-buttons']}>
          <Button type={'cancel'} size={'base'} onClick={() => saveWithValidationHandler('quit')}>Save & Quit</Button>
          <Button type={'primary'} size={'base'} rightIcon={'arrow-right'} onClick={() => saveWithValidationHandler('next')}>Next</Button>
        </div>
      </div>
    </div>
  );
}

export default ClientSetupUserManagement;
