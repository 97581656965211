import { Fragment } from "react";
import ReactCountryFlag from 'react-country-flag';

import Plot from "react-plotly.js";

import styles from './DistributionMap.module.css';
import { hasFlagEmojiSupport } from "../../../utils/emoji-support";

const DistributionMap = (props) => {
  const generateInfoBox = () => {
    return props.data.topInvestors.map((item, index) => {
      return (
        <div key={`info-box-country-${item['countryCode']}`} className={styles['item']}>
          <div className={styles['text']}>
            <h4>{`${index + 1}. ${item['name']}`}</h4>
          </div>
          <div className={styles['icon']}>
            { hasFlagEmojiSupport() &&
              <ReactCountryFlag
                countryCode={item['countryCode']}
              />
            }
            { !hasFlagEmojiSupport() &&
              <div className={styles['not-emoji']}>
                <ReactCountryFlag
                  countryCode={item['countryCode']}
                  svg
                  style={{
                    width: 16,
                    height: 12,
                    float: 'left'
                  }}
                />
              </div>
            }
          </div>
        </div>
      );
    });
  }

  return (
    <Fragment>
      <div className={styles['info-box']}>
        {generateInfoBox()}
      </div>
      <div className={styles['graph']}>
        <Plot
          data={[{
            type: 'choropleth',
            locations: props.data.locations,
            z: props.data.values,
            text: props.data.names,
            colorscale: [
              [0, 'rgb(225, 230, 239)'], [1, 'rgb(255, 89, 89)']
            ],
            marker: {
              line: {
                color: 'rgb(180,180,180)',
                width: 0.5
              }
            },
            showscale: false,
            showlegend: false,
            autocolorscale: false,
            reversescale: false
          }]}
          layout={{
            autosize: false,
            width: props.width,
            height: props.height,
            margin: { l: 0, r: 0, t: 0, b: 0, autoexpand: false },
            plot_bgcolor: "rgba(0,0,0,0)",
            paper_bgcolor: "rgba(0,0,0,0)",
            hovermode: false,
            geo: {
              showframe: false,
              showcoastlines: false,
              projection: {
                type: 'patterson',
                scale: 1
              }
            }
          }}
          config={{
            showLink: false,
            staticPlot: true
          }}
        />
      </div>
    </Fragment>
  );
}

export default DistributionMap;
