import AqtionLogoVertical from '../../assets/report/aqtion-logo-vertical.png';
import MeetingNoteVerticalText from '../../assets/report/meeting-note-vertical-text.png';
import OtherNoteVerticalText from '../../assets/report/other-note-vertical-text.png';
import ESGTopicsMemoVerticalText from '../../assets/report/esg-topics-memo-vertical-text.png';

import { 
  AlignmentType, BorderStyle, ExternalHyperlink, Footer, FrameAnchorType, Header, HorizontalPositionAlign, ImageRun, PageNumber, 
  Paragraph, SectionType, ShadingType, TextRun, VerticalPositionAlign 
} from 'docx';

export const FONT_MONTSERRAT_REGULAR = 'Montserrat';
export const FONT_MONTSERRAT_SEMI_BOLD = 'Montserrat SemiBold';

export const COLOR_BLACK = '000000';
export const COLOR_WHITE = 'FFFFFF';
export const COLOR_DARK_BLUE = '00263D';
export const COLOR_LIGHT_BLUE = '1396C3';
export const COLOR_DARK_GRAY = 'A3A3A3';
export const COLOR_LIGHT_GRAY = 'CDCDCD';
export const COLOR_LIGHTER_GRAY = 'EAEAEA';
export const COLOR_MEDIUM_GRAY = '646464';
export const COLOR_AQTION_PINK = 'FF5959';

export const BORDER_NONE = {
  style: BorderStyle.NONE,
  size: 0,
  color: COLOR_WHITE
};

export const BORDER_WHITE = {
  style: BorderStyle.SINGLE,
  size: 8,
  color: COLOR_WHITE
};

export const BORDER_DARK_BLUE = {
  style: BorderStyle.SINGLE,
  size: 8,
  color: COLOR_DARK_BLUE
};

export const BORDER_LIGHT_BLUE = {
  style: BorderStyle.SINGLE,
  size: 8,
  color: COLOR_LIGHT_BLUE
}

export const BORDER_DARK_GRAY = {
  style: BorderStyle.SINGLE,
  size: 8,
  color: COLOR_DARK_GRAY
}

export const BORDER_MEDIUM_GRAY = {
  style: BorderStyle.SINGLE,
  size: 8,
  color: COLOR_MEDIUM_GRAY
}

export const BORDER_LIGHT_GRAY = {
  style: BorderStyle.SINGLE,
  size: 8,
  color: COLOR_LIGHT_GRAY
}

export const BORDER_LIGHTER_GRAY = {
  style: BorderStyle.SINGLE,
  size: 8,
  color: COLOR_LIGHTER_GRAY
}

export const BORDER_AQTION_PINK = {
  style: BorderStyle.SINGLE,
  size: 8,
  color: COLOR_AQTION_PINK
}

export const LINE_BREAK = new Paragraph({ children: [] });

export const generateHeader = async (documentType) => {
  let headerVerticalText = '';
  let headerVerticalTextWidth = '';
  let headerVerticalTextHeight = '';

  if(documentType === 'other-note') {
    headerVerticalText = await fetch(OtherNoteVerticalText);
    headerVerticalTextWidth = 11.75;
    headerVerticalTextHeight = 103.75;
  }
  else if(documentType === 'meeting-note') {
    headerVerticalText = await fetch(MeetingNoteVerticalText);
    headerVerticalTextWidth = 11.75;
    headerVerticalTextHeight = 120.50;
  }
  else if(documentType === 'esg-topics-memo') {
    headerVerticalText = await fetch(ESGTopicsMemoVerticalText);
    headerVerticalTextWidth = 11.75;
    headerVerticalTextHeight = 149.25;
  }

  const aqtionLogoVertical = await fetch(AqtionLogoVertical);

  return new Header({
    children: [
      new Paragraph({
       frame: {
         position: {
           x: -1140,
           y: -1440,
         },
         width: 665,
         height: 17000,
         anchor: {
           horizontal: FrameAnchorType.MARGIN,
           vertical: FrameAnchorType.MARGIN,
         },
         alignment: {
           x: HorizontalPositionAlign.CENTER,
           y: VerticalPositionAlign.TOP,
         }
       },
       shading: {
         type: ShadingType.SOLID,
         color: COLOR_DARK_BLUE
       },
       children: [],
     }),
     new Paragraph({
       frame: {
         position: {
           x: -900,
           y: -1000,
         },
         width: 665,
         height: 2000,
         anchor: {
           horizontal: FrameAnchorType.MARGIN,
           vertical: FrameAnchorType.MARGIN,
         },
         alignment: {
           x: HorizontalPositionAlign.CENTER,
           y: VerticalPositionAlign.TOP,
         }
       },
       children: [
         new ImageRun({
           data: await headerVerticalText.blob(),
           transformation: {
             width: headerVerticalTextWidth,
             height: headerVerticalTextHeight
           }
         })
       ]
     }),
     new Paragraph({
       frame: {
         position: {
           x: 9000,
           y: -1100,
         },
         anchor: {
           horizontal: FrameAnchorType.MARGIN,
           vertical: FrameAnchorType.MARGIN,
         },
         alignment: {
           x: HorizontalPositionAlign.CENTER,
           y: VerticalPositionAlign.TOP,
         }
       },
       children: [
         new ImageRun({
           data: await aqtionLogoVertical.blob(),
           transformation: {
             width: 79.5,
             height: 64.5
           }
         })
       ]
     })
   ]
 });
}

export const generateFooter = (footerTitle) => {
  return new Footer({
    children: [
      new Paragraph({
        frame: {
          position: {
            x: 0,
            y: 14680,
          },
          width: 3600,
          anchor: {
            horizontal: FrameAnchorType.MARGIN,
            vertical: FrameAnchorType.MARGIN,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.BOTTOM,
          }
        },
        children: [
          new TextRun({
            text: 'CONFIDENTIAL | \u00A9 Copyright AQTION',
            color: COLOR_BLACK,
            font: FONT_MONTSERRAT_REGULAR,
            size: 18
          })
        ]
      }),
      new Paragraph({
        frame: {
          position: {
            x: 10140,
            y: 14680,
          },
          width:360,
          anchor: {
            horizontal: FrameAnchorType.MARGIN,
            vertical: FrameAnchorType.MARGIN,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.BOTTOM,
          }
        },
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            children: [PageNumber.CURRENT],
            color: COLOR_BLACK,
            font: FONT_MONTSERRAT_REGULAR,
            size: 18
          })
        ]
      }),
      new Paragraph({
        frame: {
          position: {
            x: 4360,
            y: 14680,
          },
          width: 5600,
          anchor: {
            horizontal: FrameAnchorType.MARGIN,
            vertical: FrameAnchorType.MARGIN,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.BOTTOM,
          }
        },
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            text: `${footerTitle}`,
            color: COLOR_BLACK,
            font: FONT_MONTSERRAT_REGULAR,
            size: 18
          })
        ]
      }),
      new Paragraph({
        frame: {
          position: {
            x: 10140,
            y: 14620,
          },
          width: 8,
          anchor: {
            horizontal: FrameAnchorType.MARGIN,
            vertical: FrameAnchorType.MARGIN,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.BOTTOM,
          }
        },
        shading: {
          type: ShadingType.SOLID,
          color: COLOR_BLACK
        },
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            text: '|',
            color: COLOR_WHITE,
            font: FONT_MONTSERRAT_REGULAR,
            size: 30
          })
        ]
      })
    ]
  });
}

export const generateSection = (header, footer, sections) => {
  return {
    properties: {
      type: SectionType.CONTINUOUS,
      page: {
        margin: {
          top: 1440,
          right: 560,
          bottom: 850,
          left: 1140,
        },
      },
    },
    children: sections,
    headers: {
      default: header
    },
    footers: {
      default: footer
    },
  };
}

/* Convertors to convert html to word format. */

let htmlParagraphIndent = {
  left: 0
};

export const convertTextFromTopicCommentHTML = (htmlContent, pIndent) => {
  const htmlObject = document.createElement('div');
  htmlObject.innerHTML = htmlContent;
  
  const htmlItems = htmlObject.children;
  
  if(pIndent !== undefined && pIndent !== null){
    htmlParagraphIndent = pIndent;
  }
  else{
    htmlParagraphIndent = {
      left: 0
    };
  }

  let paragraphItems = [];
  for(let i=0; i<htmlItems.length; i++){
    if(htmlItems[i].nodeName === "H1"){
      paragraphItems.push(createH1Element(htmlItems[i]));
    }
    else if(htmlItems[i].nodeName === "H2"){
      paragraphItems.push(createH2Element(htmlItems[i]));
    }
    else if(htmlItems[i].nodeName === "P"){
      paragraphItems.push(createPElement(htmlItems[i]));
    }
    else if(htmlItems[i].nodeName === "SPAN"){
      paragraphItems.push(createPElement(htmlItems[i]));
    }
    else if(htmlItems[i].nodeName === "UL"){
      paragraphItems = paragraphItems.concat(createULElement(htmlItems[i]));
    }
    // It should be updated for ordered list
    else if(htmlItems[i].nodeName === "OL"){
      paragraphItems = paragraphItems.concat(createULElement(htmlItems[i]));
    }
    else{
      // console.log(htmlItems[i].nodeName);
    }
  }

  return paragraphItems;
}

const createH1Element = (htmlItem) => {
  const textAttributes = {
    'bold': true,
    'italics': false,
    'underline': null,
    'color': '000000',
    'size': 28
  };
  const textItems = createTextElement(htmlItem.childNodes, textAttributes);
  const h1Item = new Paragraph({
    spacing: {
      line: 276
    },
    indent: htmlParagraphIndent,
    children: textItems
  });
  return h1Item;
}

const createH2Element = (htmlItem) => {
  const textAttributes = {
    'bold': true,
    'italics': false,
    'underline': null,
    'color': '000000',
    'size': 24
  };
  const textItems = createTextElement(htmlItem.childNodes, textAttributes);
  const h2Item = new Paragraph({
    spacing: {
      line: 276
    },
    indent: htmlParagraphIndent,
    children: textItems
  });
  return h2Item;
}

const createPElement = (htmlItem) => {
  const textAttributes = {
    'bold': false,
    'italics': false,
    'underline': null,
    'color': '000000',
    'size': 18
  };
  const textItems = createTextElement(htmlItem.childNodes, textAttributes);
  const pItem = new Paragraph({
    spacing: {
      line: 276
    },
    indent: htmlParagraphIndent,
    children: textItems
  });
  return pItem;
}

const createULElement = (htmlItem) => {
  const textAttributes = {
    'bold': false,
    'italics': false,
    'underline': null,
    'color': '000000',
    'size': 18
  };

  const ulItem = [];
  for(let i=0; i<htmlItem.childNodes.length; i++){
    ulItem.push(new Paragraph({
      spacing: {
        line: 276
      },
      bullet: {
        level: 0
      },
      children: createTextElement(htmlItem.childNodes[i].childNodes, textAttributes)
    }));
  }

  return ulItem;
}

const createTextElement = (htmlItems, textAttributes) => {
  let textItems = [];

  for(let i=0; i<htmlItems.length; i++){
    let childTextAttributes = {};
    Object.assign(childTextAttributes, textAttributes);

    let htmlItem = htmlItems[i];
    if(htmlItem.nodeName === '#text'){
      const textItem = new TextRun({
        text: htmlItem.nodeValue,
        bold: textAttributes['bold'],
        italics: textAttributes['italics'],
        underline: textAttributes['underline'],
        color: textAttributes['color'],
        font: FONT_MONTSERRAT_REGULAR,
        size: textAttributes['size']
      });
      textItems.push(textItem);
    }
    else if(htmlItem.nodeName === 'STRONG'){
      childTextAttributes['bold'] = true;
      textItems = textItems.concat(createTextElement(htmlItem.childNodes, childTextAttributes));
    }
    else if(htmlItem.nodeName === 'EM'){
      childTextAttributes['italics'] = true;
      textItems = textItems.concat(createTextElement(htmlItem.childNodes, childTextAttributes));
    }
    else if(htmlItem.nodeName === 'U'){
      childTextAttributes['underline'] = {
        type: 'single',
        color: textAttributes['color']
      };
      textItems = textItems.concat(createTextElement(htmlItem.childNodes, childTextAttributes));
    }
    else if(htmlItem.nodeName === 'SPAN'){
      textItems = textItems.concat(createTextElement(htmlItem.childNodes, childTextAttributes));
    }
    else if(htmlItem.nodeName === 'P'){
      textItems = textItems.concat(createTextElement(htmlItem.childNodes, childTextAttributes));
    }
    else if(htmlItem.nodeName === 'BR'){
      const textItem = new TextRun({
        text: '',
        color: textAttributes['color'],
        font: FONT_MONTSERRAT_REGULAR,
        size: textAttributes['size']
      });
      textItems.push(textItem);
    }
    else if(htmlItem.nodeName === 'A'){
      const textItem = new ExternalHyperlink({
        children: [
          new TextRun({
            text: htmlItem.innerText,
            bold: textAttributes['bold'],
            italics: textAttributes['italics'],
            font: FONT_MONTSERRAT_REGULAR,
            size: textAttributes['size'],
            style: 'Hyperlink'
          }),
        ],
        link: htmlItem.href
      });
      textItems.push(textItem);
    }
  }

  return textItems;
}