import { 
  BORDER_AQTION_PINK, BORDER_DARK_BLUE, BORDER_LIGHTER_GRAY, BORDER_LIGHT_GRAY, BORDER_NONE, BORDER_WHITE, COLOR_AQTION_PINK, COLOR_BLACK, 
  COLOR_DARK_BLUE, COLOR_LIGHTER_GRAY, COLOR_LIGHT_GRAY, COLOR_MEDIUM_GRAY, COLOR_WHITE, FONT_MONTSERRAT_REGULAR, 
  FONT_MONTSERRAT_SEMI_BOLD, LINE_BREAK, convertTextFromTopicCommentHTML, generateFooter, generateHeader, generateSection 
} from './commons';

import { saveAs } from 'file-saver';
import { 
  AlignmentType,
  Document, ImageRun, Packer, Paragraph, ShadingType, Table, TableCell, TableRow, TextRun,
  VerticalAlign, WidthType 
} from 'docx';


export const generateInvestorTopicsDocument = async (investorName, investorProfilePictureBlob, investorType, topicMainCategory, topicSubCategory, questionComments) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const now = new Date();
  const publicationDate = `${now.getDate()} ${months[now.getMonth()]} ${now.getFullYear()}`;

  const header = await generateHeader('esg-topics-memo');
  const footer = generateFooter(`${topicMainCategory} / ${topicSubCategory}`);

  const publicationDateText = generatePublicationDateText(publicationDate);

  const investorLogoImage = await generateInvestorLogoImage(investorProfilePictureBlob);
  const investorNameAndHeaderTitle = generateInvestorNameTitle(investorName, topicMainCategory, topicSubCategory);

  const questionTable = questionComments.map(question => {
    return [
      generateQuestionTable(question, investorType), LINE_BREAK, LINE_BREAK
    ]
  }).flat();


  const publicationDateSection = generateSection(header, footer, [publicationDateText, LINE_BREAK]);
  const investorLogoSection = generateSection(header, footer, [investorLogoImage]);
  const fileTitleSection = generateSection(header, footer, [investorNameAndHeaderTitle, LINE_BREAK]);
  const questionSection = generateSection(header, footer, questionTable);

  const doc = new Document({
    creator: 'AQTION',
    description: 'AQTION Investor Topics',
    title: 'AQTION Investor Topics',
    sections: [
      publicationDateSection, investorLogoSection, fileTitleSection, questionSection
    ]
  });
  
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `AQTION – ESG Topics - ${investorName}_[${topicMainCategory}]-[${topicSubCategory}].docx`);
  });
}

const generatePublicationDateText = (publicationDate) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: `Publication date: ${publicationDate}`,
        color: COLOR_AQTION_PINK,
        font: FONT_MONTSERRAT_REGULAR,
        size: 16
      })
    ]
  })
}

const generateInvestorLogoImage = async (investorProfilePictureBlob) => {
  return new Paragraph({
    children: [
      new ImageRun({
        data: await investorProfilePictureBlob,
        transformation: {
          width: 88,
          height: 88
        }
      })
    ]
  })
}

const generateInvestorNameTitle = (investorName, topicMainCategory, topicSubCategory) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: `${investorName} – ${topicMainCategory} / ${topicSubCategory}`,
        color: COLOR_BLACK,
        font: FONT_MONTSERRAT_SEMI_BOLD,
        size: 36
      })
    ]
  })
}

const generateQuestionTable = (question, investorType) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE
    },
    margins: {
      top: 80,
      right: 0,
      bottom: 80,
      left: 80
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 7,
              type: WidthType.PERCENTAGE
            },
            margins: {
              top: 0,
              right: 80,
              bottom: 0,
              left: 0
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_AQTION_PINK,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: 'Q',
                    color: COLOR_WHITE,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 36
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_BLUE,
              right: BORDER_WHITE,
              bottom: BORDER_AQTION_PINK,
              left: BORDER_DARK_BLUE
            }
          }),
          new TableCell({
            width: {
              size: 93,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              color: COLOR_AQTION_PINK,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: question['text'],
                    color: COLOR_WHITE,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 22
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_BLUE,
              right: BORDER_DARK_BLUE,
              bottom: BORDER_AQTION_PINK,
              left: BORDER_WHITE
            }
          }),
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 2,
            shading: {
              color: COLOR_LIGHT_GRAY,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: 'Description',
                    color: COLOR_BLACK,
                    font: FONT_MONTSERRAT_SEMI_BOLD,
                    size: 16
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_LIGHT_GRAY,
              right: BORDER_DARK_BLUE,
              bottom: BORDER_LIGHT_GRAY,
              left: BORDER_DARK_BLUE
            }
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE
            },
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 2,
            shading: {
              color: COLOR_LIGHTER_GRAY,
              type: ShadingType.SOLID,
            },
            children: [
              new Paragraph({
                spacing: {
                  line: 276
                },
                children: [
                  new TextRun({
                    text: question['description'] !== '' ? question['description'] : '-',
                    color: COLOR_MEDIUM_GRAY,
                    font: FONT_MONTSERRAT_REGULAR,
                    size: 18
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_LIGHTER_GRAY,
              right: BORDER_DARK_BLUE,
              bottom: BORDER_LIGHTER_GRAY,
              left: BORDER_DARK_BLUE
            }
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE
            },
            margins: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            },
            verticalAlign: VerticalAlign.TOP,
            columnSpan: 2,
            shading: {
              color: COLOR_DARK_BLUE,
              type: ShadingType.SOLID,
            },
            children: [
              new Table({
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE
                },
                margins: {
                  top: 80,
                  right: 0,
                  bottom: 80,
                  left: 80
                },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 28,
                          type: WidthType.PERCENTAGE
                        },
                        verticalAlign: VerticalAlign.TOP,
                        shading: {
                          color: COLOR_DARK_BLUE,
                          type: ShadingType.SOLID,
                        },
                        children: [
                          new Paragraph({
                            spacing: {
                              line: 276
                            },
                            children: [
                              new TextRun({
                                text: investorType === 'investor' ? 'Investor Commentary' : 'Proxy Advisor Commentary',
                                color: COLOR_WHITE,
                                font: FONT_MONTSERRAT_SEMI_BOLD,
                                size: 18
                              })
                            ]
                          })
                        ],
                        borders: {
                          top: BORDER_NONE,
                          right: BORDER_NONE,
                          bottom: BORDER_NONE,
                          left: BORDER_NONE
                        }
                      }),
                      new TableCell({
                        width: {
                          size: 72,
                          type: WidthType.PERCENTAGE
                        },
                        verticalAlign: VerticalAlign.TOP,
                        shading: {
                          color: COLOR_WHITE,
                          type: ShadingType.SOLID,
                        },
                        children: convertTextFromTopicCommentHTML(question['comment']),
                        borders: {
                          top: BORDER_NONE,
                          right: BORDER_NONE,
                          bottom: BORDER_NONE,
                          left: BORDER_NONE
                        }
                      }),
                    ]
                  })
                ]
              })
            ],
            borders: {
              top: BORDER_DARK_BLUE,
              right: BORDER_DARK_BLUE,
              bottom: BORDER_DARK_BLUE,
              left: BORDER_DARK_BLUE
            }
          })
        ]
      })
    ]
  });
}