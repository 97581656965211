import styles from './TextInput.module.css';

const TextInput = (props) => {
  const isDisabled = props.isDisabled ? true : false;
  const sizeStyle = props.size === undefined ? styles['base'] : styles[props.size];
  const errorStyle = props.hasError ? styles['error'] : '';

  const inputChangeHandler = (event) => {
    if (props.onChange !== undefined) {
      props.onChange(event.target.value);
    }
  }

  const inputBlurHandler = (event) => {
    if (props.onBlur !== undefined) {
      props.onBlur(event.target.value);
    }
  }

  const customStyle = {};
  if (props.width !== undefined) {
    customStyle['width'] = props.width
  }

  return(
    <div className={styles['input-container']} style={customStyle}>
      { props.label && <label className={styles['input-label']}>{props.label}</label> }
      <input
        id={props.id}
        className={`${styles['text-input']} ${sizeStyle} ${errorStyle}`}
        placeholder={props.placeholder}
        value={props.value}
        disabled={isDisabled}
        onBlur={inputBlurHandler}
        onChange={inputChangeHandler}
        data-is-valid={props.isValid ? props.isValid : true}
        tabIndex={props.tabIndex}
      />

      { props.warningText && 
        <div className={styles['error-message-container']}>
          <div className={styles['warning-icon']}></div>
          <p>{props.warningText}</p>
        </div>
      }
    </div>
  );
}

export default TextInput
