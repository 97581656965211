import { useEffect, useState } from 'react';
import { fetchClientEngagementsMeetingRequestRequest, fetchClientEngagementsShareholderCommunicationRequest } from '../../../requests/client-engagement-requests';
import { getValueFromVariable } from '../../../utils/helpers';
import { getAuthClientId } from '../../../utils/auth';
import { handleRequestError } from '../../../utils/request-error-helpers';
import useHttp from '../../../hooks/use-http';
import parse from 'html-react-parser';

import Button from '../../UI/Button/Button';

import styles from './EngagementDraftEmailModal.module.css';

const EngagementDraftEmailModal = (props) => {
  
  const clientId = getAuthClientId();
  const meetingRequestId = props.meetingRequestId;
  const shareholderCommunicationId = props.shareholderCommunicationId;

  const [isLoading, error, sendRequest] = useHttp();

  const [meetingRequestDetailsData, setMeetingRequestDetailsData] = useState([]);

  useEffect(() => {
    if(meetingRequestId) {
      sendRequest(
        fetchClientEngagementsMeetingRequestRequest(clientId, meetingRequestId),
        (data) => { 
          setMeetingRequestDetailsData(data);
        }
      );
    }
    if(shareholderCommunicationId) {
      sendRequest(
        fetchClientEngagementsShareholderCommunicationRequest(clientId, shareholderCommunicationId),
        (data) => { 
          setMeetingRequestDetailsData(data);
        }
      );
    }
  }, [sendRequest, clientId, meetingRequestId, shareholderCommunicationId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  return (
    <div className={styles['modal-container']}>
      <div className={styles['overlay']}></div>
      <div className={styles['content']}>
        <div className={styles['header']}>
          <div className={styles['title']}>
            <h2>{getValueFromVariable(meetingRequestDetailsData['engagement_name'])}</h2>
          </div>
          <div className={styles['type-chip']}>
            <div className={`${styles['icon']} ${styles['meeting-request']}`}></div>
            <div className={styles['label']}>
              <p>Meeting Request</p>
            </div>
          </div>
          <div className={styles['close-button']} onClick={props.onCloseClick}>
            <div className={styles['close-icon']}></div>
          </div>
          <div className={styles['history']}>
            <div className={styles['creation-info']}>
            { meetingRequestDetailsData['created_by'] !== null && meetingRequestDetailsData['created_by'] !== undefined &&
                <p>
                  Created by <strong>{getValueFromVariable(meetingRequestDetailsData['created_by'])}</strong> on
                  <strong> {getValueFromVariable(meetingRequestDetailsData['created_at'], 'date').toLocaleDateString('en-GB')}</strong>
                </p>
              }
            </div>
            <div className={styles['separator']}></div>
            <div className={styles['update-info']}>
              { (meetingRequestDetailsData['updated_by'] === null || meetingRequestDetailsData['updated_by'] === undefined) &&
                <p>Not yet edited</p>
              }
              { meetingRequestDetailsData['updated_by'] !== null && meetingRequestDetailsData['updated_by'] !== undefined &&
                <p>
                  Updated by <strong>{getValueFromVariable(meetingRequestDetailsData['updated_by'])}</strong> on
                  <strong> {getValueFromVariable(meetingRequestDetailsData['updated_at'], 'date').toLocaleDateString('en-GB')}</strong>
                </p>
              }
            </div>
          </div>
        </div>

        <div className={styles['body']}>
          <div className={styles['email-subject']}>
            <h4>{getValueFromVariable(meetingRequestDetailsData['draft_email_subject'])}</h4>
          </div>
          <div className={styles['email-body']}>
          {parse(getValueFromVariable(meetingRequestDetailsData['draft_email_body']))}
          </div>
        </div>

        <div className={styles['footer']}>
          <Button
            className={styles['modal-button']}
            type={'primary'}
            size={'base'}
            leftIcon={'edit'}
            onClick={props.onEditEngagement}
          >
            Edit Engagement
          </Button>
          <Button
            className={styles['modal-button']}
            type={'cancel'}
            size={'base'}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EngagementDraftEmailModal;
