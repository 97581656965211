import { Fragment } from 'react';
import styles from './Textarea.module.css';

const Textarea = (props) => {
  const isDisabled = props.isDisabled ? true : false;
  const errorStyle = props.hasError ? styles['error'] : '';

  const textareaChangeHandler = (event) => {
    if (props.onChange !== undefined) {
      props.onChange(event.target.value);
    }
  }

  const textareaBlurHandler = (event) => {
    if (props.onBlur !== undefined) {
      props.onBlur(event.target.value);
    }
  }

  const customStyle = {};
  if (props.width !== undefined) {
    customStyle['width'] = props.width
  }
  if (props.width !== undefined) {
    customStyle['height'] = props.height
  }

  return(
    <Fragment>
      <div className={styles['textarea-container']} style={customStyle}>
        { props.label && <label className={styles['textarea-label']}>{props.label}</label> }
        <textarea
          className={errorStyle}
          placeholder={props.placeholder}
          disabled={isDisabled}
          onChange={textareaChangeHandler}
          onBlur={textareaBlurHandler}
          value={props.children}
        >
        </textarea>
      </div>
    </Fragment>
  );
}

export default Textarea
