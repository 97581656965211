import { numberToCommaSeperatedValue } from './helpers';

/* Client - Show Investor List Table */
export const generateClientInvestorListTableItems = (clientType, investorListItems, totalNumOfShares, sortConfig, pinClickHandler, sortFunction) => {
  const createInvestorRowLeft = (item) => {
    const investorName = item['name'];
    const isStarred = item['is_starred'];
    const isCustom = item['is_custom'];
    const investorId = item['id'];
    const rowId = `client-investor-${item['id']}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#investor-name`,
          'type': 'link-button',
          'text': investorName,
          'href': `/investors/${investorId}`,
          'hasPin': true,
          'pinIcon': 'star',
          'isPinSelected': isStarred,
          'onPinClick': pinClickHandler,
          'has_custom_chip': isCustom,
          'stretch': false
        }
      ]
    };
  }

  const createInvestorRowMiddle = (item) => {
    const levelOfInsightsColors = {
      'Strong': 'success',
      'Standard': 'warning',
      'Weak': 'danger',
      'Not Profiled': 'unknown',
      'Unknown': 'unknown'
    };

    const country = item['general_information']['country'];
    const numberOfShares = item['num_of_shares'];
    const iscPercentage = numberOfShares !== '' ? ((numberOfShares / totalNumOfShares) * 100).toFixed(2) : '-';
    const prevMeetingVotes = item['past_general_meeting_votes']['last_general_meeting_votes'];

    const investorType = item['general_information']['investor_type'];
    const levelOfInsights = item['general_information']['level_of_insight'];
    const votingDecisionMaker = item['stewardship']['voting_decision_maker'];
    const proxyAdvisors = item['stewardship']['proxy_advisors'];
    const proxyAdvisorsReliance = item['stewardship']['proxy_advisors_reliance'];
    const esgFocus = item['environment_and_social']['esg_focus'];
    const esgServiceMainProviders = item['esg_service_providers']['main_providers'];
    const activist = item['activism']['activist'];
    const receptivityToActivists = item['activism']['receptivity_to_activists'];
    
    const rowId = `client-investor-${item['id']}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#filler-left`,
          'type': 'empty-cell',
          'stretch': false
        },
        {
          'id': `${rowId}#country`,
          'type': 'default',
          'text': country,
          'stretch': true
        }
      ].concat((clientType === 'Corporate') ? [
        {
          'id': `${rowId}#number-of-shares`,
          'type': 'default',
          'text': numberToCommaSeperatedValue(numberOfShares),
          'stretch': true
        },
        {
          'id': `${rowId}#isc-percentage`,
          'type': 'default',
          'text': iscPercentage,
          'stretch': true
        },
        {
          'id': `${rowId}#previous-general-meeting-votes`,
          'type': 'default',
          'text': prevMeetingVotes,
          'stretch': true
        }
      ] : []).concat([
        {
          'id': `${rowId}#investor-type`,
          'type': 'default',
          'text': investorType,
          'stretch': true
        },
        {
          'id': `${rowId}#level-of-insights`,
          'type': 'traffic-light',
          'color_code': levelOfInsightsColors[levelOfInsights],
          'text': levelOfInsights,
          'stretch': true
        },
        {
          'id': `${rowId}#voting-decision-maker`,
          'type': 'default',
          'text': votingDecisionMaker,
          'stretch': true
        },
        {
          'id': `${rowId}#proxy-advisors`,
          'type': 'default',
          'text': proxyAdvisors,
          'stretch': true
        },
        {
          'id': `${rowId}#proxy-advisors-reliance`,
          'type': 'default',
          'text': proxyAdvisorsReliance,
          'stretch': true
        },
        {
          'id': `${rowId}#esg-focus`,
          'type': 'default',
          'text': esgFocus,
          'stretch': true
        },
        {
          'id': `${rowId}#esg-service-main-providers`,
          'type': 'default',
          'text': esgServiceMainProviders,
          'stretch': true
        },
        {
          'id': `${rowId}#activist`,
          'type': 'default',
          'text': activist,
          'stretch': true
        },
        {
          'id': `${rowId}#receptivity-to-activists`,
          'type': 'default',
          'text': receptivityToActivists,
          'stretch': true
        }
      ])
    };
  }

  const createTableLeftItems = () => {
    const leftItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'investor-list-header-left',
            'columns': [
              {
                'text': 'INVESTOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('name'),
                'orderBy': sortConfig['by'].includes('name') ? sortConfig['order'][sortConfig['by'].indexOf('name')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [320],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [320],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32}
          ]
        }
      }
    };
    return leftItems;
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'investor-list-header-middle',
            'columns': [
              {'text': '', 'stretch': false},
              {
                'text': 'COUNTRY',
                'isSortable': true,
                'onSortClick': () => sortFunction('country'),
                'orderBy': sortConfig['by'].includes('country') ? sortConfig['order'][sortConfig['by'].indexOf('country')] : 'asc',
                'stretch': true
              }
            ].concat((clientType === 'Corporate') ? [
              {
                'text': '# OF SHARES',
                'isSortable': true,
                'onSortClick': () => sortFunction('num_of_shares'),
                'orderBy': sortConfig['by'].includes('num_of_shares') ? sortConfig['order'][sortConfig['by'].indexOf('num_of_shares')] : 'asc',
                'stretch': true
              },
              {'text': 'ISC %', 'stretch': true},
              {'text': 'LAST GENERAL MEETING VOTES', 'stretch': true}
            ] : []).concat([
              {'text': 'INVESTOR TYPE', 'stretch': true},
              {'text': 'LEVEL OF INSIGHT', 'stretch': true},
              {'text': 'VOTING DECISION MAKER', 'stretch': true},
              {'text': 'PROXY ADVISORS', 'stretch': true},
              {'text': 'PROXY ADVISORS RELIANCE', 'stretch': true},
              {'text': 'ESG FOCUS', 'stretch': true},
              {'text': 'ESG SERVICE PROVIDERS', 'stretch': true},
              {'text': 'ACTIVIST', 'stretch': true},
              {'text': 'RECEPTIVITY TO ACTIVISTS', 'stretch': true}
            ])
          }
        ],
        'config': {
          'columnWidths': 
            [320, 168]
              .concat((clientType === 'Corporate') ? [192, 48, 240] : [])
              .concat([168, 168, 192, 240, 192, 120, 240, 120, 192]),
          'columnPaddings': [
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 32},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ].concat((clientType === 'Corporate') ? [
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ] : []).concat([
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ])
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': 
            [320, 168]
              .concat((clientType === 'Corporate') ? [192, 48, 240] : [])
              .concat([168, 168, 192, 240, 192, 120, 240, 120, 192]),
          'columnPaddings': [
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 32},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ].concat((clientType === 'Corporate') ? [
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ] : []).concat([
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ])
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'left': createTableLeftItems(),
    'middle': createTableMiddleItems()
  };

  investorListItems.forEach(item => {
    tableItems['left']['body']['items'].push(
      createInvestorRowLeft(item)
    );
    tableItems['middle']['body']['items'].push(
      createInvestorRowMiddle(item)
    );
  });

  return tableItems;
}









/* Client - Show Investor Contact List Table */
export const generateClientInvestorContactListTableItems = (investorContactListItems, sortConfig, pinClickHandler, contactClickHandler, sortFunction) => {
  const createInvestorContactRowLeft = (item) => {
    const investorContactName = item['fullname'];
    const investorContactProfilePictureUrl = item['profile_picture_url'];
    const isStarred = item['is_starred'];
    const isCustom = item['is_custom'];
    const rowId = `client-investor-contact-${item['id']}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#investor-contact-fullname`,
          'type': 'link-button',
          'text': investorContactName,
          'hasPin': true,
          'pinIcon': 'star',
          'isPinSelected': isStarred,
          'onPinClick': pinClickHandler,
          'hasPhoto': true,
          'photoUrl': investorContactProfilePictureUrl,
          'has_custom_chip': isCustom,
          'stretch': false,
          'onClick': contactClickHandler
        }
      ]
    };
  }

  const createInvestorContactRowMiddle = (item) => {
    const relationshipStatusColors = {
      'Strong': 'success',
      'Standard': 'warning',
      'Weak': 'danger',
      'Unknown': 'unknown'
    };

    const role = item['role'];
    const department = item['department'];
    const relationshipStatus = item['relationship_status'];
    const city = item['city'];
    const email = item['email'];
    const phoneNumber = item['phone_number'];
    const address = item['address'];
    const notes = item['notes'];
    const rowId = `client-investor-contact-${item['id']}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#filler-left`,
          'type': 'empty-cell',
          'stretch': false
        },
        {
          'id': `${rowId}#role`,
          'type': 'default',
          'text': role,
          'stretch': true
        },
        {
          'id': `${rowId}#department`,
          'type': 'default',
          'text': department,
          'stretch': true
        },
        {
          'id': `${rowId}#relationship-status`,
          'type': 'traffic-light',
          'color_code': relationshipStatusColors[relationshipStatus],
          'text': relationshipStatus,
          'stretch': true
        },
        {
          'id': `${rowId}#city`,
          'type': 'default',
          'text': city,
          'stretch': true
        },
        {
          'id': `${rowId}#email`,
          'type': 'default',
          'text': email,
          'stretch': true
        },
        {
          'id': `${rowId}#phone-number`,
          'type': 'default',
          'text': phoneNumber,
          'stretch': true
        },
        {
          'id': `${rowId}#address`,
          'type': 'default',
          'text': address,
          'stretch': true
        },
        {
          'id': `${rowId}#notes`,
          'type': 'default',
          'text': notes,
          'stretch': true
        }
      ]
    };
  }

  const createTableLeftItems = () => {
    const leftItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'investor-contact-list-header-left',
            'columns': [
              {
                'text': 'CONTACT NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('fullname'),
                'orderBy': sortConfig['by'].includes('fullname') ? sortConfig['order'][sortConfig['by'].indexOf('fullname')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [280],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [280],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32}
          ]
        }
      }
    };
    return leftItems;
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'investor-contact-list-header-middle',
            'columns': [
              {'text': '', 'stretch': false},
              {
                'text': 'ROLE',
                'isSortable': true,
                'onSortClick': () => sortFunction('role'),
                'orderBy': sortConfig['by'].includes('role') ? sortConfig['order'][sortConfig['by'].indexOf('role')] : 'asc',
                'stretch': true
              },
              {
                'text': 'DEPARTMENT',
                'isSortable': true,
                'onSortClick': () => sortFunction('department'),
                'orderBy': sortConfig['by'].includes('department') ? sortConfig['order'][sortConfig['by'].indexOf('department')] : 'asc',
                'stretch': true
              },
              {'text': 'RELATIONSHIP STATUS', 'stretch': true},
              {'text': 'CITY', 'stretch': true},
              {'text': 'EMAIL', 'stretch': true},
              {'text': 'PHONE NUMBER', 'stretch': true},
              {'text': 'ADDRESS', 'stretch': true},
              {'text': 'NOTES', 'stretch': true}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            280, 168, 192, 240, 168, 240, 240, 240, 240
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            280, 168, 192, 240, 168, 240, 240, 240, 240
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'left': createTableLeftItems(),
    'middle': createTableMiddleItems()
  };

  investorContactListItems.forEach(item => {
    tableItems['left']['body']['items'].push(
      createInvestorContactRowLeft(item)
    );
    tableItems['middle']['body']['items'].push(
      createInvestorContactRowMiddle(item)
    );
  });

  return tableItems;
}
























/* Client - Show Investor Engagements - Other Notes List Table */
export const generateClientInvestorEngagementsOtherNotesTableItems = (investorEngagementsOtherNotesItems, otherNoteClickHandler, sortConfig, sortFunction) => {
  const createInvestorEngagementOtherNoteRow = (item) => {
    const otherNoteId = item['id'];
    const otherNoteHeader = item['note_header'];
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];

    const rowId = `client-investor-engagements-other-note-${otherNoteId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#note-header`,
          'type': 'link-button',
          'text': otherNoteHeader,
          'stretch': true,
          'onClick': otherNoteClickHandler
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-investor-engagements-other-notes-list-header-middle',
            'columns': [
              {
                'text': 'NOTE HEADER',
                'isSortable': true,
                'onSortClick': () => sortFunction('note_header'),
                'orderBy': sortConfig['by'].includes('note_header') ? sortConfig['order'][sortConfig['by'].indexOf('note_header')] : 'asc',
                'stretch': true
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            600, 240, 192
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            600, 240, 192
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  investorEngagementsOtherNotesItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createInvestorEngagementOtherNoteRow(item)
    );
  });

  return tableItems;
}


/* Client - Show Investor Engagements - Meeting Notes List Table */
export const generateClientInvestorEngagementsMeetingNotesTableItems = (investorEngagementsMeetingNotesItems, meetingNoteClickHandler, sortConfig, sortFunction) => {
  const createInvestorEngagementMeetingNoteRow = (item) => {
    const meetingNoteId = item['id'];
    const meetingNoteHeader = item['note_header'];
    const meetingDate = new Date(item['meeting_date']).toLocaleDateString('en-GB');
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];

    const rowId = `client-investor-engagements-meeting-note-${meetingNoteId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#note-header`,
          'type': 'link-button',
          'text': meetingNoteHeader,
          'stretch': true,
          'onClick': meetingNoteClickHandler
        },
        {
          'id': `${rowId}#meeting-date`,
          'type': 'default',
          'text': meetingDate,
          'stretch': false
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-investor-engagements-meeting-notes-list-header-middle',
            'columns': [
              {
                'text': 'NOTE HEADER',
                'isSortable': true,
                'onSortClick': () => sortFunction('note_header'),
                'orderBy': sortConfig['by'].includes('note_header') ? sortConfig['order'][sortConfig['by'].indexOf('note_header')] : 'asc',
                'stretch': true
              },
              {
                'text': 'MEETING DATE',
                'isSortable': true,
                'onSortClick': () => sortFunction('meeting_date'),
                'orderBy': sortConfig['by'].includes('meeting_date') ? sortConfig['order'][sortConfig['by'].indexOf('meeting_date')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            400, 192, 240, 192
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            400, 192, 240, 192
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  investorEngagementsMeetingNotesItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createInvestorEngagementMeetingNoteRow(item)
    );
  });

  return tableItems;
}


/* Client - Show Investor Engagements - Meeting Requests List Table */
export const generateClientInvestorEngagementsMeetingRequestsTableItems = (investorEngagementsMeetingRequestItems, sortConfig, sortFunction) => {
  const createInvestorEngagementMeetingRequestRow = (item) => {
    const emailStatusColors = {
      'Sent': 'success',
      'Not Sent': 'unknown'
    };

    const meetingStatusColors = {
      'Pending': 'unknown',
      'In Progress': 'info',
      'Confirmed': 'success',
      'Declined': 'danger',
      'Cancelled': 'warning'
    };

    const meetingRequestId = item['id'];
    const engagementName = item['engagement_name'];
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');
    
    let meetingStatus = '';
    if(emailStatus === 'Not Sent') {
      meetingStatus = '-';
    }
    else {
      meetingStatus = item['meeting_status'] === null ? 'Pending' : item['meeting_status'];
    }

    const rowId = `client-investor-engagements-meeting-request-${meetingRequestId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#engagement-name`,
          'type': 'link-button',
          'text': engagementName,
          'stretch': true,
          'onClick': () => window.location = `../engagements?meeting_request_id=${meetingRequestId}`
        },
        {
          'id': `${rowId}#email-status`,
          'type': 'traffic-light',
          'color_code': emailStatusColors[emailStatus],
          'text': emailStatus,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': false
        },
        {
          'id': `${rowId}#meeting-status`,
          'type': 'traffic-light',
          'color_code': meetingStatusColors[meetingStatus],
          'text': meetingStatus,
          'stretch': false
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-investor-engagements-meeting-requests-list-header-middle',
            'columns': [
              {
                'text': 'ENGAGEMENT NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('engagement_name'),
                'orderBy': sortConfig['by'].includes('engagement_name') ? sortConfig['order'][sortConfig['by'].indexOf('engagement_name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'EMAIL STATUS',
                'isSortable': false,
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'isSortable': false,
                'stretch': false
              },
              {
                'text': 'MEETING STATUS',
                'isSortable': false,
                'stretch': false
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            200, 144, 96, 144, 176, 96
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            200, 144, 96, 144, 176, 96
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  investorEngagementsMeetingRequestItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createInvestorEngagementMeetingRequestRow(item)
    );
  });

  return tableItems;
}


/* Client - Show Investor Engagements - Shareholder Communications List Table */
export const generateClientInvestorEngagementsShareholderCommunicationsTableItems = (investorEngagementsShareholderCommunicationItems, sortConfig, sortFunction) => {
  const createInvestorEngagementShareholderCommunicationRow = (item) => {
    const emailStatusColors = {
      'Sent': 'success',
      'Not Sent': 'unknown'
    };

    const shareholderCommunicationId = item['id'];
    const engagementName = item['engagement_name'];
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');

    const rowId = `client-investor-engagements-shareholder-communication-${shareholderCommunicationId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#engagement-name`,
          'type': 'link-button',
          'text': engagementName,
          'stretch': true,
          'onClick': () => window.location = `../engagements?shareholder_communication_id=${shareholderCommunicationId}`
        },
        {
          'id': `${rowId}#email-status`,
          'type': 'traffic-light',
          'color_code': emailStatusColors[emailStatus],
          'text': emailStatus,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': false
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-investor-engagements-shareholder-communications-list-header-middle',
            'columns': [
              {
                'text': 'ENGAGEMENT NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('engagement_name'),
                'orderBy': sortConfig['by'].includes('engagement_name') ? sortConfig['order'][sortConfig['by'].indexOf('engagement_name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'EMAIL STATUS',
                'isSortable': false,
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'isSortable': false,
                'stretch': false
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            200, 144, 96, 176, 96
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            200, 144, 96, 176, 96
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  investorEngagementsShareholderCommunicationItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createInvestorEngagementShareholderCommunicationRow(item)
    );
  });

  return tableItems;
}