import { Fragment, useEffect, useState } from "react";
import parse from 'html-react-parser';

import ComboBox from "../../UI/ComboBox/ComboBox";
import DateInput from "../../UI/DateInput/DateInput";
import Dropdown from "../../UI/Dropdown/Dropdown";
import NumberInput from "../../UI/NumberInput/NumberInput";
import RichTextEditor from "../../UI/RichTextEditor/RichTextEditor";
import Textarea from "../../UI/Textarea/Textarea";
import TextInput from "../../UI/TextInput/TextInput";

import styles from './EngagementFormItem.module.css';


const EngagementFormItem = (props) => {
  const [comboboxLastSavedValue, setComboboxLastSavedValue] = useState(props.value); // Only for ComboBox

  const propsType = props.type;
  const propsValue = props.value;
  useEffect(() => {
    if (propsType === 'combobox') {
      setComboboxLastSavedValue(propsValue); // Only for ComboBox 
    }
  }, [propsType, propsValue]);

  const comboValueChangeHandler = (newValue) => {
    props.onChange(newValue);
    if(newValue === '') {
      setComboboxLastSavedValue(newValue);  
    }
  }

  const comboValueSelectHandler = (newValue) => {
    props.onChange(newValue);
    setComboboxLastSavedValue(newValue);
    if(props.onSelected) {
      props.item['onSelected'](props.item['id'].split('#')[0], newValue);
    }
  }


  let content = '';
  if (props.type === 'text'){
    content = (
      <Fragment>
        <div className={styles['label']}>
          <h4>{props.label}</h4>
        </div>
        <div className={styles['text']}>
          <p>{props.value}</p>
        </div>
      </Fragment>
    );
  }
  else if (props.type === 'text-block'){
    content = (
      <Fragment>
        <div className={styles['label']}>
          <h4>{props.label}</h4>
        </div>
        <div className={`${styles['text-block']} ${styles[props.size]}`}>
          {props.value}
        </div>
      </Fragment>
    );
  }
  else if (props.type === 'text-input') {
    content = (
      <div className={styles['text']}>
         <TextInput
            id={props.id}
            width={props.width ? props.width : '100%'}
            label={props.label}
            placeholder={props.placeholder}
            value={props.value}
            hasError={props.hasError}
            onBlur={props.onBlur}
            onChange={props.onChange}
            isDisabled={props.isDisabled}
          />
      </div>
    );
  }
  else if (props.type === 'textarea-input') {
    content = (
      <div className={styles['text']}>
         <Textarea
            id={props.id}
            width={props.width ? props.width : '100%'}
            height={props.height}
            label={props.label}
            placeholder={props.placeholder}
            value={props.value}
            hasError={props.hasError}
            onBlur={props.onBlur}
            onChange={props.onChange}
            isDisabled={props.isDisabled}
          >
            {props.value}
          </Textarea>
      </div>
    );
  }
  else if (props.type === 'rich-text-input') {
    let isDisabled = false;
    if(props.isDisabled !== undefined && props.isDisabled === true) {
      isDisabled = true;
    }
    content = (
      <RichTextEditor
        id={props.id}
        width={props.width ? props.width : '100%'}
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        hasError={props.hasError}
        onBlur={props.onBlur}
        onChange={props.onChange}
        disabled={isDisabled}
      />
    );
  }
  else if (props.type === 'rich-text-content'){
    content = (
      <Fragment>
        <div className={styles['label']}>
          <h4>{props.label}</h4>
        </div>
        <div className={`${styles['text-block']} ${styles[props.size]}`}>
          {parse(props.value)}
        </div>
      </Fragment>
    );
  }
  else if (props.type === 'number-input') {
    content = (
      <NumberInput
        id={props.id}
        width={props.width ? props.width : '100%'}
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        hasError={props.hasError}
        onBlur={props.onBlur}
        onChange={props.onChange}
        isDisabled={props.isDisabled}
      />
    );
  }
  else if (props.type === 'date-input') {
    content = (
      <DateInput
        id={props.id}
        width={props.width ? props.width : '100%'}
        label={props.label}
        placeholder={props.placeholder}
        selected={props.value}
        hasError={props.hasError}
        onBlur={props.onBlur}
        onChange={props.onChange}
        isDisabled={props.isDisabled}
      />
    );
  }
  else if (props.type === 'dropdown') {
    content = (
      <Dropdown
        id={props.id}
        width={props.width ? props.width : '100%'}
        label={props.label}
        placeholder={props.placeholder}
        items={props.items}
        hasChip={props.hasChip}
        selected={props.value}
        hasError={props.hasError}
        onBlur={props.onBlur}
        onItemClick={props.onChange}
        isDisabled={props.isDisabled}
      />
    )
  }
  else if (props.type === 'combobox') {
    content = (
      <ComboBox
        id={props.id}
        width={props.width ? props.width : '100%'}
        label={props.label}
        placeholder={props.placeholder}
        items={props.items}
        allowCustomValues={props.allowCustomValues}
        value={props.value}
        lastSavedValue={comboboxLastSavedValue}
        hasError={props.hasError}
        onChange={comboValueChangeHandler}
        onItemClick={comboValueSelectHandler}
        onButtonClick={comboValueSelectHandler}
        isDisabled={props.isDisabled}
      />
    )
  }
  else if (props.type === 'new-attachment-button'){
    content = (
      <div className={styles['new-attachment-container']}>
        <label className={styles['new-attachment-button']}>
          <div className={styles['new-attachment-icon']}></div>
          <div className={styles['new-attachment-text']}>
            <h4>Add Attachment</h4>
          </div>
          <input type='file' accept='image/gif, image/jpeg, image/png, .doc, .docx, .pdf' onChange={props.onChange} />
        </label>
      </div>
    );
  }
  else if (props.type === 'new-attachment-icon-button'){
    content = (
      <div className={styles['new-attachment-container']}>
        <label className={styles['new-attachment-icon-button']}>
          <div className={styles['new-attachment-icon']}></div>
          <input type='file' accept='image/gif, image/jpeg, image/png, .doc, .docx, .pdf' onChange={props.onChange} />
        </label>
      </div>
    );
  }
  else if (props.type === 'attached-item'){
    content = (
      <div className={styles['attached-item-container']}>
        <div className={styles['attached-item']}>
          <div className={styles['attached-item-icon']}></div>
          <div className={`${styles['attached-item-text']} ${!props.isRemovable && styles['clickable']}`} onClick={props.onItemClick}>
            <h4>{props.value}</h4>
          </div>
          {props.isRemovable &&
            <div className={styles['remove-button']} onClick={props.onRemoveClick}></div>
          }
        </div>
      </div>
    );
  }
  else if (props.type === 'traffic-light'){
    content = (
      <Fragment>
        <div className={styles['label']}>
          <h4>{props.label}</h4>
        </div>
        <div className={styles['traffic-light']}>
          <div className={`${styles['color-code']} ${styles[props.color_code]} `}></div>
          <div className={styles['text']}>
            <p>{props.value}</p>
          </div>
        </div>
      </Fragment>
    );
  }
  
  return content;
}

export default EngagementFormItem;
