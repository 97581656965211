import { useCallback, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { getAccessToken, getIsAccessTokenRenewalRequired } from "../utils/auth";
import { renewAccessTokenRequest } from "../requests/auth-requests";


const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const sendRequest = useCallback(async (config, applyFunction, dataType) => {
    setIsLoading(true);
    setError();

    let body = null;
    if (config.options.body) {
      if (config.options.dataType === "FormData") {
        body = config.options.body;
      }
      else {
        body = JSON.stringify(config.options.body);
      }
    }

    try {
      const response = await fetch(
        config.url,
        {
          method: config.options.method ? config.options.method : 'GET',
          headers: config.options.headers ? config.options.headers : {},
          body: body
        }
      )
      
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('Not found!');
        }
        else if (response.status === 401 || response.status === 403 || response.status === 410) {
          const error_object = await response.json();
          error_object['status'] = response.status;
          throw error_object;
        }
        else {
          throw new Error('Request failed!');
        }
      }
      else {
        const accessToken = getAccessToken();
        const isAccessTokenRenewalRequired = getIsAccessTokenRenewalRequired();
        if(accessToken !== undefined && accessToken !== null && isAccessTokenRenewalRequired === null) {
          const decodedToken = jwtDecode(accessToken);
          const expiredAt = new Date(decodedToken['exp']);
          const remainedTimeInSeconds = (expiredAt - (new Date().getTime() / 1000));
          if(remainedTimeInSeconds < 3600 && remainedTimeInSeconds > 0) {
            localStorage.setItem('isAccessTokenRenewalRequired', true);
            // TODO: Update the logic here
            const renewalRequest = renewAccessTokenRequest();
            const responseRenawalRequest = await fetch(
              renewalRequest.url,
              {
                method: renewalRequest.options.method,
                headers: renewalRequest.options.headers
              }
            );
            if (!responseRenawalRequest.ok) {
              // TODO: Handle this one
            }
            else {
              const data = await responseRenawalRequest.json();
              const newAccessToken = data['access_token'];
              localStorage.removeItem('isAccessTokenRenewalRequired');
              localStorage.setItem('accessToken', newAccessToken);
            }
          }
        }
      }
      
      if (config.options.returnDataType === 'blob') {
        const data = await response.blob();
        applyFunction(data);
        setIsLoading(false);
      }
      else {
        const data = await response.json();
        applyFunction(data);
        setIsLoading(false);
      }
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, []);

  return [isLoading, error, sendRequest];
}

export default useHttp;