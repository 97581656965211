import { Fragment, useEffect, useState } from 'react';
import styles from './StepProgressBar.module.css';

const StepProgressBar = (props) => {
  const [selectedStep, setSelectedStep] = useState(props.selectedStep === undefined ? 'initial' : props.selectedStep);
  const [completedSteps, setCompletedSteps] = useState(props.completedSteps);
  const [warningSteps, setWarningSteps] = useState(props.warningSteps);
  const [clickableSteps, setClickableSteps] = useState(props.clickableSteps);

  const changeStepHandler = (stepId) => {
    // setSelectedStep(stepId);
    props.onStepChange(stepId);
  }

  useEffect(() => {
    setSelectedStep(props.selectedStep);
    setCompletedSteps(props.completedSteps);
    setWarningSteps(props.warningSteps);
    setClickableSteps(props.clickableSteps)
  }, [props.selectedStep, props.completedSteps, props.warningSteps, props.clickableSteps]);

  const progressItems = props.items.map(item => {
    const isWarning = warningSteps.includes(item['id']);
    const isCompleted = completedSteps.includes(item['id']);
    const isClickable = clickableSteps.includes(item['id']);
    const isSelected = selectedStep === item['id'];

    const itemStyle = isSelected ? styles['selected'] : 
                        isWarning ? styles['warning'] : 
                        isCompleted ? styles['completed'] : '';

    const numberItem = isSelected ? <p>{item['number']}</p> : 
                        isWarning ? <div className={`${styles['icon']} ${styles['exclamation-mark']}`}></div> :
                        isCompleted ? <div className={`${styles['icon']} ${styles['success']}`}></div> : <p>{item['number']}</p>;
                        
                   
    return (
      <Fragment key={`progress-item-${item['id']}`}>
        <div className={`${styles['item']} ${styles['process']} ${itemStyle} ${isClickable ? '' : styles['disabled']}`} onClick={ () => changeStepHandler(item['id']) }>
          <div className={styles['number']}>
            {numberItem}
          </div>
          <div className={styles['text']}>
            <p>{item['text']}</p>
            <p className={styles['hidden-width-reference']}>{item['text']}</p>
          </div>
        </div>
        <div className={styles['separator']}></div>
      </Fragment>
    );
  })

  return (
    <div className={styles['step-progress-bar-container']}>
      <div className={styles['steps']}>
        <div
          className={`${styles['item']} ${styles['initial']} ${selectedStep === 'initial' ? styles['selected'] : ''} ${clickableSteps.includes('initial') ? '' : styles['disabled']}`}
          onClick={ () => changeStepHandler('initial') }
        >
          <div className={styles['number']}></div>
          <div className={styles['text']}><p>Start</p></div>
        </div>
        <div className={styles['separator']}></div>
        {progressItems}
        <div
          className={`${styles['item']} ${styles['final']} ${selectedStep === 'final' ? styles['selected'] : ''} ${clickableSteps.includes('final') ? '' : styles['disabled']}`}
          onClick={ () => changeStepHandler('final') }
        >
          <div className={styles['number']}></div>
          <div className={styles['text']}><p>Complete</p></div>
        </div>
      </div>
    </div>
  )
}

export default StepProgressBar
