import { Fragment, useEffect, useState } from 'react';
import useInputValidation from '../../../../hooks/use-input-validation';

import Button from '../../Button/Button';
import NumberInput from '../../NumberInput/NumberInput';
import TextInput from '../../TextInput/TextInput';
import Dropdown from '../../Dropdown/Dropdown';
import ComboBox from '../../ComboBox/ComboBox';

import styles from './TableCell.module.css';

const TableCell = (props) => {
  const cellTypeStyle = styles[props.item['type']] !== undefined ? styles[props.item['type']] : styles['default'];
  
  const [cellValue, setCellValue, isCellValueValid, hasCellValueError, cellValueChangeHandler, cellValueBlurHandler] =
    useInputValidation(props.item['value'], (cellValue) => cellValue !== undefined ? cellValue.toString().trim() !== '' : false);
  const [lastCellValue, setLastCellValue] = useState(props.item['lastSavedValue']); // Only for ComboBox

  const propsItem = props.item;
  useEffect(() => {
    setCellValue(propsItem['value']);
    setLastCellValue(propsItem['lastSavedValue']); // Only for ComboBox
  }, [propsItem, setCellValue]);

  const comboValueChangeHandler = (newValue) => {
    setCellValue(newValue);
  }

  const comboValueSelectHandler = (newValue) => {
    setCellValue(newValue);
    setLastCellValue(newValue);
    if(props.item['onSelected']) {
      props.item['onSelected'](props.item['id'].split('#')[0], newValue);
    }
  }

  const wrappedCellValueChangeHandler = (enteredValue) => {
    cellValueChangeHandler(enteredValue);
    if(props.item['onChange'] && props.item['id']) {
      props.item['onChange'](props.item['id'].split('#')[0]);
    }
    if(props.item['onSelected']) {
      props.item['onSelected'](props.item['id'].split('#')[0], enteredValue);
    }
  }


  const generateHeaderCellContent = (item, isSortable) => {
    let sortIcon = '';
    if (isSortable) {
      let orderIcon = '';
      if (item['orderBy'] === 'asc') {
        orderIcon = styles['arrow-down'];
      }
      else {
        orderIcon = styles['arrow-up'];
      }
      sortIcon = (
        <div className={`${styles['sort-icon']} ${orderIcon}`} onClick={item['onSortClick']}></div>
      );
    }

    return (
      <Fragment>
        <div className={styles['text']}>
          <p id={item['id']}>{item['text']}</p>
        </div>
        {sortIcon}
      </Fragment>
    );
  }


  const generateDefaultTextContent = (item, viewMode, cellWidth) => {
    // If it is in skeleton mode, it is returning template cell.
    if (viewMode !== undefined && viewMode === 'skeleton') {
      return (
        <div className={styles['text']}>
          <div className={styles['skeleton']} style={{width: cellWidth * 0.70}}></div>
        </div>
      );
    }
    else {
      return (
        <div className={styles['text']}>
          <p id={item['id']}>{item['text']}</p>
        </div>
      );
    }
  }


  const generateLinkButtonContent = (item, viewMode, cellWidth) => {
    // If it is in skeleton mode, it is returning template cell.
    if (viewMode !== undefined && viewMode === 'skeleton') {
      return (
        <div className={styles['text']}>
          <div className={styles['skeleton']} style={{width: cellWidth * 0.70}}></div>
        </div>
      );
    }

    // Checking the cell has a pin or not and generating content.
    let pinContent = '';
      if (item['hasPin']) {
        pinContent = (
          <div
            className={`${styles['pin']} ${styles[item['pinIcon']]} ${item['isPinSelected'] ? styles['selected'] : ''}`}
            onClick={() => item['onPinClick'](item['id'])}
          ></div>
        );
      }

      // Checking the cell has a photo or not and generating content.
      let photoContent = '';
      let hasPhotoStyle = '';
      let photoUrlStyle = {};
      if (item['hasPhoto'] && item['photoUrl']) {
        photoContent = (
          <div className={`${styles['photo']}`} style={photoUrlStyle}></div>
        );
        hasPhotoStyle = styles['has-photo'];
        photoUrlStyle['backgroundImage'] = `url("${item['photoUrl']}")`;
      }

      // Checking the cell has a custom label or not and generating content.
      let isCustomContent = '';
      let hasCustomChipStyle = '';
      if (item['has_custom_chip']) {
        isCustomContent = (
          <div className={styles['chip']}>
            <p>Custom</p>
          </div>
        );
        hasCustomChipStyle = styles['has-custom-chip'];
      }

      // Generating content the text content.
      let linkContent = '';
      if (item['onClick']) {
        linkContent = (
          <div className={`${styles['text']} ${hasPhotoStyle} ${hasCustomChipStyle}`} onClick={() => item['onClick'](item['id'])}>
            <p>{item['text']}</p>
          </div>
        );
      }
      else {
        linkContent = (
          <div className={`${styles['text']} ${hasPhotoStyle} ${hasCustomChipStyle}`}>
            <p>
              <a href={item['href']}>{item['text']}</a>
            </p>
          </div>
        );
      }

      return (
        <Fragment>
          {pinContent}
          {photoContent}
          {linkContent}
          {isCustomContent}
        </Fragment>
      );
  }

  const generateCellContent = () => {
    let content = '';
    if (props.item['type'] === 'traffic-light') {
      content = (
        <Fragment>
          <div className={`${styles['color-code']} ${styles[props.item['color_code']]} `}></div>
          <div className={styles['text']}>
            <p>{props.item['text']}</p>
          </div>
        </Fragment>
      );
    }
    else if (props.item['type'] === 'icon-button') {
      content = (
        <div className={`${styles[props.item['icon']]} `} onClick={() => props.item['onClick'](props.item['id'])}></div>
      );
    }
    else if (props.item['type'] === 'text-input') {
      content = (
        <div className={`${styles['text-input']} `}>
          <TextInput
            id={props.item['id']}
            size='xsmall'
            width={props.item['width']}
            placeholder={props.item['placeholder']}
            value={cellValue}
            hasError={hasCellValueError}
            isValid={isCellValueValid}
            onBlur={cellValueBlurHandler}
            onChange={wrappedCellValueChangeHandler}
            tabIndex={props.item['tabIndex']}
            isDisabled={props.item['isDisabled'] ? props.item['isDisabled'] : false}
          />
        </div>
      );
    }
    else if (props.item['type'] === 'number-input') {
      content = (
        <div className={`${styles['number-input']} `}>
          <NumberInput
            id={props.item['id']}
            size='xsmall'
            width={props.item['width']}
            placeholder={props.item['placeholder']}
            value={cellValue}
            hasError={hasCellValueError}
            isValid={isCellValueValid}
            onBlur={cellValueBlurHandler}
            onChange={wrappedCellValueChangeHandler}
            tabIndex={props.item['tabIndex']}
            isDisabled={props.item['isDisabled'] ? props.item['isDisabled'] : false}
          />
        </div>
      );
    }
    else if (props.item['type'] === 'dropdown') {
      content = (
        <div className={`${styles['dropdown']} `}>
          <Dropdown
            id={props.item['id']}
            size='xsmall'
            width={props.item['width']}
            placeholder={props.item['placeholder']}
            items={props.item['items']}
            selected={cellValue}
            hasError={hasCellValueError}
            hasChip={props.item['hasChip']}
            hasHeaderColor={props.item['hasHeaderColor']}
            onBlur={cellValueBlurHandler}
            onItemClick={wrappedCellValueChangeHandler}
            tabIndex={props.item['tabIndex']}
            isDisabled={props.item['isDisabled']}
          />
        </div>
      );
    }
    else if (props.item['type'] === 'combobox') {
      content = (
        <div className={`${styles['combobox']}`}>
          <ComboBox
            id={props.item['id']}
            size='xsmall'
            width={props.item['width']}
            placeholder={props.item['placeholder']}
            items={props.item['items']}
            allowCustomValues={true}
            value={cellValue}
            lastSavedValue={lastCellValue}
            onChange={comboValueChangeHandler}
            onItemClick={comboValueSelectHandler}
            onButtonClick={comboValueSelectHandler}
            tabIndex={props.item['tabIndex']}
          />
        </div>
      );
    }
    else if (props.item['type'] === 'button') {
      content = (
        <div className={`${styles['button']} `}>
          <Button type={"secondary"} leftIcon={"continue"} size={"xsmall"} onClick={() => props.item['onClick'](props.item['id'])}>Continue</Button>
        </div>
      );
    }
    else if (props.item['type'] === 'link-button') {
      content = generateLinkButtonContent(props.item, props.viewMode, props.width);
    }
    else if (props.item['type'] === 'progress') {
      content = (
        <Fragment>
          <div className={`${styles['progress-value']}`}>
            <p>{props.item['completed']} / {props.item['total']}</p>
          </div>
          <div className={`${styles['progress-bar']}`}>
          {
            Array(props.item['total']).fill(0).map((_, index) => {
              if (index < props.item['completed']) {
                return (
                  <div key={`progress-${index}`} className={`${styles['bar-item']} ${styles['active']}`}></div>
                );
              }
              else {
                return (
                  <div key={`progress-${index}`} className={`${styles['bar-item']} ${styles['passive']}`}></div>
                );
              }
            })
          }
          </div>
        </Fragment>
      );
    }
    else if (props.item['type'] === 'empty-cell') {
      content = (
        <div className={styles['text']}>
          <p>&nbsp;</p>
        </div>
      );
    }
    else if (props.item['type'] === 'text-with-chip') {
      let isCustomContent = '';
      let hasCustomChipStyle = '';
      if (props.item['has_custom_chip']) {
        isCustomContent = (
          <div className={styles['chip']}>
            <p>Custom</p>
          </div>
        );
        hasCustomChipStyle = styles['has-custom-chip'];
      }

      content = (
        <Fragment>
          <div className={`${styles['text']} ${hasCustomChipStyle}`}>
            <p id={props.item['id']}>{props.item['text']}</p>
          </div>
          {isCustomContent}
        </Fragment>
      );
    }
    else {
      if (props.section === 'header') {
        content = generateHeaderCellContent(props.item, props.item['isSortable'])
      }
      else {
        content = generateDefaultTextContent(props.item, props.viewMode, props.width);
      }
    }

    return content;
  }

  // CONTAINER
  return (
    <div
      className={`${styles['cell']} ${styles[props.section]} ${cellTypeStyle}`}
      style={{ 'width': props.width, 'paddingRight': props.padding['right'], 'paddingLeft': props.padding['left'], 'paddingTop': props.padding['top'], 'paddingBottom': props.padding['bottom'] }}
    >
      {generateCellContent()}
    </div>
  );
}

export default TableCell
