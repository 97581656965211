import { useEffect, useState } from 'react';
import { clientTypeDropdownItems, countryDropdownItems, departmentDropdownItems, regionDropdownItems, relationshipStatusDropdownItems } from '../../../../utils/constants';
import useInputValidation from '../../../../hooks/use-input-validation';

import Button from '../../../../components/UI/Button/Button';
import DateInput from '../../../../components/UI/DateInput/DateInput';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import Modal from '../../../../components/UI/Modal/Modal';
import NumberInput from '../../../../components/UI/NumberInput/NumberInput';
import TextInput from '../../../../components/UI/TextInput/TextInput';

import styles from './ClientSetupGeneralInfo.module.css';
import { commaSeperatedValueToNumber } from '../../../../utils/helpers';

const ClientSetupGeneralInfo = (props) => {
  /* Company Information */
  const [profilePictureUrl, setProfilePictureUrl] = useState(props.generalInformationData['profile_picture_url']);

  const [name, setName, isNameValid, hasNameError, nameChangeHandler, nameBlurHandler] =
    useInputValidation(props.generalInformationData['name'], (name) => name.trim() !== '');

  const [clientType, setClientType, isClientTypeValid, hasClientTypeError, clientTypeChangeHandler, clientTypeBlurHandler] =
    useInputValidation(props.generalInformationData['client_type'], (clientType) => true);

  const [region, setRegion, isRegionValid, hasRegionError, regionChangeHandler, regionBlurHandler] =
    useInputValidation(props.generalInformationData['region'], (region) => region.trim() !== '');

  const [country, setCountry, isCountryValid, hasCountryError, countryChangeHandler, countryBlurHandler] =
    useInputValidation(props.generalInformationData['country'], (country) => country.trim() !== '');

  const [totalNumOfShares, setTotalNumOfShares, isTotalNumOfSharesValid, hasTotalNumOfSharesError, totalNumOfSharesChangeHandler, totalNumOfSharesBlurHandler] =
    useInputValidation(props.generalInformationData['total_num_of_shares'], (totalNumOfShares) => totalNumOfShares.toString().trim() !== '');

  const [dateNumOfShares, setDateNumOfShares, isDateNumOfSharesValid, hasDateNumOfSharesError, dateNumOfSharesChangeHandler, dateNumOfSharesBlurHandler] =
    useInputValidation((props.generalInformationData['date_num_of_shares'] !== null && props.generalInformationData['date_num_of_shares'] !== undefined && props.generalInformationData['date_num_of_shares'] !== '') ?
      new Date(props.generalInformationData['date_num_of_shares']) : 
      '', (dateNumOfShares) => true);
    // (dateNumOfShares) => dateNumOfShares !== undefined && dateNumOfShares !== null && dateNumOfShares.toString().trim() !== ''

  /* Main Contact */
  const [fullname, setFullname, isFullnameValid, hasFullnameError, fullnameChangeHandler, fullnameBlurHandler] =
    useInputValidation(props.mainContactData['fullname'], (fullname) => fullname.trim() !== '');

  const [jobTitle, setJobTitle, isJobTitleValid, hasJobTitleError, jobTitleChangeHandler, jobTitleBlurHandler] =
    useInputValidation(props.mainContactData['job_title'], (jobTitle) => jobTitle.trim() !== '');

  const [department, setDepartment, isDepartmentValid, hasDepartmentError, departmentChangeHandler, departmentBlurHandler] =
    useInputValidation(props.mainContactData['department'], (department) => department.trim() !== '');

  const [email, setEmail, isEmailValid, hasEmailError, emailChangeHandler, emailBlurHandler] =
    useInputValidation(props.mainContactData['email'], (email) => email.trim() !== '');

  const [phoneNumber, setPhoneNumber, isPhoneNumberValid, hasPhoneNumberError, phoneNumberChangeHandler, phoneNumberBlurHandler] =
    useInputValidation(props.mainContactData['phone_number'], (phoneNumber) => true);
  
  const [relationshipStatus, setRelationshipStatus, isRelationshipStatusValid, hasRelationshipStatusError, relationshipStatusChangeHandler, relationshipStatusBlurHandler] =
    useInputValidation(props.mainContactData['relationship_status'], (relationshipStatus) => true);


  const isGeneralInformationFormValid = () => {
    nameBlurHandler();
    regionBlurHandler();
    countryBlurHandler();
    totalNumOfSharesBlurHandler();
    const isMandatoryFieldsValid = isNameValid && isClientTypeValid && isRegionValid && isCountryValid;
    return isMandatoryFieldsValid && ((clientType === 'Corporate' && isTotalNumOfSharesValid && isDateNumOfSharesValid) || (clientType === 'Non-Corporate'));
  }

  const isMainContactFormValid = () => {
    fullnameBlurHandler();
    jobTitleBlurHandler();
    departmentBlurHandler();
    emailBlurHandler();
    return isFullnameValid && isJobTitleValid && isDepartmentValid && isEmailValid && isPhoneNumberValid && isRelationshipStatusValid;
  }

  const [pagePreference, setPagePreference] = useState('next');

  const saveClientGeneralInfo = (clickedButton) => {
    const updatedGeneralInformationData = {
      'name': name,
      'region': region,
      'country': country,
      'profile_picture_url': profilePictureUrl,
      'client_type': clientType
    };
    if(clientType === 'Corporate') {
      updatedGeneralInformationData['total_num_of_shares'] = commaSeperatedValueToNumber(totalNumOfShares);
      updatedGeneralInformationData['date_num_of_shares'] = dateNumOfShares;
    }
    else {
      updatedGeneralInformationData['total_num_of_shares'] = 0;
      updatedGeneralInformationData['date_num_of_shares'] = '';
    }

    const updatedMainContactData = {
      'fullname': fullname,
      'job_title': jobTitle,
      'department': department,
      'email': email,
      'phone_number': phoneNumber,
      'relationship_status': relationshipStatus
    };
    if(clickedButton === 'next') {
      props.onNextClick(updatedGeneralInformationData, updatedMainContactData, 'next');
    }
    else if(clickedButton === 'quit') {
      props.onSaveAndQuitClick(updatedGeneralInformationData, updatedMainContactData, 'quit');
    }
  }


  const saveWithValidationHandler = (clickedButton) => {
    setPagePreference(clickedButton);
    if(!isGeneralInformationFormValid()) {
      setShowGeneralInfoWarning(true);
    }
    else if(!isMainContactFormValid()) {
      setShowMainContactWarning(true);
    }
    else {
      saveClientGeneralInfo(clickedButton);
    }
  }


  const propsGeneralInformationData = props.generalInformationData;
  const propsMainContactData = props.mainContactData;
  useEffect(() => {
    /* Company Information */
    setName(propsGeneralInformationData['name']);
    setClientType(propsGeneralInformationData['client_type']);
    setRegion(propsGeneralInformationData['region']);
    setCountry(propsGeneralInformationData['country']);
    setTotalNumOfShares(propsGeneralInformationData['total_num_of_shares']);
    setDateNumOfShares(
      (propsGeneralInformationData['date_num_of_shares'] !== null && propsGeneralInformationData['date_num_of_shares'] !== undefined && propsGeneralInformationData['date_num_of_shares'] !== '') ?
        new Date(propsGeneralInformationData['date_num_of_shares']) : 
        ''
    );

    /* Main Contact */
    setFullname(propsMainContactData['fullname']);
    setJobTitle(propsMainContactData['job_title']);
    setDepartment(propsMainContactData['department']);
    setEmail(propsMainContactData['email']);
    setPhoneNumber(propsMainContactData['phone_number']);
    setRelationshipStatus(propsMainContactData['relationship_status']);
  }, [propsGeneralInformationData, propsMainContactData, 
      setName, setClientType, setRegion, setCountry, setTotalNumOfShares, setDateNumOfShares,
      setFullname, setJobTitle, setDepartment, setEmail, setPhoneNumber, setRelationshipStatus
     ]);


  const [showGeneralInfoWarning, setShowGeneralInfoWarning] = useState(false);
  const [showMainContactWarning, setShowMainContactWarning] = useState(false);


  const companyPhotoChangeHandler = (event) => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if(file.size > 500000){
        alert('File size should be smaller than 450Kb');
        return false;				
      }

      var data = new FormData();
			data.append('profile_picture', file, file.name);
      props.onProfilePictureUploadClick(data, setProfilePictureUrl);
    }
  }


  return (
    <div className={`${styles['client-setup-general-info-step']}`}>
      { showGeneralInfoWarning && 
        <Modal
          type='warning'
          title='Missing Field Error'
          message='One or several fields are missing. Please review and fill in missing information to be able to complete General Info set-up.'
          onCloseClick={() => setShowGeneralInfoWarning(false)}
          approveButton = {{
            'text': 'Close',
            'onClick': () => setShowGeneralInfoWarning(false)
          }}
        />
      }

      { showMainContactWarning && 
        <Modal
          type='warning'
          title='Missing Field Error'
          message='One or several fields are missing. Please review and fill in missing information to be able to complete General Info set-up.'
          onCloseClick={() => setShowMainContactWarning(false)}
          approveButton = {{
            'text': 'Continue',
            'onClick': () => saveClientGeneralInfo(pagePreference)
          }}
          cancelButton = {{
            'text': 'Cancel',
            'onClick': () => setShowMainContactWarning(false)
          }}
        />
      }

      <div className={styles['company-information-form']}>
        <div className={styles['title']}>
          <h3>Company Information</h3>
        </div>
        <div className={styles['row']}>
          <div className={styles['client-logo-upload-container']}>
            <div className={styles['client-logo']}>
              <div className={styles['logo']} style={{backgroundImage:`url("${profilePictureUrl}")`}}></div>
              <label className={styles['client-logo-upload-button']}>
                <div className={styles['plus-icon']}></div>
                <input type='file' accept='image/gif, image/jpeg, image/png' onChange={companyPhotoChangeHandler} />
              </label>
            </div>
            <h4>Company Logo</h4>
          </div>
        </div>
        <div className={styles['row']}>
          <TextInput
            id='general-info#investor-name'
            width='100%'
            label='Company Name *'
            placeholder='Enter Company Name'
            value={name}
            hasError={hasNameError}
            onBlur={nameBlurHandler}
            onChange={nameChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='subscription-details#client-type'
            width='100%'
            label='Client Type'
            placeholder='Select a Client Type Option'
            items={clientTypeDropdownItems}
            selected={clientType}
            hasError={hasClientTypeError}
            onBlur={clientTypeBlurHandler}
            onItemClick={clientTypeChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='general-info#region'
            width='100%'
            label='Region *'
            placeholder='Select a Region'
            items={regionDropdownItems}
            selected={region}
            hasError={hasRegionError}
            onBlur={regionBlurHandler}
            onItemClick={regionChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='general-info#country'
            width='100%'
            label='Country *'
            placeholder='Select a Country'
            items={countryDropdownItems}
            selected={country}
            hasSearchBox={true}
            hasError={hasCountryError}
            onBlur={countryBlurHandler}
            onItemClick={countryChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <NumberInput
            id='general-info#total-num-of-shares'
            width='100%'
            label='Total # of Shares *'
            placeholder='Enter Total # of Shares'
            value={(clientType === 'Non-Corporate') ? '-' : totalNumOfShares}
            hasError={hasTotalNumOfSharesError}
            onBlur={totalNumOfSharesBlurHandler}
            onChange={totalNumOfSharesChangeHandler}
            isDisabled={(clientType === 'Non-Corporate') ? true : false}
          />
        </div>
        <div className={styles['row']}>
          {clientType !== "Non-Corporate" &&
            <DateInput
              id='general-info#date-num-of-shares'
              width='100%'
              label='Date Of # Of Shares'
              selected={dateNumOfShares}
              hasError={hasDateNumOfSharesError}
              onBlur={dateNumOfSharesBlurHandler}
              onChange={dateNumOfSharesChangeHandler}
            />
          }
          {clientType === 'Non-Corporate' &&
            <TextInput
              id='general-info#date-num-of-shares'
              width='100%'
              label='Date Of # Of Shares'
              value='-'
              isDisabled={true}
            />
          }
        </div>
      </div>
      <div className={styles['main-contact-form']}>
        <div className={styles['title']}>
          <h3>Main Contact Information</h3>
        </div>
        <div className={styles['row']} style={{marginTop: 44}}>
          <TextInput
            id='main-contact#fullname'
            width='100%'
            label='Full Name *'
            placeholder='Enter Name and Surname'
            value={fullname}
            hasError={hasFullnameError}
            onBlur={fullnameBlurHandler}
            onChange={fullnameChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <TextInput
            id='main-contact#title'
            width='100%'
            label='Job Title *'
            placeholder='Enter Job Title'
            value={jobTitle}
            hasError={hasJobTitleError}
            onBlur={jobTitleBlurHandler}
            onChange={jobTitleChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='main-contact#department'
            width='100%'
            label='Department *'
            placeholder='Select a Department'
            items={departmentDropdownItems}
            selected={department}
            hasError={hasDepartmentError}
            onBlur={departmentBlurHandler}
            onItemClick={departmentChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <TextInput
            id='main-contact#email'
            width='100%'
            label='Email *'
            placeholder='Enter Email'
            value={email}
            hasError={hasEmailError}
            onBlur={emailBlurHandler}
            onChange={emailChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <TextInput
            id='main-contact#phone-number'
            width='100%'
            label='Phone Number'
            placeholder='Enter Phone Number'
            value={phoneNumber}
            hasError={hasPhoneNumberError}
            onBlur={phoneNumberBlurHandler}
            onChange={phoneNumberChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='main-contact#relationship-status'
            width='100%'
            label='Relationship Status'
            placeholder='Select a Relationship Status'
            items={relationshipStatusDropdownItems}
            selected={relationshipStatus}
            hasChip={true}
            hasError={hasRelationshipStatusError}
            onBlur={relationshipStatusBlurHandler}
            onItemClick={relationshipStatusChangeHandler}
          />
        </div>
      </div>

      <div className={styles['action-buttons-container']} style={{'width': props.pageWidth}}>
        <div className={styles['right-buttons']}>
          <Button type={'cancel'} size={'base'} onClick={() => saveWithValidationHandler('quit')}>Save & Quit</Button>
          <Button type={'primary'} size={'base'} rightIcon={'arrow-right'} onClick={() => saveWithValidationHandler('next')}>Next</Button>
        </div>
      </div>
    </div>
  );
}

export default ClientSetupGeneralInfo;
