import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import parse from 'html-react-parser';

import styles from '../EsgAllTopicsContainer.module.css';


const EsgAllTopicsQuestionInvestorItem = (props) => {
  const questionId = props.questionId;
  const investorId = props.investorId;

  const [isOpened, setIsOpened] = useState(false);
  const [comment, setComment] = useState('');

  const showQuestionCommentHandler = () => {
    if (!isOpened) {
      props.onShowQuestionCommenClick(investorId, questionId, setComment, setIsOpened);
    }
    else {
      setIsOpened(false);
    }
  }

  return (
    <div className={styles['question-investor-item']} onClick={showQuestionCommentHandler}>
      <div className={styles['header']}>
        <div className={styles['title']}>
          <h4>{props.investorName}</h4>
        </div>
        <div className={styles['icon-container']}>
          <div className={styles['drawer-icon']}>
            <div className={styles['caret']}></div>
          </div>
          {props.isPriority && 
            <div className={styles['priority-icon']}>
              <div className={styles['flag']} data-tooltip-id={`question-investor-priority-tooltip`}>
                <Tooltip
                  id={`question-investor-priority-tooltip`}
                  place='bottom'
                  content='Investor Priority'
                  className='aqtion-tooltip'
                />
              </div>
            </div>
          }
        </div>
      </div>
      {isOpened &&
        <div className={styles['body']}>
          {parse(comment)}
        </div>
      }
    </div>
  );
}


const EsgAllTopicsQuestionProxyAdvisorItem = (props) => {
  const questionId = props.questionId;
  const proxyAdvisorId = props.proxyAdvisorId;

  const [isOpened, setIsOpened] = useState(false);
  const [comment, setComment] = useState('');

  const showQuestionCommentHandler = () => {
    if (!isOpened) {
      props.onShowQuestionCommenClick(proxyAdvisorId, questionId, setComment, setIsOpened);
    }
    else {
      setIsOpened(false);
    }
  }

  return (
    <div className={styles['question-investor-item']} onClick={showQuestionCommentHandler}>
      <div className={styles['header']}>
        <div className={styles['title']}>
          <h4>{props.proxyAdvisorName}</h4>
        </div>
        <div className={styles['icon-container']}>
          <div className={styles['drawer-icon']}>
            <div className={styles['caret']}></div>
          </div>
          {props.isPriority && 
            <div className={styles['priority-icon']}>
              <div className={styles['flag']} data-tooltip-id={`question-proxy-advisor-priority-tooltip`}>
                <Tooltip
                  id={`question-proxy-advisor-priority-tooltip`}
                  place='bottom'
                  content='Proxy Advisor Priority'
                  className='aqtion-tooltip'
                />
              </div>
            </div>
          }
        </div>
      </div>
      {isOpened &&
        <div className={styles['body']}>
          {parse(comment)}
        </div>
      }
    </div>
  );
}


const EsgAllTopicsQuestionDetails = (props) => {
  const questionId = props.id;
  const investors = props.investors;
  const proxyAdvisors = props.proxyAdvisors;

  const generateInvestorList = () => {
    return investors.map(item => {
      return (
        <EsgAllTopicsQuestionInvestorItem
          key={`question-investor-item-${item['client_investor_id']}`}
          questionId={questionId}
          investorId={item['client_investor_id']}
          investorName={item['investor_name']}
          isPriority={item['is_priority']}
          onShowQuestionCommenClick={props.onShowQuestionInvestorCommenClick}
        />
      );
    });
  }

  const generateProxyAdvisorList = () => {
    return proxyAdvisors.map(item => {
      return (
        <EsgAllTopicsQuestionProxyAdvisorItem
          key={`question-proxy-advisor-item-${item['client_proxy_advisor_id']}`}
          questionId={questionId}
          proxyAdvisorId={item['client_proxy_advisor_id']}
          proxyAdvisorName={item['investor_name']}
          isPriority={item['is_priority']}
          onShowQuestionCommenClick={props.onShowQuestionProxyAdvisorCommenClick}
        />
      );
    });
  }

  return (
    <div className={styles['question-details']}>
      <div className={styles['topic-title']}>
        <h3>{props.topicTitle}</h3>
      </div>
      <div className={styles['question-text']}>
        <h3>{props.text}</h3>
      </div>
      <div className={styles['question-description']}>
        <p>{props.description}</p>
      </div>
      <div className={styles['question-investor-list']}>
        <div className={styles['header-item']}>
          <div className={styles['num-of-comments-icon']}>
            <div className={styles['num-of-comments']}></div>
            <p>Proxy Advisor Commentary ({proxyAdvisors.length})</p>
          </div>
        </div>
        {proxyAdvisors.length > 0 &&
          generateProxyAdvisorList()
        }
      </div>
      <div className={styles['question-investor-list']}>
        <div className={styles['header-item']}>
          <div className={styles['num-of-comments-icon']}>
            <div className={styles['num-of-comments']}></div>
            <p>Investor Commentary ({investors.length})</p>
          </div>
        </div>
        {investors.length > 0 &&
          generateInvestorList()
        }
      </div>
    </div>
  );
}

export default EsgAllTopicsQuestionDetails;
