import Plot from "react-plotly.js";

import styles from './HorizontalBarChart.module.css';

const HorizontalBarChart = (props) => {
  const generateBarItems = () => {
    return props.data['items'].map(item => {
      return {
        type: 'bar',
        // x: [item['value']],
        x: [item['percentage']],
        y: [props.id],
        name: item['name'],
        orientation: 'h',
        marker: {
          color: item['color'],
          width: 1,
          line: {color: '#FFFFFF', width: 2}
        }
      };
    });
  }

  const generateInfoBox = () => {
    return props.data['items'].map(item => {
      return (
        <div key={`info-box-${props.id}-${item['id']}`} className={styles['item']}>
          <div className={styles['color']} style={{backgroundColor: item['color']}}></div>
          <div className={styles['text']}>
            <h4>{item['name']}</h4>
          </div>
          <div className={styles['percentage']}>
            <h4>{`(${item['percentage']}%)`}</h4>
          </div>
          <div className={styles['count']}>
            <h4>{`${item['value']}`}</h4>
          </div>
        </div>
      );
    });
  }

  return (
    <div className={styles['horizonral-bar-chart-container']}>
      <div className={styles['graph']}>
        <Plot
          data={generateBarItems()}
          layout={{
            autosize: false,
            width: props.width,
            height: props.height,
            barmode: 'stack',
            showlegend: false,
            margin: { l: 0, r: 0, t: 0, b: 0, autoexpand: false },
            plot_bgcolor: "rgba(0,0,0,0)",
            paper_bgcolor: "rgba(0,0,0,0)",
            hovermode: false
          }}
          config={{
            showLink: false,
            staticPlot: true
          }}
        />
      </div>
      <div className={styles['info-box']}>
        <div className={styles['item']}>
          {generateInfoBox()}
        </div>
      </div>
    </div>
  );
}

export default HorizontalBarChart;
