import { useEffect, useState } from 'react';
import { resendLoginCodeRequest, userLoginRequest, validateUserLoginCodeRequest } from '../../../requests/auth-requests';
import useInputValidation from '../../../hooks/use-input-validation';
import useHttp from '../../../hooks/use-http';

import Button from '../../../components/UI/Button/Button';
import LinkButton from '../../../components/UI/LinkButton/LinkButton';
import TextInput from '../../../components/UI/TextInput/TextInput';
import PasswordInput from '../../../components/UI/PasswordInput/PasswordInput';

import styles from './LoginPage.module.css';
import { getClientAvailableModules, setLocalStorageClientData } from '../../../utils/auth';
import { fetchClientBasicProfileRequest } from '../../../requests/client-requests';

const LoginPage = (props) => {
  const [email, setEmail, isEmailValid, hasEmailError, emailChangeHandler, emailBlurHandler] = 
    useInputValidation('', (email) => email.trim() !== '');

  const [password, setPassword, isPasswordValid, hasPasswordError, passwordChangeHandler, passwordBlurHandler] = 
    useInputValidation('', (password) => password.trim() !== '');

  const [userData, setUserData] = useState(null);

  const [loginCodeDigit1, setLoginCodeDigit1, isLoginCodeDigit1Valid, hasLoginCodeDigit1Error, loginCodeDigit1ChangeHandler, loginCodeDigit1BlurHandler] = 
    useInputValidation('', (loginCodeDigit1) => loginCodeDigit1.trim() !== '');
  
  const [loginCodeDigit2, setLoginCodeDigit2, isLoginCodeDigit2Valid, hasLoginCodeDigit2Error, loginCodeDigit2ChangeHandler, loginCodeDigit2BlurHandler] = 
    useInputValidation('', (loginCodeDigit2) => loginCodeDigit2.trim() !== '');
  
  const [loginCodeDigit3, setLoginCodeDigit3, isLoginCodeDigit3Valid, hasLoginCodeDigit3Error, loginCodeDigit3ChangeHandler, loginCodeDigit3BlurHandler] = 
    useInputValidation('', (loginCodeDigit3) => loginCodeDigit3.trim() !== '');
  
    const [loginCodeDigit4, setLoginCodeDigit4, isLoginCodeDigit4Valid, hasLoginCodeDigit4Error, loginCodeDigit4ChangeHandler, loginCodeDigit4BlurHandler] = 
    useInputValidation('', (loginCodeDigit4) => loginCodeDigit4.trim() !== '');
  
    const [loginCodeDigit5, setLoginCodeDigit5, isLoginCodeDigit5Valid, hasLoginCodeDigit5Error, loginCodeDigit5ChangeHandler, loginCodeDigit5BlurHandler] = 
    useInputValidation('', (loginCodeDigit5) => loginCodeDigit5.trim() !== '');
  
    const [loginCodeDigit6, setLoginCodeDigit6, isLoginCodeDigit6Valid, hasLoginCodeDigit6Error, loginCodeDigit6ChangeHandler, loginCodeDigit6BlurHandler] = 
    useInputValidation('', (loginCodeDigit6) => loginCodeDigit6.trim() !== '');

  const [isLoading, error, sendRequest] = useHttp();

  const isLoginFormValid = () => {
    return isEmailValid && isPasswordValid;
  }

  const [activeForm, setActiveForm] = useState('login-form');

  const [formError, setFormError] = useState({
    hasError: false,
    title: '',
    text: ''
  });

  const [resendCodeInfo, setResendCodeInfo] = useState({
    hasInfo: false,
    title: '',
    text: ''
  });

  const loginHandler = () => {
    if (!isLoginFormValid) {
      return;
    }

    const body = {
      'email': email,
      'password': password
    };
    sendRequest(
      userLoginRequest(body),
      (data) => {
        if(data['is_account_locked'] === true) { 
          setFormError({
            hasError: true,
            title: 'Account Locked',
            text: `Previously, you entered wrong credentials 3 times in a row. Your account is locked at the moment. Please contact with your Account Manager.`
          });
          return;
        }

        setFormError({
          hasError: false,
          title: '',
          text: ''
        });

        setUserData(data);
        setActiveForm('validate-login-code-form');
      }
    );
  }

  const isLoginCodeValid = () => {
    return isLoginCodeDigit1Valid && isLoginCodeDigit2Valid && isLoginCodeDigit3Valid &&
            isLoginCodeDigit4Valid && isLoginCodeDigit5Valid && isLoginCodeDigit6Valid;
  }

  const loginCodeCheckHandler = () => {
    if (!isLoginCodeValid()) {
      return;
    }

    const body = {
      'user_id': userData['id'],
      'login_code': `${loginCodeDigit1}${loginCodeDigit2}${loginCodeDigit3}-${loginCodeDigit4}${loginCodeDigit5}${loginCodeDigit6}`
    };
    sendRequest(
      validateUserLoginCodeRequest(body),
      (data) => {
        if(data['is_account_locked'] === true) { 
          setFormError({
            hasError: true,
            title: 'Account Locked',
            text: `Previously, you entered wrong credentials 3 times in a row. Your account is locked at the moment. Please contact with your Account Manager.`
          });
          return;
        }
        
        localStorage.setItem('token', data['token']);
        localStorage.setItem('accessToken', data['access_token']);
        localStorage.setItem('clientId', data['client_id']);
        localStorage.setItem('userRole', userData['user_role']);
        localStorage.setItem('userType', userData['user_type']);
        localStorage.setItem('userName', userData['firstname'] + ' ' + userData['lastname']);
        localStorage.setItem('userProfilePictureUrl', userData['profile_picture_url']);

        setFormError({
          hasError: false,
          title: '',
          text: ''
        });

        if (data['user_role'] === 'Client') {
          sendRequest(
            fetchClientBasicProfileRequest(data['client_id']),
            (dataClient) => {
              setLocalStorageClientData(dataClient);
              const clientAvailableModules = getClientAvailableModules();
              if(clientAvailableModules['dasboard']) {
                window.location.href = 'dashboard';
              }
              else {
                window.location.href = 'investors';
              }
            }
          );
        }
        else if (data['user_role'] === 'Account Manager') {
          window.location.href = 'clients';
        }
      }
    );
  }

  useEffect(() => {
    if(!isLoading && error !== undefined) {
      if (error.message === 'Not found!') { 
        setFormError({
          hasError: true,
          title: 'Wrong Username or Password',
          text: 'Please check entered username and password.'
        });
      }
      else if(activeForm === 'login-form' && error.status === 401) {
        const remaining_wrong_attempts = 3 - error.num_of_wrong_attempts;
        if (remaining_wrong_attempts === 1) {
          setFormError({
            hasError: true,
            title: 'Wrong Username or Password',
            text: `Username or password is wrong. ${remaining_wrong_attempts} attempt remaining before your account is locked.`
          });
        }
        else if (remaining_wrong_attempts === 2) {
          setFormError({
            hasError: true,
            title: 'Wrong Username or Password',
            text: `Username or password is wrong. ${remaining_wrong_attempts} attempts remaining before your account is locked.`
          });
        }
        else {
          setFormError({
            hasError: true,
            title: 'Wrong Username or Password',
            text: `You've entered wrong credentials 3 times in a row, your account has been locked. Please contact with your Account Manager.`
          });
        }
      }
      else if(activeForm === 'validate-login-code-form' && error.status === 401) {
        setFormError({
          hasError: true,
          title: 'Wrong Login Code',
          text: `Login code is wrong. Please enter the login code that you received before it expires.`
        });
      }
      else if(activeForm === 'validate-login-code-form' && error.status === 410) {
        setFormError({
          hasError: true,
          title: 'Expired Login Code',
          text: `Login code is expired. Please go back and login again to receive another code.`
        });
      }
    }
  }, [isLoading, error, activeForm]);

  useEffect(() => {
    setEmail('');
    setPassword('');
    setLoginCodeDigit1('');
    setLoginCodeDigit2('');
    setLoginCodeDigit3('');
    setLoginCodeDigit4('');
    setLoginCodeDigit5('');
    setLoginCodeDigit6('');
  }, [setEmail, setPassword, setLoginCodeDigit1, setLoginCodeDigit2, setLoginCodeDigit3,
        setLoginCodeDigit4, setLoginCodeDigit5, setLoginCodeDigit6]);

  const loginCodeDigit1ChangeAndMoveHandler = (enteredValue) => {
    loginCodeDigit1ChangeHandler(enteredValue);
    document.getElementById('login-form#code-box-2').focus();
  }

  const loginCodeDigit2ChangeAndMoveHandler = (enteredValue) => {
    loginCodeDigit2ChangeHandler(enteredValue);
    document.getElementById('login-form#code-box-3').focus();
  }

  const loginCodeDigit3ChangeAndMoveHandler = (enteredValue) => {
    loginCodeDigit3ChangeHandler(enteredValue);
    document.getElementById('login-form#code-box-4').focus();
  }

  const loginCodeDigit4ChangeAndMoveHandler = (enteredValue) => {
    loginCodeDigit4ChangeHandler(enteredValue);
    document.getElementById('login-form#code-box-5').focus();
  }

  const loginCodeDigit5ChangeAndMoveHandler = (enteredValue) => {
    loginCodeDigit5ChangeHandler(enteredValue);
    document.getElementById('login-form#code-box-6').focus();
  }

  const resendCodeHandler = () => {
    const body = {
      'user_id': userData['id']
    };

    sendRequest(
      resendLoginCodeRequest(body),
      (data) => {
        setResendCodeInfo({
          hasInfo: true,
          title: 'Login Code Has Been Sent',
          text: `Login code has been sent again. Please contact your Account Manager if you don't receive the code.`
        });

        setLoginCodeDigit1('');
        setLoginCodeDigit2('');
        setLoginCodeDigit3('');
        setLoginCodeDigit4('');
        setLoginCodeDigit5('');
        setLoginCodeDigit6('');
      }
    );
  }

  return (
    <div className={styles['login-page']}>
      {activeForm === 'login-form' && 
        <div className={styles['login-form-container']}>
          <div className={styles['form-row']}>
            <div className={styles['logo-area']}>
              <div className={styles['logo']}></div>
            </div>
          </div>
          <div className={styles['form-row']}>
            <div className={styles['title']}>
              <h3>Sign in</h3>
            </div>
          </div>
          <div className={styles['form-row']}>
            <TextInput
              id='login-form#email'
              width='100%'
              label='Email'
              placeholder='Enter your Email'
              value={email}
              hasError={hasEmailError}
              warningText={hasEmailError && 'Email needs to be filled!'}
              onBlur={emailBlurHandler}
              onChange={emailChangeHandler}
              isDisabled={false}
            />
          </div>
          <div className={styles['form-row']}>
            <PasswordInput
              id='login-form#password'
              width='100%'
              label='Password'
              placeholder='Enter your Password'
              value={password}
              hasError={hasPasswordError}
              warningText={hasPasswordError && 'Password needs to be filled!'}
              onBlur={passwordBlurHandler}
              onChange={passwordChangeHandler}
              isDisabled={false}
            />
          </div>
          {formError['hasError'] && 
            <div className={styles['form-row']}>
              <div className={styles['form-error']}>
                <div className={styles['error-title']}>
                  <div className={styles['warning-icon']}></div>
                  <h4>{formError['title']}</h4>
                </div>
                <div className={styles['error-content']}>
                  <p>{formError['text']}</p>
                </div>
              </div>
            </div>
          }
          <div className={styles['form-row']}>
            <Button width='100%' size='base' type='primary' onClick={loginHandler} isDisabled={false}>Sign in</Button>
          </div>
          <div className={styles['form-row']}>
            <LinkButton onClick={() => window.location.href='forgot-password'}width='100%' type={"primary"} size={"base"}>Forgot Your Password?</LinkButton>
          </div>
          <div className={styles['form-row']}>
            <div className={styles['note']}>
              <p>If you don’t have a username and/or password, please contact your Account Manager.</p>
            </div>
          </div>
        </div>
      }

      {activeForm === 'validate-login-code-form' && 
        <div className={styles['login-form-container']}>
          <div className={styles['form-row']}>
            <div className={styles['logo-area']}>
              <div className={styles['logo']}></div>
            </div>
          </div>
          <div className={styles['form-row']}>
            <div className={`${styles['title']} ${styles['no-bottom-padding']}`}>
              <h3>Sign in</h3>
            </div>
          </div>
          <div className={styles['form-row']}>
            <div className={styles['description']}>
              <p>
                An e-mail has been sent to your address <strong>{`${email}`}</strong> with a validation code that you may enter below.
              </p>
            </div>
          </div>
          <div className={styles['form-row']}>
            <div className={styles['code-area']}>
              <div className={styles['code-box']}>
                <TextInput
                  id='login-form#code-box-1'
                  width='40px'
                  placeholder=''
                  value={loginCodeDigit1}
                  hasError={hasLoginCodeDigit1Error}
                  onBlur={loginCodeDigit1BlurHandler}
                  onChange={loginCodeDigit1ChangeAndMoveHandler}
                  isDisabled={false}
                />
              </div>
              <div className={styles['code-box']}>
                <TextInput
                  id='login-form#code-box-2'
                  width='40px'
                  placeholder=''
                  value={loginCodeDigit2}
                  hasError={hasLoginCodeDigit2Error}
                  onBlur={loginCodeDigit2BlurHandler}
                  onChange={loginCodeDigit2ChangeAndMoveHandler}
                  isDisabled={false}
                />
              </div>
              <div className={styles['code-box']}>
                <TextInput
                  id='login-form#code-box-3'
                  width='40px'
                  placeholder=''
                  value={loginCodeDigit3}
                  hasError={hasLoginCodeDigit3Error}
                  onBlur={loginCodeDigit3BlurHandler}
                  onChange={loginCodeDigit3ChangeAndMoveHandler}
                  isDisabled={false}
                />
              </div>
              <div className={styles['code-separator']}></div>
              <div className={styles['code-box']}>
                <TextInput
                  id='login-form#code-box-4'
                  width='40px'
                  placeholder=''
                  value={loginCodeDigit4}
                  hasError={hasLoginCodeDigit4Error}
                  onBlur={loginCodeDigit4BlurHandler}
                  onChange={loginCodeDigit4ChangeAndMoveHandler}
                  isDisabled={false}
                />
              </div>
              <div className={styles['code-box']}>
                <TextInput
                  id='login-form#code-box-5'
                  width='40px'
                  placeholder=''
                  value={loginCodeDigit5}
                  hasError={hasLoginCodeDigit5Error}
                  onBlur={loginCodeDigit5BlurHandler}
                  onChange={loginCodeDigit5ChangeAndMoveHandler}
                  isDisabled={false}
                />
              </div>
              <div className={styles['code-box']}>
                <TextInput
                  id='login-form#code-box-6'
                  width='40px'
                  placeholder=''
                  value={loginCodeDigit6}
                  hasError={hasLoginCodeDigit6Error}
                  onBlur={loginCodeDigit6BlurHandler}
                  onChange={loginCodeDigit6ChangeHandler}
                  isDisabled={false}
                />
              </div>
            </div>
          </div>
          {formError['hasError'] && 
            <div className={styles['form-row']}>
              <div className={styles['form-error']}>
                <div className={styles['error-title']}>
                  <div className={styles['warning-icon']}></div>
                  <h4>{formError['title']}</h4>
                </div>
                <div className={styles['error-content']}>
                  <p>{formError['text']}</p>
                </div>
              </div>
            </div>
          }
          <div className={styles['form-row']}>
            <Button width='100%' size='base' type='primary' onClick={loginCodeCheckHandler} isDisabled={false}>Submit</Button>
          </div>
          {!resendCodeInfo['hasInfo'] &&
            <div className={styles['form-row']}>
              <div className={styles['note']}>
                <p>Have you not received the code? </p>
                <div className={styles['send-code-button']}>
                  <LinkButton type={"primary"} size={"xsmall"} hasUnderline={false} onClick={resendCodeHandler}>Resend Code</LinkButton>
                </div>
              </div>
            </div>
          }
          {resendCodeInfo['hasInfo'] &&
            <div className={styles['form-row']}>
              <div className={styles['form-info']}>
                <div className={styles['info-title']}>
                  <div className={styles['warning-icon']}></div>
                  <h4>{resendCodeInfo['title']}</h4>
                </div>
                <div className={styles['info-content']}>
                  <p>{resendCodeInfo['text']}</p>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>
  );
}

export default LoginPage;
