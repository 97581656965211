import { useEffect, useState } from 'react';

import Button from '../../../../components/UI/Button/Button';
import Modal from '../../../../components/UI/Modal/Modal';

import styles from './ClientSetupFinalStep.module.css';

const ClientSetupFinalStep = (props) => {
  const clientSteupData = props.clientSetupData;
  const [numOfMissingFieldsGeneralInfo, setNumOfMissingFieldsGeneralInfo] = useState(0);
  const [numOfMissingFieldsSubscriptionDetails, setNumOfMissingFieldsSubscriptionDetails] = useState(0);
  const [numOfMissingUsers, setNumOfMissingUsers] = useState(0);
  const [hasError, setHasError] = useState(null);

  const [clientSetupCompletionApproval, setClientSetupCompletionApproval] = useState(false);
  
  useEffect(() => {
    let updatedNumOfMissingFieldsGeneralInfo = 0;
    const companyInformationData = clientSteupData['general_information']['data'];
    const mainContactData = clientSteupData['main_contact']['data'];
    
    // Company Information - Check Missing Fields
    if(companyInformationData['name'] === '') {
      updatedNumOfMissingFieldsGeneralInfo += 1;
    }
    if(companyInformationData['region'] === '') {
      updatedNumOfMissingFieldsGeneralInfo += 1;
    }
    if(companyInformationData['region'] === '') {
      updatedNumOfMissingFieldsGeneralInfo += 1;
    }
    if(companyInformationData['total_num_of_shares'] === '') {
      updatedNumOfMissingFieldsGeneralInfo += 1;
    }

    // Main Contact - Check Missing Fields
    if(mainContactData['fullname'] === '') {
      updatedNumOfMissingFieldsGeneralInfo += 1;
    }
    if(mainContactData['job_title'] === '') {
      updatedNumOfMissingFieldsGeneralInfo += 1;
    }
    if(mainContactData['department'] === '') {
      updatedNumOfMissingFieldsGeneralInfo += 1;
    }
    if(mainContactData['email'] === '') {
      updatedNumOfMissingFieldsGeneralInfo += 1;
    }

    setNumOfMissingFieldsGeneralInfo(updatedNumOfMissingFieldsGeneralInfo);

    // Subscription Details - Check Missing Fields
    let updatedNumOfMissingFieldsSubscriptionDetails = 0;
    const subscriptionDetailsData = clientSteupData['subscription_details']['data'];
    if(subscriptionDetailsData['number_of_investors'] === '') {
      updatedNumOfMissingFieldsSubscriptionDetails += 1;
    }
    if(subscriptionDetailsData['number_of_users'] === '') {
      updatedNumOfMissingFieldsSubscriptionDetails += 1;
    }
    setNumOfMissingFieldsSubscriptionDetails(updatedNumOfMissingFieldsSubscriptionDetails);

    // User Management - Check Missing Users
    let updatedNumOfMissingUsers = 0;
    const userManagementData = clientSteupData['users']['items'];
    if(subscriptionDetailsData['number_of_users'] === '') {
      updatedNumOfMissingUsers = -1;
    }
    else {
      updatedNumOfMissingUsers = subscriptionDetailsData['number_of_users'] - userManagementData.length;
    }
    setNumOfMissingUsers(updatedNumOfMissingUsers);

    setHasError(
      (updatedNumOfMissingFieldsGeneralInfo !== 0) ||
      (updatedNumOfMissingFieldsSubscriptionDetails !== 0) ||
      (updatedNumOfMissingUsers !== 0) ||
      !clientSteupData['proxy_advisors']['is_activated'] ||
      !clientSteupData['investors']['is_activated']
    );
  }, [clientSteupData]);


  const activateClientSetupHandler = () => {
    props.onCompleteClick();
    setClientSetupCompletionApproval(false);
  }


  return (
    <div className={styles['client-setup-final-step']}>
      { clientSetupCompletionApproval &&
        <Modal
          type='info'
          title='Complete Client Setup?'
          message='Once you "Activate" Client Setup, an email will be sent to registered users to confirm AQTION setup and provide them with relevant credentials. Are you sure you want to proceed with activation?'
          onCloseClick={() => setClientSetupCompletionApproval(false)}
          approveButton = {{
            'text': 'Activate',
            'onClick': () => activateClientSetupHandler()
          }}
          cancelButton = {{
            'text': 'Cancel',
            'onClick': () => setClientSetupCompletionApproval(false)
          }}
        />
      }

      { hasError !== null && hasError &&
        <div className={styles['text-container']}>
          <h3>You’re about to finalize new Client set-up...</h3>
          <p>Make sure to fill in missing info in below mentioned sections to be able to complete the setup.</p>
        </div>
      }

      { hasError !== null && !hasError &&
        <div className={styles['text-container']}>
          <h3>You’re about to finalize new Client set-up...</h3>
          <p>Once setup "completed", you may proceed with further edits from "Client Details" page.</p>
        </div>
      }

      { hasError !== null && hasError &&
        <div className={styles['error-list-container']}>
          { numOfMissingFieldsGeneralInfo > 0 && 
            <div className={styles['error-box']}>
              <div className={styles['step-number']}>
                <h3>1</h3>
              </div>
              <div className={styles['content']}>
                <div className={styles['title']}>
                  <h3>General Info</h3>
                </div>
                <div className={styles['subtitle']}>
                  { numOfMissingFieldsGeneralInfo === 1 &&
                    <h4>{numOfMissingFieldsGeneralInfo} missing input</h4>
                  }
                  { numOfMissingFieldsGeneralInfo > 1 &&
                    <h4>{numOfMissingFieldsGeneralInfo} missing inputs</h4>
                  }
                </div>
              </div>
            </div>
          }

          { numOfMissingFieldsSubscriptionDetails > 0 && 
            <div className={styles['error-box']}>
              <div className={styles['step-number']}>
                <h3>2</h3>
              </div>
              <div className={styles['content']}>
                <div className={styles['title']}>
                  <h3>Subscription Details</h3>
                </div>
                <div className={styles['subtitle']}>
                  { numOfMissingFieldsSubscriptionDetails === 1 &&
                    <h4>{numOfMissingFieldsSubscriptionDetails} missing input</h4>
                  }
                  { numOfMissingFieldsSubscriptionDetails > 1 &&
                    <h4>{numOfMissingFieldsSubscriptionDetails} missing inputs</h4>
                  }
                </div>
              </div>
            </div>
          }

          { (numOfMissingUsers > 0 || numOfMissingUsers === -1) && 
            <div className={styles['error-box']}>
              <div className={styles['step-number']}>
                <h3>3</h3>
              </div>
              <div className={styles['content']}>
                <div className={styles['title']}>
                  <h3>User Management</h3>
                </div>
                <div className={styles['subtitle']}>
                  { numOfMissingUsers === -1 &&
                    <h4>Number of users not defined</h4>
                  }
                  { numOfMissingUsers === 1 &&
                    <h4>{numOfMissingUsers} missing user</h4>
                  }
                  { numOfMissingUsers > 1 &&
                    <h4>{numOfMissingUsers} missing users</h4>
                  }
                </div>
              </div>
            </div>
          }

          { !clientSteupData['proxy_advisors']['is_activated'] && 
            <div className={styles['error-box']}>
              <div className={styles['step-number']}>
                <h3>4</h3>
              </div>
              <div className={styles['content']}>
                <div className={styles['title']}>
                  <h3>Proxy Advisors</h3>
                </div>
                <div className={styles['subtitle']}>
                  <h4>Proxy advisor list is not activated</h4>
                </div>
              </div>
            </div>
          }

          { !clientSteupData['investors']['is_activated'] && 
            <div className={styles['error-box']}>
              <div className={styles['step-number']}>
                <h3>5</h3>
              </div>
              <div className={styles['content']}>
                <div className={styles['title']}>
                  <h3>Investor List</h3>
                </div>
                <div className={styles['subtitle']}>
                  <h4>Investor list is not activated</h4>
                </div>
              </div>
            </div>
          }
        </div>
      }

      { hasError !== null &&
        <div className={styles['button-container']}>
          <Button type='cancel' size='base' leftIcon='arrow-left' onClick={props.onPrevClick}>Previous</Button>
          <Button type='primary' size='base' width={160} onClick={() => setClientSetupCompletionApproval(true)} isDisabled={hasError}>Complete</Button>
        </div>
      }
    </div>
  );
}

export default ClientSetupFinalStep;
