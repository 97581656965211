import { deleteSession } from "./auth";

export const handleRequestError = (error) => {
  console.log('ERROR', error);
  if(error.status === 401) {
    if(error.message === "Missing Authorization Header") {
      console.log("Missing Authorization Header");
      deleteSession();
    }
    else if(error.message === "Token has expired") {
      console.log("Token has expired");
      deleteSession();
    }
  }
  else if(error.status === 403) {
    if(error.message === "Insufficient authority.") {
      console.log("Insufficient authority.");
    }
  }
}