import { Fragment, useEffect, useState } from "react";

import TextInput from "../UI/TextInput/TextInput";
import EsgAllTopicsQuestionList from "./EsgAllTopicsQuestionList/EsgAllTopicsQuestionList";

import styles from './EsgAllTopicsContainer.module.css';
import EsgAllTopicsQuestionDetails from "./EsgAllTopicsQuestionList/EsgAllTopicsQuestionDetails";
import EsgAllTopicsQuestionSearchResults from "./EsgAllTopicsQuestionList/EsgAllTopicsQuestionSearchResults";
import Button from "../UI/Button/Button";

const EsgAllTopicsContainer = (props) => {
  const esgTopics = props.esgTopics;

  const setBreadcrumbItems = props.setBreadcrumbItems;

  const [searchValue, setSearchValue] = useState('');
  const [restructuredEsgTopicsForSearch, setRestructuredEsgTopicsForSearch] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSubtopic, setSelectedSubtopic] = useState(null);
  const [openedTopicDrawerIds, setOpenedTopicDrawerIds] = useState([]);

  const [esgTopicInvestorsData, setEsgTopicInvestorsData] = useState([]);
  const [esgTopicProxyAdvisorsData, setEsgTopicProxyAdvisorsData] = useState([]);

  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    if (esgTopics !== null && esgTopics !== undefined && esgTopics.length > 0) {
      if (esgTopics[0]['subtopics'].length > 0) {
        setSelectedTopic(esgTopics[0]);
        setOpenedTopicDrawerIds([esgTopics[0]['id']]);
        setSelectedSubtopic(esgTopics[0]['subtopics'][0]);

        setBreadcrumbItems([{
          'text': 'ESG Topics',
          'href': ''
        },
        {
          'text': esgTopics[0]['name'],
          'href': ''
        },
        {
          'text': esgTopics[0]['subtopics'][0]['name'],
          'href': ''
        }]);
      }
    }
  }, [esgTopics, setBreadcrumbItems]);

  /* Filters topic list according to the search keyword that entered by the user. */
  const searchValueChangeHandler = (enteredValue) => {
    setSearchValue(enteredValue);
    setBreadcrumbItems([{
      'text': 'ESG Topics',
      'href': ''
    },
    {
      'text': 'Search Results',
      'href': ''
    }]);
    const items = JSON.parse(JSON.stringify(restructuredEsgTopicsForSearch));
    setSearchResults(items.map(item => {
      const filteredItem = item;
      filteredItem['questions'] = filteredItem['questions'].filter(question => question['text'].toLowerCase().includes(enteredValue.toLocaleLowerCase())).map(question => {
        const beginningIndex = question['text'].toLowerCase().indexOf(enteredValue.toLocaleLowerCase());
        const endingIndex = beginningIndex + enteredValue.length;
        const beginningPart = question['text'].substring(0, beginningIndex);
        const highlightedPart = question['text'].substring(beginningIndex, endingIndex);
        const endingPart = question['text'].substring(endingIndex);
        question['highlighted_text'] = <Fragment>{beginningPart}<span className={styles['highlighted']}>{highlightedPart}</span>{endingPart}</Fragment>;
        return question;
      });
      return filteredItem;
    }).filter(item => item['questions'].length > 0));
  }

  /* Shows list of questions located under the subtopic. */
  const selectSubtopicHandler = (topicId, subtopicId) => {
    const filteredTopics = esgTopics.filter(item => item['id'] === topicId);
    if (filteredTopics.length > 0) {
      const filteredSubtopics = filteredTopics[0]['subtopics'].filter(subItem => subItem['id'] === subtopicId);
      if (filteredTopics.length > 0) {
        setSelectedTopic(filteredTopics[0]);
        setSelectedSubtopic(filteredSubtopics[0]);
        setBreadcrumbItems([{
          'text': 'ESG Topics',
          'href': ''
        },
        {
          'text': filteredTopics[0]['name'],
          'href': ''
        },
        {
          'text': filteredSubtopics[0]['name'],
          'href': ''
        }]);
        setOpenedTopicDrawerIds(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          items.push(topicId);
          return items;
        });

        setSelectedQuestion(null);
        setEsgTopicProxyAdvisorsData([]);
        setEsgTopicInvestorsData([]);
      } 
    }
  }

  /* Returns the `list` viewing mode when the back button is clicked. */
  const backButtonClickHandler = () => {
    setSearchValue('');
    setSelectedQuestion(null);
    setEsgTopicProxyAdvisorsData([]);
    setEsgTopicInvestorsData([]);

    setBreadcrumbItems([{
      'text': 'ESG Topics',
      'href': ''
    },
    {
      'text': selectedTopic['name'],
      'href': ''
    },
    {
      'text': selectedSubtopic['name'],
      'href': ''
    }]);
  }

  /* Shows comments of selected question. */
  const openTopicDrawerHandler = (topicId) => {
    setOpenedTopicDrawerIds(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      items.push(topicId);
      return items;
    });
  }

  /* Hides comments area for the question. */
  const closeTopicDrawerHandler = (topicId) => {
    setOpenedTopicDrawerIds(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const selectedItemIndex = items.findIndex(item => item === topicId);
      items.splice(selectedItemIndex, 1);
      return items;
    });
  }

  const showQuestionDetailsHandler = (question) => {
    props.onQuestionItemClick(question['id'], setEsgTopicInvestorsData, setEsgTopicProxyAdvisorsData);
    setSearchValue('');
    setSelectedQuestion(question);
  }

  const showQuestionDetailsFromSearchResultsHandler = (topicId, subtopicId, question) => {
    selectSubtopicHandler(topicId, subtopicId);
    props.onQuestionItemClick(question['id'], setEsgTopicInvestorsData, setEsgTopicProxyAdvisorsData);
    setSearchValue('');
    setSelectedQuestion(question);
    setBreadcrumbItems([{
      'text': 'ESG Topics',
      'href': ''
    },
    {
      'text': selectedTopic['name'],
      'href': ''
    },
    {
      'text': selectedSubtopic['name'],
      'href': ''
    }]);
  }

  const generateEsgTopicsDrawer = () => {
    return esgTopics.map(item => {
      const onClickHandler = openedTopicDrawerIds.includes(item['id']) ? () => closeTopicDrawerHandler(item['id']) : () => openTopicDrawerHandler(item['id']);
      return (
        <div key={`esg-topic-${item['id']}`} className={styles['topic-item']} onClick={onClickHandler}>
          <div className={`${styles['header']} ${selectedTopic && selectedTopic['id'] === item['id'] && styles['selected']}`}>
            <div className={styles['title']}>
              <h3>{item['name']}</h3>
            </div>
            <div className={styles['drawer-icon']}>
              <div className={styles['caret']}></div>
            </div>
          </div>
          {openedTopicDrawerIds.includes(item['id']) && 
            <div className={styles['body']}>
              <div className={styles['sub-topics-container']}>
                {item['subtopics'].map(subItem => {
                  return (
                    <div
                      key={`esg-sub-topic-${subItem['id']}`}
                      className={`${styles['sub-topic-item']} ${selectedSubtopic && selectedSubtopic['id'] === subItem['id'] && styles['selected']}`}
                      onClick={() => selectSubtopicHandler(item['id'], subItem['id'])}
                    >
                      <h4>{subItem['name']}</h4>
                      {subItem['is_priority'] && 
                        <div className={styles['priority-icon']}>
                          <div className={styles['flag']}></div>
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            </div>
          }
        </div>
      );

    });
  }


  /* Sets initial data for the topics. */
  useEffect(() => {
    setSearchResults(esgTopics.map(item => {
      if (item['subtopics']) {
        return item['subtopics'].map(subItem => {
          return {
            'topic_id': item['id'],
            'topic_name': item['name'],
            'subtopic_id': subItem['id'],
            'subtopic_name': subItem['name'],
            'questions': subItem['questions'].map(question => {
              question['highlighted_text'] = question['text'];
              return question;
            })
          };
        });
      }
      else {
        return null;
      }
    }).filter(item => item !== null).flat());
    
    setRestructuredEsgTopicsForSearch(esgTopics.map(item => {
      if (item['subtopics']) {
        return item['subtopics'].map(subItem => {
          return {
            'topic_id': item['id'],
            'topic_name': item['name'],
            'subtopic_id': subItem['id'],
            'subtopic_name': subItem['name'],
            'questions': subItem['questions']
          };
        });
      }
      else {
        return null;
      }
    }).filter(item => item !== null).flat());
  }, [esgTopics]);

  return (
    <div>
      <div className={styles['search-and-filter-area']}>
        <div className={styles['search-input']}>
          <TextInput
            id='question-item-search-box'
            width='200px'
            size='xsmall'
            placeholder='Search Topics'
            value={searchValue}
            onChange={searchValueChangeHandler}
          />
        </div>

        {selectedTopic && selectedQuestion != null && 
          <div className={styles['export-button']}>
            <Button 
              type={"secondary"}
              size={"xsmall"}
              leftIcon={'download'}
              onClick={
                () => props.onExportQuestionCommentsClick(selectedTopic["name"], selectedSubtopic["name"], selectedQuestion)
              }
            >
              Export to Word
            </Button>
          </div>
        }
      </div>

      <div className={styles['left-side-container']}>
        {(searchValue !== '' || selectedQuestion !== null) && 
          <div className={styles['back-button-container']}>
            <div className={styles['back-button']} onClick={backButtonClickHandler}>
              <div className={styles['back-icon']}></div>
              <h4>Back</h4>
            </div>
          </div>
        }

        {(searchValue === '') &&
          <div className={styles['topic-drawer']}>
            { generateEsgTopicsDrawer() }
          </div>
        }
      </div>

      {esgTopics && esgTopics.length > 0 && selectedTopic && searchValue === '' && selectedQuestion === null &&
        <div className={styles['question-list-container']}>
          <EsgAllTopicsQuestionList
            title={`${selectedTopic["name"]} / ${selectedSubtopic["name"]}`}
            questions={selectedSubtopic["questions"]}
            onShowQuestionDetailsClick={showQuestionDetailsHandler}
          />
        </div>
      }

      {selectedTopic && selectedQuestion != null && searchValue === '' &&
        <div className={styles['question-details-container']}>
          <EsgAllTopicsQuestionDetails
            id={selectedQuestion['id']}
            topicTitle={`${selectedTopic["name"]} / ${selectedSubtopic["name"]}`}
            text={selectedQuestion['text']}
            description={selectedQuestion['description']}
            investors={esgTopicInvestorsData}
            proxyAdvisors={esgTopicProxyAdvisorsData}
            onShowQuestionInvestorCommenClick={props.onShowQuestionInvestorCommenClick}
            onShowQuestionProxyAdvisorCommenClick={props.onShowQuestionProxyAdvisorCommenClick}
          />
        </div>
      }

      {searchValue !== '' && searchResults !== null && 
        <div className={styles['question-list-container']}>
          <EsgAllTopicsQuestionSearchResults
            data={searchResults}
            onShowQuestionDetailsClick={showQuestionDetailsFromSearchResultsHandler}
          />
        </div>
      }

    </div>
  );
}

export default EsgAllTopicsContainer;
