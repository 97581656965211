import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { generateAccountManagerClientSetupsTableItems, generateAccountManagerMyClientsTableItems } from '../../../utils/account-manager-my-clients-helpers';
import { listClientSetupsRequest } from '../../../requests/client-setup-requests';
import { listClientsRequest } from '../../../requests/client-requests';
import { accountManagerMenuItems } from '../../../utils/constants';
import { getPageStyle, updatePageStyle } from "../../../utils/helpers";
import { getAuthUserProfilePictureUrl } from '../../../utils/auth';
import { handleRequestError } from '../../../utils/request-error-helpers';
import useHttp from '../../../hooks/use-http';

import Button from '../../../components/UI/Button/Button';
import SideNavigationBar from '../../../components/UI/SideNavigationBar/SideNavigationBar';
import Table from '../../../components/UI/Table/Table';

import SquareWellProfilePicture from '../../../assets/other/squarewell-profile-picture.png';
import styles from './MyClientsPage.module.css';

const MyClientsPage = (props) => {

  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  /* eslint-disable no-unused-vars */

  const userProfilePictureUrl = getAuthUserProfilePictureUrl();

  const [isLoading, error, sendRequest] = useHttp();
  const [clients, setClients] = useState([]);
  const [clientSetups, setClientSetups] = useState([]);

  useEffect(() => {
    const handleListClientSetupsResponse = (data) => {
      setClientSetups(data['items']);
    }
    sendRequest(listClientSetupsRequest(), handleListClientSetupsResponse);

    const handleListClientsResponse = (data) => {
      setClients(data['items']);
    }
    sendRequest(listClientsRequest(), handleListClientsResponse);
  }, [sendRequest]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  return (
    <div className={styles['page']}>
      <SideNavigationBar
        menuItems={accountManagerMenuItems}
        profilePictureUrl={userProfilePictureUrl === '' ? SquareWellProfilePicture : userProfilePictureUrl}
        selected='my-clients'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div className={`${styles['container']} ${styles[pageStyle]}`} style={{height: 'calc(100% - 148px)', paddingBottom: 84, overflowY: 'auto', overflowX: 'hidden'}} ref={ref}>
        <div className={styles['page-header']}>
          <div className={styles['section']}>
            <div className={styles['row']}>
              <h1>My Clients</h1>
            </div>
          </div>
        </div>
        <div className={styles['page-body']} >

        { !isLoading && clientSetups.length > 0 &&
            <div className={`${styles['section']} ${styles['client-setup-list']}`}>
              <div className={styles['row']}>
                <h3>In-progress Client Set-up</h3>
              </div>
              <div className={styles['row']}>
                <Table
                  width={isNaN(width) ? 1400 : (width - 2)}
                  items={generateAccountManagerClientSetupsTableItems(clientSetups)}
                  isScrollable={true}
                />
              </div>
            </div>
          }
          
          { !isLoading && clients.length === 0 &&
            <div className={styles['section']}>
              <div className={styles['row']}>
                <div className={styles['no-clients-div']}>
                  <div className={styles['icon-container']}>
                    <div className={styles['icon']}></div>
                  </div>
                  <div className={styles['text-container']}>
                    <h4>No Client Registered</h4>
                    <p>Use below button to Add a New Client</p>
                  </div>
                  <div className={styles['button-container']}>
                    <Button type={"primary"} leftIcon={"plus"} size={"xsmall"} onClick={() => window.location.href='/new-client-setup'}>Add New Client</Button>
                  </div>
                </div>
              </div>
            </div>
          }

          { !isLoading && clients.length > 0 &&
            <div className={`${styles['section']} ${styles['client-list']}`}>
              <div className={styles['row']}>
                <div className={styles['client-list-title']}>
                  <h3>Client List</h3>
                </div>
                <div className={styles['client-list-buttons']}>
                  <Button type={"primary"} leftIcon={"plus"} size={"base"} onClick={() => window.location.href='/new-client-setup'}>Add New Client</Button>
                  <Button type={"cancel"} size={"base"} onClick={() => console.log()}>Deactivated Clients</Button>
                </div>
              </div>
              <div className={styles['row']}>
                <Table
                  width={isNaN(width) ? 1400 : (width - 2)}
                  items={generateAccountManagerMyClientsTableItems(clients)}
                  isScrollable={true}
                />
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default MyClientsPage;
