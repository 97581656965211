import EsgTopicQuestionItem from './EsgTopicQuestionItem';

import styles from '../EsgTopicsContainer.module.css';

const EsgTopicQuestionSearchResults = (props) => {
  const searchResults = props.data;

  const questionContent = searchResults.map(item => {
    return (
      <div key={`topic-item-${item['topic_id']}-${item['subtopic_id']}`} className={styles['search-topic-block']}>
        <div className={styles['search-topic-title']}>
          <h4>{item['topic_name']} / {item['subtopic_name']}</h4>
        </div>
        {item['questions'].map(question => {
          return (
            <EsgTopicQuestionItem
              key={`question-item-${question['id']}`}
              id={question['id']}
              text={question['highlighted_text']}
              description={question['description']}
              isPriority={question['is_priority']}
              onShowQuestionCommenClick={props.onShowQuestionCommenClick}
            />
          );
        })}
      </div>
    );
  })

  return (
    <div className={styles['question-list']}>
      <div className={styles['list-title']}>
        <h3>Search Results:</h3>
      </div>
      {questionContent}
    </div>
  );
}

export default EsgTopicQuestionSearchResults;
