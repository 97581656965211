import { BASE_URI } from "./config";

const SQUAREWELL_BASE_URI = 'https://platform.squarewell-partners.com/aqtion';
const ACCESS_TOKEN = 'e33wsE1ZWsEXShzguzrYRGd1ZkN6iqEi';

export const listSquareWellInvestorsRequest = () => {
  return {
    url: `${SQUAREWELL_BASE_URI}/investors`,
    options: {
      method: 'GET',
      headers: {
        'X-Access-Token': ACCESS_TOKEN
      }
    }
  };
}

export const listSquareWellProxyAdvisorsRequest = () => {
  return {
    url: `${SQUAREWELL_BASE_URI}/proxy_advisors`,
    options: {
      method: 'GET',
      headers: {
        'X-Access-Token': ACCESS_TOKEN
      }
    }
  };
}

export const getSquareWellFileRequest = (body) => {
  return {
    url: `${BASE_URI}/squarewell/files`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        // 'X-Access-Token': getAuthToken()
      },
      body: body,
      returnDataType: 'blob'
    }
  };
}