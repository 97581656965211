import { useEffect, useState } from "react";
import { generateNewShareHolderCommunicationInvestorTableItems, generateNewShareHolderCommunicationProxyAdvisorTableItems } from "../../../utils/client-engagement-helpers";
import { fetchClientBasicProfileRequest, fetchClientInvestorsRequest, fetchClientProxyAdvisorsRequest } from "../../../requests/client-requests";
import { createId } from "../../../utils/helpers";
import { getAuthClientId, getClientType } from "../../../utils/auth";
import { handleRequestError } from "../../../utils/request-error-helpers";
import useHttp from "../../../hooks/use-http";
import useInputValidation from "../../../hooks/use-input-validation";

import Button from "../../UI/Button/Button";
import MultiSelectComboBox from "../../UI/MultiSelectComboBox/MultiSelectComboBox";
import Table from "../../UI/Table/Table";

import EngagementFormItem from "../EngagementFormItem/EngagementFormItem";

import styles from './EngagementShareholderCommunicationForm.module.css';

const EngagementShareholderCommunicationCreateForm = (props) => {

  const clientId = getAuthClientId();
  const clientType = getClientType();

  const [isLoading, error, sendRequest] = useHttp();

  const hasFormTitle = props.hasFormTitle;

  const [clientTotalNumOfShares, setClientTotalNumOfShares] = useState(null);

  const [investorListData, setInvestorListData] = useState([]);
  const [proxyAdvisorListData, setProxyAdvisorListData] = useState([]);

  const [engagementName, setEngagementName, isEngagementNameValid, hasEngagementNameError, engagementNameChangeHandler, engagementNameBlurHandler] =
    useInputValidation('', (engagementName) => engagementName.trim() !== '');

  const [emailSubject, setEmailSubject, isEmailSubjectValid, hasEmailSubjectError, emailSubjectChangeHandler, emailSubjectBlurHandler] =
    useInputValidation('', (emailSubject) => emailSubject.trim() !== '');

  const [emailBody, setEmailBody, isEmailBodyValid, hasEmailBodyError, emailBodyChangeHandler, emailBodyBlurHandler] =
    useInputValidation('', (emailBody) => emailBody.trim() !== '');


  /* Send a request to fetch investor and proxy advisor list for the client to
   * fill combobox values located in the investor and proxy advisor tables. */
  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (data) => { setClientTotalNumOfShares(data['total_num_of_shares']); }
    );

    sendRequest(
      fetchClientInvestorsRequest(clientId),
      (data) => { setInvestorListData(data['items']); }
    );

    sendRequest(
      fetchClientProxyAdvisorsRequest(clientId),
      (data) => { setProxyAdvisorListData(data['items']); }
    );
  }, [sendRequest, clientId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const comboBoxInvestorGroups = [
    {
      'id': 'investor-group-1',
      'title': 'All Investors',
      'isClickable': true,
      'items': investorListData.map(item => {
        return {
          'id': item['id'],
          'text': item['name']
        };
      })
    }
  ];

  const comboBoxProxyAdvisorGroups = [
    {
      'id': 'proxy-advisor-group-1',
      'title': 'All Proxy Advisors',
      'isClickable': true,
      'items': proxyAdvisorListData.map(item => {
        return {
          'id': item['id'],
          'text': item['name']
        };
      })
    }
  ];

  const [showAddInvestorComboBox, setShowAddInvestorComboBox] = useState(false);
  const [investorSearchValue, setInvestorSearchValue] = useState('');
  const [selectedInvestorItems, setSelectedInvestorItems] = useState([]);
  const [investorTableItems, setInvestorTableItems] = useState(null);

  const [showAddProxyAdvisorComboBox, setShowAddProxyAdvisorComboBox] = useState(false);
  const [proxyAdvisorSearchValue, setProxyAdvisorSearchValue] = useState('');
  const [selectedProxyAdvisorItems, setSelectedProxyAdvisorItems] = useState([]);
  const [proxyAdvisorTableItems, setProxyAdvisorTableItems] = useState(null);

  const [emailCcSearchValue, setEmailCcSearchValue] = useState('');
  const [addedEmailCcItems, setAddedEmailCcItems] = useState([]);

  /* Opens investor combo box when "Add Investor" button clicked. */
  const addInvestorClickHandler = () => {
    setShowAddInvestorComboBox(true);
  }

  /* Removes all added investor items from the table. */
  const removeAllInvestorsClickHandler = () => {
    setSelectedInvestorItems([]);
    setInvestorTableItems(prevInvestorTableItems => {
      const newInvestorTableItems = {...prevInvestorTableItems};
      newInvestorTableItems['middle']['body']['items'] = [];
      newInvestorTableItems['middle']['header']['buttons'][1]['isVisible'] = false;
      return newInvestorTableItems;
    });
  }

  /* Removes investor item from the table and selected investor item
   * list when the trash icon clicked. */
  const removeInvestorItemClickHandler = (investorId) => {
    setSelectedInvestorItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const itemIndex = items.indexOf(investorId);
      items.splice(itemIndex, 1);
      return items;
    });

    setInvestorTableItems(prevInvestorTableItems => {
      const newInvestorTableItems = {...prevInvestorTableItems};
      const itemIndex = newInvestorTableItems['middle']['body']['items'].map(item => item['id']).indexOf(`selected-investor-${investorId}`);
      newInvestorTableItems['middle']['body']['items'].splice(itemIndex, 1);
      if (newInvestorTableItems['middle']['body']['items'].length <= 0) {
        newInvestorTableItems['middle']['header']['buttons'][1]['isVisible'] = false;
      }
      return newInvestorTableItems;
    });
  }

  /* Creates a row item for the investor item given as paramater. */ 
  const createInvestorRow = (item) => {
    const rowId = `selected-investor-${item['id']}`;
    const investorName = item['name'] !== null ? item['name'] : '';
    const isCustom = item['is_custom'];

    let iscPercentage = 0;
    if(clientType === 'Corporate') {
      iscPercentage = item['num_of_shares'] !== '' ? ((item['num_of_shares'] / clientTotalNumOfShares) * 100).toFixed(2) : '-';
    }

    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#investor-name`,
          'type': 'text-with-chip',
          'text': investorName,
          'has_custom_chip': isCustom,
          'width': 352,
          'stretch': false
        }
      ].concat((clientType === 'Corporate') ? [
        {
          'id': `${rowId}#isc-percentage`,
          'type': 'default',
          'text': iscPercentage,
          'width': 52,
          'stretch': true
        }
      ] : []).concat([
        { 
          'id': `${rowId}#delete-button`,
          'type': 'icon-button',
          'icon': 'trash',
          'onClick': () => removeInvestorItemClickHandler(item['id'])
        }
      ])
    };
  }

  /* Creates a row in the investor table or removes it from the 
   * investor table according the value of the clicked checkbox. */
  const investorItemClickHandler = (investorId) => {
    const operation = selectedInvestorItems.indexOf(investorId) > -1 ? 'remove' : 'add';
    setSelectedInvestorItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const itemIndex = items.indexOf(investorId);
      if (itemIndex > -1) {
        items.splice(itemIndex, 1);
      }
      else {
        items.push(investorId);
      }
      return items;
    });

    if (operation === 'add') {
      setInvestorTableItems(prevInvestorTableItems => {
        const itemIndex = investorListData.map(item => item['id']).indexOf(investorId);
        const newInvestorTableItems = {...prevInvestorTableItems};
        newInvestorTableItems['middle']['body']['items'].push(
          createInvestorRow(investorListData[itemIndex])
        );
        if (newInvestorTableItems['middle']['body']['items'].length > 0) {
          newInvestorTableItems['middle']['header']['buttons'][1]['isVisible'] = true;
        }
        return newInvestorTableItems;
      });
    }
    else if (operation === 'remove') {
      setInvestorTableItems(prevInvestorTableItems => {
        const newInvestorTableItems = {...prevInvestorTableItems};
        const itemIndex = newInvestorTableItems['middle']['body']['items'].map(item => item['id']).indexOf(`selected-investor-${investorId}`);
        newInvestorTableItems['middle']['body']['items'].splice(itemIndex, 1);
        if (newInvestorTableItems['middle']['body']['items'].length <= 0) {
          newInvestorTableItems['middle']['header']['buttons'][1]['isVisible'] = false;
        }
        return newInvestorTableItems;
      });
    }
  }

  /* Either selects all investors located in the investor combo box 
   * and creates rows for each one of them in the investor table or
   * removes every one of them according the value of the clicked checkbox. */
  const allInvestorsClickHandler = () => {
    const operation = comboBoxInvestorGroups[0]['items'].length === selectedInvestorItems.length ? 'remove' : 'add';
    if (operation === 'add') {
      setSelectedInvestorItems(investorListData.map(item => item['id']));
      setInvestorTableItems(prevInvestorTableItems => {
        const items = investorListData.map(item => createInvestorRow(item));
        const newInvestorTableItems = {...prevInvestorTableItems};
        newInvestorTableItems['middle']['body']['items'] = items;
        newInvestorTableItems['middle']['header']['buttons'][1]['isVisible'] = true;
        return newInvestorTableItems;
      });
    }
    else if (operation === 'remove') {
      setSelectedInvestorItems([]);
      setInvestorTableItems(prevInvestorTableItems => {
        const newInvestorTableItems = {...prevInvestorTableItems};
        newInvestorTableItems['middle']['body']['items'] = [];
        newInvestorTableItems['middle']['header']['buttons'][1]['isVisible'] = false;
        return newInvestorTableItems;
      });
    }
  }


  /* Opens proxy advisor combo box when "Add Proxy Advisor" button clicked. */
  const addProxyAdvisorClickHandler = () => {
    setShowAddProxyAdvisorComboBox(true);
  }

  /* Removes all added proxy advisor items from the table. */
  const removeAllProxyAdvisorsClickHandler = () => {
    setSelectedProxyAdvisorItems([]);
    setProxyAdvisorTableItems(prevProxyAdvisorTableItems => {
      const newProxyAdvisorTableItems = {...prevProxyAdvisorTableItems};
      newProxyAdvisorTableItems['middle']['body']['items'] = [];
      newProxyAdvisorTableItems['middle']['header']['buttons'][1]['isVisible'] = false;
      return newProxyAdvisorTableItems;
    });
  }

  /* Removes proxy advisor item from the table and selected proxy advisor item
   * list when the trash icon clicked. */
  const removeProxyAdvisorItemClickHandler = (proxyAdvisorId) => {
    setSelectedProxyAdvisorItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const itemIndex = items.indexOf(proxyAdvisorId);
      items.splice(itemIndex, 1);
      return items;
    });

    setProxyAdvisorTableItems(prevProxyAdvisorTableItems => {
      const newProxyAdvisorTableItems = {...prevProxyAdvisorTableItems};
      const itemIndex = newProxyAdvisorTableItems['middle']['body']['items'].map(item => item['id']).indexOf(`selected-proxy-advisor-${proxyAdvisorId}`);
      newProxyAdvisorTableItems['middle']['body']['items'].splice(itemIndex, 1);
      if (newProxyAdvisorTableItems['middle']['body']['items'].length <= 0) {
        newProxyAdvisorTableItems['middle']['header']['buttons'][1]['isVisible'] = false;
      }
      return newProxyAdvisorTableItems;
    });
  }

  /* Creates a row item for the proxy advisor item given as paramater. */ 
  const createProxyAdvisorRow = (item) => {
    const rowId = `selected-proxy-advisor-${item['id']}`;
    const proxyAdvisorName = item['name'] !== null ? item['name'] : '';
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#proxy-advisor-name`,
          'type': 'text-with-chip',
          'text': proxyAdvisorName,
          'has_custom_chip': false,
          'width': 352,
          'stretch': false
        },
        { 
          'id': `${rowId}#delete-button`,
          'type': 'icon-button',
          'icon': 'trash',
          'onClick': () => removeProxyAdvisorItemClickHandler(item['id'])
        }
      ]
    };
  }

  /* Creates a row in the proxy advisor table or removes it from the 
   * proxy advisor table according the value of the clicked checkbox. */
  const proxyAdvisorItemClickHandler = (proxyAdvisorId) => {
    const operation = selectedProxyAdvisorItems.indexOf(proxyAdvisorId) > -1 ? 'remove' : 'add';
    setSelectedProxyAdvisorItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const itemIndex = items.indexOf(proxyAdvisorId);
      if (itemIndex > -1) {
        items.splice(itemIndex, 1);
      }
      else {
        items.push(proxyAdvisorId);
      }
      return items;
    });

    if (operation === 'add') {
      setProxyAdvisorTableItems(prevProxyAdvisorTableItems => {
        const itemIndex = proxyAdvisorListData.map(item => item['id']).indexOf(proxyAdvisorId);
        const newProxyAdvisorTableItems = {...prevProxyAdvisorTableItems};
        newProxyAdvisorTableItems['middle']['body']['items'].push(
          createProxyAdvisorRow(proxyAdvisorListData[itemIndex])
        );
        if (newProxyAdvisorTableItems['middle']['body']['items'].length > 0) {
          newProxyAdvisorTableItems['middle']['header']['buttons'][1]['isVisible'] = true;
        }
        return newProxyAdvisorTableItems;
      });
    }
    else if (operation === 'remove') {
      setProxyAdvisorTableItems(prevProxyAdvisorTableItems => {
        const newProxyAdvisorTableItems = {...prevProxyAdvisorTableItems};
        const itemIndex = newProxyAdvisorTableItems['middle']['body']['items'].map(item => item['id']).indexOf(`selected-proxy-advisor-${proxyAdvisorId}`);
        newProxyAdvisorTableItems['middle']['body']['items'].splice(itemIndex, 1);
        if (newProxyAdvisorTableItems['middle']['body']['items'].length <= 0) {
          newProxyAdvisorTableItems['middle']['header']['buttons'][1]['isVisible'] = false;
        }
        return newProxyAdvisorTableItems;
      });
    }
  }

  /* Either selects all proxy advisors located in the proxy advisor combo box 
   * and creates rows for each one of them in the proxy advisor table or
   * removes every one of them according the value of the clicked checkbox. */
  const allProxyAdvisorsClickHandler = () => {
    const operation = comboBoxProxyAdvisorGroups[0]['items'].length === selectedProxyAdvisorItems.length ? 'remove' : 'add';
    if (operation === 'add') {
      setSelectedProxyAdvisorItems(proxyAdvisorListData.map(item => item['id']));
      setProxyAdvisorTableItems(prevProxyAdvisorTableItems => {
        const items = proxyAdvisorListData.map(item => createProxyAdvisorRow(item));
        const newProxyAdvisorTableItems = {...prevProxyAdvisorTableItems};
        newProxyAdvisorTableItems['middle']['body']['items'] = items;
        newProxyAdvisorTableItems['middle']['header']['buttons'][1]['isVisible'] = true;
        return newProxyAdvisorTableItems;
      });
    }
    else if (operation === 'remove') {
      setSelectedProxyAdvisorItems([]);
      setProxyAdvisorTableItems(prevProxyAdvisorTableItems => {
        const newProxyAdvisorTableItems = {...prevProxyAdvisorTableItems};
        newProxyAdvisorTableItems['middle']['body']['items'] = [];
        newProxyAdvisorTableItems['middle']['header']['buttons'][1]['isVisible'] = false;
        return newProxyAdvisorTableItems;
      });
    }
  }

  const generateEmailCcItems = () => {
    return addedEmailCcItems.map(item => {
      return (
        <div key={`added-cc-contact-${item['id']}`} className={styles['added-cc-contact']}>
          <div className={styles['added-cc-contact-text']}>
            {item['is_custom_email'] &&
              <h3>{item['email']}</h3>
            }
            {!item['is_custom_email'] &&
              <h3>{item['fullname']} ({item['role']})</h3>
            }
          </div>
          <div className={styles['remove-button']} onClick={() => removeCustomEmailCcItemHandler(item)}></div>
        </div>
      );
    });
  }

  const addCustomEmailCcItemHandler = (email) => {
    setAddedEmailCcItems(prevAddedEmailCcItems => {
      const newAddedEmailCcItems = JSON.parse(JSON.stringify(prevAddedEmailCcItems));
      newAddedEmailCcItems.push({
        'id': createId(4),
        'email': email,
        'is_custom_email': true
      });
      return newAddedEmailCcItems;
    });
    setEmailCcSearchValue('');
  }

  const removeCustomEmailCcItemHandler = (clickedItem) => { 
    if (clickedItem['is_custom_email']) {
      setAddedEmailCcItems(prevAddedEmailCcItems => {
        const newAddedEmailCcItems = JSON.parse(JSON.stringify(prevAddedEmailCcItems));
        return newAddedEmailCcItems.filter(item => item['email'] !== clickedItem['email']);
      });
    }
  }

  /* Fills investor combo box and proxy advisor combo box with existing 
   * investor and proxy advisor items when the page loaded. */
  useEffect(() => {
    setInvestorTableItems(
      generateNewShareHolderCommunicationInvestorTableItems(
        clientType,
        addInvestorClickHandler,
        removeAllInvestorsClickHandler
      )
    );

    setProxyAdvisorTableItems(
      generateNewShareHolderCommunicationProxyAdvisorTableItems(
        addProxyAdvisorClickHandler,
        removeAllProxyAdvisorsClickHandler
      )
    );

    setEngagementName('');
    setEmailSubject('');
    setEmailBody('');
  }, [clientType, setEngagementName, setEmailSubject, setEmailBody]);



  const isShareholderCommunicationFormValid = () => {
    engagementNameBlurHandler();
    emailSubjectBlurHandler();
    emailBodyBlurHandler();
    return isEngagementNameValid && isEmailSubjectValid && isEmailBodyValid;
  }

  const saveShareholderCommunicationClickHandler = () => {
    if (!isShareholderCommunicationFormValid()) {
      return;
    }

    const shareholderCommunicationInvestors = investorListData.filter(item => selectedInvestorItems.includes(item['id'])).map(item => {
      return {
        'id': item['id'],
        'name': item['name'],
        'num_of_shares': item['num_of_shares'],
        'isc': (clientType === 'Corporate') ? (item['num_of_shares'] / clientTotalNumOfShares) * 100 : 0
      }
    });
    const shareholderCommunicationtProxyAdvisors = proxyAdvisorListData.filter(item => selectedProxyAdvisorItems.includes(item['id'])).map(item => {
      return {
        'id': item['id'],
        'name': item['name']
      }
    });

    const shareholderCommunicationData = {
      'engagement_name': engagementName,
      'draft_email_cc': addedEmailCcItems.map(item => item['email']).join(';'),
      'draft_email_subject': emailSubject,
      'draft_email_body': emailBody,
      'investors': shareholderCommunicationInvestors,
      'proxy_advisors': shareholderCommunicationtProxyAdvisors
    };
    props.onSaveClick(shareholderCommunicationData);
  }

  
  return (
    <div className={styles['shareholder-communication-container']}>
      <div className={styles['header']}>
        {hasFormTitle && 
          <div className={styles['form-title']}>
            <h2>Create a Shareholder Communication</h2>
          </div>
        }
      </div>
      <div className={styles['body']}>
        <div className={styles['row']}>
          <div className={styles['shareholder-communication-name-container']}>
            <EngagementFormItem
              id='investor-shareholder-communication#engagement-name'
              type='text-input'
              label='Engagement Name'
              value={engagementName}
              placeholder='Enter Engagement Name'
              hasError={hasEngagementNameError}
              onBlur={engagementNameBlurHandler}
              onChange={engagementNameChangeHandler}
            />
          </div>
          <div className={styles['action-button-container']}>
            <Button type={'primary'} size={'base'} onClick={saveShareholderCommunicationClickHandler}>Save</Button>
            <Button type={"cancel"} size={"base"} onClick={props.onCancel}>Cancel</Button>
          </div>
        </div>

        <div className={styles['row']}>
          <div className={styles['shareholder-communication-email-subject-body-container']}>
            <div className={styles['email-subject']}>
              <EngagementFormItem
                id='investor-shareholder-communication#email-subject'
                type='text-input'
                label='Email Subject'
                value={emailSubject}
                placeholder='Enter Email Subject'
                hasError={hasEmailSubjectError}
                onBlur={emailSubjectBlurHandler}
                onChange={emailSubjectChangeHandler}
              />
            </div>
            <div className={styles['email-body']}>
              <EngagementFormItem
                id='investor-shareholder-communication#email-body'
                type='rich-text-input'
                label='Email Body'
                value={emailBody}
                placeholder='Enter Content'
                hasError={hasEmailBodyError}
                onBlur={emailBodyBlurHandler}
                onChange={emailBodyChangeHandler}
              />
            </div>
          </div>

          <div className={styles['shareholder-communication-email-cc-container']}>
            <MultiSelectComboBox
              id='add-email-cc-combobox'
              width={500}
              size='base'
              label='Cc'
              itemGroups={[]}
              alwaysShowItems={false}
              hasSearchIcon={false}
              hasHeaderCover={false}
              value={emailCcSearchValue}
              placeholder='Enter recipient emails manually'
              selected={[]}
              onChange={setEmailCcSearchValue}
              onItemClick={null}
              autoFocus={false}
              hasAddCustomButton={true}
              onAddCustomClick={addCustomEmailCcItemHandler}
            />

            <div className={styles['added-cc-contacts-container']}>
              {generateEmailCcItems()}
            </div>
          </div>
        </div>

        <div className={styles['row']} style={{marginBottom: 192}}>
          {investorTableItems && 
            <div className={styles['investor-table']}>
              <Table width={600} items={investorTableItems} isScrollable={false} />
              
              <div className={styles['investor-combobox-container']}>
                {showAddInvestorComboBox &&
                  <MultiSelectComboBox
                    id='add-investor-combobox'
                    size={"xsmall"}
                    itemGroups={comboBoxInvestorGroups}
                    alwaysShowItems={true}
                    hasSearchIcon={true}
                    hasHeaderCover={true}
                    value={investorSearchValue}
                    placeholder="Search Investor"
                    selected={selectedInvestorItems}
                    onChange={setInvestorSearchValue}
                    onItemClick={investorItemClickHandler}
                    onClickOutside={() => setShowAddInvestorComboBox(false)}
                    autoFocus={true}
                    onAllItemsClick={allInvestorsClickHandler}
                  />
                }
              </div>
            </div>
          }

          {proxyAdvisorTableItems && 
            <div className={styles['proxy-advisor-table']}>
              <Table width={500} items={proxyAdvisorTableItems} isScrollable={false} />
              
              <div className={styles['proxy-advisor-combobox-container']}>
                {showAddProxyAdvisorComboBox &&
                  <MultiSelectComboBox
                    id='add-proxy-advisor-combobox'
                    size={"xsmall"}
                    itemGroups={comboBoxProxyAdvisorGroups}
                    alwaysShowItems={true}
                    hasSearchIcon={true}
                    hasHeaderCover={true}
                    value={proxyAdvisorSearchValue}
                    placeholder="Search Proxy Advisor"
                    selected={selectedProxyAdvisorItems}
                    onChange={setProxyAdvisorSearchValue}
                    onItemClick={proxyAdvisorItemClickHandler}
                    onClickOutside={() => setShowAddProxyAdvisorComboBox(false)}
                    autoFocus={true}
                    onAllItemsClick={allProxyAdvisorsClickHandler}
                  />
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default EngagementShareholderCommunicationCreateForm;
