import { useRef } from "react";
import TableCell from "../TableCell/TableCell";
import styles from './TableRow.module.css';

const TableRow = (props) => {
  const ref = useRef();

  const createId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const cellItems = props.columns.map((item, index) => {
    return (
      <TableCell
        key={`cell-${createId(8)}`}
        item={item}
        width={props.config['columnWidths'][index]}
        padding={props.config['columnPaddings'][index]}
        section={props.section}
        container={props.container}
        viewMode={props.viewMode}
      />
    );
  })

  const rowHoverHandler = () => {
    if( props.section === 'body'){ 
      const idPrefix = ref.current.id.split('#')[0];
      if(document.getElementById(idPrefix + '#left')) {
        document.getElementById(idPrefix + '#left').style.backgroundColor = 'var(--color-neutral-100)';
      }
      if(document.getElementById(idPrefix + '#right')) {
        document.getElementById(idPrefix + '#right').style.backgroundColor = 'var(--color-neutral-100)';
      }
      document.getElementById(idPrefix + '#middle').style.backgroundColor = 'var(--color-neutral-100)';
    }
  }

  const rowUnhoverHandler = () => {
    if( props.section === 'body'){ 
      const idPrefix = ref.current.id.split('#')[0];
      if(document.getElementById(idPrefix + '#left')) {
        document.getElementById(idPrefix + '#left').style.backgroundColor = 'var(--color-white)';
      }
      if(document.getElementById(idPrefix + '#right')) {
        document.getElementById(idPrefix + '#right').style.backgroundColor = 'var(--color-white)';
      }
      document.getElementById(idPrefix + '#middle').style.backgroundColor = 'var(--color-white)';
    }
  }

  return (
    <div
      id={props.id}
      ref={ref}
      className={`${styles['row']} ${styles[props.section]} `}
      onMouseEnter={rowHoverHandler}
      onMouseLeave={rowUnhoverHandler}
    >
      {cellItems}
    </div>
  );
}

export default TableRow
