import { disableAccountManagerViewMode } from "./auth";

export const createId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const getValueFromTextInput = (inputId) => {
  const element = document.getElementById(inputId);
  if(element === null || element === undefined) {
    return '';
  }
  else {
    return element.value
  }
}

export const setValueOfTextInput = (inputId, value) => {
  const element = document.getElementById(inputId);
  if(element !== null && element !== undefined) {
    element.value = value;
  }
}

export const getValueFromDropdown = (inputId) => {
  const element = document.getElementById(inputId);
  if(element === null || element === undefined) {
    return '';
  }
  else {
    if (element.dataset.selected === element.dataset.placeholder) {
      return '';
    }
    return element.dataset.selected;
  }
}

export const getValueFromVariable = (item, dataType) => {
  if(item !== undefined && item !== null) {
    if(dataType === 'date') {
      return item !== '' ? new Date(item) : '';
    }
    else {
      return item;
    }
  }
  else {
    return '';
  }
}

export const setValueOfParagraph = (inputId, value) => {
  const element = document.getElementById(inputId);
  if(element !== null && element !== undefined) {
    element.innerText = value;
  }
}

export const getValueFromParagraph = (inputId) => {
  const element = document.getElementById(inputId);
  if(element === null || element === undefined) {
    return '';
  }
  else {
    return element.innerText;
  }
}




export const commaSeperatedValueToNumber = (commaSeperatedValue) => {
  return parseInt(commaSeperatedValue.toString().replaceAll(',', ''));
}

export const numberToCommaSeperatedValue = (number) => {
  const commaSeperatedNumberRegex = /(\d+)(\d{3})/;
  const commaSeperatedValue = String(number).replace(/^\d+/, (updatedNumber) => {
    while(commaSeperatedNumberRegex.test(updatedNumber)){
      updatedNumber = updatedNumber.replace(commaSeperatedNumberRegex, '$1,$2');
    }
    return updatedNumber;
  });
  return commaSeperatedValue;
}

export function getPageStyle() {
  const pageStyle = localStorage.getItem('pageStyle');
  if(pageStyle !== 'wide' && pageStyle !== 'narrow') {
    return 'wide';
  }
  return pageStyle;
}

export function updatePageStyle(pageStyle) {
  localStorage.setItem('pageStyle', pageStyle);
}

export const exitViewAsClientMode = (redirectUrl) => {
  disableAccountManagerViewMode();
  window.location = redirectUrl;
}