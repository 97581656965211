import { createId } from '../../../utils/helpers';

import LeftFixedContent from './LeftFixedContent/LeftFixedContent';
import RightFixedContent from './RightMixedContent/RightFixedContent';
import MiddleScrollableContent from './MiddleScrollableContent/MiddleScrollableContent';

import styles from './Table.module.css';

const Table = (props) => {

  const calcWidth = (config) => {
    return config['columnWidths']
      .map((item, index) => item + config['columnPaddings'][index]['left'] + config['columnPaddings'][index]['right'])
      .reduce((acc, curr) => (acc + curr), 0);
  }

  const tableContainerId = `table-container-${createId(4)}`
  const leftWidth = props.items.left !== undefined ? calcWidth(props.items.left['header']['config']) : 0;
  const rightWidth = props.items.right !== undefined ? calcWidth(props.items.right['header']['config']) : 0;
  const middleWidth = calcWidth(props.items.middle['header']['config']);
  const middleScrollableWidth = props.width - (leftWidth + rightWidth);

  const numberOfEligibleCols = props.items.middle['header']['items'][0]['columns'].filter(item => item['stretch'] === true).length;
  const additionalColumnWidth = (props.width - middleWidth) > 0 ? (props.width - middleWidth) / numberOfEligibleCols : 0;

  return (
    <div id={tableContainerId} className={styles['table-container']} style={{ 'width': props.width }}>
      <div className={styles['content']}>
        {props.items.left && 
          <LeftFixedContent
            width={leftWidth}
            items={props.items.left}
            viewMode={props.viewMode}
          />
        }

        <MiddleScrollableContent
          width={props.width > middleWidth ? props.width : middleWidth}
          items={props.items.middle}
          marginLeft={leftWidth}
          marginRight={rightWidth}
          scrollableWidth={middleScrollableWidth}
          additionalColumnWidth={additionalColumnWidth}
          isScrollable={props.isScrollable}
          tableContainerId={tableContainerId}
          hasDynamicScroll={props.hasDynamicScroll}
          viewMode={props.viewMode}
        />

        {props.items.right &&
          <RightFixedContent
            width={rightWidth}
            items={props.items.right}
            viewMode={props.viewMode}
          />
        }
      </div>
    </div>
  )
}

export default Table
