import { useEffect, useState } from "react";
import { BASE_URI } from "../../../../requests/config";
import useInputValidation from "../../../../hooks/use-input-validation";

import Button from "../../../../components/UI/Button/Button";
import Modal from "../../../../components/UI/Modal/Modal";
import RichTextEditor from "../../../../components/UI/RichTextEditor/RichTextEditor";

import styles from './InvestorListSettingSection.module.css';

const InvestorListSettingSection = (props) => {

  const [csvAttachment, setCSVAttachment] = useState(null);

  const [additionalInformation, setAdditionalInformation, isAdditionalInformationValid, hasAdditionalInformationError, additionalInformationChangeHandler, additionalInformationBlurHandler] = 
    useInputValidation('', (additionalInformation) => true);
  
  const [showInvestorListUpdateModal, setShowInvestorListUpdateModal] = useState(false);
  const [showInvestorListUpdateRequestSentInfo, setShowInvestorListUpdateRequestSentInfo] = useState(false);
  const [isSendInvestorListButtonDisabled, setIsSendInvestorListButtonDisabled] = useState(true);

  const investorListCSVChangeHandler = (event) => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if(file.size > 500000){
        alert('File size should be smaller than 450Kb');
        return false;
      }

      var data = new FormData();
			data.append('attachment', file, file.name);
      props.onInvestorListCSVFileUploadClick(data, setCSVAttachment, setIsSendInvestorListButtonDisabled);
    }
  }

  useEffect(() => {
    setAdditionalInformation('');
  }, [setAdditionalInformation]);

  const removeInvestorListCSVFileHandler = () => {
    setCSVAttachment(null);
    setIsSendInvestorListButtonDisabled(true);
  }

  const sendUpdateInvestorListRequestHandler = () => {
    if(isAdditionalInformationValid) {
      const data = {
        'attachment': csvAttachment,
        'additional_information': additionalInformation
      }
      props.onSendUpdatedInvestorListClick(data, setShowInvestorListUpdateModal, setShowInvestorListUpdateRequestSentInfo);
    }
  }

  const showInvestorListUpdateModalHandler = () => {
    setAdditionalInformation('');
    setCSVAttachment(null);
    setShowInvestorListUpdateModal(true);
  }

  return (
    <div className={styles['update-investor-list-container']}>
      {showInvestorListUpdateRequestSentInfo && 
        <Modal
          type='success'
          title='Investor Update Request Sent'
          message={<span>Your Investor Update Request has been sent. Your Account Manager will in touch with you regarding the next steps.</span>}
          onCloseClick={() => setShowInvestorListUpdateRequestSentInfo(false)}
          approveButton = {{
            'text': 'Close',
            'onClick': () => setShowInvestorListUpdateRequestSentInfo(false)
          }}
        />
      }

      {showInvestorListUpdateModal && 
        <div className={styles['modal-container']}>
          <div className={styles['overlay']}></div>
          <div className={styles['content']}>
            <div className={styles['header']}>
              <div className={styles['title']}>
                <h3>Request Investor List Update</h3>
              </div>
              <div className={styles['close-button']}  onClick={() => setShowInvestorListUpdateModal(false)}>
                <div className={styles['close-icon']}></div>
              </div>
            </div>
            <div className={styles['body']}>
              {!csvAttachment &&
                <div className={styles['new-attachment-container']}>
                  <label className={styles['new-attachment-button']}>
                    <div className={styles['new-attachment-icon']}></div>
                    <div className={styles['new-attachment-text']}>
                      <h4>Upload Investor List</h4>
                    </div>
                    <input type='file' accept='.csv' onChange={investorListCSVChangeHandler} />
                  </label>
                </div>
              }
              {csvAttachment &&
                <div className={styles['attached-item-container']}>
                  <div className={styles['attached-item']}>
                    <div className={styles['attached-item-icon']}></div>
                    <div className={`${styles['attached-item-text']} ${styles['clickable']}`} onClick={() => window.open(csvAttachment['attachment_url'], '_blank')}>
                      <h4>{csvAttachment['attachment_name']}</h4>
                    </div>
                    <div className={styles['remove-button']} onClick={removeInvestorListCSVFileHandler}></div>
                  </div>
                </div>
              }
              <div className={styles['attachment-note-container']}>
                <p>Please <a href={`${BASE_URI}/aqtion/help-guide/files/aqtion-investor-list-update-template.csv`} target='_blank' rel='noreferrer'>download the CSV template</a> and incorporate your data accordingly to submit your request to AQTION.</p>
              </div>
              <RichTextEditor
                width='100%'
                label='Any additional information you want to share with your Account Manager?'
                placeholder='Enter Additional Information'
                value={additionalInformation}
                hasError={hasAdditionalInformationError}
                onBlur={additionalInformationBlurHandler}
                onChange={additionalInformationChangeHandler}
                disabled={false}
              />
            </div>
            <div className={styles['footer']}>
              <Button
                className={styles['modal-button']}
                type={'primary'}
                size={'base'}
                onClick={sendUpdateInvestorListRequestHandler}
                isDisabled={isSendInvestorListButtonDisabled}
              >
                Send
              </Button>
              <Button
                className={styles['modal-button']}
                type={'cancel'}
                size={'base'}
                onClick={() => setShowInvestorListUpdateModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      }
      <div className={styles['update-investor-list-content']}>
        <div className={styles['title-container']}>
          <h3>Investor List Setting</h3>
        </div>
        <div className={styles['button-container']}>
          <Button size='small' type='secondary' onClick={showInvestorListUpdateModalHandler}>Update Investor List</Button>
        </div>
      </div>
    </div>
  )
}

export default InvestorListSettingSection;
