import { useState } from 'react';
import styles from './PasswordInput.module.css';

const PasswordInput = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const isDisabled = props.isDisabled ? true : false;
  const errorStyle = props.hasError ? styles['error'] : '';
  const passwordIcon = isPasswordVisible ? `${styles['password-icon']} ${styles['show']}` 
                                         : `${styles['password-icon']} ${styles['hide']}`;

  const inputChangeHandler = (event) => {
    if (props.onChange !== undefined) {
      props.onChange(event.target.value);
    }
  }

  const inputBlurHandler = (event) => {
    if (props.onBlur !== undefined) {
      props.onBlur(event.target.value);
    }
  }

  const togglePasswordHandler = () => {
    if (!isDisabled) {
      setIsPasswordVisible((prevState) => {
        return !prevState;
      });
    }
  }

  const customStyle = {};
  if (props.width !== undefined) {
    customStyle['width'] = props.width
  }

  return(
    <div className={`${styles['input-container']} ${isDisabled && styles['disabled']}`} style={customStyle}>
      { props.label && <label className={styles['input-label']}>{props.label}</label> }
      <div className={styles['input-wrapper']}>
        <input
          type={ isPasswordVisible ? "text" : "password" }
          className={`${styles['password-input']} ${errorStyle}`}
          placeholder={props.placeholder}
          value={props.value}
          disabled={isDisabled}
          onBlur={inputBlurHandler}
          onChange={inputChangeHandler}
        />
        <div className={passwordIcon} onClick={togglePasswordHandler}></div>

        { props.warningText && 
          <div className={styles['error-message-container']}>
            <div className={styles['warning-icon']}></div>
            <p>{props.warningText}</p>
          </div>
        }
      </div>
    </div>
  );
}

export default PasswordInput;
