import EsgAllTopicsQuestionItem from './EsgAllTopicsQuestionItem';

import styles from '../EsgAllTopicsContainer.module.css';

const EsgAllTopicsQuestionSearchResults = (props) => {
  const searchResults = props.data;

  const questionContent = searchResults.map(item => {
    return (
      <div key={`topic-item-${item['topic_id']}-${item['subtopic_id']}`} className={styles['search-topic-block']}>
        <div className={styles['search-topic-title']}>
          <h4>{item['topic_name']} / {item['subtopic_name']}</h4>
        </div>
        {item['questions'].map(question => {
          return (
            <EsgAllTopicsQuestionItem
              key={`question-item-${question['id']}`}
              id={question['id']}
              text={question['highlighted_text']}
              numberOfComments={question['comment_count']}
              description={question['description']}
              onShowQuestionDetailsClick={() => props.onShowQuestionDetailsClick(item['topic_id'],  item['subtopic_id'], question)}
            />
          );
        })}
      </div>
    );
  })

  return (
    <div className={styles['question-list']}>
      <div className={styles['list-title']}>
        <h3>Search Results:</h3>
      </div>
      {questionContent}
    </div>
  );
}

export default EsgAllTopicsQuestionSearchResults;
