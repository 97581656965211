import { useState } from "react";

import Tab from "../../../../components/UI/Tab/Tab";

import ProxyAdvisorEngagementsOtherNotes from "./ProxyAdvisorEngagementsOtherNotes/ProxyAdvisorEngagementsOtherNotes";
import ProxyAdvisorEngagementsMeetingNotes from "./ProxyAdvisorEngagementsMeetingNotes/ProxyAdvisorEngagementsMeetingNotes";
import ProxyAdvisorEngagementsMeetingRequests from "./ProxyAdvisorEngagementsMeetingRequests/ProxyAdvisorEngagementsMeetingRequests";
import ProxyAdvisorEngagementsShareholderCommunications from "./ProxyAdvisorEngagementsShareholderCommunications/ProxyAdvisorEngagementsShareholderCommunications";

import styles from './ProxyAdvisorEngagements.module.css';

const ProxyAdvisorEngagements = (props) => {
  const [selectedTab, setSelectedTab] = useState('tab-meeting-requests');
  const [showEngagementsTab, setShowEngagementsTab] = useState(true);

  const proxyAdvisorEngagementsTabItems = [
    {
      'id': 'tab-meeting-requests',
      'text': 'Meeting Requests'
    },
    {
      'id': 'tab-shareholder-communications',
      'text': 'Shareholder Communications'
    },
    {
      'id': 'tab-meeting-notes',
      'text': 'Meeting Notes'
    },
    {
      'id': 'tab-other-notes',
      'text': 'Other Notes'
    }
  ];

  const proxyAdvisorEngagementsTabChangeHandler = (selectedTabId) => {
    setSelectedTab(selectedTabId)
  }

  return (
    <div>
      {showEngagementsTab &&
        <div className={styles['tab-container']}>
          <Tab
            items={proxyAdvisorEngagementsTabItems}
            selectedTab={selectedTab}
            type='second-level'
            onTabChange={proxyAdvisorEngagementsTabChangeHandler}
          />
        </div>
      }

      <div className={styles['table-container']}>
        { selectedTab === 'tab-meeting-requests' &&
          <ProxyAdvisorEngagementsMeetingRequests
            width={props.width}
            proxyAdvisorId={props.proxyAdvisorId}
          />
        }
        { selectedTab === 'tab-shareholder-communications' &&
          <ProxyAdvisorEngagementsShareholderCommunications
            width={props.width}
            proxyAdvisorId={props.proxyAdvisorId}
          />
        }
        { selectedTab === 'tab-meeting-notes' && 
          <ProxyAdvisorEngagementsMeetingNotes
            width={props.width}
            proxyAdvisorId={props.proxyAdvisorId}
            setShowEngagementsTab={setShowEngagementsTab}
          />
        }
        { selectedTab === 'tab-other-notes' && 
          <ProxyAdvisorEngagementsOtherNotes
            width={props.width}
            proxyAdvisorId={props.proxyAdvisorId}
            setShowEngagementsTab={setShowEngagementsTab}
          />
        }
      </div>
    </div>
  );
}

export default ProxyAdvisorEngagements;
