import SectionInfoBox from '../SectionInfoBox/SectionInfoBox';

import summaryPageStyles from '../ProxyAdvisorSummary.module.css';

const ProxyAdvisorSummaryGeneralInfoSection = (props) => {
  return (
    <div className={summaryPageStyles['section']}>
      <div className={summaryPageStyles['section-title']}>
        <h3>General Info</h3>
      </div>

      <div className={summaryPageStyles['content']}>
        <SectionInfoBox
          id='proxy-advisor#website'
          size='full-size-col'
          type='url'
          label='Website'
          value={props.generalInformationData['website']}
        />
      </div>
    
      <div className={summaryPageStyles['separator']}></div>
    </div>
  );
}

export default ProxyAdvisorSummaryGeneralInfoSection;
