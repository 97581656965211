/*
'use strict';

function detect() {
    if (detect.cache !== null) {
        return detect.cache
    }

    if (typeof window === 'undefined') {
        return false;
    }

    var node = window.document.createElement('canvas');
    var ctx = node.getContext('2d');
    if (!ctx) {
        return false;
    }
    var backingStoreRatio =
        ctx.webkitBackingStorePixelRatio ||
        ctx.mozBackingStorePixelRatio ||
        ctx.msBackingStorePixelRatio ||
        ctx.oBackingStorePixelRatio ||
        ctx.backingStorePixelRatio ||
        1;
    var offset = 12 * backingStoreRatio;

    ctx.fillStyle = '#f00';
    ctx.textBaseline = 'top';
    ctx.font = '32px Arial';
    ctx.fillText('\ud83d\udc28', 0, 0); // U+1F428 KOALA

    var support = ctx.getImageData(offset, offset, 1, 1).data[0] !== 0;

    detect.cache = support

    return support;
};

detect.cache = null;

module.exports = detect;
*/

export const hasEmojiSupport = () => {
    var node = document.createElement('canvas');
    if (!node.getContext || !node.getContext('2d') ||
        typeof node.getContext('2d').fillText !== 'function')
        return false;
    var ctx = node.getContext('2d');
    ctx.textBaseline = 'top';
    ctx.font = '32px Arial';
    ctx.fillText('\ud83d\ude03', 0, 0);
    return ctx.getImageData(16, 16, 1, 1).data[0] !== 0;
}

export const hasFlagEmojiSupport = () => {
    var canvas = document.createElement("canvas");
    canvas.height = 10;
    canvas.width = canvas.height*2;
    var ctx = canvas.getContext("2d");
    ctx.font = canvas.height+"px Arial";
    ctx.fillText("🇬🇧", 0, canvas.height);
    var data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    var i = 0;
    while(i<data.length) {
        if (data[i] !== data[i+1] || data[i] !== data[i+2]) return true;
        i+=4;
    }
    return false;
}