import { Fragment } from 'react';
import { Tooltip } from 'react-tooltip'

import Dropdown from '../../../../../components/UI/Dropdown/Dropdown';
import TextInput from '../../../../../components/UI/TextInput/TextInput';
import Textarea from '../../../../../components/UI/Textarea/Textarea';

import styles from './SectionInfoBox.module.css';

const SectionInfoBox = (props) => {

  const generateGroupItems = () => {
    return props.items.map(item => {
      return (
        <div key={`group-item-${item.id}`} className={styles['sub-item']}>
          <div className={styles['label']}>
            <h4>{item.label}</h4>
            { item.hasTooltip && 
              <div className={styles['tooltip-icon']} data-tooltip-id={`${item.id}-tooltip`}>
                <Tooltip
                  id={`${item.id}-tooltip`}
                  place='bottom'
                  content={item.tooltipContent}
                  className='aqtion-tooltip'
                />
              </div>
            }
          </div>
          <div className={styles['value']}>
            { item.type === 'text' && 
              <div className={styles['text']}>
                <p>{item.value}</p>
              </div>
            }
            
            { item.type === 'dropdown' && 
              <Dropdown
                id={item.id}
                width='100%'
                size='base'
                placeholder={item.placeholder}
                items={item.dropdownItems}
                selected={item.value}
                onItemClick={item.onInputChange}
              />
            }
          </div>
        </div>
      )
    });
  }

  return (
    <div className={`${styles['col-item']} ${styles[props.size]}`}>
      <div className={styles['label']}>
        <h4>{props.label}</h4>
        { props.hasTooltip && 
          <div className={styles['tooltip-icon']} data-tooltip-id={`${props.id}-tooltip`}>
            <Tooltip
              id={`${props.id}-tooltip`}
              place='bottom'
              content={props.tooltipContent}
              className='aqtion-tooltip'
            />
          </div>
        }
      </div>
      <div className={styles['value']}>
        { props.type === 'text' && 
          <div className={styles['text']}>
            <p>{props.value}</p>
          </div>
        }
        
        { props.type === 'text-input' && 
          <TextInput
            id={props.id}
            width='100%'
            size='base'
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onInputChange}
          />
        }

        { props.type === 'url' && 
          <div className={styles['url']}>
            <p><a href={props.value} target='_blank' rel='noreferrer'>{props.value}</a></p>
          </div>
        }

        { props.type === 'dropdown' && 
          <Dropdown
            id={props.id}
            width='100%'
            size='base'
            placeholder={props.placeholder}
            items={props.dropdownItems}
            selected={props.value}
            hasSearchBox={props.hasSearchBox}
            onItemClick={props.onInputChange}
          />
        }

        
        { props.type === 'indicator' && 
          <Fragment>
            <div className={styles['indicator']}>
              <div className={`${styles['level']} ${styles['first-level']} ${styles[props.indicatorStyle]}`}></div>
              <div className={`${styles['level']} ${styles['second-level']} ${styles[props.indicatorStyle]}`}></div>
              <div className={`${styles['level']} ${styles['third-level']} ${styles[props.indicatorStyle]}`}></div>
              <div className={`${styles['pin-container']} ${styles[props.indicatorLevel]} ${styles[props.indicatorStyle]}`}>
                <div className={styles['pin']}></div>
              </div>
            </div>
            <div className={styles['indicator-text']}>
              <p>{props.value}</p>
            </div>
          </Fragment>
        }

        { props.type === 'text-block' && 
          <div className={styles['text-block']}>
            <p>{props.value}</p>
          </div>
        }
        { props.type === 'text-block-input' && 
          <Textarea
            id={props.id}
            width='100%'
            height={props.height !== undefined ? props.height : '100%'}
            size='base'
            placeholder={props.placeholder}  
            onChange={props.onInputChange}
          >
            {props.value}
          </Textarea>
        }

        { props.type === 'group' && 
          <div className={styles['group']}>
            {generateGroupItems()}
          </div>
        }
        { props.type === 'group-edit' && 
          <div className={`${styles['group']} ${styles['edit-mode']}`}>
            {generateGroupItems()}
          </div>
        }
      </div>
    </div>
  );
}

export default SectionInfoBox;
