import { BASE_URI } from "./config";
import { getAccessToken } from "../utils/auth";

export const sendContactUsFormRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/help-tickets?type=contact-us`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}

export const sendUpdateInvestorListFormRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/help-tickets?type=investor-list-update`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}

export const uploadInvestorListCSVFileRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/help-tickets/attachments`,
    options: {
      method: 'POST',
      body: body,
      dataType: 'FormData',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}

export const uploadHelpFormImageFileRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/help-tickets/attachments`,
    options: {
      method: 'POST',
      body: body,
      dataType: 'FormData',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}