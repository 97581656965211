import { Fragment, useEffect, useState } from "react";

import TextInput from "../UI/TextInput/TextInput";
import RadioButtonGroup from "../UI/RadioButtonGroup/RadioButtonGroup";

import EsgTopicQuestionList from "./EsgTopicQuestionList/EsgTopicQuestionList";

import styles from './EsgTopicsContainer.module.css';
import EsgTopicQuestionSearchResults from "./EsgTopicQuestionList/EsgTopicQuestionSearchResults";
import Button from "../UI/Button/Button";

const EsgTopicsContainer = (props) => {

  const esgTopics = props.esgTopics;
  const investorType = props.investorType;

  const [esgTopicsFilteredItems, setEsgTopicsFilteredItems] = useState(esgTopics);
  const [restructuredEsgTopicsForSearch, setRestructuredEsgTopicsForSearch] = useState([]);

  const [viewMode, setViewMode] = useState('list');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSubtopic, setSelectedSubtopic] = useState(null);
  const [openedTopicDrawerIds, setOpenedTopicDrawerIds] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [selectedEsgTopicFilter, setSelectedEsgTopicFilter] = useState('filter-all-topics');

  /* Updates the topic list / drawer with all the available topics when the all 
   * topics option is selected. */
  const radioButtonChangeToAllEsgTopicsFilterHandler = () => {
    setSelectedEsgTopicFilter('filter-all-topics');
    setEsgTopicsFilteredItems(esgTopics);
  }

  /* Updates the topic list / drawer with only the priority topics when the priority 
   * topics option is selected. */
  const radioButtonChangeToEsgPriorityTopicsFilterHandler = () => {
    setSelectedEsgTopicFilter('filter-priority-topics');
    
    /*
    setEsgTopicsFilteredItems(
      esgTopics.map(item => {
        return {
          'id': item['id'],
          'name': item['name'],
          'subtopics': item['subtopics'].filter(subItem => subItem['is_priority'])
        };
      }).filter(item => item['subtopics'].length > 0)
    );
    */


    setEsgTopicsFilteredItems(esgTopics.map(item => {
      return {
        'id': item['id'],
        'name': item['name'],
        'subtopics': item['subtopics'].map(subItem => {
          return {
            'id': subItem['id'],
            'name': subItem['name'],
            'is_priority': subItem['is_priority'],
            'questions': subItem['questions'].filter(question => (question['is_priority'] || subItem['is_priority']))
          };
        }).filter(subItem => subItem['questions'].length > 0)
      }
    }).filter(item => item['subtopics'].length > 0));
  }

  const esgTopicFilterRadioButtonItems = [
    {
      id: 'filter-all-topics',
      value: 'All Topics',
      text: 'All Topics',
      isChecked: true,
      onChange: radioButtonChangeToAllEsgTopicsFilterHandler
    },
    {
      id: 'filter-priority-topics',
      value: investorType === 'investor' ? 'Investor Priorities' : 'Advisor Priorities',
      text: investorType === 'investor' ? 'Investor Priorities' : 'Advisor Priorities',
      icon: 'flag',
      isChecked: false,
      onChange: radioButtonChangeToEsgPriorityTopicsFilterHandler
    }
  ];

  /* Filters topic list according to the search keyword that entered by the user. */
  const searchValueChangeHandler = (enteredValue) => {
    setSearchValue(enteredValue);
    setViewMode(enteredValue === '' ? 'list' : 'drawer');

    const items = JSON.parse(JSON.stringify(restructuredEsgTopicsForSearch));
    setSearchResults(items.map(item => {
      const filteredItem = item;
      filteredItem['questions'] = filteredItem['questions'].filter(question => question['text'].toLowerCase().includes(enteredValue.toLocaleLowerCase())).map(question => {
        const beginningIndex = question['text'].toLowerCase().indexOf(enteredValue.toLocaleLowerCase());
        const endingIndex = beginningIndex + enteredValue.length;
        const beginningPart = question['text'].substring(0, beginningIndex);
        const highlightedPart = question['text'].substring(beginningIndex, endingIndex);
        const endingPart = question['text'].substring(endingIndex);
        question['highlighted_text'] = <Fragment>{beginningPart}<span className={styles['highlighted']}>{highlightedPart}</span>{endingPart}</Fragment>;
        return question;
      });
      return filteredItem;
    }).filter(item => item['questions'].length > 0));
  }

  /* Returns the `list` viewing mode when the back button is clicked. */
  const backButtonClickHandler = () => {
    setSelectedTopic(null);
    setSelectedSubtopic(null);
    setViewMode('list');
    setSearchValue('');
    setOpenedTopicDrawerIds([]);
  }

  /* Shows list of questions located under the subtopic. */
  const selectSubtopicHandler = (topicId, subtopicId) => {
    const filteredTopics = esgTopics.filter(item => item['id'] === topicId);
    if (filteredTopics.length > 0) {
      const filteredSubtopics = filteredTopics[0]['subtopics'].filter(subItem => subItem['id'] === subtopicId);
      if (filteredTopics.length > 0) {
        setSelectedTopic(filteredTopics[0]);
        setSelectedSubtopic(filteredSubtopics[0]);
        setViewMode('drawer');
        setOpenedTopicDrawerIds(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          items.push(topicId);
          return items;
        });
      } 
    }
  }

  /* Shows comments of selected question. */
  const openTopicDrawerHandler = (topicId) => {
    setOpenedTopicDrawerIds(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      items.push(topicId);
      return items;
    });
  }

  /* Hides comments area for the question. */
  const closeTopicDrawerHandler = (topicId) => {
    setOpenedTopicDrawerIds(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const selectedItemIndex = items.findIndex(item => item === topicId);
      items.splice(selectedItemIndex, 1);
      return items;
    });
  }

  /* Creates content for the `list` viewing mode. */
  const esgTopicListContent = esgTopicsFilteredItems.map(item => {
    return (
      <div key={`esg-topic-${item['id']}`} className={styles['topic-item']}>
        <div className={styles['header']}>
          <div className={styles['title']}>
            <h3>{item['name']}</h3>
          </div>
        </div>
        <div className={styles['body']}>
          <div className={styles['sub-topics-container']}>
            {item['subtopics'].map(subItem => {
              return (
                <div
                  key={`esg-sub-topic-${subItem['id']}`}
                  className={styles['sub-topic-item']}
                  onClick={() => selectSubtopicHandler(item['id'], subItem['id'])}
                >
                  <h4>{subItem['name']}</h4>
                  {subItem['is_priority'] && 
                    <div className={styles['priority-icon']}>
                      <div className={styles['flag']}></div>
                    </div>
                  }
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  });

  /* Creates content for the `drawer` viewing mode. */
  const esgTopicDrawerContent = esgTopicsFilteredItems.map(item => {
    const onClickHandler = openedTopicDrawerIds.includes(item['id']) ? () => closeTopicDrawerHandler(item['id']) : () => openTopicDrawerHandler(item['id']);
    return (
      <div key={`esg-topic-${item['id']}`} className={styles['topic-item']} onClick={onClickHandler}>
        <div className={`${styles['header']} ${selectedTopic && selectedTopic['id'] === item['id'] && styles['selected']}`}>
          <div className={styles['title']}>
            <h3>{item['name']}</h3>
          </div>
          <div className={styles['drawer-icon']}>
            <div className={styles['caret']}></div>
          </div>
        </div>
        {openedTopicDrawerIds.includes(item['id']) && 
          <div className={styles['body']}>
            <div className={styles['sub-topics-container']}>
              {item['subtopics'].map(subItem => {
                return (
                  <div
                    key={`esg-sub-topic-${subItem['id']}`}
                    className={`${styles['sub-topic-item']} ${selectedSubtopic && selectedSubtopic['id'] === subItem['id'] && styles['selected']}`}
                    onClick={() => selectSubtopicHandler(item['id'], subItem['id'])}
                  >
                    <h4>{subItem['name']}</h4>
                    {subItem['is_priority'] && 
                      <div className={styles['priority-icon']}>
                        <div className={styles['flag']}></div>
                      </div>
                    }
                  </div>
                );
              })}
            </div>
          </div>
        }
      </div>
    )
  });

  /* Sets initial data for the topics. */
  useEffect(() => {
    setEsgTopicsFilteredItems(esgTopics);

    setSearchResults(esgTopics.map(item => {
      if (item['subtopics']) {
        return item['subtopics'].map(subItem => {
          return {
            'topic_id': item['id'],
            'topic_name': item['name'],
            'subtopic_id': subItem['id'],
            'subtopic_name': subItem['name'],
            'questions': subItem['questions'].map(question => {
              question['highlighted_text'] = question['text'];
              return question;
            })
          };
        });
      }
      else {
        return null;
      }
    }).filter(item => item !== null).flat());
    
    setRestructuredEsgTopicsForSearch(esgTopics.map(item => {
      if (item['subtopics']) {
        return item['subtopics'].map(subItem => {
          return {
            'topic_id': item['id'],
            'topic_name': item['name'],
            'subtopic_id': subItem['id'],
            'subtopic_name': subItem['name'],
            'questions': subItem['questions']
          };
        });
      }
      else {
        return null;
      }
    }).filter(item => item !== null).flat());
  }, [esgTopics]);

  return (
    <div>
      <div className={styles['search-and-filter-area']}>
        <div className={styles['search-input']}>
          <TextInput
            id='question-item-search-box'
            width='200px'
            size='xsmall'
            placeholder='Search Topics'
            value={searchValue}
            onChange={searchValueChangeHandler}
          />
        </div>
        {searchValue === '' && false && 
          <div className={styles['priority-filter']}>
            <RadioButtonGroup
              name='filter-all-topics'
              items={esgTopicFilterRadioButtonItems}
              selected={selectedEsgTopicFilter}
            />
          </div>
        }
        {searchValue === '' && selectedSubtopic && 
          <div className={styles['export-button']}>
            <Button 
              type={"secondary"}
              size={"xsmall"}
              leftIcon={'download'}
              onClick={() => props.onExportSubtopicsCommentsClick(selectedSubtopic)}
            >
              Export to Word
            </Button>
          </div>
        }
      </div>

      {viewMode === 'list' &&
        <div className={styles['topic-list']}>
          { esgTopicListContent }
        </div>
      }

      {viewMode === 'drawer' &&
        <div className={styles['left-side-container']}>
          <div className={styles['back-button-container']}>
            <div className={styles['back-button']} onClick={backButtonClickHandler}>
              <div className={styles['back-icon']}></div>
              <h4>Back</h4>
            </div>
          </div>

          {searchValue === '' &&
            <div className={styles['topic-drawer']}>
              { esgTopicDrawerContent }
            </div>
          }
        </div>
      }

      {esgTopics && esgTopics.length > 0 && viewMode === 'drawer' && searchValue === '' &&
        <div className={styles['question-list-container']}>
          { selectedEsgTopicFilter === 'filter-all-topics' && 
            <EsgTopicQuestionList
              title={`${selectedTopic["name"]} / ${selectedSubtopic["name"]}`}
              questions={selectedSubtopic["questions"]}
              onShowQuestionCommenClick={props.onShowQuestionCommenClick}
            />
          }
          { selectedEsgTopicFilter === 'filter-priority-topics' && selectedSubtopic['is_priority'] &&
            <EsgTopicQuestionList
              title={`${selectedTopic["name"]} / ${selectedSubtopic["name"]}`}
              questions={selectedSubtopic["questions"]}
              onShowQuestionCommenClick={props.onShowQuestionCommenClick}
            />
          }
          { selectedEsgTopicFilter === 'filter-priority-topics' && !selectedSubtopic['is_priority'] &&
            <EsgTopicQuestionList
              title={`${selectedTopic["name"]} / ${selectedSubtopic["name"]}`}
              questions={selectedSubtopic["questions"].filter(question => question['is_priority'])}
              onShowQuestionCommenClick={props.onShowQuestionCommenClick}
            />
          }
        </div>
      }

      {esgTopics && esgTopics.length > 0 && viewMode === 'drawer' && searchValue !== '' &&
        <EsgTopicQuestionSearchResults
          data={searchResults}
          onShowQuestionCommenClick={props.onShowQuestionCommenClick}
        />
      }
    </div>
  )
}

export default EsgTopicsContainer;
