import { Fragment } from 'react';
import styles from './IconButton.module.css';

const IconButton = (props) => {
  const isDisabled = props.isDisabled ? true : false;
  const sizeStyle = props.size === undefined ? styles['base'] : styles[props.size];

  const clickHandler = () => {
    props.onClick();
  }

  return (
    <Fragment>
      <div className={styles['icon-button-container']}>
        <button className={`${styles[props.type]} ${sizeStyle}`} disabled={isDisabled} onClick={clickHandler}>
          <div className={`${styles['icon-container']} ${styles[props.icon]}`}></div>
        </button>
      </div>
    </Fragment>
  )
}

export default IconButton
