import { useCallback, useEffect, useState } from "react";
import { generateClientMeetingRequestInvestorsTableItems, generateClientMeetingRequestProxyAdvisorsTableItems } from "../../../utils/client-engagement-helpers";
import { fetchClientEngagementsMeetingRequestRequest, updateClientEngagementsMeetingRequestInvestorRequest, updateClientEngagementsMeetingRequestProxyAdvisorRequest } from "../../../requests/client-engagement-requests";
import { getAuthClientId, getClientType } from "../../../utils/auth";
import { handleRequestError } from "../../../utils/request-error-helpers";
import useHttp from "../../../hooks/use-http";
import sortArray from 'sort-array';

import Button from "../../UI/Button/Button";
import Modal from "../../UI/Modal/Modal";
import Table from "../../UI/Table/Table";

import EngagementDraftEmailModal from "../EngagementDraftEmailModal/EngagementDraftEmailModal";
import EngagementEmailCustomizationModal from "../EngagementEmailCustomizationModal/EngagementEmailCustomizationModal";

import styles from './EngagementMeetingRequestForm.module.css';

const EngagementMeetingRequestDetailsForm = (props) => {

  const clientId = getAuthClientId();
  const clientType = getClientType();
  const meetingRequestId = props.meetingRequestId;

  const [isLoading, error, sendRequest] = useHttp();

  const [meetingRequestDetailsData, setMeetingRequestDetailsData] = useState([]);

  const setBreadcrumbItems = props.setBreadcrumbItems;

  const [showUpdateEmailContentWarning, setShowUpdateEmailContentWarning] = useState(false);
  const [showDraftEmailModal, setShowDraftEmailModal] = useState(false);
  const [showEmailCustomizationModal, setShowEmailCustomizationModal] = useState(false);

  const [selectedInvestorType, setSelectedInvestorType] = useState(null);
  const [selectedInvestorId, setSelectedInvestorId] = useState(null);
  const [selectedProxyAdvisorId, setSelectedProxyAdvisorId] = useState(null);
  const [isSelectedItemDraftEmailDisabled, setIsSelectedItemDraftEmailDisabled] = useState(false);

  const [meetingRequestInvestorsData, setMeetingRequestInvestorsData] = useState([]);
  const [meetingRequestInvestorsTableItems, setMeetingRequestInvestorsTableItems] = useState(null);

  const [meetingRequestProxyAdvisorsData, setMeetingRequestProxyAdvisorsData] = useState([]);
  const [meetingRequestProxyAdvisorsTableItems, setMeetingRequestProxyAdvisorsTableItems] = useState(null);

  const [meetingRequestInvestorsTableSortConfig, setMeetingRequestInvestorsTableSortConfig] = useState({
    'by': ['name'],
    'order': ['asc']
  });

  const meetingRequestInvestorsTableSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setMeetingRequestInvestorsTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setMeetingRequestInvestorsData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, meetingRequestInvestorsTableSortConfig);
    });
  }, [meetingRequestInvestorsTableSortConfig]);


  const [meetingRequestProxyAdvisorsTableSortConfig, setMeetingRequestProxyAdvisorsTableSortConfig] = useState({
    'by': ['name'],
    'order': ['asc']
  });

  const meetingRequestProxyAdvisorsTableSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setMeetingRequestProxyAdvisorsTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setMeetingRequestProxyAdvisorsData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, meetingRequestProxyAdvisorsTableSortConfig);
    });
  }, [meetingRequestProxyAdvisorsTableSortConfig]);

  /* Navigates to details page for the selected investor. */
  const investorClickHandler = (investorId) => {
    window.location.href = `../investors/${investorId}`;
  }

  const investorCustomizeClickHandler = useCallback((investorId) => {
    setSelectedInvestorId(investorId);
    setSelectedProxyAdvisorId(null);
    setSelectedInvestorType('type-investor');
    setShowEmailCustomizationModal(true);

    const indexOfInvestor = meetingRequestInvestorsData.map(item => item['client_investor_id']).indexOf(investorId);
    if(meetingRequestInvestorsData[indexOfInvestor]['email_status'] === 'Sent') {
      setIsSelectedItemDraftEmailDisabled(true);
    }
    else {
      setIsSelectedItemDraftEmailDisabled(false);
    }
  }, [meetingRequestInvestorsData]);

  /* Updates email status value for the selected investor. */
  const investorEmailStatusChangeHandler = useCallback((itemId, selectedStatus) => {
    const clickedInvestorId = parseInt(itemId.split('client-engagements-meeting-request-investor-')[1]);
    const body = {
      'email_status': selectedStatus
    }
    sendRequest(
      updateClientEngagementsMeetingRequestInvestorRequest(clientId, meetingRequestId, clickedInvestorId, body),
      (data) => { 
        setMeetingRequestInvestorsData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          const indexOfInvestor = items.map(item => item['client_investor_id']).indexOf(clickedInvestorId);
          items[indexOfInvestor] = {...items[indexOfInvestor], ...data};
          return items;
        });
      }
    );
  }, [clientId, meetingRequestId, sendRequest]);

  /* Updates meeting status value for the selected investor. */
  const investorMeetingStatusChangeHandler = useCallback((itemId, selectedStatus) => {
    const clickedInvestorId = parseInt(itemId.split('client-engagements-meeting-request-investor-')[1]);
    const body = {
      'meeting_status': selectedStatus
    }
    sendRequest(
      updateClientEngagementsMeetingRequestInvestorRequest(clientId, meetingRequestId, clickedInvestorId, body),
      (data) => { 
        setMeetingRequestInvestorsData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          const indexOfInvestor = items.map(item => item['client_investor_id']).indexOf(clickedInvestorId);
          items[indexOfInvestor] = {...items[indexOfInvestor], ...data};
          return items;
        });
      }
    );
  }, [clientId, meetingRequestId, sendRequest]);

  /* Navigates to details page for the selected proxy advisor. */
  const proxyAdvisorClickHandler = (proxyAdvisorId) => {
    window.location.href = `../proxy-advisors/${proxyAdvisorId}`;
  }

  const proxyAdvisorCustomizeClickHandler = useCallback((proxyAdvisorId) => {
    setSelectedInvestorId(null);
    setSelectedProxyAdvisorId(proxyAdvisorId);
    setSelectedInvestorType('type-proxy-advisor');
    setShowEmailCustomizationModal(true);

    const indexOfInvestor = meetingRequestProxyAdvisorsData.map(item => item['client_proxy_advisor_id']).indexOf(proxyAdvisorId);
    if(meetingRequestProxyAdvisorsData[indexOfInvestor]['email_status'] === 'Sent') {
      setIsSelectedItemDraftEmailDisabled(true);
    }
    else {
      setIsSelectedItemDraftEmailDisabled(false);
    }
  }, [meetingRequestProxyAdvisorsData]);

  /* Updates email status value for the selected proxy advisor. */
  const proxyAdvisorEmailStatusChangeHandler = useCallback((itemId, selectedStatus) => {
    const clickedProxyAdvisorId = parseInt(itemId.split('client-engagements-meeting-request-proxy-advisor-')[1]);
    const body = {
      'email_status': selectedStatus
    }
    sendRequest(
      updateClientEngagementsMeetingRequestProxyAdvisorRequest(clientId, meetingRequestId, clickedProxyAdvisorId, body),
      (data) => { 
        setMeetingRequestProxyAdvisorsData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          const indexOfProxyAdvisor = items.map(item => item['client_proxy_advisor_id']).indexOf(clickedProxyAdvisorId);
          items[indexOfProxyAdvisor] = {...items[indexOfProxyAdvisor], ...data};
          return items;
        });
      }
    );
  }, [clientId, meetingRequestId, sendRequest]);

  /* Updates meeting status value for the selected proxy advisor. */
  const proxyAdvisorMeetingStatusChangeHandler = useCallback((itemId, selectedStatus) => {
    const clickedProxyAdvisorId = parseInt(itemId.split('client-engagements-meeting-request-proxy-advisor-')[1]);
    const body = {
      'meeting_status': selectedStatus
    }
    sendRequest(
      updateClientEngagementsMeetingRequestProxyAdvisorRequest(clientId, meetingRequestId, clickedProxyAdvisorId, body),
      (data) => { 
        setMeetingRequestProxyAdvisorsData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          const indexOfProxyAdvisor = items.map(item => item['client_proxy_advisor_id']).indexOf(clickedProxyAdvisorId);
          items[indexOfProxyAdvisor] = {...items[indexOfProxyAdvisor], ...data};
          return items;
        });
      }
    );
  }, [clientId, meetingRequestId, sendRequest]);


  /* Updates draft email for the selected investor. */
  const selectedItemEmailCustomizeHandler = (customizedEmailData, emailStatus) => {
    const body = {
      'draft_email': customizedEmailData
    };
    if (emailStatus !== null && emailStatus !== undefined) {
      body['email_status'] = emailStatus;
    }
    if (selectedInvestorType === 'type-investor') {
      sendRequest(
        updateClientEngagementsMeetingRequestInvestorRequest(clientId, meetingRequestId, selectedInvestorId, body),
        (data) => { 
          setShowEmailCustomizationModal(false);
          setSelectedInvestorId(null);
          setSelectedInvestorType(null);
          setMeetingRequestInvestorsData(prevState => {
            const items = JSON.parse(JSON.stringify(prevState));
            const indexOfInvestor = items.map(item => item['client_investor_id']).indexOf(selectedInvestorId);
            items[indexOfInvestor] = {...items[indexOfInvestor], ...data};
            return items;
          });
        }
      );
    }
    else if (selectedInvestorType === 'type-proxy-advisor') {
      sendRequest(
        updateClientEngagementsMeetingRequestProxyAdvisorRequest(clientId, meetingRequestId, selectedProxyAdvisorId, body),
        (data) => { 
          setShowEmailCustomizationModal(false);
          setSelectedInvestorId(null);
          setSelectedInvestorType(null);
          setMeetingRequestProxyAdvisorsData(prevState => {
            const items = JSON.parse(JSON.stringify(prevState));
            const indexOfProxyAdvisor = items.map(item => item['client_proxy_advisor_id']).indexOf(selectedProxyAdvisorId);
            items[indexOfProxyAdvisor] = {...items[indexOfProxyAdvisor], ...data};
            return items;
          });
        }
      );
    }
  }

  /* Send a request to fetch meeting request item and its investor and proxy advisor list. */
  useEffect(() => {
    sendRequest(
      fetchClientEngagementsMeetingRequestRequest(clientId, meetingRequestId),
      (data) => { 
        setMeetingRequestDetailsData(data)
        setMeetingRequestInvestorsData(data['investors']);
        setMeetingRequestProxyAdvisorsData(data['proxy_advisors']);
        setBreadcrumbItems(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          if(items.length < 3){
            items[1]['text'] = 'Meeting Requests';
            items[1]['href'] = '../engagements?type=meeting-requests';
            items.push({
              'text': data['engagement_name'],
              'href': ''
            });
          }
          return items;
        });
      }
    );
  }, [sendRequest, clientId, meetingRequestId, setBreadcrumbItems]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  useEffect(() => {
    setMeetingRequestInvestorsTableItems(
      generateClientMeetingRequestInvestorsTableItems(
        clientType, meetingRequestInvestorsData, investorClickHandler, investorCustomizeClickHandler,
        investorEmailStatusChangeHandler, investorMeetingStatusChangeHandler,
        meetingRequestInvestorsTableSortConfig, meetingRequestInvestorsTableSortHandler
      )
    );
  }, [clientType, meetingRequestInvestorsData, meetingRequestInvestorsTableSortConfig,
      investorCustomizeClickHandler, investorEmailStatusChangeHandler, investorMeetingStatusChangeHandler]);

  useEffect(() => {
    setMeetingRequestProxyAdvisorsTableItems(
      generateClientMeetingRequestProxyAdvisorsTableItems(
        meetingRequestProxyAdvisorsData, proxyAdvisorClickHandler, proxyAdvisorCustomizeClickHandler,
        proxyAdvisorEmailStatusChangeHandler, proxyAdvisorMeetingStatusChangeHandler,
        meetingRequestProxyAdvisorsTableSortConfig, meetingRequestProxyAdvisorsTableSortHandler
      )
    );
  }, [meetingRequestProxyAdvisorsData, meetingRequestProxyAdvisorsTableSortConfig,
      proxyAdvisorCustomizeClickHandler, proxyAdvisorEmailStatusChangeHandler, proxyAdvisorMeetingStatusChangeHandler]);

  return (
    <div className={styles['meeting-request-container']}>
      {showUpdateEmailContentWarning &&
        <Modal
          type='critical'
          title='Edit Email Content'
          message={`If you proceed with editing the Email content, you will override all customizations you may have done except the ones marked as sent. Would you still like to proceed with updating the template?`}
          onCloseClick={() => setShowUpdateEmailContentWarning(false)}
          approveButton = {{
            'text': 'Proceed',
            'onClick': () => props.onEditEngagement()
          }}
          cancelButton = {{
            'text': 'Cancel',
            'onClick': () => setShowUpdateEmailContentWarning(false)
          }}
        />
      }

      <div className={styles['header']}>
        <div className={styles['meeting-request-title-container']}>
          <div className={styles['title']}>
            <h2>{meetingRequestDetailsData['engagement_name']}</h2>
          </div>
          <div className={styles['type-chip']}>
            <div className={`${styles['icon']} ${styles['meeting-request']}`}></div>
            <div className={styles['label']}>
              <p>Meeting Request</p>
            </div>
          </div>
        </div>

        <div className={styles['meeting-request-engagement-list-buttons']}>  
          <div className={styles['right-buttons']}>
            <Button type={'primary'} size={'xsmall'} leftIcon={'document'} onClick={() => setShowDraftEmailModal(true)}>Preview Email</Button>
            <Button type={'secondary'} size={'xsmall'} leftIcon={'edit'} onClick={() => setShowUpdateEmailContentWarning(true)}>Edit Engagement</Button>
          </div>
        </div>
      </div>

      { meetingRequestProxyAdvisorsTableItems &&
        <div className={styles['engagement-meeting-request-proxy-advisor-list-container']}>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={meetingRequestProxyAdvisorsTableItems} isScrollable={true} />
        </div>
      }

      { meetingRequestInvestorsTableItems &&
        <div className={styles['engagement-meeting-request-investor-list-container']}>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={meetingRequestInvestorsTableItems} isScrollable={true} />
        </div>
      }

      { showDraftEmailModal &&
        <EngagementDraftEmailModal
          meetingRequestId={meetingRequestId}
          onCloseClick={ () => setShowDraftEmailModal(false) }
          onCancel={ () => setShowDraftEmailModal(false) }
          onEditEngagement={() => {
            setShowDraftEmailModal(false);
            setShowUpdateEmailContentWarning(true);
          }}
        />
      }

      { showEmailCustomizationModal && 
        <EngagementEmailCustomizationModal
          meetingRequestId={meetingRequestId}
          investorType={selectedInvestorType}
          investorId={selectedInvestorId}
          proxyAdvisorId={selectedProxyAdvisorId}
          onSaveClick={selectedItemEmailCustomizeHandler}
          onCloseClick={ () => setShowEmailCustomizationModal(false) }
          isDisabled={isSelectedItemDraftEmailDisabled}
        />
      }

    </div>
  );
}

export default EngagementMeetingRequestDetailsForm;
