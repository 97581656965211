import { Fragment, useState } from 'react';
import styles from './SideNavigationBar.module.css';
import { deleteSession, getAuthUserName, getAuthUserType } from '../../../utils/auth';

const SideNavigationBar = (props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(props.selected);

  const userName = getAuthUserName();
  const userType = getAuthUserType();

  const selectMenuItemHandler = (itemId) => {
    const menuItem = props.menuItems.filter(item => item.id === itemId)[0];
    if (menuItem && menuItem['href'] !== undefined) {
      window.location.href = window.location.origin + menuItem.href;
    }
    else if(itemId === 'help') {
      window.location.href = window.location.origin + '/help';
    }
    else if(itemId === 'profile') {
      window.location.href = window.location.origin + '/profile';
    }
    setSelectedMenuItem(itemId);
  }

  const topMenuItems = props.menuItems.map(item => {
    return (
      <div 
        key={item.id}
        className={`${styles['item']} ${selectedMenuItem === item.id ? styles['selected'] : ''}`}
        onClick={() => selectMenuItemHandler(item.id)}
      >
        <div className={styles['ribbon']}></div>
        <div className={`${styles['icon']} ${styles[item['icon']]}`}></div>
        { props.type === "wide" &&
          <div className={styles['text']}>
            <p>{item['text']}</p>
          </div>
        }
      </div>
    );
  })

  const bottomMenuItems = (
    <Fragment>
      <div className={`${styles['item']} ${selectedMenuItem === 'help' ? styles['selected'] : ''}`} onClick={() => selectMenuItemHandler('help')}>
        <div className={styles['ribbon']}></div>
         <div className={`${styles['icon']} ${styles['help']}`}></div>
        { props.type === "wide" &&
          <div className={styles['text']}>
            <p>Help</p>
          </div>
        }
      </div>
      <div className={`${styles['profile']} ${selectedMenuItem === 'profile' ? styles['selected'] : ''}`} onClick={() => selectMenuItemHandler('profile')}>
        <div className={styles['ribbon']}></div>
        {props.profilePictureUrl && 
          <div
            className={`${styles['photo']}`}
            style={{
              'backgroundImage': `url("${props.profilePictureUrl}")`,
              'backgroundPosition': 'center center',
              'backgroundRepeat': 'no-repeat',
              'backgroundSize': 'cover',
              'backgroundColor': '#FFFFFF'
            }}
          ></div>
        }
        {!props.profilePictureUrl && 
          <div className={`${styles['photo']}`}></div>
        }
        { props.type === "wide" &&
          <div className={styles['info']}>
            <div className={styles['name']}>
              <p>{userName}</p>
            </div>
            <div className={styles['role']}>
              <p>{userType}</p>
            </div>
          </div>
        }
      </div>
      <div className={styles['item']} onClick={() => deleteSession()}>
        <div className={styles['ribbon']}></div>
        <div className={`${styles['icon']} ${styles['logout']}`}></div>
        { props.type === "wide" &&
          <div className={styles['text']}>
            <p>Logout</p>
          </div>
        }
      </div>
    </Fragment>
  )

  return (
    <Fragment>
      <div id='side-nav-bar-container' className={`${styles['side-nav-bar-container']} ${styles[props.type]}`}>
        <div className={styles['logo']}></div>
        <div className={styles['uptade-type-button']} onClick={props.onToggle}></div>
        <div className={styles['top-menu']}>
          {topMenuItems}
        </div>
        <div className={styles['bottom-menu']}>
          {bottomMenuItems}
        </div>
      </div>
    </Fragment>
  )
}

export default SideNavigationBar
