import { BASE_URI } from "./config";
import { getAccessToken } from "../utils/auth";

/* MEETING REQUESTS */

export const listClientEngagementsMeetingRequestsRequest = (clientId, queryParams) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-requests?${queryParams}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const fetchClientEngagementsMeetingRequestRequest = (clientId, meetingRequestId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-requests/${meetingRequestId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const createClientEngagementsMeetingRequestRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-requests`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateClientEngagementsMeetingRequestRequest = (clientId, meetingRequestId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-requests/${meetingRequestId}`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const fetchClientEngagementsMeetingRequestInvestorRequest = (clientId, meetingRequestId, investorId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-requests/${meetingRequestId}/investors/${investorId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const updateClientEngagementsMeetingRequestInvestorRequest = (clientId, meetingRequestId, investorId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-requests/${meetingRequestId}/investors/${investorId}`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const fetchClientEngagementsMeetingRequestProxyAdvisorRequest = (clientId, meetingRequestId, proxyAdvisorId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-requests/${meetingRequestId}/proxy-advisors/${proxyAdvisorId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const updateClientEngagementsMeetingRequestProxyAdvisorRequest = (clientId, meetingRequestId, proxyAdvisorId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-requests/${meetingRequestId}/proxy-advisors/${proxyAdvisorId}`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


/* MEETING REQUESTS */


/* SHAREHOLDER COMMUNICATIONS */

export const listClientEngagementsShareholderCommunicationsRequest = (clientId, queryParams) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/shareholder-communications?${queryParams}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const fetchClientEngagementsShareholderCommunicationRequest = (clientId, shareholderCommunicationId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/shareholder-communications/${shareholderCommunicationId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const createClientEngagementsShareholderCommunicationRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/shareholder-communications`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateClientEngagementsShareholderCommunicationRequest = (clientId, shareholderCommunicationId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/shareholder-communications/${shareholderCommunicationId}`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const fetchClientEngagementsShareholderCommunicationInvestorRequest = (clientId, shareholderCommunicationId, investorId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/shareholder-communications/${shareholderCommunicationId}/investors/${investorId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const updateClientEngagementsShareholderCommunicationInvestorRequest = (clientId, shareholderCommunicationId, investorId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/shareholder-communications/${shareholderCommunicationId}/investors/${investorId}`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const fetchClientEngagementsShareholderCommunicationProxyAdvisorRequest = (clientId, shareholderCommunicationId, proxyAdvisorId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/shareholder-communications/${shareholderCommunicationId}/proxy-advisors/${proxyAdvisorId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const updateClientEngagementsShareholderCommunicationProxyAdvisorRequest = (clientId, shareholderCommunicationId, proxyAdvisorId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/shareholder-communications/${shareholderCommunicationId}/proxy-advisors/${proxyAdvisorId}`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}

/* SHAREHOLDER COMMUNICATIONS */


/* MEETING NOTES */

export const listClientEngagementsMeetingNotesRequest = (clientId, queryParams) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-notes?${queryParams}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const fetchClientEngagementsMeetingNoteRequest = (clientId, meetingNoteId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-notes/${meetingNoteId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const uploadClientEngagementsMeetingNoteAttachmentRequest = (clientId, meetingNoteId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-notes/${meetingNoteId}/attachments`,
    options: {
      method: 'POST',
      body: body,
      dataType: 'FormData',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const createClientEngagementsMeetingNoteRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-notes`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateClientEngagementsMeetingNoteRequest = (clientId, meetingNoteId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/meeting-notes/${meetingNoteId}`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}

/* MEETING NOTES */


/* OTHER NOTES */

export const listClientEngagementsOtherNotesRequest = (clientId, queryParams) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/other-notes?${queryParams}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const fetchClientEngagementsOtherNoteRequest = (clientId, otherNoteId) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/other-notes/${otherNoteId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const uploadClientEngagementsOtherNoteAttachmentRequest = (clientId, otherNoteId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/other-notes/${otherNoteId}/attachments`,
    options: {
      method: 'POST',
      body: body,
      dataType: 'FormData',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const createClientEngagementsOtherNoteRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/other-notes`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateClientEngagementsOtherNoteRequest = (clientId, otherNoteId, body) => {
  return {
    url: `${BASE_URI}/clients/${clientId}/other-notes/${otherNoteId}`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}

/* OTHER NOTES */
