import { useState } from "react";

import Tab from "../../../../components/UI/Tab/Tab";

import InvestorEngagementsOtherNotes from "./InvestorEngagementsOtherNotes/InvestorEngagementsOtherNotes";
import InvestorEngagementsMeetingNotes from "./InvestorEngagementsMeetingNotes/InvestorEngagementsMeetingNotes";
import InvestorEngagementsMeetingRequests from "./InvestorEngagementsMeetingRequests/InvestorEngagementsMeetingRequests";
import InvestorEngagementsShareholderCommunications from "./InvestorEngagementsShareholderCommunications/InvestorEngagementsShareholderCommunications";

import styles from './InvestorEngagements.module.css';


const InvestorEngagements = (props) => {
  const [selectedTab, setSelectedTab] = useState('tab-meeting-requests');
  const [showEngagementsTab, setShowEngagementsTab] = useState(true);

  const investorEngagementsTabItems = [
    {
      'id': 'tab-meeting-requests',
      'text': 'Meeting Requests'
    },
    {
      'id': 'tab-shareholder-communications',
      'text': 'Shareholder Communications'
    },
    {
      'id': 'tab-meeting-notes',
      'text': 'Meeting Notes'
    },
    {
      'id': 'tab-other-notes',
      'text': 'Other Notes'
    }
  ];

  const investorEngagementsTabChangeHandler = (selectedTabId) => {
    setSelectedTab(selectedTabId)
  }

  return (
    <div>
      {showEngagementsTab &&
        <div className={styles['tab-container']}>
          <Tab items={investorEngagementsTabItems} selectedTab={selectedTab} type='second-level' onTabChange={investorEngagementsTabChangeHandler} />
        </div>
      }

      <div className={styles['table-container']}>
        { selectedTab === 'tab-meeting-requests' &&
          <InvestorEngagementsMeetingRequests
            width={props.width}
            investorId={props.investorId}
          />
        }
        { selectedTab === 'tab-shareholder-communications' &&
          <InvestorEngagementsShareholderCommunications
            width={props.width}
            investorId={props.investorId}
          />
        }
        { selectedTab === 'tab-meeting-notes' && 
          <InvestorEngagementsMeetingNotes
            width={props.width}
            investorId={props.investorId}
            setShowEngagementsTab={setShowEngagementsTab}
          />
        }
        { selectedTab === 'tab-other-notes' && 
          <InvestorEngagementsOtherNotes
            width={props.width}
            investorId={props.investorId}
            setShowEngagementsTab={setShowEngagementsTab}
          />
        }
      </div>
    </div>
  );
}

export default InvestorEngagements;
