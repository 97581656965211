import { BASE_URI } from "./config";
import { getAccessToken } from "../utils/auth";

export const getUserProfileRequest = () => {
  return {
    url: `${BASE_URI}/user-profile`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const updateUserPasswordRequest = (body) => {
  return {
    url: `${BASE_URI}/user-profile/password`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateUserProfilePictureRequest = (body) => {
  return {
    url: `${BASE_URI}/user-profile/profile-picture`,
    options: {
      method: 'PUT',
      body: body,
      dataType: 'FormData',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}