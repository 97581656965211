import { enableAccountManagerViewMode, getClientAvailableModules } from "./auth";

/* Account Manager - My Clients Table */
export const generateAccountManagerMyClientsTableItems = (clientListItems) => {
  const createShowUserRowLeft = (item) => {
    const clientId = item['id'];
    const clientName = item['company_information']['name'];
    const rowId = `client-item-${clientId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#client-name`,
          'type': 'link-button',
          'text': clientName,
          'href': `/clients/${clientId}`,
          'stretch': false
        }
      ]
    };
  }

  const createShowUserRowRight = (item) => {
    const clientId = item['id'];
    const clientName = item['company_information']['name'];
    const clientType = item['company_information']['client_type'];
    const clientAvailableModules = item['module_management'];
    const rowId = `client-item-${clientId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#show-details`,
          'type': 'icon-button',
          'icon': 'eye',
          'onClick': () => {
            enableAccountManagerViewMode(clientId, clientName, clientType, clientAvailableModules);
            const availableModules = getClientAvailableModules();
            window.location.href = availableModules['dashboard'] ? `../dashboard` : `../investors`;
          },
          'stretch': false
        }
      ]
    };
  }
  
  const createShowUserRowMiddle = (item) => {
    const relationshipStatusColors = {
      'Strong': 'success',
      'Standard': 'warning',
      'Weak': 'danger',
      'Unknown': 'unknown'
    };

    const clientId = item['id'];
    const relationshipStatus = item['main_contact']['relationship_status'] !== '' ? item['main_contact']['relationship_status'] : 'Unknown';
    const country = item['company_information']['country'];
    const mainContactFullname = item['main_contact']['fullname'];
    const mainContactTitle = item['main_contact']['job_title'];
    const contractStartDate = item['subscription_details']['contract_start_date'] === null ? '-' : new Date(item['subscription_details']['contract_start_date']).toLocaleDateString('en-GB');
    const contractEndDate =  item['subscription_details']['contract_end_date'] === null ? '-' : new Date(item['subscription_details']['contract_end_date']).toLocaleDateString('en-GB');
    const rowId = `client-item-${clientId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#filler-left`,
          'type': 'empty-cell',
          'stretch': false
        },
        {
          'id': `${rowId}#relationship-status`,
          'type': 'traffic-light',
          'color_code': relationshipStatusColors[relationshipStatus],
          'text': relationshipStatus,
          'stretch': true
        },
        {
          'id': `${rowId}#country`,
          'type': 'default',
          'text': country,
          'stretch': true
        },
        {
          'id': `${rowId}#main-contact-fullname`,
          'type': 'default',
          'text': mainContactFullname,
          'stretch': true
        },
        {
          'id': `${rowId}#main-contact-title`,
          'type': 'default',
          'text': mainContactTitle,
          'stretch': true
        },
        {
          'id': `${rowId}#contract-start-date`,
          'type': 'default',
          'text': contractStartDate,
          'stretch': true
        },
        {
          'id': `${rowId}#contract-start-end`,
          'type': 'default',
          'text': contractEndDate,
          'stretch': true
        },
        {
          'id': `${rowId}#filler-right`,
          'type': 'empty-cell',
          'stretch': false
        },
      ]
    };
  }

  const createTableLeftItems = () => {
    const leftItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'my-clients-header-left',
            'columns': [
              {'text': 'CLIENT NAME', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [272],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [272],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return leftItems;
  }

  const createTableRightItems = () => {
    const rightItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'my-clients-header-right',
            'columns': [
              {'text': 'VIEW AS CLIENT', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [100],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [100],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return rightItems;
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': '', 'stretch': false},
              {'text': 'RELATIONSHIP STATUS', 'stretch': true},
              {'text': 'COUNTRY', 'stretch': true},
              {'text': 'MAIN CONTACT', 'stretch': true},
              {'text': 'TITLE / ROLE', 'stretch': true},
              {'text': 'CONTRACT START DATE', 'stretch': true},
              {'text': 'CONTRACT END DATE', 'stretch': true},
              {'text': '', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            272, 184, 96, 144, 120, 160, 160, 100
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            272, 184, 96, 144, 120, 160, 160, 100
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'left': createTableLeftItems(),
    'middle': createTableMiddleItems(),
    'right': createTableRightItems()
  };

  clientListItems.forEach(item => {
    tableItems['left']['body']['items'].push(
      createShowUserRowLeft(item)
    );
    tableItems['right']['body']['items'].push(
      createShowUserRowRight(item)
    );
    tableItems['middle']['body']['items'].push(
      createShowUserRowMiddle(item)
    );
  });

  return tableItems;
}


/* Account Manager - Client Setups Table */
export const generateAccountManagerClientSetupsTableItems = (clientSetupItems) => {
  const createShowUserRowLeft = (item) => {
    const clientSetupId = item['id'];
    const clientName = item['general_information']['data']['name'];
    const rowId = `client-setup-${clientSetupId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#client-name`,
          'type': 'link-button',
          'text': clientName,
          'href': `/client-setups/${clientSetupId}`,
          'stretch': false
        }
      ]
    };
  }

  const createShowUserRowRight = (item) => {
    const clientSetupId = item['id'];
    const rowId = `client-setup-${clientSetupId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#continue`,
          'type': 'button',
          'stretch': false,
          'onClick': () => window.location.href = `../client-setups/${item['id']}`
        }
      ]
    };
  }
  
  const createShowUserRowMiddle = (item) => {
    const clientSetupId = item['id'];
    const region = item['general_information']['data']['region'];
    const country = item['general_information']['data']['country'];
    const progress = item['progress'];
    const rowId = `client-setup-${clientSetupId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#filler-left`,
          'type': 'empty-cell',
          'stretch': false
        },
        {
          'id': `${rowId}#region`,
          'type': 'default',
          'text': region,
          'stretch': true
        },
        {
          'id': `${rowId}#country`,
          'type': 'default',
          'text': country,
          'stretch': true
        },
        {
          'id': `${rowId}#progress`,
          'type': 'progress',
          'total': 5,
          'completed': progress,
          'stretch': true
        },
        {
          'id': `${rowId}#filler-right`,
          'type': 'empty-cell',
          'stretch': false
        },
      ]
    };
  }

  const createTableLeftItems = () => {
    const leftItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-setups-header-left',
            'columns': [
              {'text': 'CLIENT NAME', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [272],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [272],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return leftItems;
  }

  const createTableRightItems = () => {
    const rightItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-setups-header-right',
            'columns': [
              {'type': 'empty-cell', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [102],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [102],
          'columnPaddings': [
            {'top': 11, 'right': 24, 'bottom': 11, 'left': 24}
          ]
        }
      }
    };
    return rightItems;
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': '', 'stretch': false},
              {'text': 'REGION', 'stretch': true},
              {'text': 'COUNTRY', 'stretch': true},
              {'text': 'PROGRESS', 'stretch': true},
              {'text': '', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            272, 128, 128, 128, 102
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            272, 128, 128, 128, 102
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'left': createTableLeftItems(),
    'middle': createTableMiddleItems(),
    'right': createTableRightItems()
  };

  clientSetupItems.forEach(item => {
    tableItems['left']['body']['items'].push(
      createShowUserRowLeft(item)
    );
    tableItems['right']['body']['items'].push(
      createShowUserRowRight(item)
    );
    tableItems['middle']['body']['items'].push(
      createShowUserRowMiddle(item)
    );
  });

  return tableItems;
}