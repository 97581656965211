import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { getValueFromVariable } from '../../../../../utils/helpers';

import Button from '../../../../../components/UI/Button/Button';
import SectionInfoBox from '../SectionInfoBox/SectionInfoBox';

import summaryPageStyles from '../ProxyAdvisorSummary.module.css';

const ProxyAdvisorSummaryPastGeneralMeetingRecommendationsSection = (props) => {

  const [pastGeneralMeetingRecommendationsData, setPastGeneralMeetingRecommendationsData] = useState(props.pastGeneralMeetingRecommendationsData);
  const [isEditMode, setIsEditMode] = useState(false);

  const [lastGeneralMeetingRecommendations, setLastGeneralMeetingRecommendations] = useState(getValueFromVariable(pastGeneralMeetingRecommendationsData['last_general_meeting_recommendations']));
  const [previousGeneralMeetingRecommendations, setPreviousGeneralMeetingRecommendations] = useState(getValueFromVariable(pastGeneralMeetingRecommendationsData['previous_general_meeting_recommendations']));

  const cancelEditHandler = () => {
    setIsEditMode(false);
    setLastGeneralMeetingRecommendations(getValueFromVariable(pastGeneralMeetingRecommendationsData['last_general_meeting_recommendations']));
    setPreviousGeneralMeetingRecommendations(getValueFromVariable(pastGeneralMeetingRecommendationsData['previous_general_meeting_recommendations']));
  }

  const updatePastGeneralMeetingRecommendationsHandler = () => {
    const updatedPastGeneralMeetingRecommendationsData = {
      'last_general_meeting_recommendations': lastGeneralMeetingRecommendations,
      'previous_general_meeting_recommendations': previousGeneralMeetingRecommendations
    };
    setPastGeneralMeetingRecommendationsData(updatedPastGeneralMeetingRecommendationsData);
    setIsEditMode(false);
    props.onSaveClick(updatedPastGeneralMeetingRecommendationsData);
  }

  return (
    <div className={summaryPageStyles['section']}>
      <div className={summaryPageStyles['section-title']}>
        <h3>Past General Meeting Recommendations</h3>
        <div className={summaryPageStyles['tooltip-icon']} data-tooltip-id='past-general-meeting-recommendations-title-tooltip'>
          <Tooltip
            id='past-general-meeting-recommendations-title-tooltip'
            place='bottom'
            content='This section allows the user to put notes on how this proxy advisor recommended at general meetings. Please note, depending on your subscription, AQTION may pre-populate these sections.'
            className='aqtion-tooltip'
          />
        </div>
      </div>
      { !isEditMode && 
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='secondary' leftIcon='edit' onClick={() => setIsEditMode(true)}>Edit</Button>
        </div>
      }
      { isEditMode && 
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='cancel' onClick={cancelEditHandler}>Cancel</Button>
          <Button size='xsmall' type='primary' onClick={updatePastGeneralMeetingRecommendationsHandler}>Save</Button>
        </div>
      }

      <div className={`${summaryPageStyles['content']} ${isEditMode && summaryPageStyles['edit-mode']}`}>
        <SectionInfoBox
          id='proxy-advisor#last-general-meeting-recommendations'
          size='double-col'
          type={!isEditMode ? 'text-block' : 'text-block-input'}
          height='184px'
          label='Last General Meeting Recommendations' 
          value={lastGeneralMeetingRecommendations}
          onInputChange={setLastGeneralMeetingRecommendations}
        />
        <SectionInfoBox
          id='proxy-advisor#previous-general-meeting-recommendations'
          size='double-col'
          type={!isEditMode ? 'text-block' : 'text-block-input'}
          height='184px'
          label='Previous General Meeting Recommendations' 
          value={previousGeneralMeetingRecommendations}
          onInputChange={setPreviousGeneralMeetingRecommendations}
        />
      </div>
      <div className={summaryPageStyles['separator']}></div>
    </div>
  );
}

export default ProxyAdvisorSummaryPastGeneralMeetingRecommendationsSection;
