import { useState } from 'react';
import { investorActivistDropdownItems, investorReceptivityToActivistsDropdownItems } from '../../../../../utils/constants';
import { getValueFromVariable } from '../../../../../utils/helpers';

import Button from '../../../../../components/UI/Button/Button';
import SectionInfoBox from '../SectionInfoBox/SectionInfoBox';

import summaryPageStyles from '../InvestorSummary.module.css';

const InvestorSummaryActivismSection = (props) => {

  const isCustomInvestor = props.isCustomInvestor;
  const [activismData, setActivismData] = useState(props.activismData);
  const [isEditMode, setIsEditMode] = useState(false);

  const [activist, setActivist] = useState(getValueFromVariable(activismData['activist']));
  const [receptivityToActivists, setReceptivityToActivists] = useState(getValueFromVariable(activismData['receptivity_to_activists']));

  let indicatorLevel = '';
  let indicatorStyle = 'active';
  if (receptivityToActivists === 'High') { 
    indicatorLevel = 'third-level';
  }
  else if (receptivityToActivists === 'Medium') { 
    indicatorLevel = 'second-level';
  }
  else if (receptivityToActivists === 'Low') { 
    indicatorLevel = 'first-level';
  }
  else { 
    indicatorStyle = 'passive';
  }

  const cancelEditHandler = () => {
    setIsEditMode(false);
    setActivist(getValueFromVariable(activismData['activist']));
    setReceptivityToActivists(getValueFromVariable(activismData['receptivity_to_activists']));
  }

  const updateActivismHandler = () => {
    const updatedActivismData = {
      'activist': activist,
      'receptivity_to_activists': receptivityToActivists
    };
    setActivismData(updatedActivismData);
    setIsEditMode(false);
    props.onSaveClick(updatedActivismData);
  }

  return (
    <div className={summaryPageStyles['section']}>
      <div className={summaryPageStyles['section-title']}>
        <h3>Activism</h3>
      </div>
      { isCustomInvestor && !isEditMode && 
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='secondary' leftIcon='edit' onClick={() => setIsEditMode(true)}>Edit</Button>
        </div>
      }
      { isCustomInvestor && isEditMode && 
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='cancel' onClick={cancelEditHandler}>Cancel</Button>
          <Button size='xsmall' type='primary' onClick={updateActivismHandler}>Save</Button>
        </div>
      }

      <div className={`${summaryPageStyles['content']} ${isEditMode && summaryPageStyles['edit-mode']}`}>
        <SectionInfoBox
          id='investor#activist'
          size='single-col'
          type={!isEditMode ? 'text' : 'dropdown'}
          label='Activist'
          dropdownItems={investorActivistDropdownItems}
          value={activist}
          onInputChange={setActivist}
          hasTooltip={true}
          tooltipContent='This field indicates whether this investor is considered as an “Activist” according to AQTION. Further information can be found in the glossary.'
        />
        <SectionInfoBox
          id='investor#receptivity-to-activists'
          size='single-col'
          type={!isEditMode ? 'indicator' : 'dropdown'}
          label='Receptivity to Activists'
          value={receptivityToActivists}
          indicatorLevel={indicatorLevel}
          indicatorStyle={indicatorStyle}
          dropdownItems={investorReceptivityToActivistsDropdownItems}
          onInputChange={setReceptivityToActivists}
          hasTooltip={true}
          tooltipContent='This field shows how receptive/supportive this investor has been to previous activist campaigns as determined by AQTION. Further information can be found in the glossary.'
        />
      </div>
      <div className={summaryPageStyles['separator']}></div>
    </div>
  );
}

export default InvestorSummaryActivismSection;
