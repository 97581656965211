/* Client - Show Proxy Advisor List Table */
export const generateClientProxyAdvisorListTableItems = (clientType, proxyAdvisorListItems) => {
  const createProxyAdvisorRowMiddle = (item) => {
    const proxyAdvisorName = item['name'];
    const lastAgmRecommendations = item['last_general_meeting_recommendations'];

    const proxyAdvisorId = item['id'];
    const rowId = `client-proxy-advisor-${proxyAdvisorId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#proxy-advisor-name`,
          'type': 'link-button',
          'text': proxyAdvisorName,
          'href': `/proxy-advisors/${proxyAdvisorId}`,
          'stretch': false
        }].concat((clientType === 'Corporate') ? [
          {
            'id': `${rowId}#last-agm-recommendations`,
            'type': 'default',
            'text': lastAgmRecommendations,
            'stretch': true
          }
        ] : [])
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'proxy-advisor-list-header',
            'columns': [
              {'text': `PROXY ADVISOR NAME`, 'stretch': false}
            ].concat((clientType === 'Corporate') ? [
              {'text': 'LAST GENERAL MEETING RECOMMENDATIONS', 'stretch': true}
            ] : [])
          }
        ],
        'config': {
          'columnWidths': [
            360
          ].concat((clientType === 'Corporate') ? [
            344
          ] : []),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : [])
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            360
          ].concat((clientType === 'Corporate') ? [
            344
          ] : []),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []),
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  proxyAdvisorListItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createProxyAdvisorRowMiddle(item)
    );
  });

  return tableItems;
}










/* Client - Show Proxy Advisor Contact List Table */
export const generateClientProxyAdvisorContactListTableItems = (proxyAdvisorContactListItems, sortConfig, pinClickHandler, contactClickHandler, sortFunction) => {
  const createProxyAdvisorContactRowLeft = (item) => {
    const proxyAdvisorContactName = item['fullname'];
    const proxyAdvisorContactProfilePictureUrl = item['profile_picture_url'];
    const isStarred = item['is_starred'];
    const isCustom = item['is_custom'];
    const rowId = `client-proxy-advisor-contact-${item['id']}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#proxy-advisor-contact-name`,
          'type': 'link-button',
          'text': proxyAdvisorContactName,
          'hasPin': true,
          'pinIcon': 'star',
          'isPinSelected': isStarred,
          'onPinClick': pinClickHandler,
          'hasPhoto': true,
          'photoUrl': proxyAdvisorContactProfilePictureUrl,
          'has_custom_chip': isCustom,
          'stretch': false,
          'onClick': contactClickHandler
        }
      ]
    };
  }

  const createProxyAdvisorContactRowMiddle = (item) => {
    const relationshipStatusColors = {
      'Strong': 'success',
      'Standard': 'warning',
      'Weak': 'danger',
      'Unknown': 'unknown'
    };

    const role = item['role'];
    const department = item['department'];
    const relationshipStatus = item['relationship_status'];
    const city = item['city'];
    const email = item['email'];
    const phoneNumber = item['phone_number'];
    const address = item['address'];
    const notes = item['notes'];
    const rowId = `client-proxy-advisor-contact-${item['id']}`;;

    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#filler-left`,
          'type': 'empty-cell',
          'stretch': false
        },
        {
          'id': `${rowId}#role`,
          'type': 'default',
          'text': role,
          'stretch': true
        },
        {
          'id': `${rowId}#department`,
          'type': 'default',
          'text': department,
          'stretch': true
        },
        {
          'id': `${rowId}#relationship-status`,
          'type': 'traffic-light',
          'color_code': relationshipStatusColors[relationshipStatus],
          'text': relationshipStatus,
          'stretch': true
        },
        {
          'id': `${rowId}#city`,
          'type': 'default',
          'text': city,
          'stretch': true
        },
        {
          'id': `${rowId}#email`,
          'type': 'default',
          'text': email,
          'stretch': true
        },
        {
          'id': `${rowId}#phone-number`,
          'type': 'default',
          'text': phoneNumber,
          'stretch': true
        },
        {
          'id': `${rowId}#address`,
          'type': 'default',
          'text': address,
          'stretch': true
        },
        {
          'id': `${rowId}#notes`,
          'type': 'default',
          'text': notes,
          'stretch': true
        }
      ]
    };
  }

  const createTableLeftItems = () => {
    const leftItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'proxy-advisor-contact-list-header-left',
            'columns': [
              {
                'text': 'CONTACT NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('fullname'),
                'orderBy': sortConfig['by'].includes('fullname') ? sortConfig['order'][sortConfig['by'].indexOf('fullname')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [280],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [280],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32}
          ]
        }
      }
    };
    return leftItems;
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'proxy-advisor-contact-list-header-middle',
            'columns': [
              {'text': '', 'stretch': false},
              {
                'text': 'ROLE',
                'isSortable': true,
                'onSortClick': () => sortFunction('role'),
                'orderBy': sortConfig['by'].includes('role') ? sortConfig['order'][sortConfig['by'].indexOf('role')] : 'asc',
                'stretch': true
              },
              {
                'text': 'DEPARTMENT',
                'isSortable': true,
                'onSortClick': () => sortFunction('department'),
                'orderBy': sortConfig['by'].includes('department') ? sortConfig['order'][sortConfig['by'].indexOf('department')] : 'asc',
                'stretch': true
              },
              {'text': 'RELATIONSHIP STATUS', 'stretch': true},
              {'text': 'CITY', 'stretch': true},
              {'text': 'EMAIL', 'stretch': true},
              {'text': 'PHONE NUMBER', 'stretch': true},
              {'text': 'ADDRESS', 'stretch': true},
              {'text': 'NOTES', 'stretch': true}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            280, 168, 192, 240, 168, 240, 240, 240, 240
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            280, 168, 192, 240, 168, 240, 240, 240, 240
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'left': createTableLeftItems(),
    'middle': createTableMiddleItems()
  };

  proxyAdvisorContactListItems.forEach(item => {
    tableItems['left']['body']['items'].push(
      createProxyAdvisorContactRowLeft(item)
    );
    tableItems['middle']['body']['items'].push(
      createProxyAdvisorContactRowMiddle(item)
    );
  });

  return tableItems;
}






























/* Client - Show Proxy Advisor Engagements - Other Notes List Table */
export const generateClientProxyAdvisorEngagementsOtherNotesTableItems = (proxyAdvisorEngagementsOtherNotesItems, otherNoteClickHandler, sortConfig, sortFunction) => {
  const createProxyAdvisorEngagementOtherNoteRow = (item) => {
    const otherNoteId = item['id'];
    const otherNoteHeader = item['note_header'];
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];

    const rowId = `client-proxy-advisor-engagements-other-note-${otherNoteId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#note-header`,
          'type': 'link-button',
          'text': otherNoteHeader,
          'stretch': true,
          'onClick': otherNoteClickHandler
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-proxy-advisor-engagements-other-notes-list-header-middle',
            'columns': [
              {
                'text': 'NOTE HEADER',
                'isSortable': true,
                'onSortClick': () => sortFunction('note_header'),
                'orderBy': sortConfig['by'].includes('note_header') ? sortConfig['order'][sortConfig['by'].indexOf('note_header')] : 'asc',
                'stretch': true
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            600, 240, 192
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            600, 240, 192
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  proxyAdvisorEngagementsOtherNotesItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createProxyAdvisorEngagementOtherNoteRow(item)
    );
  });

  return tableItems;
}


/* Client - Show Proxy Advisor Engagements - Meeting Notes List Table */
export const generateClientProxyAdvisorEngagementsMeetingNotesTableItems = (proxyAdvisorEngagementsMeetingNotesItems, meetingNoteClickHandler, sortConfig, sortFunction) => {
  const createProxyAdvisorEngagementMeetingNoteRow = (item) => {
    const meetingNoteId = item['id'];
    const meetingNoteHeader = item['note_header'];
    const meetingDate = new Date(item['meeting_date']).toLocaleDateString('en-GB');
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];

    const rowId = `client-proxy-advisor-engagements-meeting-note-${meetingNoteId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#note-header`,
          'type': 'link-button',
          'text': meetingNoteHeader,
          'stretch': true,
          'onClick': meetingNoteClickHandler
        },
        {
          'id': `${rowId}#meeting-date`,
          'type': 'default',
          'text': meetingDate,
          'stretch': false
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-proxy-advisor-engagements-meeting-notes-list-header-middle',
            'columns': [
              {
                'text': 'NOTE HEADER',
                'isSortable': true,
                'onSortClick': () => sortFunction('note_header'),
                'orderBy': sortConfig['by'].includes('note_header') ? sortConfig['order'][sortConfig['by'].indexOf('note_header')] : 'asc',
                'stretch': true
              },
              {
                'text': 'MEETING DATE',
                'isSortable': true,
                'onSortClick': () => sortFunction('meeting_date'),
                'orderBy': sortConfig['by'].includes('meeting_date') ? sortConfig['order'][sortConfig['by'].indexOf('meeting_date')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            400, 192, 240, 192
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            400, 192, 240, 192
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  proxyAdvisorEngagementsMeetingNotesItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createProxyAdvisorEngagementMeetingNoteRow(item)
    );
  });

  return tableItems;
}


/* Client - Show Proxy Advisor Engagements - Meeting Requests List Table */
export const generateClientProxyAdvisorEngagementsMeetingRequestsTableItems = (proxyAdvisorEngagementsMeetingRequestItems, sortConfig, sortFunction) => {
  const createProxyAdvisorEngagementMeetingRequestRow = (item) => {
    const emailStatusColors = {
      'Sent': 'success',
      'Not Sent': 'unknown'
    };

    const meetingStatusColors = {
      'Pending': 'unknown',
      'In Progress': 'info',
      'Confirmed': 'success',
      'Declined': 'danger',
      'Cancelled': 'warning'
    };
    
    const meetingRequestId = item['id'];
    const engagementName = item['engagement_name'];
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');
    
    let meetingStatus = '';
    if(emailStatus === 'Not Sent') {
      meetingStatus = '-';
    }
    else {
      meetingStatus = item['meeting_status'] === null ? 'Pending' : item['meeting_status'];
    }

    const rowId = `client-proxy-advisor-engagements-meeting-request-${meetingRequestId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#engagement-name`,
          'type': 'link-button',
          'text': engagementName,
          'stretch': true,
          'onClick': () => window.location = `../engagements?meeting_request_id=${meetingRequestId}`
        },
        {
          'id': `${rowId}#email-status`,
          'type': 'traffic-light',
          'color_code': emailStatusColors[emailStatus],
          'text': emailStatus,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': false
        },
        {
          'id': `${rowId}#meeting-status`,
          'type': 'traffic-light',
          'color_code': meetingStatusColors[meetingStatus],
          'text': meetingStatus,
          'stretch': false
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-proxy-advisor-engagements-meeting-requests-list-header-middle',
            'columns': [
              {
                'text': 'ENGAGEMENT NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('engagement_name'),
                'orderBy': sortConfig['by'].includes('engagement_name') ? sortConfig['order'][sortConfig['by'].indexOf('engagement_name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'EMAIL STATUS',
                'isSortable': false,
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'isSortable': false,
                'stretch': false
              },
              {
                'text': 'MEETING STATUS',
                'isSortable': false,
                'stretch': false
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            200, 144, 96, 144, 176, 96
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            200, 144, 96, 144, 176, 96
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  proxyAdvisorEngagementsMeetingRequestItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createProxyAdvisorEngagementMeetingRequestRow(item)
    );
  });

  return tableItems;
}


/* Client - Show Proxy Advisor Engagements - Shareholder Communications List Table */
export const generateClientProxyAdvisorEngagementsShareholderCommunicationsTableItems = (proxyAdvisorEngagementsShareholderCommunicationItems, sortConfig, sortFunction) => {
  const createProxyAdvisorEngagementShareholderCommunicationRow = (item) => {
    const emailStatusColors = {
      'Sent': 'success',
      'Not Sent': 'unknown'
    };
    
    const shareholderCommunicationId = item['id'];
    const engagementName = item['engagement_name'];
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');

    const rowId = `client-proxy-advisor-engagements-shareholder-communication-${shareholderCommunicationId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#engagement-name`,
          'type': 'link-button',
          'text': engagementName,
          'stretch': true,
          'onClick': () => window.location = `../engagements?shareholder_communication_id=${shareholderCommunicationId}`
        },
        {
          'id': `${rowId}#email-status`,
          'type': 'traffic-light',
          'color_code': emailStatusColors[emailStatus],
          'text': emailStatus,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': false
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-proxy-advisor-engagements-shareholder-communications-list-header-middle',
            'columns': [
              {
                'text': 'ENGAGEMENT NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('engagement_name'),
                'orderBy': sortConfig['by'].includes('engagement_name') ? sortConfig['order'][sortConfig['by'].indexOf('engagement_name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'EMAIL STATUS',
                'isSortable': false,
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'isSortable': false,
                'stretch': false
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            200, 144, 96, 176, 96
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            200, 144, 96, 176, 96
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  proxyAdvisorEngagementsShareholderCommunicationItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createProxyAdvisorEngagementShareholderCommunicationRow(item)
    );
  });

  return tableItems;
}