import { Fragment, useState } from 'react';
import styles from './Tab.module.css';

const Tab = (props) => {
  const [selectedTab, setSelectedTab] = useState(props.selectedTab);

  const tabStyle = props.type ? styles[props.type] : styles['first-level'];

  const changeTabHandler = (tabId) => {
    setSelectedTab(tabId);
    props.onTabChange(tabId);
  }

  const tabItems = props.items.map(item => {
    const isSelected = selectedTab === item['id'];
    const itemStyle = isSelected ? styles['selected'] : '';

    return (
      <Fragment key={`tab-item-${item['id']}`}>
        <div className={`${styles['item']} ${itemStyle}`} onClick={ () => changeTabHandler(item['id']) }>
          <h4>{item['text']}</h4>
        </div>
      </Fragment>
    );
  });

  return (
    <div className={`${styles['tab-container']} ${tabStyle}`}>
      <div className={styles['tab-items']}>
        {tabItems}
      </div>
    </div>
  );
}

export default Tab;
