import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import parse from 'html-react-parser';

import styles from '../EsgTopicsContainer.module.css';

const EsgTopicQuestionItem = (props)  => {
  const [isOpened, setIsOpened] = useState(false);
  const [comment, setComment] = useState('');

  const showQuestionCommentHandler = () => {
    if (!isOpened) {
      props.onShowQuestionCommenClick(props.id, setComment, setIsOpened);
    }
    else {
      setIsOpened(false);
    }
  }

  return (
    <div className={styles['question-item']} onClick={showQuestionCommentHandler}>
      <div className={styles['header']} id={`question-item-header-${props.id}`}>
        <div className={styles['title']}>
          <h4>{props.text}</h4>
        </div>
        <div className={styles['icon-container']}>
          <div className={styles['drawer-icon']}>
            <div className={styles['caret']}></div>
          </div>
          <div className={styles['information-icon']}>
            <div className={styles['information']} data-tooltip-id={`question-description-tooltip-${props.id}`}>
              <Tooltip
                id={`question-description-tooltip-${props.id}`}
                place='bottom'
                content={props.description}
                className='aqtion-tooltip'
              />
            </div>
          </div>
          {props.isPriority && 
            <div className={styles['priority-icon']}>
              <div className={styles['flag']}></div>
            </div>
          }
        </div>
      </div>
      {isOpened &&
        <div className={styles['body']}>
          {parse(comment)}
        </div>
      }
    </div>
  );
}

export default EsgTopicQuestionItem;
