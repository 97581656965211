import { Fragment, useCallback, useEffect, useState } from "react";
import { generateClientMeetingRequestsTableItems } from "../../../../utils/client-engagement-helpers";
import { createClientEngagementsMeetingRequestRequest, listClientEngagementsMeetingRequestsRequest, updateClientEngagementsMeetingRequestRequest } from "../../../../requests/client-engagement-requests";
import { getAuthClientId } from "../../../../utils/auth";
import { useSearchParams } from "react-router-dom";
import { handleRequestError } from "../../../../utils/request-error-helpers";
import useHttp from "../../../../hooks/use-http";
import sortArray from 'sort-array';

import Table from "../../../../components/UI/Table/Table";

import Button from "../../../../components/UI/Button/Button";
import EngagementMeetingRequestCreateForm from "../../../../components/EngagementForm/EngagementMeetingRequestForm/EngagementMeetingRequestCreateForm";
import EngagementMeetingRequestDetailsForm from "../../../../components/EngagementForm/EngagementMeetingRequestForm/EngagementMeetingRequestDetailsForm";
import EngagementMeetingRequestUpdateForm from "../../../../components/EngagementForm/EngagementMeetingRequestForm/EngagementMeetingRequestUpdateForm";

import styles from '../EngagementListPage.module.css';

const EngagementsMeetingRequests = (props) => {

  const clientId = getAuthClientId();
  const pageType = props.pageType;
  const setPageType = props.setPageType;
  
  const [isLoading, error, sendRequest] = useHttp();
  const [searchParams, setSearchParams] = useSearchParams();

  const [meetingRequestsData, setMeetingRequestsData] = useState([]);
  const [meetingRequestsTableItems, setMeetingRequestsTableItems] = useState(null);

  const [selectedMeetingRequestId, setSelectedMeetingRequestId] = useState(searchParams.get('meeting_request_id'));

  const [meetingRequestsTableSortConfig, setMeetingRequestsTableSortConfig] = useState({
    'by': ['engagement_name'],
    'order': ['asc']
  });

  const meetingRequestsSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setMeetingRequestsTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setMeetingRequestsData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, meetingRequestsTableSortConfig);
    });
  }, [meetingRequestsTableSortConfig]);


  /* Show meeting request details. */
  const meetingRequestClickHandler = useCallback((meetingRequestId) => {
    setSearchParams({'meeting_request_id' : meetingRequestId});
    setPageType('details');
    setSelectedMeetingRequestId(meetingRequestId);
  }, [setPageType, setSearchParams]);

  
  /* Create new meeting request. */
  const clientMeetingRequestCreateHandler = (meetingRequestData) => {
    const createClientEngagementsMeetingRequestResponse = (data) => {
      setMeetingRequestsData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        items.push(data)
        return items;
      });
      setPageType('list');
    }
    sendRequest(
      createClientEngagementsMeetingRequestRequest(clientId, meetingRequestData),
      createClientEngagementsMeetingRequestResponse
    );
  }

  /* Update selected meeting request. */
  const clientMeetingRequestEditHandler = (meetingRequestData) => {
    const updateClientEngagementsMeetingRequestResponse = (data) => {
      setMeetingRequestsData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        const updatedItemIndex = items.map(item => item['id']).indexOf(selectedMeetingRequestId);
        items[updatedItemIndex] = data;
        return items;
      });
      setPageType('details');
    }
    sendRequest(
      updateClientEngagementsMeetingRequestRequest(clientId, selectedMeetingRequestId, meetingRequestData),
      updateClientEngagementsMeetingRequestResponse
    );
  }

  /* Return back to meeting requests table. */
  const returnToMeetingRequestsClickHandler = () => {
    searchParams.delete('form_type');
    setSearchParams(searchParams);

    setSelectedMeetingRequestId(null);
    setPageType('list');
  }

  
  /* Send a request to fetch meeting request items and set the list with the response. */
  useEffect(() => {
    sendRequest(
      listClientEngagementsMeetingRequestsRequest(clientId),
      (data) => { 
        setMeetingRequestsData(data['items']);
      }
    );
  }, [sendRequest, clientId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  /* Generate meeting requests table. */
  useEffect(() => {
    setMeetingRequestsTableItems(
      generateClientMeetingRequestsTableItems(
        meetingRequestsData, meetingRequestClickHandler, meetingRequestsTableSortConfig, meetingRequestsSortHandler
      )
    );
  }, [meetingRequestsData, meetingRequestsTableSortConfig, meetingRequestClickHandler]);


  return (
    <Fragment>

      { pageType === 'list' && meetingRequestsTableItems && meetingRequestsTableItems['middle']['body']['items'].length > 0 &&
        <div className={styles['engagement-meeting-request-list-container']}>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={meetingRequestsTableItems} isScrollable={false} />
        </div>
      }

      { pageType === 'list' && meetingRequestsTableItems && meetingRequestsTableItems['middle']['body']['items'].length === 0 &&
        <div className={styles['no-enagements-div']}>
          <div className={styles['icon-container']}>
            <div className={styles['icon']}></div>
          </div>
          <div className={styles['text-container']}>
            <h4>You Have No Meeting Requests</h4>
            <p>Use below button to Create a Meeting Request</p>
          </div>
          <div className={styles['button-container']}>
            <Button
              type={"primary"}
              leftIcon={"plus"}
              size={"xsmall"}
              onClick={props.onCreateEngagementClick}>
                Create Meeting Request
              </Button>
          </div>
        </div>
      }

      { pageType === 'new' &&
        <EngagementMeetingRequestCreateForm
          hasFormTitle={true}
          onSaveClick={clientMeetingRequestCreateHandler}
          onCancel={returnToMeetingRequestsClickHandler}
        />
      }

      { pageType === 'edit' && selectedMeetingRequestId &&
        <EngagementMeetingRequestUpdateForm
          width={props.width}
          meetingRequestId={selectedMeetingRequestId}
          onSaveClick={clientMeetingRequestEditHandler}
          onCancel={() => setPageType('details')}
        />
      }

      { pageType === 'details' && selectedMeetingRequestId &&
        <EngagementMeetingRequestDetailsForm
          width={props.width}
          meetingRequestId={selectedMeetingRequestId}
          onEditEngagement={() => setPageType('edit')}
          setBreadcrumbItems={props.setBreadcrumbItems}
        />
      }
    </Fragment>
  );
}

export default EngagementsMeetingRequests;
