import parse from 'html-react-parser';
import { gloassaryAlphabet, glossaryDictionary } from '../../../../utils/glossary-helpers';

import styles from './Glossary.module.css';

const Glossary = () => {
  const generateLetterItems = () => {
    return gloassaryAlphabet.map(letter => {
      const letterStyle = glossaryDictionary[letter] !== undefined ? 'active' : 'passive';
      return (
        <div className={`${styles['letter-item']} ${styles[letterStyle]}`} onClick={()=>{window.location=`#letter${letter}`}}>
          <h3>{letter}</h3>
        </div>
      );
    })
  }

  const generateDictionaryItems = () => {
    const dictionaryItems = [];
    for(const letter in glossaryDictionary) {
      const wordItems = glossaryDictionary[letter].map(item => {
        return (
          <div className={styles['word-item']}>
            <div className={styles['word']}>
              <h4>{item['word']}</h4>
            </div>
            <div className={styles['definition']}>
              {parse(item['definition'])}
            </div>
          </div>
        )
      });
      dictionaryItems.push(
        <div className={styles['letter-item']} id={`letter${letter}`}>
          <h2>{letter}</h2>
          <div className={styles['word-list']}>
            {wordItems}
          </div>
        </div>
      )
    }
    return dictionaryItems;
  }

  return (
    <div className={styles['glossary-section']}>
      <div className={styles['glossary-navigation']}>
        <div className={styles['letter-picker']}>
          {generateLetterItems()}
        </div>
        <div className={styles['glossary-introduction']}>
          <p>The investment sector can sometimes use jargon. We have developed this glossary to explain these terms in more detail. You'll also find the glossary items throughout the platform where they have been used. The explanation will appear by hovering on the icon.</p>
        </div>
      </div>
      <div className={styles['dictionary']}>
        {generateDictionaryItems()}
      </div>
    </div>
  );
}

export default Glossary;