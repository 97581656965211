import { Fragment, useState } from 'react';
import parse from 'html-react-parser';

import styles from './DocuSearchPage.module.css';

const DocuSearchResultItem = (props) => {
  const report = props.report;
  const searchKeyword = props.searchKeyword;
  const pageViewMode = props.pageViewMode;

  const [isOpened, setIsOpened] = useState(false);

  const showReportOccurencesHandler = () => {
    setIsOpened(prevState => {
      return !prevState;
    })
  }

  const downloadReportHandler = (event, pageNumber) => {
    event.stopPropagation();
    props.onClickDownloadFile(report['fileIdentifier'], pageNumber);
  }

  return (
    <div className={styles['report-item']} onClick={showReportOccurencesHandler}>
      <div className={styles['header']}>
        <div className={styles['title']}>
          <div className={styles['report-title']}>
            { pageViewMode === 'normal' &&
              <Fragment>
                <div className={styles['investor-name']}>
                  <a href={report['investorUrl']} target='_blank' rel='noreferrer' onClick={(event) => { event.stopPropagation(); }}>{report['investorName']}</a>
                </div>
                <div className={styles['separator']}>
                  <h4 className={styles['dark']}>|</h4>
                </div>
                <div className={styles['source-type']}>
                  <h4 className={styles['dark']}>{report['sourceTypeName']}</h4>
                </div>
                <div className={styles['separator']}>
                  <h4 className={styles['dark']}>|</h4>
                </div>
                <div className={styles['published-date']}>
                  <h4 className={styles['dark']}>{report['publicationDate']}</h4>
                </div>
              </Fragment>
            }
            { pageViewMode === 'skeleton' &&
              <div className={styles['skeleton-placeholder']} style={{width: '50%'}}></div>
            }
          </div>
          <div className={styles['report-details']}>
            { pageViewMode === 'normal' &&
              <Fragment>
                <div className={styles['document-title']}>
                  <h5 onClick={(event) => { event.stopPropagation(); window.open(report['sourceUrl'], '_blank'); }}>{report['documentTitle']}</h5>
                </div>
              </Fragment>
            }
            { pageViewMode === 'skeleton' &&
              <div className={styles['investor-name']} style={{width: '75%'}}>
                <div className={styles['skeleton-placeholder']} style={{width: '100%'}}></div>
              </div>
            }
          </div>
        </div>
        <div className={styles['icon-container']}>
          { pageViewMode === 'normal' &&
            <Fragment>
              <div className={styles['drawer-icon']}>
                <div className={styles['caret']}></div>
              </div>
              <div className={styles['num-of-hits-icon']}>
                <div className={styles['num-of-hits']}></div>
                <p>{report['occurences'].length}</p>
              </div>
            </Fragment>
          }
        </div>
      </div>
      { isOpened &&
        report['occurences'].map((citation, citationIndex) => {
          const keywords = searchKeyword.toLocaleLowerCase().split(' ');
          const indices = [];
          for(const keywordIndex in keywords) {
            const keyword = keywords[keywordIndex];
            const beginning = citation['Text'].toLowerCase().indexOf(keyword);
            const end = beginning + keyword.length;
            indices.push(beginning);
            indices.push(end);
          }
          indices.sort(function(a, b){return a-b});

          let citationText = '<p>';
          let currentIndex = 0;
          for(let i=0; i<indices.length; i=i+2){
            if((currentIndex + 1) === indices[i]) {
              indices[i] = indices[i] - 1;
            }
            citationText += citation['Text'].substring(currentIndex, indices[i]);
            citationText += `<span className=${styles['highlighted']}>${citation['Text'].substring(indices[i], indices[i+1])}</span>`;
            currentIndex = indices[i+1];
          }
          citationText += citation['Text'].substring(indices[indices.length-1]);
          citationText += '</p>';

          return(
            <div key={`report-${report['fileIdentifier']}-citation-${citation['_id']}-${citationIndex}`} className={styles['body']}>
              <div className={styles['citation']}>
                {parse(citationText)}
              </div>
              <div className={styles['page-reference']} onClick={(event) => downloadReportHandler(event, citation['Page'])}>
                <p>Page {citation['Page']}</p>
              </div>
            </div>
          )
        })
      }
    </div>
  );
}

export default DocuSearchResultItem;