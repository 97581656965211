import { Fragment, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.css';

const RichTextEditor = (props) => {
  const modules = {
    toolbar: [
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      { 'list': 'ordered' },
      { 'list': 'bullet' },
      { 'indent': '-1' },
      { 'indent': '+1' },
      'link'
    ],
    clipboard: {
      matchVisual: false
    }
  }

  const ref = useRef();

  useEffect(() => {
    ref.current.getEditor().root.dataset.placeholder = props.placeholder || '';
    if(props.disabled !== undefined && props.disabled === true) {
      ref.current.getEditor().enable(false);
    }
  }, [ref, props.placeholder, props.disabled]);

  const errorStyle = (props.hasError !== undefined && props.hasError === true) ? 'error' : '';
  const disabledStyle = (props.disabled !== undefined && props.disabled === true) ? 'disabled' : '';

  const customStyle = {};
  if (props.width !== undefined) {
    customStyle['width'] = props.width;
  }

  return (
    <Fragment>
      { props.label && <label className='rich-text-editor-label'>{props.label}</label> }
      <div className={`${'rich-text-editor-container'} ${errorStyle} ${disabledStyle}`} style={customStyle}>
        <ReactQuill
          ref={ref}
          placeholder={props.placeholder}
          theme='snow'
          modules={modules}
          value={props.value}
          onChange={props.onChange} 
        />
      </div>
    </Fragment>
  )
}

export default RichTextEditor
