import { Tooltip } from 'react-tooltip';

import styles from '../EsgAllTopicsContainer.module.css';

const EsgAllTopicsQuestionItem = (props)  => {

  const showQuestionDetailsHandler = () => {
    props.onShowQuestionDetailsClick(props.id);
  }

  return (
    <div className={styles['question-item']} onClick={showQuestionDetailsHandler}>
      <div className={styles['header']} id={`question-item-header-${props.id}`}>
        <div className={styles['title']}>
          <h4>{props.text}</h4>
        </div>
        <div className={styles['icon-container']}>
          <div className={styles['drawer-icon']}>
            <div className={styles['caret']}></div>
          </div>
          <div className={styles['information-icon']}>
            <div className={styles['information']} data-tooltip-id={`question-description-tooltip-${props.id}`}>
              <Tooltip
                id={`question-description-tooltip-${props.id}`}
                place='bottom'
                content={props.description}
                className='aqtion-tooltip'
              />
            </div>
          </div>
          <div className={styles['num-of-comments-icon']}>
            <div className={styles['num-of-comments']}></div>
            <p>{props.numberOfComments}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EsgAllTopicsQuestionItem;
