import { Fragment, useEffect, useRef, useState } from 'react';
import TableRow from '../TableRow/TableRow';
import styles from './MiddleScrollableContent.module.css';
import './Scrollbar.css';

import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import Button from '../../Button/Button';

import parse from 'html-react-parser';


const MiddleScrollableContent = (props) => {
  const ref = useRef();

  const headerRowItems = props.items['header']['items'].map(item => {
    const config = Object.create(props.items['header']['config']);
    config['columnWidths'] = config['columnWidths'].map((width, index) => {
      if(item['columns'][index]['stretch'] === true) {
        item['columns'][index]['width'] = width + props.additionalColumnWidth;
        return width + props.additionalColumnWidth
      }
      else {
        item['columns'][index]['width'] = width;
        return width;
      }
    });
    return (
      <TableRow
        key={`${item['id']}-middle`}
        section='header'
        columns={item['columns']}
        config={config}
      />
    );
  })

  const bodyRowItems = props.items['body']['items'].map(item => {
    const config = Object.create(props.items['body']['config']);
    config['columnWidths'] = config['columnWidths'].map((width, index) => {
      if(item['columns'][index]['stretch'] === true) {
        item['columns'][index]['width'] = width + props.additionalColumnWidth;
        return width + props.additionalColumnWidth
      }
      else {
        item['columns'][index]['width'] = width;
        return width;
      }
    });
    return (
      <TableRow
        id={`${item['id']}#middle`}
        key={`${item['id']}-middle`}
        section='body'
        columns={item['columns']}
        config={config}
        viewMode={props.viewMode}
      />
    );
  })

  const generateHeaderButtons = () => {
    return props.items['header']['buttons'].map(item => {
      if (item['type'] === 'icon-button') {
        if (item['isVisible']) {
          return (
            <div key={`table-button-${item['id']}`} className={styles['icon-button']} onClick={item['onClick']}>
              <p>{item['text']}</p>
              <div className={`${styles['icon']} ${styles[item['icon']]}`}></div>
            </div>
          );
        }
        else {
          return null;
        }
      }
      else {
        return (
          <Button
            key={`table-button-${item['id']}`}
            id={item['id']}
            type='primary'
            size='xsmall'
            leftIcon={item['leftIcon']}
            onClick={item['onClick']}
            isDisabled={item['isDisabled']}
          >
            {item['text']}
          </Button>
        );
      }
    });
  }

  const [scrollPosition, setScrollPosition] = useState('absolute');
  const [scrollLeftCoordinate, setScrollLeftCoordinate] = useState('');
  useEffect(() => {
    if(props.hasDynamicScroll) {
      document.getElementById('page-right-container').addEventListener('scroll', () => {
        const windowHeight = window.innerHeight;
        const pageScrollTop = document.getElementById('page-right-container').scrollTop;

        const navBarWidth = document.getElementById('side-nav-bar-container').clientWidth;

        if (document.getElementById(props.tableContainerId) !== null && document.getElementById(props.tableContainerId) !== undefined){
          const tableHeight = document.getElementById(props.tableContainerId).scrollHeight;
          if (((tableHeight + 210) - (windowHeight + pageScrollTop) > 48) && (windowHeight - (tableHeight + 176)) < 0) {
            setScrollLeftCoordinate(navBarWidth + 34);
            setScrollPosition('fixed');
          }
          else {
            setScrollLeftCoordinate('');
            setScrollPosition('absolute');
          }
        }
      });
    }
  }, [props.tableContainerId, props.hasDynamicScroll]);

  const generateCustomizedClasses = () => {
    return `
      <style>
        .ps--active-x > .ps__rail-x {
          bottom: 0px !important;
          left: ${scrollLeftCoordinate}px !important;
          position: ${scrollPosition};
        }
      </style>
    `;
  }

  return (
    <div ref={ref} className={styles['middle-scrollable']} style={{ 'width': props.width }}>
      {props.isScrollable && 
      <Fragment>
        {props.hasDynamicScroll &&
          parse(generateCustomizedClasses())
        }
        <PerfectScrollbar style={{ 'width': (props.scrollableWidth + props.marginLeft + props.marginRight) }}>
          <div className={styles['scrollable-area']} style={{ 'width': props.width }}>
            <div className={styles['header']}>
              {headerRowItems}
            </div>
            <div className={styles['body']}>
              {bodyRowItems}
            </div>
          </div>
        </PerfectScrollbar>
      </Fragment>
      }
      {!props.isScrollable && 
        <div className={styles['scrollable-area']} style={{ 'width': props.width }}>
          <div className={styles['header']}>
            {headerRowItems}
            {props.items['header']['buttons'] && props.items['header']['buttons'].length > 0 &&
              <div className={styles['button-area']}>
                {generateHeaderButtons()}
              </div>
            }
          </div>
          <div className={styles['body']}>
            {bodyRowItems}
          </div>
        </div>
      }
    </div>
  )
}

export default MiddleScrollableContent
