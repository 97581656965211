import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DateInput.module.css';
import './DateInputPicker.css';

const DateInput = (props) => {
  const isDisabled = props.isDisabled ? true : false;
  const sizeStyle = props.size === undefined ? styles['base'] : styles[props.size];
  const errorStyle = props.hasError ? styles['error'] : '';

  const customStyle = {};
  if (props.width !== undefined) {
    customStyle['width'] = props.width
  }

  const datePickerBlurHandler = (event) => {
    if (props.onBlur !== undefined) {
      props.onBlur(event.target.value);
    }
  }

  return (
    <div className={styles['input-container']} style={customStyle}>
      { props.label && <label className={styles['input-label']}>{props.label}</label> }
      <div className={`${styles['input-wrapper']} ${sizeStyle}`}>
        <DatePicker
          id={props.id}
          className={`${styles['date-input']} ${sizeStyle} ${errorStyle}`}
          selected={props.selected}
          onChange={(date) => props.onChange(date)}
          onBlur={datePickerBlurHandler}
          dateFormat='dd / MM / yyyy'
          placeholderText={'DD / MM / YYYY'}
          disabled={isDisabled}
          formatWeekDay={nameOfDay => nameOfDay.substring(0,1)}
        />
        <div className={styles['calendar-icon']}></div>
      </div> 
    </div>
  )
}

export default DateInput
