import { Fragment } from 'react';
import styles from './LinkButton.module.css';

const LinkButton = (props) => {
  const isDisabled = props.isDisabled ? true : false;
  const sizeStyle = props.size === undefined ? styles['base'] : styles[props.size];
  const underlineStyle = (props.hasUnderline === undefined || props.hasUnderline === false) ? styles['no-underline'] : '';

  const clickHandler = () => {
    if(props.onClick) {
      props.onClick();
    }
  }

  const customStyle = {};
  if (props.width !== undefined) {
    customStyle['width'] = props.width
  }

  return (
    <Fragment>
      <div className={styles['link-button-container']} style={customStyle}>
        <button className={`${styles[props.type]} ${sizeStyle} ${underlineStyle}`} disabled={isDisabled} onClick={clickHandler}>
          {props.children}
        </button>
      </div>
    </Fragment>
  )
}

export default LinkButton
