import { useState } from 'react';
import { countryDropdownItems, investorAUMDropdownItems, investorInvestmentStyleDropdownItems, investorTypeDropdownItems } from '../../../../../utils/constants';
import { getValueFromVariable } from '../../../../../utils/helpers';
import { getClientAvailableModules } from '../../../../../utils/auth';
import { Tooltip } from 'react-tooltip';

import Button from '../../../../../components/UI/Button/Button';
import Dropdown from '../../../../../components/UI/Dropdown/Dropdown';
import SectionInfoBox from '../SectionInfoBox/SectionInfoBox';

import summaryPageStyles from '../InvestorSummary.module.css';

const InvestorSummaryGeneralInfoSection = (props) => {

  const clientAvailableModules = getClientAvailableModules();

  const isCustomInvestor = props.isCustomInvestor;
  const [generalInformationData, setGeneralInformationData] = useState(props.generalInformationData);
  const [isEditMode, setIsEditMode] = useState(false);

  const [investorType, setInvestorType] = useState(getValueFromVariable(generalInformationData['investor_type']));
  const [investmentStyle, setInvestmentStyle] = useState(getValueFromVariable(generalInformationData['investment_style']));
  const [aum, setAUM] = useState(getValueFromVariable(generalInformationData['aum']));
  const [country, setCountry] = useState(getValueFromVariable(generalInformationData['country']));
  const [website, setWebsite] = useState(getValueFromVariable(generalInformationData['website']));
  const [levelOfInsight, setLevelOfInsight] = useState(getValueFromVariable(generalInformationData['level_of_insight']));

  const levelOfInsightDropdownItems = [
    {
      'id': 'level-of-insight-strong',
      'value': 'Strong',
      'width': '130px',
      'headerColor': 'success',
      'chipColor': 'success'
    },
    {
      'id': 'level-of-insight-standard',
      'value': 'Standard',
      'width': '130px',
      'headerColor': 'warning',
      'chipColor': 'warning'
    },
    {
      'id': 'level-of-insight-weak',
      'value': 'Weak',
      'width': '130px',
      'headerColor': 'danger',
      'chipColor': 'danger'
    },
    {
      'id': 'relationship-status-unknown',
      'value': 'Unknown',
      'width': '130px',
      'headerColor': 'unknown',
      'chipColor': 'unknown'
    }
  ];

  const levelOfInsightLevel1Style = generalInformationData !== null && 
    (generalInformationData['level_of_insight'] === 'Weak' || 
      generalInformationData['level_of_insight'] === 'Standard' || 
      generalInformationData['level_of_insight'] === 'Strong');

  const levelOfInsightLevel2Style = generalInformationData !== null && 
      (generalInformationData['level_of_insight'] === 'Standard' || 
        generalInformationData['level_of_insight'] === 'Strong');
  
  const levelOfInsightLevel3Style = generalInformationData !== null && 
      generalInformationData['level_of_insight'] === 'Strong';

  const cancelEditHandler = () => {
    setIsEditMode(false);
    setInvestorType(getValueFromVariable(generalInformationData['investor_type']));
    setInvestmentStyle(getValueFromVariable(generalInformationData['investment_style']));
    setAUM(getValueFromVariable(generalInformationData['aum']));
    setCountry(getValueFromVariable(generalInformationData['country']));
    setWebsite(getValueFromVariable(generalInformationData['website']));
  }

  const updateGeneralInformationHandler = () => {
    const updatedGeneralInformationData = {
      'type': investorType,
      'investment_style': investmentStyle,
      'aum': aum,
      'country': country,
      'website': website,
      'level_of_insight': levelOfInsight,
      'profile_picture_url': props.profilePictureUrl
    };
    setGeneralInformationData(updatedGeneralInformationData);
    setIsEditMode(false);
    props.onSaveClick(updatedGeneralInformationData);
  }

  const investorLogoChangeHandler = (event) => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if(file.size > 500000){
        alert('File size should be smaller than 450Kb');
        return false;				
      }

      var data = new FormData();
			data.append('profile_picture', file, file.name);
      props.onProfilePictureUploadClick(data);
    }
  }

  return (
    <div className={summaryPageStyles['section']}>
      { isCustomInvestor && isEditMode && 
        <div className={summaryPageStyles['upload-investor-logo-container']}>
          <div className={summaryPageStyles['photo']} style={{backgroundImage:`url("${props.profilePictureUrl}")`}}></div>
          <label className={summaryPageStyles['new-investor-logo-upload-button']}>
            <div className={summaryPageStyles['plus-icon']}></div>
            <input type='file' accept='image/gif, image/jpeg, image/png' onChange={investorLogoChangeHandler} />
          </label>
        </div>
      }

      <div className={summaryPageStyles['section-title']}>
        <h3>General Info</h3>
      </div>
      { isCustomInvestor && !isEditMode && 
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='secondary' leftIcon='edit' onClick={() => setIsEditMode(true)}>Edit</Button>
        </div>
      }
      { isCustomInvestor && isEditMode && 
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='cancel' onClick={cancelEditHandler}>Cancel</Button>
          <Button size='xsmall' type='primary' onClick={updateGeneralInformationHandler}>Save</Button>
        </div>
      }

      <div className={`${summaryPageStyles['level-of-insights-container']} ${!clientAvailableModules['engagements'] ? summaryPageStyles['no-engagements-module'] : ''}`}>
        {!isEditMode && 
          <div className={summaryPageStyles['value']}>
            <div className={`${summaryPageStyles['first-level']} ${levelOfInsightLevel1Style && summaryPageStyles['selected']}`}></div>
            <div className={`${summaryPageStyles['second-level']} ${levelOfInsightLevel2Style && summaryPageStyles['selected']}`}></div>
            <div className={`${summaryPageStyles['third-level']} ${levelOfInsightLevel3Style && summaryPageStyles['selected']}`}></div>
            <div className={summaryPageStyles['text']}>
              <p>{generalInformationData['level_of_insight']}</p>
            </div>
          </div>
        }
       
        {isEditMode && 
          <div className={summaryPageStyles['value']}>
            <Dropdown
              id='investor-relationship-status'
              size='small'
              width='130px'
              items={levelOfInsightDropdownItems}
              itemContainerWidth='200px'
              itemContainerFloat='right'
              selected={levelOfInsight}
              onItemClick={setLevelOfInsight}
              hasChip={true}
              hasHeaderColor={true}
            />
          </div>
        }

        <div className={summaryPageStyles['tooltip-icon']} data-tooltip-id='level-of-insights-tooltip'>
          <Tooltip
            id='level-of-insights-tooltip'
            place='bottom'
            content='This is a categorization of the level of insight provided by this investor based on the quantity and quality of disclosures available in the public domain as determined by AQTION. Further information can be found in the glossary.'
            className='aqtion-tooltip'
          />
        </div>
        <div className={summaryPageStyles['title']}>
          <h4>Level of Insight</h4>
        </div>
      </div>

      <div className={`${summaryPageStyles['content']} ${isEditMode && summaryPageStyles['edit-mode']}`}>
        <SectionInfoBox 
          id='investor#type'
          size='single-col'
          type={!isEditMode ? 'text' : 'dropdown'}
          label='Investor Type'
          dropdownItems={investorTypeDropdownItems}
          value={investorType}
          onInputChange={setInvestorType}
          hasTooltip={true}
          tooltipContent='Each investor is categorized by type, such as asset manager, pension fund, hedge fund, etc. Further information can be found in the glossary.'
        />
        <SectionInfoBox
          id='investor#investment-style'
          size='single-col'
          type={!isEditMode ? 'text' : 'dropdown'}
          label='Investment Style'
          dropdownItems={investorInvestmentStyleDropdownItems}
          value={investmentStyle}
          onInputChange={setInvestmentStyle}
          hasTooltip={true}
          tooltipContent='This field indicates the investment style of the investor, which AQTION categorizes as either passive or active. Further information can be found in the glossary.'
        />
        <SectionInfoBox
          id='investor#aum'
          size='single-col'
          type={!isEditMode ? 'text' : 'dropdown'}
          label='AUM ($)'
          dropdownItems={investorAUMDropdownItems}
          value={aum}
          onInputChange={setAUM}
        />
        <SectionInfoBox
          id='investor#country'
          size='single-col'
          type={!isEditMode ? 'text' : 'dropdown'}
          label='Country'
          dropdownItems={countryDropdownItems}
          hasSearchBox={true}
          value={country}
          onInputChange={setCountry}
        />
        <SectionInfoBox
          id='investor#website'
          size='single-col'
          type={!isEditMode ? 'url' : 'text-input'}
          label='Website'
          value={website}
          onInputChange={setWebsite}
        />
      </div>
    
      <div className={summaryPageStyles['separator']}></div>
    </div>
  );
}

export default InvestorSummaryGeneralInfoSection;
